import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Box, Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import { LoadingOverlay } from 'common/components'

import MaterialTable from 'material-table'

import { Header } from '../layout'
import { companiesGetRequest } from 'common/actions'
import {
  getError,
  getLoading,
  getCompanies,
  getSuccess,
} from 'common/reducers/companies'
import getCompanyTypeLabel from 'common/utils/getCompanyTypeLabel'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  fullWidth: {
    width: '100%',
  },
}))

const Clients = ({ error, loading, companies, onRequestCompanies }) => {
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    onRequestCompanies()
  }, [])

  return (
    <>
      {loading && <LoadingOverlay />}
      <Header />
      <Container className={classes.container}>
        <MaterialTable
          columns={[
            { title: 'Número', field: 'clientNumber' },
            { title: 'Nombre', field: 'name' },
            {
              title: 'Ubicación',
              render: (rowData) => (
                <Box>
                  <Box>{rowData.province.name}</Box>
                  <Box>{rowData.country.name}</Box>
                </Box>
              ),
            },
            {
              title: 'Responsable',
              render: (rowData) =>
                `${rowData.contactName} ${rowData.contactLastName}`,
            },
            {
              title: 'Contacto',
              render: (rowData) => (
                <Box>
                  <Box>{rowData.contactPhone}</Box>
                  <Box>{rowData.contactEmail}</Box>
                </Box>
              ),
            },
            {
              title: 'Tipo',
              field: 'companyType',
              render: (rowData) => getCompanyTypeLabel(rowData.companyType),
            },
          ]}
          data={companies}
          title='Clientes'
          actions={[
            {
              icon: () => <AddCircleIcon color='primary' />,
              tooltip: 'Nuevo Cliente',
              position: 'toolbar',
              isFreeAction: true,
              onClick: () => {
                history.push('/clients/new')
              },
            },
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'filas',
              labelRowsPerPage: 'Filas por página: ',
            },
            toolbar: {
              searchPlaceholder: 'Búsqueda',
              searchTooltip: 'Búsqueda',
            },
            header: {
              actions: 'Acciones',
            },
            body: {
              emptyDataSourceMessage: 'No hay clientes',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
              editTooltip: 'Editar',
            },
          }}
        />
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    companies: getCompanies(state),
    success: getSuccess(state),
  }
}

export default connect(mapStateToProps, {
  onRequestCompanies: companiesGetRequest,
})(Clients)
