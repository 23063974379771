import * as ActionTypes from '../actions'
import * as R from 'ramda'

const initialState = {
  user: null,
  error: null,
  loading: false,
  tokens: null,
  forgotpwSuccess: false,
  users: null,
  users_api: null,
  cognitoUser: null,
  success: false,
}

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.USER_LOGIN_RESET:
      return {
        ...state,
        user: null,
        error: null,
        loading: false,
        tokens: null,
        cognitoUser: null,
      }
    case ActionTypes.USER_LOGIN_REQUEST:
    case ActionTypes.USER_PW_CHALLENGE_REQUEST:
      return { ...state, error: null, user: null, loading: true, tokens: null }
    case ActionTypes.USER_LOGIN_SUCCESS:
    case ActionTypes.USER_PW_CHALLENGE_SUCCESS:
      return {
        ...state,
        error: null,
        user: action.user,
        loading: false,
      }
    case ActionTypes.USER_LOGIN_FAILURE:
    case ActionTypes.USER_PW_CHALLENGE_FAILURE:
      return {
        ...state,
        error: action.error,
        cognitoUser: action.error.cognitoUser,
        loading: false,
      }
    case ActionTypes.USER_REGISTRATION_RESET:
      return { ...state, error: null, loading: false, user: null }
    case ActionTypes.USER_REGISTRATION_REQUEST:
    case ActionTypes.USER_ADMIN_REGISTRATION_REQUEST:
    case ActionTypes.USER_ADMIN_CHANGE_STATUS_REQUEST:
      return { ...state, error: null, loading: true }
    case ActionTypes.USER_REGISTRATION_SUCCESS:
    case ActionTypes.USER_ADMIN_REGISTRATION_SUCCESS:
    case ActionTypes.USER_ADMIN_CHANGE_STATUS_SUCCESS:
      return { ...state, error: null, loading: false, success: true }
    case ActionTypes.USER_REGISTRATION_FAILURE:
    case ActionTypes.USER_ADMIN_REGISTRATION_FAILURE:
    case ActionTypes.USER_ADMIN_CHANGE_STATUS_FAILURE:
      return { ...state, error: action.error, loading: false }
    case ActionTypes.USER_FORGOTPW_RESET:
      return { ...state, error: null, loading: false, forgotpwSuccess: false }
    case ActionTypes.USER_FORGOTPW_REQUEST:
      return { ...state, error: null, loading: true }
    case ActionTypes.USER_FORGOTPW_SUCCESS:
      return { ...state, error: null, loading: false, forgotpwSuccess: true }
    case ActionTypes.USER_FORGOTPW_FAILURE:
      return { ...state, error: action.error, loading: false }
    case ActionTypes.USER_GET_REQUEST: // api call
      return { ...state, loading: true, users_api: null }
    case ActionTypes.USERS_GET_REQUEST: // cognito
      return { ...state, loading: true, users: null }
    case ActionTypes.USERS_GET_SUCCESS: // cognito
      return { ...state, loading: false, users: R.flatten(action.data) }
    case ActionTypes.USERS_GET_FAILURE: // cognito
    case ActionTypes.USER_GET_FAILURE: // api call
    case ActionTypes.USER_CREATE_FAILURE:
    case ActionTypes.USER_UPDATE_FAILURE:
      return { ...state, loading: false, error: action.error }
    case ActionTypes.USER_GET_SUCCESS: // api call
      return { ...state, loading: false, users_api: action.user }
    case ActionTypes.USER_CREATE_REQUEST:
    case ActionTypes.USER_UPDATE_REQUEST:
      return { ...state, loading: true, success: false }
    case ActionTypes.USER_CREATE_RESET:
      return { ...state, loading: false, success: false }
    case ActionTypes.USER_CREATE_SUCCESS:
    case ActionTypes.USER_UPDATE_SUCCESS:
      return { ...state, loading: false, success: true }
    default:
      return state
  }
}

export function getSuccess(state) {
  return state.userReducer.success
}

export function getUsersApi(state) {
  return state.userReducer.users_api
}

export function getUsers(state) {
  return state.userReducer.users
}

export function getForgotPwSuccess(state) {
  return state.userReducer.forgotpwSuccess
}

export function getUser(state) {
  return state.userReducer.user
}

export function getError(state) {
  return state.userReducer.error
}

export function getLoading(state) {
  return state.userReducer.loading
}
