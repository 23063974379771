import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  overlay: {
    height: '100vh',
    width: '100%',
    opacity: 0.5,
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
  },
  loader: {
    zIndex: 101,
    opacity: 1,
  },
}))

export default function LoadingOverlay() {
  const classes = useStyles()
  return (
    <Box className={classes.overlay}>
      <CircularProgress className={classes.loader} />
    </Box>
  )
}
