export const convertToBase64Image = (file) => {
  const reader = new FileReader()
  reader.readAsDataURL(file.Body)
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result)
    }
  })
}

export const encode = (data) => {
  var str = data.reduce(function (a, b) {
    return a + String.fromCharCode(b)
  }, '')
  return btoa(str).replace(/.{76}(?=.)/g, '$&\n')
}
