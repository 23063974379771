import * as ActionTypes from '../actions'
import * as R from 'ramda'

const initialState = {
  patient: null,
  loading: false,
  error: null,
  healthRecords: null,
  healthRecordDownload: null,
  success: false,
  patientSearch: null,
}

export function patientsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PATIENT_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        patient: null,
      }
    case ActionTypes.PATIENT_SEARCH_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        patientSearch: null,
      }
    case ActionTypes.PATIENT_CREATE_RESET:
      return {
        ...state,
        error: null,
        loading: false,
        patient: null,
        success: false,
      }
    case ActionTypes.PATIENT_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case ActionTypes.PATIENT_SEARCH_RESET:
      return {
        ...state,
        error: null,
        loading: false,
        patientSearch: null,
      }
    case ActionTypes.PATIENT_HEALTH_RECORD_DOWNLOAD_RESET:
      return {
        ...state,
        error: null,
        loading: false,
        healthRecordDownload: null,
      }
    case ActionTypes.PATIENT_HEALTH_RECORD_DOWNLOAD_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        healthRecordDownload: null,
      }
    case ActionTypes.PATIENT_HEALTH_RECORD_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        healthRecords: null,
        success: false,
      }
    case ActionTypes.PATIENT_HEALTH_RECORD_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      }
    case ActionTypes.PATIENT_CREATE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        success: true,
        patient: action.data,
      }
    case ActionTypes.PATIENT_HEALTH_RECORD_DOWNLOAD_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        healthRecordDownload: action.data,
      }
    case ActionTypes.PATIENT_HEALTH_RECORD_GET_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        healthRecords: action.data,
      }
    case ActionTypes.PATIENT_HEALTH_RECORD_CREATE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        success: true,
      }
    case ActionTypes.PATIENT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        patient: action.data,
      }
    case ActionTypes.PATIENT_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        patientSearch: Array.isArray(action.data)
          ? action.data
          : action.data
          ? [action.data]
          : null,
      }
    case ActionTypes.PATIENT_HEALTH_RECORD_DOWNLOAD_SUCCESS:
    case ActionTypes.PATIENT_HEALTH_RECORD_GET_SUCCESS:
    case ActionTypes.PATIENT_HEALTH_RECORD_CREATE_SUCCESS:
    case ActionTypes.PATIENT_GET_FAILURE:
    case ActionTypes.PATIENT_SEARCH_FAILURE:
    case ActionTypes.PATIENT_CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export function getPatient(state) {
  return state.patientsReducer.patient
}

export function getPatientSearch(state) {
  return state.patientsReducer.patientSearch
}

export function getLoading(state) {
  return state.patientsReducer.loading
}

export function getError(state) {
  return state.patientsReducer.error
}

export function getSuccess(state) {
  return state.patientsReducer.success
}

export function getHealthRecords(state) {
  return state.patientsReducer.healthRecords
}

export function getHealthRecordDownload(state) {
  return state.patientsReducer.healthRecordDownload
}
