export const phoneNumberRegex = /^[\+|\d]\d+$/
export const positiveDecimalsRegex = /^\d*(\.\d+)?$/

export const CompanyTypes = {
  MEDITAR: 'meditar',
  PARTNER: 'partner',
  RESELLER: 'reseller',
  CLIENT: 'client',
}

export const NotificationTypes = {
  NEW_APPOINTMENT: 'appointment_new',
}

export const ER_Header = {
  ENABLED: 'ER_STATUS_ON',
  DISABLED: 'ER_STATUS_OFF',
  PATIENT_NEW: 'ER_PATIENT_NEW',
  PATIENT_TAKEN: 'ER_PATIENT_TAKEN',
  APPOINTMENT_NEW: 'ER_APPOINTMENT_START',
  PROVIDER_ACCEPT: 'ER_PROVIDER_ACCEPT',
  PROVIDER_ACCEPT_DELAYED: 'ER_PROVIDER_ACCEPT_DELAYED',
  PROVIDER_IGNORE: 'ER_PROVIDER_IGNORE',
}

export const ER_Status_Types = {
  IDLE: 'AUTO_IDLE',
  CONN: 'AUTO_CONN_PROBLEM',
}

export const Topics = {
  ER: 'provider/ER',
  CLIENT: 'client',
}

export const ER_Modes = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  SNOOZED: 'SNOOZED',
  DISABLED_IDLE: 'DISABLED_IDLE',
  DISABLED_CONN: 'DISABLED_CONN',
}
