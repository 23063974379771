import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import MaterialTable from 'material-table'
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Header } from '../layout'
import {
  dealGetRequest,
  providerGetRequest,
  dealEditRequest,
} from 'common/actions'
import {
  getError,
  getLoading,
  getDeal,
  getSuccess,
} from 'common/reducers/deals'
import { getProviders } from 'common/reducers/providers'
import { LoadingButton } from 'common/components'
import { positiveDecimalsRegex } from 'common/utils/constants'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  fullWidth: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  backButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  saveBtn: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  inputsContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputsPaper: {
    padding: theme.spacing(3),
  },
  allowsER: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}))

const EditDeal = ({
  error,
  loading,
  deal,
  onRequestDeal,
  success,
  onRequestProviders,
  onEditDeal,
  providers,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const [allProviders, setAllProviders] = useState([])
  const tableRef = useRef()
  const [name, setName] = useState('')
  const [erPrice, setErPrice] = useState(0.0)
  const [allowsER, setAllowsER] = useState(false)

  useEffect(() => {
    onRequestDeal(id)
    setErPrice(0.0)
    onRequestProviders({ params: { pendingValidation: false } })
  }, [id])

  useEffect(() => {
    if (!providers || !deal) return
    setName(deal.name)
    setErPrice(deal.erPrice < 0 ? '0.0' : deal.erPrice || '0.0')
    setAllowsER(deal.erPrice >= 0)
    setAllProviders(
      providers.map((p, i) => {
        const dealProvider = R.find(R.propEq('id', p.id), deal.providers)
        return {
          ...p,
          dealConsultationPrice: dealProvider?.price || 0,
          modified: false,
          tableData: {
            id: i,
            checked: !!dealProvider,
          },
        }
      })
    )
  }, [providers, deal])

  const erPriceValid = erPrice.toString().match(positiveDecimalsRegex)

  useEffect(() => {
    if (!success || R.equals(success.id, id)) return
    history.push('/deals')
  }, [success])

  const submit = () => {
    const selected = R.filter(
      (d) => d.tableData.checked,
      tableRef.current.dataManager.data
    )

    const existingProviders = R.reduce(
      (acc, val) => ({
        ...acc,
        [val.id]: true,
      }),
      {},
      deal.providers
    )

    const newOrUpdatedProviders = R.filter(
      (p) => !existingProviders[p.id] || p.modified,
      selected
    )
    const selectedProvidersObj = R.reduce(
      (acc, val) => ({
        ...acc,
        [val.id]: true,
      }),
      {},
      selected
    )
    const deletedProviders = R.filter(
      (p) => !selectedProvidersObj[p.id],
      deal.providers
    )

    onEditDeal({
      id,
      updateProviders: newOrUpdatedProviders.map((p) => ({
        id: p.id,
        price: p.dealConsultationPrice,
      })),
      deleteProviders: deletedProviders.map((p) => p.id),
      erPrice: allowsER ? erPrice : -1,
    })
  }

  return (
    <>
      <Header />
      <Container className={classes.container}>
        <Button
          href='/admin/deals'
          component='a'
          className={classes.backButton}
          variant='outlined'
        >
          VOLVER
        </Button>
        <Typography variant='h5'>Editando Convenio: {deal?.name}</Typography>
        <Box className={classes.inputsContainer}>
          <Paper className={classes.inputsPaper}>
            <Typography variant='h6'>Configuracion</Typography>
            <Box className={classes.allowsER}>
              <FormControl component='fieldset'>
                <FormLabel component='legend'>¿Permite guardia?</FormLabel>
                <RadioGroup
                  onChange={(e) => {
                    setAllowsER(e.target.value === 'true' ? true : false)
                    setErPrice('0.0')
                  }}
                  value={allowsER}
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label='Si'
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <TextField
              label='Precio de Guardia'
              disabled={!allowsER}
              value={erPrice}
              error={!erPriceValid}
              helperText={
                !erPriceValid &&
                'El precio debe ser un numero decimal mayor o igual a cero'
              }
              onChange={(e) => setErPrice(e.target.value)}
            />
          </Paper>
        </Box>
        <MaterialTable
          tableRef={tableRef}
          columns={[
            { title: 'Nombre', field: 'name', editable: 'never' },
            { title: 'Apellido', field: 'lastName', editable: 'never' },
            {
              title: 'Precio de Consulta',
              field: 'consultationPrice',
              type: 'numeric',
              editable: 'never',
            },
            {
              title: 'Precio Convenio',
              field: 'dealConsultationPrice',
              type: 'numeric',
            },
          ]}
          data={allProviders || []}
          title='Profesionales'
          options={{
            selection: true,
            actionsColumnIndex: -1,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'filas',
              labelRowsPerPage: 'Filas por página: ',
            },
            toolbar: {
              nRowsSelected: '{0} profesional(es) seleccionados',
              searchPlaceholder: 'Búsqueda',
              searchTooltip: 'Búsqueda',
            },
            header: {
              actions: 'Acciones',
            },
            body: {
              emptyDataSourceMessage: 'No hay profesionales',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
              editTooltip: 'Editar',
            },
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                if (newData.dealConsultationPrice < 0) {
                  reject()
                  return
                }
                const index = R.findIndex(
                  R.propEq('id', oldData.id),
                  allProviders
                )
                setAllProviders(
                  R.update(
                    index,
                    {
                      ...oldData,
                      dealConsultationPrice: newData.dealConsultationPrice,
                      modified: true,
                    },
                    allProviders
                  )
                )
                resolve()
              }),
          }}
        />
        <Box className={classes.saveBtn}>
          <LoadingButton
            onClick={() => submit()}
            variant='contained'
            color='primary'
            disabled={!name.length || (erPrice > 0 && !erPriceValid)}
            loading={loading}
          >
            GUARDAR CAMBIOS
          </LoadingButton>
        </Box>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    deal: getDeal(state),
    success: getSuccess(state),
    providers: getProviders(state),
  }
}

export default connect(mapStateToProps, {
  onRequestDeal: dealGetRequest,
  onRequestProviders: providerGetRequest,
  onEditDeal: dealEditRequest,
})(EditDeal)
