import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import dayjs from 'dayjs'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction' // for selectable
import timeGridPlugin from '@fullcalendar/timegrid'
import esLocale from '@fullcalendar/core/locales/es'
import clsx from 'clsx'

import {
  Box,
  Grid,
  Link,
  Button,
  Popover,
  Container,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  Chip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ScheduleSlotModal from './scheduleSlotModal'
import { Header } from '../layout'
import { LoadingButton, LoadingOverlay } from 'common/components'

import {
  requestAppointments,
  providerScheduleMultipleCreateRequest,
  providerScheduleGetRequest,
  requestAssistantSpecialtiesGet,
  providerScheduleDeleteRequest,
  multipleFileGetRequest,
} from 'common/actions'
import { getAppointments } from 'common/reducers/appointment'
import {
  getLoading,
  getSuccess,
  getSchedules,
  getError,
} from 'common/reducers/providers'
import { getSpecialties, getProviders } from 'common/reducers/assistant'
import { getFiles } from 'common/reducers/files'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(6),
  },
  backBtn: {
    marginRight: theme.spacing(2),
  },
  selectedEventPopover: {
    padding: theme.spacing(3),
  },
  inputBox: {
    marginBottom: theme.spacing(3),
  },
  eventDuration: {
    marginTop: theme.spacing(1),
  },
  title: {},
  providerBox: {
    flex: '1',
    marginLeft: theme.spacing(1),
  },
  appointment: {
    backgroundColor: 'red',
    color: '#fff',
    textAlign: 'center',
    width: '100%',
    cursor: 'not-allowed',
  },
  schedule: {
    cursor: 'pointer',
    width: '100%',
    textAlign: 'center',
  },
  leftContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  list: {
    height: '600px',
    overflowY: 'auto',
  },
  calendarOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: '4px',
    cursor: 'disabled',
  },
  calendarContainer: {
    position: 'relative',
    overflow: 'hidden',
  },
  chip: {
    margin: '0.1rem',
  },
  chipsContainer: {
    display: 'block',
  },
  titleAvatar: {
    display: 'inline',
  },
  removedSchedule: {
    cursor: 'not-allowed',
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'yellow',
  },
}))

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const Schedule = ({
  createMultipleSchedule,
  onRetrieveSchedules,
  loading,
  success,
  specialties,
  providers,
  onRetrieveSpecialties,
  deleteSchedules,
  schedules,
  appointments,
  onRetrieveAppointments,
  multipleFileGet,
  files,
  error,
}) => {
  const classes = useStyles()
  const calendarRef = useRef()
  const [events, setEvents] = useState([])
  const [selectedEventId, setSelectedEventId] = useState(null)
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)
  const [selectedInfo, setSelectedInfo] = useState(null)
  const [initialDates, setInitialDates] = useState(null)
  const [lastDates, setLastDates] = useState(null)
  const [loadedAppointments, setLoadedAppointments] = useState({})
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [pendingChanges, setPendingChanges] = useState(false)
  const [pendingChangesModal, setPendingChangesModal] = useState(false)
  const [providerToBeSelected, setProviderToBeSelected] = useState(null)

  const [search, setSearch] = useState('')

  useEffect(() => {
    setSelectedProvider(null)
    if (!specialties?.length || !providers?.length) onRetrieveSpecialties()
  }, [])

  useEffect(() => {
    setSelectedProvider(null)
    if (!providers) return
    multipleFileGet(
      R.filter(
        (p) =>
          p.profilePicture &&
          p.profilePicture.key &&
          !files[p.profilePicture.key],
        providers
      ).map((p) => p.profilePicture)
    )
  }, [providers])

  useEffect(() => {
    if (
      !schedules ||
      !schedules.length ||
      !specialties?.length ||
      !selectedProvider
    )
      return

    const loadedSchedules = R.reduce(
      (acc, val) => {
        return {
          ...acc,
          [val.id]: val,
        }
      },
      {},
      R.filter((p) => !p.appointment && !p.removed, events)
    )

    setEvents(
      R.flatten([
        ...events,
        R.filter((s) => !loadedSchedules[s.timestamp], schedules).map((s) => ({
          id: s.timestamp,
          duration: s['length'],
          start: dayjs(s.timestamp * 1000)
            .tz(selectedProvider.timezone)
            .format(),
          end: dayjs(s.timestamp * 1000)
            .add(s['length'], 'minutes')
            .tz(selectedProvider.timezone)
            .format(),
          display: 'background',
          specialties: s.specialties.map((specialty) =>
            R.find((sp) => R.equals(sp.id, specialty), specialties || [])
          ),
          exists: true,
        })),
      ])
    )
  }, [schedules, specialties])

  useEffect(() => {
    if (!R.valuesIn(appointments)?.length || !selectedProvider) return
    setEvents(
      R.flatten([
        ...events,
        R.filter(
          (a) => !loadedAppointments[a.timestamp],
          R.valuesIn(appointments)
        ).map((a) => ({
          start: dayjs(a.timestamp * 1000)
            .tz(selectedProvider.timezone)
            .format(),
          end: dayjs(a.timestamp * 1000)
            .add(a['length'], 'minutes')
            .tz(selectedProvider.timezone)
            .format(),
          id: a.timestamp,
          duration: a['length'],
          appointment: true,
          display: 'background',
        })),
      ])
    )

    setLoadedAppointments(appointments)
  }, [appointments])

  useEffect(() => {
    setEvents([])
    setScheduleModalOpen(false)
    if (!selectedProvider) {
      return
    }
    setPendingChanges(false)
    onRetrieveSchedules({ providerId: selectedProvider.id })
    setLastDates(null)
    setLoadedAppointments({})
    if (initialDates) {
      refreshDate(initialDates.fromDate, initialDates.toDate)
      setInitialDates(null)
    }
  }, [selectedProvider])

  useEffect(() => {
    if (!success || !selectedProvider) return
    // for refreshing after saving
    setEvents(
      R.filter((e) => (e.exists && !e.removed) || e.appointment, events)
    )
    onRetrieveSchedules({ providerId: selectedProvider.id })
  }, [success])

  const refreshDate = (fromDate, toDate) => {
    if (!initialDates) {
      setInitialDates({ fromDate, toDate })
      return
    }
    if (
      !selectedProvider ||
      (R.equals(lastDates?.fromDate, fromDate) &&
        R.equals(lastDates?.toDate, toDate))
    )
      return
    onRetrieveAppointments({
      providerId: selectedProvider.id,
      from: fromDate,
      to: toDate,
    })
    setLastDates({ fromDate, toDate })
  }

  const onSave = () => {
    setPendingChanges(false)
    setSelectedEventId(null)
    const newOnes = R.filter((s) => !s.exists && !s.appointment, events)

    if (newOnes.length)
      createMultipleSchedule({
        providerId: selectedProvider.id,
        schedules: newOnes.map((s) => ({
          fromDatetime: dayjs(s.start).utc().format(),
          toDatetime: dayjs(s.end).utc().format(),
          length: s.duration,
          specialties: s.specialties.map((specialty) => specialty.id),
        })),
      })

    const deletedOnes = R.filter((s) => s.removed, events)
    if (deletedOnes.length)
      deleteSchedules({
        providerId: selectedProvider.id,
        schedules: deletedOnes.map((d) => d.id),
      })
  }

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
  }, [])

  const nextId = () =>
    R.reduce((acc, value) => (value.id > acc ? value.id : acc), 0, events) + 1

  const updateEvent = (data) => {
    setPendingChanges(true)
    const index = R.findIndex((s) =>
      R.equals(parseInt(data.id), parseInt(s.id))
    )(events)

    setEvents(
      R.update(
        index,
        {
          start: data.startStr || data.start,
          end: data.endStr || data.end,
          specialties: data.specialties || events[index].specialties,
          duration: data.duration || events[index].duration,
          id: events[index].id,
          ...data,
        },
        events
      )
    )
  }

  const removeEvent = (data) => {
    setPendingChanges(true)
    setEvents(
      R.remove(
        R.findIndex((s) => R.equals(parseInt(selectedEventId), parseInt(s.id)))(
          events
        ),
        1,
        events
      )
    )
    setSelectedEventId(null)
  }

  const addEvent = (data) => {
    setPendingChanges(true)
    setEvents(
      R.append(
        {
          ...data,
          id: nextId(),
        },
        events
      )
    )
  }

  if (selectedInfo) {
    const sameDayEvents = R.filter(
      (e) => dayjs(e.start).isSame(selectedInfo.end, 'day'),
      events
    )
    const minimumDurationEvent = R.filter(
      (sde) => dayjs(selectedInfo.end).isSameOrBefore(sde.start),
      sameDayEvents
    ).map((e) => dayjs(selectedInfo.end).diff(e.start, 'minutes'))

    const minimumDurationEventTime = R.reduce(
      R.max,
      -Infinity,
      minimumDurationEvent
    )
    selectedInfo.maximumDuration = 30 + minimumDurationEventTime * -1
  }

  return (
    <>
      {loading && <LoadingOverlay />}
      <Header />
      <Box className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography variant='h5'>Profesionales</Typography>
            <Box className={classes.leftContainer}>
              {providers == null && <CircularProgress />}
              {providers != null && (
                <TextField
                  label='Buscar Profesional'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className={classes.fullWidth}
                />
              )}
              <List className={clsx(classes.marginTop, classes.list)}>
                {R.filter(
                  (p) =>
                    p.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                    p.lastName.toLowerCase().indexOf(search.toLowerCase()) > -1,
                  R.sortBy(R.prop('lastName'), providers || [])
                ).map((provider) => (
                  <ListItem
                    key={provider.id}
                    button
                    alignItems='flex-start'
                    divider
                    onClick={() => {
                      if (pendingChanges) {
                        setProviderToBeSelected(provider)
                        setPendingChangesModal(true)
                      } else setSelectedProvider(provider)
                    }}
                    selected={provider === selectedProvider}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={files[provider.profilePicture?.key]?.Image}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${provider.title} ${provider.lastName}, ${provider.name}`}
                      secondary={
                        <Typography variant='body2' component='span'>
                          {provider.tagLine}
                          <Typography
                            component='span'
                            className={classes.chipsContainer}
                          >
                            {provider.specialties.map((specialty) => (
                              <Chip
                                className={classes.chip}
                                key={`${provider.id}_${specialty}`}
                                size='small'
                                variant='outlined'
                                color='primary'
                                label={
                                  R.find(
                                    (s) => R.equals(s.id, specialty),
                                    specialties
                                  )?.name
                                }
                              />
                            ))}
                          </Typography>
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            className={classes.calendarContainer}
            onClick={(e) => {
              if (selectedProvider) return
              e.stopPropagation()
              e.preventDefault()
              return false
            }}
          >
            <Box mb={3} display='flex' alignItems='center'>
              <Box flex='1'>
                {selectedProvider
                  ? `Horarios de `
                  : 'No hay profesional seleccionado'}
                <Box display='flex' alignItems='center'>
                  {selectedProvider && (
                    <>
                      <Avatar
                        src={files[selectedProvider.profilePicture?.key]?.Image}
                      />
                      <Box className={classes.providerBox}>
                        <Typography variant='h4' className={classes.title}>
                          {selectedProvider.title} {selectedProvider.name}{' '}
                          {selectedProvider.lastName}
                        </Typography>
                        {selectedProvider.timezone != browserTimezone && (
                          <Typography variant='body2'>
                            Atención: Los horarios a continuación se muestran en
                            la zona horaria del profesional.
                          </Typography>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              <LoadingButton
                disabled={!events.length}
                variant='contained'
                color='primary'
                onClick={() => onSave()}
                loading={loading}
              >
                GUARDAR CAMBIOS
              </LoadingButton>
            </Box>
            <Grid container>
              <ScheduleSlotModal
                timezone={selectedProvider?.timezone}
                isOpen={scheduleModalOpen && !!selectedProvider}
                onSubmit={(data) => {
                  if (selectedEventId)
                    updateEvent({ ...data, id: selectedEventId })
                  else addEvent(data)
                  setSelectedEventId(null)
                  setScheduleModalOpen(false)
                }}
                onClose={() => {
                  setSelectedEventId(null)
                  setScheduleModalOpen(false)
                }}
                specialties={
                  selectedProvider &&
                  R.filter(
                    (s) => R.includes(s.id, selectedProvider?.specialties),
                    specialties || []
                  )
                }
                schedule={
                  selectedEventId &&
                  R.find((s) =>
                    R.equals(parseInt(selectedEventId), parseInt(s.id))
                  )(events)
                }
                onDelete={() => {
                  const schedule = R.find((s) =>
                    R.equals(parseInt(selectedEventId), parseInt(s.id))
                  )(events)
                  if (schedule.exists)
                    updateEvent({ ...schedule, removed: true })
                  else removeEvent()
                  setScheduleModalOpen(false)
                }}
                info={selectedInfo}
              />
              <Grid item xs={12}>
                <FullCalendar
                  timeZone={selectedProvider?.timezone || browserTimezone}
                  locale={esLocale}
                  headerToolbar={{
                    left: 'prev,today,next',
                    center: 'title',
                    right: '',
                  }}
                  datesSet={(info) => refreshDate(info.startStr, info.endStr)}
                  validRange={(now) => ({ start: now })}
                  events={events}
                  plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                  initialView='timeGridWeek'
                  selectable={!!selectedProvider}
                  ref={calendarRef}
                  allDaySlot={false}
                  scrollTime='08:00:00'
                  selectAllow={(info) => {
                    return dayjs(dayjs(info.startStr).$d).isAfter(
                      dayjs().tz(selectedProvider?.timezone).$d
                    )
                  }}
                  select={(info) => {
                    setSelectedInfo(info)
                    setScheduleModalOpen(true)
                  }}
                  eventClick={(info) => {
                    const currentEvent = R.find(
                      (s) => R.equals(parseInt(s.id), parseInt(info.event.id)),
                      events
                    )
                    if (currentEvent.appointment || currentEvent.removed) return
                    setSelectedEventId(info.event.id)
                    setSelectedInfo(info.event)
                    setScheduleModalOpen(true)
                  }}
                  editable={!!selectedProvider}
                  selectOverlap={false}
                  eventOverlap={false}
                  eventChange={(info) => updateEvent(info.event)}
                  eventResize={(info) => updateEvent(info.event)}
                  eventContent={({ event }) => {
                    if (!event) return

                    const currentEvent = R.find(
                      (s) => R.equals(parseInt(s.id), parseInt(event.id)),
                      events
                    )
                    if (!currentEvent) return

                    if (currentEvent.removed) {
                      return (
                        <Box className={classes.removedSchedule}>Removido</Box>
                      )
                    }

                    if (currentEvent.exists) {
                      return <Box className={classes.schedule}>Disponible</Box>
                    }

                    if (currentEvent.appointment)
                      return (
                        <Box className={classes.appointment}>No Disponible</Box>
                      )

                    return (
                      <Box>
                        {currentEvent.specialties.map((s) => (
                          <Box key={s.id} fontWeight='600'>
                            - {s.name}
                          </Box>
                        ))}
                        <Box className={classes.eventDuration}>
                          Turnos de {currentEvent.duration} minutos
                        </Box>
                      </Box>
                    )
                  }}
                />
                <Box mt={4} textAlign='center' position='relative'>
                  <LoadingButton
                    disabled={!events.length}
                    variant='contained'
                    color='primary'
                    onClick={() => onSave()}
                    loading={loading}
                  >
                    GUARDAR CAMBIOS
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={pendingChangesModal}
        onClose={() => setPendingChangesModal(false)}
      >
        <DialogTitle>Cambios Pendientes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sus cambios no fueron guardados, ¿desea continuar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setPendingChangesModal(false)}
            color='primary'
            variant='contained'
          >
            Volver
          </Button>
          <Button
            onClick={() => {
              setSelectedProvider(providerToBeSelected)
              setPendingChangesModal(false)
            }}
            color='primary'
          >
            Continuar sin guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: getLoading(state),
    success: getSuccess(state),
    schedules: getSchedules(state),
    specialties: getSpecialties(state),
    providers: getProviders(state),
    appointments: getAppointments(state),
    files: getFiles(state),
    error: getError(state),
  }
}

export default connect(mapStateToProps, {
  createMultipleSchedule: providerScheduleMultipleCreateRequest,
  onRetrieveSchedules: providerScheduleGetRequest,
  onRetrieveSpecialties: requestAssistantSpecialtiesGet,
  deleteSchedules: providerScheduleDeleteRequest,
  onRetrieveAppointments: requestAppointments,
  multipleFileGet: multipleFileGetRequest,
})(Schedule)
