import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import {
  Box,
  TextField,
  Grid,
  CircularProgress,
  Snackbar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core'
import AttachFile from '@material-ui/icons/AttachFile'
import Save from '@material-ui/icons/Save'
import MuiAlert from '@material-ui/lab/Alert'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import DeleteIcon from '@material-ui/icons/Delete'

import theme from 'common/theme'

import { makeStyles } from '@material-ui/core/styles'
import {
  fileGetRequest,
  fileUploadRequest,
  prescriptionAppointment,
  multipleFileGetRequest,
  fileUploadReset,
  whatsappPrescriptionAppointmentRequest,
  requestAppointments,
  deletePrescriptionRequest,
} from 'common/actions'
import {
  getError,
  getLoading,
  getUploadResponse,
  getFiles,
} from 'common/reducers/files'

import {
  getAppointments,
  getSuccess,
  getLoading as getAppointmentLoading,
} from 'common/reducers/appointment'

import { getPatient } from 'common/reducers/patients'

import { LoadingButton } from 'common/components'
import { getClientResponse } from 'common/reducers/pubsub'
import { getCompany } from 'common/reducers/companies'
import { canUploadPrescription } from 'common/utils/appointmentStatus'

const useStyles = makeStyles((theme) => ({
  secondaryCard: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(3),
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  bold: {
    fontWeight: '600',
  },
  tab: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  textarea: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  patientCard: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  fileInput: {
    display: 'none',
  },
  whatsAppAttachment: {
    marginLeft: theme.spacing(2),
  },
  deleteBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'white',
    color: '#999',
    border: '1px solid #999',
    opacity: 0.7,
    '&:hover': {
      backgroundColor: 'white',
      opacity: 1,
      border: '1px solid red',
      color: '#f44336',
    },
  },
  gridBox: {
    position: 'relative',
  },
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const actions = {
  save: 'save',
  whatsapp: 'wapp',
  delete: 'delete',
}

const getSnackbarMessage = (action) => {
  switch (action) {
    case actions.save:
      return 'RECETAS GUARDADAS'
    case actions.whatsapp:
      return 'POR FAVOR REVISE WHATSAPP'
    case actions.delete:
      return 'RECETA ELIMINADA'
    default:
      return ''
  }
}

const AppointmentAttachments = ({
  onRequestFile,
  onResetFileUpload,
  onRequestUpload,
  onCreatePrescription,
  uploadResponse,
  loading,
  appointment,
  multipleFileGet,
  files,
  success,
  patient,
  onRequestPrescription,
  onDeletePrescription,
  clientResponse,
  onGetAppointments,
  company,
}) => {
  const classes = useStyles()

  const fileInputRef = useRef(null)
  const [prescriptionFiles, setPrescriptionFiles] = useState([])
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [action, setAction] = useState(actions.save)
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
  const [selectedPrescription, setSelectedPrescription] = useState(null)

  useEffect(() => {
    if (
      !uploadResponse ||
      !appointment ||
      !!R.find(
        R.propEq('key', uploadResponse.key),
        appointment?.prescription || []
      )
    )
      return
    const fileObj = {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      key: `private/${uploadResponse.key}`,
    }
    onCreatePrescription({
      timestamp: appointment.timestamp,
      provider: appointment.provider,
      files: [fileObj],
    })
    setAction(actions.save)
    onResetFileUpload()
  }, [uploadResponse])

  useEffect(() => {
    if (!appointment) return
    const newFiles = R.filter(
      (p) => !files[p.key],
      appointment.prescription || []
    )
    if (newFiles.length) multipleFileGet(newFiles)
    else
      setPrescriptionFiles(
        (appointment.prescription || []).map((p) => ({
          ...p,
          img: files[p.key]?.Image,
        }))
      )
    setSelectedPrescription(null)
    setDeleteConfirmationModal(false)
  }, [appointment])

  const refreshPrescriptions = () =>
    onGetAppointments({
      timestamp: appointment.timestamp,
      provider: appointment.provider,
    })

  useEffect(() => {
    if (
      !clientResponse ||
      clientResponse.header != 'PROVIDER_PRESCRIPTION_ADDED'
    )
      return
    refreshPrescriptions()
  }, [clientResponse])

  useEffect(() => {
    if (!R.keys(files).length || !appointment?.prescription) return
    setPrescriptionFiles(
      appointment.prescription.map((p) => ({
        ...p,
        img: files[p.key]?.Image,
      }))
    )
  }, [files])

  const handleUpload = () => {
    const file = fileInputRef.current.files[0]
    onRequestUpload({ file, privateId: patient.user, folder: 'prescription' })
  }

  useEffect(() => {
    if (!success) return
    if (action == actions.delete) {
      refreshPrescriptions()
      setDeleteConfirmationModal(false)
    }
    setSnackbarOpen(true)
  }, [success])

  return (
    <>
      <Box className={classes.marginTop}>
        <LoadingButton
          variant='outlined'
          color='primary'
          loading={loading}
          onClick={() => fileInputRef.current.click()}
          disabled={
            !canUploadPrescription(
              appointment.status,
              company.cantUploadPrescriptionInFinishedAppointment
            )
          }
        >
          <AttachFile /> Adjuntar
        </LoadingButton>
        <LoadingButton
          variant='outlined'
          color='primary'
          loading={loading}
          disabled={
            !canUploadPrescription(
              appointment.status,
              company.cantUploadPrescriptionInFinishedAppointment
            )
          }
          onClick={() => {
            setAction(actions.whatsapp)
            onRequestPrescription(appointment.timestamp)
          }}
          className={classes.whatsAppAttachment}
        >
          <WhatsAppIcon /> Adjuntar por WhatsApp
        </LoadingButton>
        <Box className={classes.fileInput}>
          <TextField
            name='file'
            type='file'
            onChange={handleUpload}
            inputProps={{ ref: fileInputRef, accept: 'image/*' }}
          />
        </Box>
        <Grid container spacing={2} className={classes.marginTop}>
          {prescriptionFiles.map((i) => (
            <Grid item key={i.key} className={classes.gridBox}>
              {/\.jpg|\.png|\.jpeg/.test(i.key) ? (
                <img src={i.img} width='150' style={{ maxHeight: '150' }} />
              ) : (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  borderRadius='50%'
                  borderColor='primary.main'
                  border={1}
                  padding={1}
                >
                  {R.last(i.key.split('.'))}
                </Box>
              )}
              <IconButton
                aria-label='delete'
                size='small'
                className={classes.deleteBtn}
                onClick={() => {
                  setSelectedPrescription(i)
                  setDeleteConfirmationModal(true)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='success'>
          {getSnackbarMessage(action)}
        </Alert>
      </Snackbar>
      <Dialog
        open={deleteConfirmationModal}
        onClose={() => {
          setDeleteConfirmationModal(false)
          setSelectedPrescription(null)
        }}
      >
        {selectedPrescription && (
          <>
            <DialogTitle id='alert-dialog-title'>Eliminar receta</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                ¿Está seguro que desea eliminar la receta?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setDeleteConfirmationModal(false)
                  setSelectedPrescription(null)
                }}
              >
                Volver
              </Button>
              <LoadingButton
                style={theme.danger}
                loading={loading}
                onClick={() => {
                  setAction(actions.delete)
                  onDeletePrescription({
                    timestamp: appointment.timestamp,
                    file: {
                      bucket: selectedPrescription.bucket,
                      key: selectedPrescription.key,
                    },
                  })
                }}
              >
                Si, eliminar
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: getLoading(state) || getAppointmentLoading(state),
    error: getError(state),
    files: getFiles(state),
    uploadResponse: getUploadResponse(state),
    success: getSuccess(state),
    patient: getPatient(state),
    clientResponse: getClientResponse(state),
    company: getCompany(state),
  }
}

export default connect(mapStateToProps, {
  onRequestUpload: fileUploadRequest,
  onResetFileUpload: fileUploadReset,
  onRequestFile: fileGetRequest,
  onCreatePrescription: prescriptionAppointment,
  multipleFileGet: multipleFileGetRequest,
  onRequestPrescription: whatsappPrescriptionAppointmentRequest,
  onDeletePrescription: deletePrescriptionRequest,
  onGetAppointments: requestAppointments,
})(AppointmentAttachments)
