import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import dayjs from 'dayjs'
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Link,
  Paper,
  Tabs,
  Tab,
  CircularProgress,
} from '@material-ui/core'
import AttachFile from '@material-ui/icons/AttachFile'
import Save from '@material-ui/icons/Save'

import { makeStyles } from '@material-ui/core/styles'
import { getError, getLoading, getFiles } from 'common/reducers/files'
import {
  fileGetRequest,
  requestAppointmentReasons,
  fileDownloadGetRequest,
} from 'common/actions'
import { getReasons, getAppointments } from 'common/reducers/appointment'
import {
  getLoading as getFileLoading,
  getDownloadFile,
} from 'common/reducers/files'
import { getCoverages } from 'common/reducers/coverages'

import AppointmentAttachments from './appointmentAttachments'
import HealthRecords from './healthRecord'

import downloadFile from 'common/utils/downloadFile'
import appointmentReasonsWithER from 'common/utils/appointmentReasonsWithER'

const useStyles = makeStyles((theme) => ({
  secondaryCard: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(3),
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  bold: {
    fontWeight: '600',
  },
  tab: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  textarea: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  patientCard: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  header: {
    display: 'block',
    alignItems: 'stretch',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  appointmentCard: {
    marginRight: theme.spacing(1),
    textAlign: 'center',
  },
  appointmentCardBGM: {
    marginBottom: theme.spacing(1),
  },
  appointmentCardContentBGM: {
    display: 'flex',
    alignItems: 'center',
  },
  patientCardWrapper: {
    flex: '1',
  },
  headerBGM: {
    display: 'block',
  },
  appointmentCardContentBGMCol1: {
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'center',
    borderRight: '1px solid #CCC',
  },
}))

const PatientData = ({
  patient,
  files,
  requestFile,
  loading,
  appointmentReasons,
  onRequestAppointmentReasons,
  appointment,
  onFileDownload,
  fileToDownload,
  fileLoading,
  coverages,
  bigCameraMode,
  appointments,
}) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const [downloaded, setDownloaded] = useState(true)
  const [nextAppointment, setNextAppointment] = useState(null)

  useEffect(() => {
    if (!fileToDownload || downloaded) return
    downloadFile(fileToDownload)
    setDownloaded(true)
  }, [fileToDownload])

  useEffect(() => {
    if (!appointmentReasons) onRequestAppointmentReasons()
  }, [appointmentReasons])

  useEffect(() => {
    if (!patient?.profilePicture) return
    const profilePicture =
      typeof patient.profilePicture === 'string'
        ? JSON.parse(patient.profilePicture)
        : patient.profilePicture

    if (!files[profilePicture.key]) requestFile(profilePicture)
  }, [patient])

  useEffect(() => {
    const keys = R.keys(appointments)
    if (!keys?.length) return

    const index = R.findIndex(R.equals(appointment.timestamp.toString()), keys)
    if (index >= appointments.length - 1) return

    setNextAppointment(appointments[keys[index + 1]])
  }, [appointments])

  const startDateTime = dayjs(appointment.timestamp * 1000)
  const endDateTime = dayjs(startDateTime).add(appointment['length'], 'minutes')

  const profilePicture =
    typeof patient?.profilePicture === 'string' &&
    patient?.profilePicture.length > 0
      ? JSON.parse(patient.profilePicture)
      : patient?.profilePicture

  return (
    <>
      <Box className={bigCameraMode ? classes.headerBGM : classes.header}>
        {bigCameraMode ? (
          <Card className={classes.appointmentCardBGM}>
            <CardContent className={classes.appointmentCardContentBGM}>
              <Box className={classes.appointmentCardContentBGMCol1}>
                <Typography variant='body2'>
                  {startDateTime.format('MMMM').toUpperCase()}
                </Typography>
                <Typography variant='h4' color='primary'>
                  {startDateTime.format('DD')}
                </Typography>
              </Box>
              <Box>
                <Typography variant='body1'>
                  {startDateTime.format('HH:mm [hs]')}
                  {!!appointment['length'] &&
                    ` - ${endDateTime.format('HH:mm [hs]')}`}
                </Typography>
                <Box>
                  {nextAppointment && (
                    <Typography variant='body2'>
                      Próximo Turno:{' '}
                      {dayjs(nextAppointment.timestamp * 1000).format(
                        'HH:mm [hs]'
                      )}
                    </Typography>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Card className={classes.appointmentCard}>
            <CardContent>
              <Typography variant='body2'>
                {startDateTime.format('MMMM').toUpperCase()}
              </Typography>
              <Typography variant='h1' color='primary'>
                {startDateTime.format('DD')}
              </Typography>
              <Typography variant='body1'>
                {startDateTime.format('HH:mm [hs]')}
                {!!appointment['length'] &&
                  ` - ${startDateTime
                    .add(appointment['length'], 'minutes')
                    .format('HH:mm [hs]')}`}
              </Typography>
              {nextAppointment && (
                <Typography variant='body2'>
                  Próximo Turno:{' '}
                  {dayjs(nextAppointment.timestamp * 1000).format('HH:mm [hs]')}
                </Typography>
              )}
            </CardContent>
          </Card>
        )}
        <Card className={classes.patientCardWrapper}>
          <CardContent>
            <Box className={classes.patientCard}>
              <Avatar
                className={classes.avatar}
                alt={`${patient.name} ${patient.lastName}`}
                src={files[profilePicture.key]?.Image}
              />
              <Box>
                <Typography variant='h5' color='primary'>
                  {patient.name} {patient.lastName}
                </Typography>
                <Typography>
                  Cobertura{' '}
                  {R.find(R.propEq('id', patient.coverage), coverages)?.name}{' '}
                  {patient.coverageNumber}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.marginTop}>
              <Typography className={classes.bold}>
                Motivo de Consulta
              </Typography>
              <Box>
                {appointment?.reason &&
                  appointmentReasons &&
                  appointment.reason.map((r) => (
                    <Typography variant='body2' key={r}>
                      {
                        R.find(
                          (ar) => R.equals(ar.id, r),
                          appointmentReasonsWithER(appointmentReasons)
                        )?.name
                      }
                    </Typography>
                  ))}
                {appointment?.reasonOther && (
                  <Typography variant='body2'>
                    {appointment.reasonOther}
                  </Typography>
                )}
              </Box>
            </Box>
            {appointment?.attachments?.length ? (
              <Box className={classes.marginTop}>
                <Typography>Archivos adjuntos: </Typography>
                {appointment.attachments.map((a, i) => (
                  <Link
                    key={a.key}
                    onClick={() => {
                      onFileDownload(a)
                      setDownloaded(false)
                    }}
                  >
                    Adjunto {i + 1}
                  </Link>
                ))}
                {fileLoading && <CircularProgress />}
              </Box>
            ) : (
              ''
            )}
          </CardContent>
        </Card>
      </Box>
      <Paper square className={classes.secondaryCard}>
        <Tabs
          value={selectedTab}
          indicatorColor='primary'
          textColor='primary'
          onChange={(e, t) => setSelectedTab(t)}
        >
          <Tab label='Datos del Paciente' />
          <Tab label='Recetas' />
          <Tab label='Historia Clínica' />
        </Tabs>
        <Box hidden={selectedTab != 0} className={classes.tab}>
          <Typography variant='h6' color='primary'>
            Datos del Paciente
          </Typography>
          {patient.birthDate ? (
            <Box className={classes.marginTop}>
              <Typography className={classes.bold}>Fecha Nacimiento</Typography>
              <Typography>
                {dayjs(patient.birthDate).format('DD-MM-YYYY')}
              </Typography>
            </Box>
          ) : (
            ''
          )}
          <Box className={classes.marginTop}>
            <Typography className={classes.bold}>Género</Typography>
            <Typography>
              {patient.gender === 'MALE' ? 'Masculino' : 'Femenino'}
            </Typography>
          </Box>
          <Box className={classes.marginTop}>
            <Typography className={classes.bold}>Cobertura</Typography>
            <Typography>
              {R.find(R.propEq('id', patient.coverage), coverages)?.name} -{' '}
              {patient.coverageNumber}
            </Typography>
          </Box>
        </Box>
        <Box hidden={selectedTab != 1} className={classes.tab}>
          <Typography variant='h6' color='primary'>
            Recetas
          </Typography>
          <AppointmentAttachments appointment={appointment} />
        </Box>
        <Box hidden={selectedTab != 2} className={classes.tab}>
          <Typography variant='h6' color='primary'>
            Historia Clínica
          </Typography>
          <HealthRecords appointment={appointment} />
        </Box>
      </Paper>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: getLoading(state),
    error: getError(state),
    files: getFiles(state),
    appointmentReasons: getReasons(state),
    fileLoading: getFileLoading(state),
    fileToDownload: getDownloadFile(state),
    coverages: getCoverages(state),
    appointments: getAppointments(state),
  }
}

export default connect(mapStateToProps, {
  requestFile: fileGetRequest,
  onFileDownload: fileDownloadGetRequest,
  onRequestAppointmentReasons: requestAppointmentReasons,
})(PatientData)
