import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import * as R from 'ramda'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useHistory } from 'react-router-dom'
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import MaterialTable from 'material-table'

import theme from 'common/theme'
import { LoadingOverlay } from 'common/components'
import { CompanyTypes } from 'common/utils/constants'

import { Header } from '../layout'
import { dealsGetRequest, dealCreateRequest } from 'common/actions'
import {
  getError,
  getLoading,
  getDeals,
  getSuccess,
} from 'common/reducers/deals'
import { getCompany } from 'common/reducers/companies'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  fullWidth: {
    width: '100%',
  },
}))

const Deals = ({
  error,
  loading,
  deals,
  onRequestDeals,
  onCreateDeal,
  success,
  company,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
  const [newDealModal, setNewDealModal] = useState(false)
  const [selectedDeal, setSelectedDeal] = useState(null)

  const schema = yup.object().shape({
    name: yup.string().required('Este campo es requerido'),
  })

  const { control, errors, handleSubmit, triggerValidation } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    onRequestDeals()
  }, [])

  const onSubmit = (data) => {
    setNewDealModal(false)
    onCreateDeal({ ...data, erPrice: -1 }) // by default, ER is disabled (-1)
  }

  useEffect(() => {
    if (!success || !success.id) return
    history.push(`/deals/${success.id}`)
  }, [success])

  return (
    <>
      {loading && <LoadingOverlay />}
      <Header />
      <Container className={classes.container}>
        <MaterialTable
          columns={[{ title: 'Nombre', field: 'name' }]}
          data={deals}
          title='Convenios'
          options={{
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <AddCircleIcon color='primary' />,
              tooltip: 'Nuevo Convenio',
              position: 'toolbar',
              onClick: () => {
                setNewDealModal(true)
              },
              hidden: !R.includes(company?.companyType, [
                CompanyTypes.MEDITAR,
                CompanyTypes.PARTNER,
              ]),
            },
            (rowData) => ({
              icon: () => <EditIcon color='primary' />,
              tooltip: 'Editar Convenio',
              onClick: (event) => {
                history.push(`deals/${rowData.id}`)
              },
            }),
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'filas',
              labelRowsPerPage: 'Filas por página: ',
            },
            toolbar: {
              searchPlaceholder: 'Búsqueda',
              searchTooltip: 'Búsqueda',
            },
            header: {
              actions: 'Acciones',
            },
            body: {
              emptyDataSourceMessage: 'No hay convenios',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
              editTooltip: 'Editar',
            },
          }}
        />
        <Dialog open={newDealModal} onClose={() => setNewDealModal(false)}>
          <DialogTitle id='alert-dialog-title'>
            Crear nuevo convenio
          </DialogTitle>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Controller
                control={control}
                name='name'
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
                label='Nombre'
                onBlur={() => triggerValidation('name')}
                fullWidth
                as={<TextField />}
              />
              {error && (
                <Box mt={3}>
                  <Alert severity='error'>
                    No se puede eliminar el convenio, ya hay datos relacionados
                    con la misma
                  </Alert>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={() => setNewDealModal(false)}>
                Volver
              </Button>
              <Button type='submit' color='primary' variant='contained'>
                CREAR
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={deleteConfirmationModal}
          onClose={() => {
            setDeleteConfirmationModal(false)
            setSelectedDeal(null)
          }}
        >
          {selectedDeal && (
            <>
              <DialogTitle id='alert-dialog-title'>
                Eliminar {selectedDeal.name}
              </DialogTitle>
              <DialogContent>
                {selectedDeal && (
                  <DialogContentText id='alert-dialog-description'>
                    ¿Está seguro que desea eliminar la especialidad{' '}
                    {selectedDeal.name}?
                  </DialogContentText>
                )}
                {selectedDeal.providerCount && (
                  <Box mt={3}>
                    <Alert severity='error'>
                      No se puede eliminar la especialidad, ya hay datos
                      relacionados con la misma
                    </Alert>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setDeleteConfirmationModal(false)
                    setSelectedDeal(null)
                  }}
                >
                  Volver
                </Button>
                <Button
                  style={theme.danger}
                  disabled={!!selectedDeal.providerCount}
                >
                  Si, eliminar
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    deals: getDeals(state),
    success: getSuccess(state),
    company: getCompany(state),
  }
}

export default connect(mapStateToProps, {
  onRequestDeals: dealsGetRequest,
  onCreateDeal: dealCreateRequest,
})(Deals)
