import * as ActionTypes from '../actions'

const initialState = {}

export function awsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AWS_REFRESH_CREDENTIALS_REQUEST:
    case ActionTypes.AWS_REFRESH_CREDENTIALS_SUCCESS:
    case ActionTypes.AWS_REFRESH_CREDENTIALS_FAILURE:
      return state
    default:
      return state
  }
}
