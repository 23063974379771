import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import dayjs from 'dayjs'
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Container,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Link,
} from '@material-ui/core'
import theme from 'common/theme'
import CancelAppointmentModal from './cancelAppointmentModal'
import { makeStyles } from '@material-ui/core/styles'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction' // for selectable
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import esLocale from '@fullcalendar/core/locales/es'

import { useHistory, useLocation } from 'react-router-dom'
import { Header } from '../layout'
import {
  requestAppointments,
  requestAppointmentReasons,
  providerScheduleGetRequest,
  fileDownloadGetRequest,
} from 'common/actions'
import {
  getError,
  getLoading,
  getAppointments,
  getReasons,
} from 'common/reducers/appointment'
import {
  getSchedules,
  getLoading as getProviderLoading,
} from 'common/reducers/providers'
import {
  getLoading as getFileLoading,
  getDownloadFile,
} from 'common/reducers/files'

import {
  appointmentStatusStrings,
  cancellableAppointment,
} from 'common/utils/appointmentStatus'
import downloadFile from 'common/utils/downloadFile'
import { NotificationTypes } from 'common/utils/constants'
import { onNotificationReceived } from 'common/utils/firebase'
import appointmentReasonsWithER from 'common/utils/appointmentReasonsWithER'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  dateBoxTitle: {
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: '#F1F0F2',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  },
  item: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toggleBox: {
    textAlign: 'right',
  },
}))

const Appointments = ({
  error,
  loading,
  appointments,
  onRetrieveAppointments,
  provider,
  onRetrieveAppointmentReasons,
  reasons,
  onRetrieveSchedules,
  schedules,
  onFileDownload,
  fileToDownload,
  fileLoading,
}) => {
  const calendarRef = useRef()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const [events, setEvents] = useState([]) // parsed appointments and schedules
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [cancelAppointment, setCancelAppointment] = useState(null)
  const [loadedAppointments, setLoadedAppointments] = useState({})
  const [initialDates, setInitialDates] = useState(null)
  const [currentDates, setCurrentDates] = useState(null)

  useEffect(() => {
    if (!fileToDownload) return
    downloadFile(fileToDownload)
  }, [fileToDownload])

  useEffect(() => {
    if (!reasons) onRetrieveAppointmentReasons()
  }, [])

  useEffect(() => {
    if (!provider) return
    onRetrieveSchedules({ providerId: provider.id })
    if (initialDates) {
      refreshDate(initialDates.fromDate, initialDates.toDate)
      setInitialDates(null)
    }
  }, [provider])

  const refreshDate = (fromDate, toDate) => {
    setCurrentDates({ fromDate, toDate })
    if (!provider) {
      setInitialDates({ fromDate, toDate })
      return
    }
    onRetrieveAppointments({
      providerId: provider.id,
      from: fromDate,
      to: toDate,
    })
  }

  useEffect(() => {
    const subscribeToNotification = async () => {
      const notification = await onNotificationReceived()
      if (
        R.equals(
          notification.data.notificationType,
          NotificationTypes.NEW_APPOINTMENT
        ) &&
        currentDates
      ) {
        const { fromDate, toDate } = currentDates
        refreshDate(fromDate, toDate)
      }
      subscribeToNotification()
    }

    subscribeToNotification()
  }, [currentDates])

  useEffect(() => {
    if (!R.valuesIn(appointments)?.length) return

    setEvents(
      R.flatten([
        ...events,
        R.filter(
          (a) => !loadedAppointments[a.timestamp],
          R.valuesIn(appointments)
        ).map((a) => ({
          start: dayjs(a.timestamp * 1000)
            .tz(provider.timezone)
            .format(),
          end: dayjs(a.timestamp * 1000)
            .add(a['length'], 'minutes')
            .tz(provider.timezone)
            .format(),
          id: a.timestamp,
          duration: a['length'],
        })),
      ])
    )

    setLoadedAppointments({ ...loadedAppointments, ...appointments })
  }, [appointments])

  useEffect(() => {
    if (!schedules) return
    setEvents(
      R.flatten([
        ...events,
        schedules.map((s) => {
          return {
            id: s.timestamp,
            duration: s['length'],
            start: dayjs(s.timestamp * 1000)
              .tz(provider.timezone)
              .format(),
            end: dayjs(s.timestamp * 1000)
              .add(s['length'], 'minutes')
              .tz(provider.timezone)
              .format(),
            display: 'background',
            exists: true,
          }
        }),
      ])
    )
  }, [schedules])

  const minHour = R.reduce(
    R.min,
    Infinity,
    events.map((e) => dayjs(e.start).tz(provider.timezone).format('HH'))
  )

  const minSlot = R.reduce(
    R.min,
    Infinity,
    events.map((e) => e.duration)
  )

  let maxHour = R.reduce(
    R.max,
    -Infinity,
    events.map((e) => dayjs(e.end).tz(provider.timezone).format('HH'))
  )

  if (minHour == maxHour) maxHour = parseInt(maxHour, 10) + 1

  useEffect(() => {
    setLoadedAppointments({})
  }, [location])

  return (
    <>
      <Header />
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12}>
            {(!provider || loading) && <CircularProgress />}
            <FullCalendar
              ref={calendarRef}
              datesSet={(info) => refreshDate(info.startStr, info.endStr)}
              customButtons={{
                refreshButton: {
                  text: 'Refrescar',
                  click: () =>
                    refreshDate(currentDates.fromDate, currentDates.toDate),
                },
              }}
              timeZone={provider?.timezone || 'local'}
              locale={esLocale}
              slotMinTime={`${minHour === Infinity ? '00' : minHour}:00:00`}
              slotMaxTime={`${
                maxHour === -Infinity || maxHour === '23' ? '24' : maxHour
              }:59:59`}
              slotDuration={`00:${minSlot === Infinity ? '30' : minSlot}:00`}
              headerToolbar={{
                left: 'prev,today,next',
                center: 'title',
                right: 'refreshButton',
              }}
              plugins={[
                listPlugin,
                dayGridPlugin,
                interactionPlugin,
                timeGridPlugin,
              ]}
              buttonText={{
                today: 'Hoy',
                month: 'Mes',
                week: 'Semana',
                day: 'Dia',
                list: 'Lista',
              }}
              nowIndicator
              initialView='timeGridWeek'
              allDaySlot={false}
              scrollTime='08:00:00'
              events={events}
              eventClick={(info) => {
                if (info.exists) return
                setSelectedAppointment(appointments[info.event.id])
              }}
              eventContent={({ event }) => {
                if (!event || (!R.valuesIn(appointments)?.length && !schedules))
                  return

                const appointment = appointments[event.id]

                if (!appointment) return <Box>Disponible</Box>

                return (
                  <Box overflow='hidden'>
                    {dayjs(event.startStr)
                      .tz(provider.timezone)
                      .format('HH:mm')}
                    {appointment.duration &&
                      `-
                    ${dayjs(event.endStr)
                      .tz(provider.timezone)
                      .format('HH:mm')}`}
                    <Box fontWeight='600' component='span'>
                      {' '}
                      {appointment.patient &&
                        `${appointment.patient.name} ${appointment.patient.lastName}`}
                    </Box>
                    <Box>
                      Estado: {appointmentStatusStrings[appointment.status]}
                    </Box>
                  </Box>
                )
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={!!selectedAppointment}
        onClose={() => setSelectedAppointment(null)}
      >
        {selectedAppointment && (
          <>
            <DialogTitle id='alert-dialog-title'>
              Turno:{' '}
              {dayjs(selectedAppointment.timestamp * 1000)
                .tz(provider.timezone)
                .format('DD/MM/YYYY HH:mm')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Estado del turno:{' '}
                {appointmentStatusStrings[selectedAppointment.status]}
              </DialogContentText>
              <DialogContentText>
                Paciente:{' '}
                <Typography fontWeight='600' component='span'>
                  {selectedAppointment.patient &&
                    `${selectedAppointment.patient.name} ${selectedAppointment.patient.lastName}`}
                </Typography>
              </DialogContentText>
              {(selectedAppointment.reason ||
                selectedAppointment.reasonOther) && (
                <Box>
                  Motivo de consulta:{' '}
                  {selectedAppointment.reason?.map((r) => (
                    <Typography key={r}>
                      {
                        R.find(
                          (ar) => R.equals(ar.id, r),
                          appointmentReasonsWithER(reasons)
                        )?.name
                      }
                    </Typography>
                  ))}
                  {selectedAppointment.reasonOther && (
                    <Typography>{selectedAppointment.reasonOther}</Typography>
                  )}
                </Box>
              )}
              {selectedAppointment.attachments?.length ? (
                <Box className={classes.marginTop}>
                  <Typography>Archivos adjuntos: </Typography>
                  {selectedAppointment.attachments.map((a, i) => (
                    <Link key={a.key} onClick={() => onFileDownload(a)}>
                      Adjunto {i + 1}
                    </Link>
                  ))}
                  {fileLoading && (
                    <Box className={classes.marginTop}>
                      Su descarga comenzará pronto...
                    </Box>
                  )}
                </Box>
              ) : (
                ''
              )}
            </DialogContent>
            <DialogActions>
              {cancellableAppointment(selectedAppointment?.status) && (
                <Button
                  style={theme.danger}
                  onClick={() => {
                    setCancelAppointment(selectedAppointment)
                    setSelectedAppointment(null)
                  }}
                >
                  Cancelar Turno
                </Button>
              )}
              <Button
                color='primary'
                onClick={() => setSelectedAppointment(null)}
              >
                Volver
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={() =>
                  history.push(`/videocall/${selectedAppointment.timestamp}`)
                }
              >
                Ir a la llamada
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <CancelAppointmentModal
        isOpen={!!cancelAppointment}
        onClose={({ refresh }) => {
          if (refresh) {
            setLoadedAppointments({
              ...loadedAppointments,
              [cancelAppointment.timestamp]: null,
            })
            setEvents(
              R.remove(
                R.findIndex(
                  R.propEq('id', cancelAppointment.timestamp),
                  events
                ),
                1,
                events
              )
            )
            refreshDate(currentDates.fromDate, currentDates.toDate)
            onRetrieveSchedules({ providerId: provider.id })
          }
          setCancelAppointment(null)
        }}
        appointment={cancelAppointment}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state) || getProviderLoading(state),
    appointments: getAppointments(state),
    reasons: getReasons(state),
    schedules: getSchedules(state),
    fileLoading: getFileLoading(state),
    fileToDownload: getDownloadFile(state),
  }
}

export default connect(mapStateToProps, {
  onRetrieveAppointments: requestAppointments,
  onRetrieveAppointmentReasons: requestAppointmentReasons,
  onRetrieveSchedules: providerScheduleGetRequest,
  onFileDownload: fileDownloadGetRequest,
})(Appointments)
