import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  Box,
  Container,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MuiAlert from '@material-ui/lab/Alert'

import { useHistory } from 'react-router-dom'
import { Header } from '../layout'
import { LoadingButton, LoadingOverlay } from 'common/components'
import { getAllTimeZones } from 'common/utils/timezones'
import { getLoading, getSuccess } from 'common/reducers/providers'
import {
  providerCurrentGetRequest,
  providerEditRequest,
  providerReset,
} from 'common/actions'

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
}))

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const Configuration = ({
  provider,
  loading,
  updateProvider,
  providerReset,
  success,
  retrieveProvider,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const [selectedTimezone, setSelectedTimezone] = useState(browserTimezone)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!provider) return
    setSelectedTimezone(provider.timezone)
    setSnackbarOpen(false)
    providerReset()
    console.log(provider)
  }, [provider])

  useEffect(() => {
    if (!success || !submitted) return
    setSnackbarOpen(true)
    setSubmitted(false)
    retrieveProvider(provider.id)
  }, [success])

  const submit = () => {
    updateProvider({ ...provider, timezone: selectedTimezone })
    setSubmitted(true)
  }

  const timezones = getAllTimeZones()

  return (
    <>
      {loading && submitted && <LoadingOverlay />}
      <Header />
      <Container className={classes.container}>
        <Box>
          <Autocomplete
            id='selectedTimezone'
            options={timezones}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            value={selectedTimezone}
            onChange={(e, v) => {
              setSelectedTimezone(v)
            }}
            renderInput={(params) => (
              <TextField
                name='selectedTimezone'
                {...params}
                label='Huso Horario'
                fullWidth
              />
            )}
          />
        </Box>
        <Box className={classes.marginTop3}>
          <LoadingButton
            variant='contained'
            color='primary'
            loading={loading && submitted}
            onClick={() => submit()}
          >
            GUARDAR
          </LoadingButton>
        </Box>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='success'>
          PERFIL GUARDADO CORRECTAMENTE
        </Alert>
      </Snackbar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: getLoading(state),
    success: getSuccess(state),
  }
}

export default connect(mapStateToProps, {
  updateProvider: providerEditRequest,
  providerReset: providerReset,
  retrieveProvider: providerCurrentGetRequest,
})(Configuration)
