import * as ActionTypes from '../actions'

const initialState = {
  erResponse: null,
  clientResponse: null,
}

export function pubSubReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PUBSUB_CLIENT_SUBSCRIBE_REQUEST:
    case ActionTypes.PUBSUB_ER_SUBSCRIBE_REQUEST:
      return state
    case ActionTypes.PUBSUB_RECEIVED_DATA:
      if (action.data.clientResponse)
        return {
          ...state,
          clientResponse: action.data.clientResponse,
        }
      if (action.data.erResponse)
        return {
          ...state,
          erResponse: action.data.erResponse,
        }
      // shouldn't happen but just in case
      return state
    case ActionTypes.PUBSUB_PUBLISH_REQUEST:
    case ActionTypes.PUBSUB_PUBLISH_SUCCESS:
    case ActionTypes.PUBSUB_PUBLISH_FAILURE:
      return state
    default:
      return state
  }
}

export function getClientResponse(state) {
  return state.pubSubReducer.clientResponse
}

export function getERResponse(state) {
  return state.pubSubReducer.erResponse
}
