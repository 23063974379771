import { Auth } from 'aws-amplify'
import * as Sentry from '@sentry/react'

const getCurrentUser = () =>
  new Promise((resolve) =>
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups = user.signInUserSession.idToken.payload['cognito:groups']
        Sentry.setUser({ email: user.username })
        resolve({ username: user.username, ...user.attributes, groups })
      })
      .catch(() => {
        resolve(null)
        Sentry.setUser(null)
      })
  )

export default getCurrentUser
