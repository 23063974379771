import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import * as R from 'ramda'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Tabs,
  Tab,
  Paper,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import theme from 'common/theme'
import { LoadingOverlay } from 'common/components'

import MaterialTable from 'material-table'

import { Header } from '../layout'
import {
  userGetRequest,
  userCreateRequest,
  adminChangeStatus,
  userCreateReset,
} from 'common/actions'
import {
  getError,
  getLoading,
  getUsersApi,
  getUser,
  getSuccess,
} from 'common/reducers/user'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  inputBox: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  newButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  newUserDialog: {
    minWidth: '25rem',
  },
}))

const Users = ({
  error,
  loading,
  users,
  onRequestUsers,
  onCreateUser,
  onChangeStatus,
  success,
  onUserCreateReset,
}) => {
  const classes = useStyles()
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [newUserModal, setNewUserModal] = useState(false)

  useEffect(() => {
    onRequestUsers('administrator')
  }, [])

  const disableSelectedUser = () => {
    setDeleteConfirmationModal(false)
    onChangeStatus({ username: selectedUser.username, enable: false })
  }

  const enableUser = ({ username }) => {
    onChangeStatus({ username, enable: true })
  }

  const getErrorMessage = () => {
    switch (error.code) {
      case 'UsernameExistsException':
        return 'El email ya existe'
      case 'InvalidPasswordException':
        return 'La contraseña debe tener al menos 8 caracteres, una mayúscula y una minúscula.'
      default:
        return `Hubo un error desconocido al intentar registrarse. Detalles: ${error.message}`
    }
  }

  const schema = yup.object().shape({
    name: yup.string().required('Este campo es requerido'),
    lastName: yup.string().required('Este campo es requerido'),
    email: yup
      .string()
      .required('Este campo es requerido')
      .email('Por favor ingrese un email válido')
      .nullable(),
  })

  const { control, errors, handleSubmit, triggerValidation } = useForm({
    defaultValues: {
      email: '',
      name: '',
      lastName: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = (data) => {
    setNewUserModal(false)
    onCreateUser({ type: 'administrator', data })
  }

  useEffect(() => {
    if (!success) return
    onRequestUsers('administrator')
    onUserCreateReset()
  }, [success])

  return (
    <>
      <MaterialTable
        columns={[
          {
            title: 'Nombre y Apellido',
            field: 'name',
            render: (rowData) => `${rowData.name} ${rowData.lastName}`,
          },
          { title: 'Dirección de Email', field: 'email' },
          {
            title: 'Activo',
            field: 'enabled',
            render: (rowData) => (rowData.enabled ? 'Si' : 'No'),
          },
        ]}
        data={users || []}
        title='Administradores'
        options={{
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: () => <AddCircleIcon color='primary' />,
            tooltip: 'Nuevo Usuario',
            position: 'toolbar',
            onClick: () => {
              setNewUserModal(true)
            },
          },
          (rowData) => ({
            icon: () => <BlockIcon style={theme.danger} />,
            tooltip: 'Deshabilitar Usuario',
            onClick: (event) => {
              setDeleteConfirmationModal(true)
              setSelectedUser(rowData)
            },
            hidden: !rowData.enabled,
          }),
          (rowData) => ({
            icon: () => <CheckCircleIcon color='primary' />,
            tooltip: 'Habilitar Usuario',
            onClick: (event) => {
              enableUser(rowData)
            },
            hidden: rowData.enabled,
          }),
        ]}
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
            labelRowsPerPage: 'Filas por página: ',
          },
          toolbar: {
            searchPlaceholder: 'Búsqueda',
            searchTooltip: 'Búsqueda',
          },
          header: {
            actions: 'Acciones',
          },
          body: {
            emptyDataSourceMessage: 'No hay usuarios',
            filterRow: {
              filterTooltip: 'Filtrar',
            },
            editTooltip: 'Editar',
          },
        }}
      />
      <Dialog open={newUserModal} onClose={() => setNewUserModal(false)}>
        <DialogTitle id='alert-dialog-title'>Crear nuevo usuario</DialogTitle>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogContent className={classes.newUserDialog}>
            <Box>
              <Controller
                control={control}
                name='name'
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
                label='Nombre'
                onBlur={() => triggerValidation('name')}
                fullWidth
                as={<TextField />}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Controller
                control={control}
                name='lastName'
                error={!!errors.lastName}
                helperText={errors.lastName && errors.lastName.message}
                label='Apellido'
                onBlur={() => triggerValidation('lastName')}
                fullWidth
                as={<TextField />}
              />
            </Box>
            <Box className={classes.inputBox}>
              <Controller
                control={control}
                name='email'
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
                label='Dirección de Email'
                onBlur={() => triggerValidation('email')}
                fullWidth
                as={<TextField />}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={() => setNewUserModal(false)}>
              Volver
            </Button>
            <Button type='submit' color='primary' variant='contained'>
              CREAR
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={deleteConfirmationModal}
        onClose={() => {
          setDeleteConfirmationModal(false)
          setSelectedUser(null)
        }}
      >
        {selectedUser && (
          <>
            <DialogTitle id='alert-dialog-title'>
              Deshabilitar Usuario {selectedUser.fullName}
            </DialogTitle>
            <DialogContent>
              {selectedUser && (
                <DialogContentText id='alert-dialog-description'>
                  ¿Está seguro que desea deshabilitar el usuario{' '}
                  {selectedUser.email}?
                </DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setDeleteConfirmationModal(false)
                  setSelectedUser(null)
                }}
              >
                Volver
              </Button>
              <Button
                style={theme.danger}
                onClick={() => disableSelectedUser()}
              >
                Si, deshabilitar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    users: getUsersApi(state),
    user: getUser(state),
    success: getSuccess(state),
  }
}

export default connect(mapStateToProps, {
  onRequestUsers: userGetRequest,
  onCreateUser: userCreateRequest,
  onChangeStatus: adminChangeStatus,
  onUserCreateReset: userCreateReset,
})(Users)
