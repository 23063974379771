import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'

import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Drawer,
  FormControl,
  Snackbar,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { LoadingButton, SelectField } from 'common/components'

import {
  provincesGetRequest,
  districtsGetRequest,
  resetPatientCreation,
  patientCreationRequest,
} from 'common/actions'
import {
  getLoading,
  getSuccess,
  getPatient,
  getError,
} from 'common/reducers/patients'
import {
  getLoading as getLocationLoading,
  getProvinces,
  getDistricts,
} from 'common/reducers/locations'

import { isDateValid } from 'common/utils/datetime'

import { phoneNumberRegex } from 'common/utils/constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  dateBoxTitle: {
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: '#F1F0F2',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  },
  item: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toggleBox: {
    textAlign: 'right',
  },
  leftContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
  drawerContainer: {
    padding: theme.spacing(3),
  },
  drawerTitle: {
    paddingRight: theme.spacing(6),
  },
  closeDrawerButton: {
    position: 'absolute',
    right: '0.5rem',
    top: '1rem',
  },
  timeAvatar: {
    fontWeight: 600,
    fontSize: '1.25rem',
    paddingRight: theme.spacing(2),
    alignItems: 'flex-start',
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
  },
  fullWidth: {
    width: '100%',
  },
}))

const NewPatientDrawer = ({
  isOpen,
  onClose,
  preloadPatient,
  locationLoading,
  provinces,
  districts,
  error,
  country,
  onRequestDistricts,
  onRequestProvinces,
  loading,
  success,
  onPatientCreation,
  onResetPatientCreation,
  patient,
}) => {
  const classes = useStyles()

  const [snackbarOpen, setSnackbarOpen] = useState(false)

  useEffect(() => {
    onResetPatientCreation()
  }, [isOpen])

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Este campo es requerido')
      .email('Por favor ingrese un email válido')
      .nullable(),
    name: yup.string().required('Este campo es requerido'),
    lastName: yup.string().required('Este campo es requerido'),
    documentType: yup.string().required('Este campo es requerido'),
    documentNumber: yup.string().required('Este campo es requerido'),
    birthDate: yup
      .mixed()
      .required('Este campo es requerido')
      .test(
        'date-format',
        'La fecha no es válida. El formato es DD/MM/YYYY',
        isDateValid
      ),
    gender: yup.string().required('Este campo es requerido'),
    phoneNumber: yup
      .string()
      .matches(phoneNumberRegex, 'El teléfono debe ser un número sin espacios')
      .required('Este campo es requerido'),
    province: yup.object().required('Este campo es requerido').nullable(),
    city: yup.object().required('Este campo es requerido').nullable(),
  })

  const formDefaultValues = {
    email: '',
    name: '',
    lastName: '',
    documentNumber: '',
    documentType: 'dni',
    birthDate: '',
    gender: '',
    phoneNumber: '',
    province: null,
    city: null,
  }

  const {
    control,
    errors,
    handleSubmit,
    triggerValidation,
    register,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (!preloadPatient) return
    reset({ ...formDefaultValues, ...preloadPatient })
  }, [preloadPatient])

  const watchProvince = watch('province')

  useEffect(() => {
    if (!country) return
    onRequestProvinces(country)
  }, [country])

  useEffect(() => {
    if (!watchProvince) return
    onRequestDistricts({
      province: watchProvince.id,
      country: country,
    })
    setValue('city', null, true)
  }, [watchProvince])

  const onSubmit = (data) => {
    onPatientCreation({
      ...data,
      province: data.province.id,
      city: data.city.id,
      deal: preloadPatient.deal,
    })
  }

  useEffect(() => {
    if (!success) return
    onClose({ schedule: preloadPatient.schedule, patient })
  }, [success])

  useEffect(() => {
    register('province')
    register('city')
  }, [register])

  const hasCensusRecord =
    preloadPatient?.name ||
    preloadPatient?.lastName ||
    preloadPatient?.gender ||
    preloadPatient?.documentType ||
    preloadPatient?.birthDate

  return (
    <>
      <Drawer anchor='right' open={isOpen} onClose={onClose}>
        <Box className={classes.drawerContainer}>
          <IconButton className={classes.closeDrawerButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography
            variant='h4'
            color='primary'
            className={classes.drawerTitle}
          >
            Nuevo Paciente
          </Typography>
          {hasCensusRecord && (
            <Box className={classes.inputBox}>
              <Alert severity='info'>
                <AlertTitle>Información Precargada</AlertTitle>
                Hemos precargado ciertos datos, por favor verificar con el
                paciente
              </Alert>
            </Box>
          )}
          <Box>
            <form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              autoComplete='off'
            >
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='email'
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  label='Dirección de Email'
                  onBlur={() => triggerValidation('email')}
                  fullWidth
                  as={<TextField />}
                  disabled={!!preloadPatient?.email}
                />
              </Box>
              <Box className={classes.inputBox}>
                <SelectField
                  error={!!errors.documentType}
                  label='Tipo de Documento'
                  options={[
                    { value: 'dni', label: 'DNI' },
                    { value: 'passport', label: 'Pasaporte' },
                  ]}
                  control={control}
                  name='documentType'
                  helperText={
                    errors.documentType && errors.documentType.message
                  }
                  onBlur={() => triggerValidation('documentType')}
                  fullWidth
                />
              </Box>
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='documentNumber'
                  error={!!errors.documentNumber}
                  helperText={
                    errors.documentNumber && errors.documentNumber.message
                  }
                  label='Número de Documento'
                  onBlur={() => triggerValidation('documentNumber')}
                  fullWidth
                  as={<TextField />}
                  disabled
                />
              </Box>
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='name'
                  error={!!errors.name}
                  helperText={errors.name && errors.name.message}
                  label='Nombre'
                  onBlur={() => triggerValidation('name')}
                  fullWidth
                  as={<TextField />}
                />
              </Box>
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='lastName'
                  error={!!errors.lastName}
                  helperText={errors.lastName && errors.lastName.message}
                  label='Apellido'
                  onBlur={() => triggerValidation('lastName')}
                  fullWidth
                  as={<TextField />}
                />
              </Box>
              <Box className={classes.inputBox}>
                <SelectField
                  error={!!errors.gender}
                  label='Sexo'
                  options={[
                    { value: 'MALE', label: 'Masculino' },
                    { value: 'FEMALE', label: 'Femenino' },
                  ]}
                  control={control}
                  name='gender'
                  helperText={errors.gender && errors.gender.message}
                  onBlur={() => triggerValidation('gender')}
                  fullWidth
                />
              </Box>
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='phoneNumber'
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber && errors.phoneNumber.message}
                  label='Teléfono'
                  onBlur={() => triggerValidation('phone')}
                  fullWidth
                  as={<TextField />}
                />
              </Box>
              <Box className={classes.inputBox}>
                <FormControl className={classes.fullWidth}>
                  <Controller
                    control={control}
                    name='birthDate'
                    error={!!errors.birthDate}
                    helperText={errors.birthDate && errors.birthDate.message}
                    label='Fecha de Nacimiento'
                    onBlur={() => triggerValidation('birthDate')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    as={<TextField type='date' />}
                  />
                </FormControl>
              </Box>
              <Box className={classes.inputBox}>
                {locationLoading && !watchProvince ? (
                  <TextField
                    disabled
                    value='Cargando...'
                    label='Provincia o Departamento'
                    fullWidth
                  />
                ) : (
                  <Autocomplete
                    id='province'
                    disabled={!provinces}
                    options={provinces || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, v) => {
                      setValue('province', v, true)
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='province'
                        {...params}
                        error={!!errors.province}
                        helperText={errors.province && errors.province.message}
                        label='Provincia o Departamento'
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                )}
              </Box>
              <Box className={classes.inputBox}>
                {locationLoading ? (
                  <TextField
                    disabled
                    value='Cargando...'
                    label='Localidad'
                    fullWidth
                  />
                ) : (
                  <Autocomplete
                    disabled={!districts || !watchProvince}
                    id='city'
                    options={districts || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, v) => {
                      setValue('city', v, true)
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='city'
                        {...params}
                        error={!!errors.city}
                        helperText={errors.city && errors.city.message}
                        label='Localidad'
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                      />
                    )}
                  />
                )}
              </Box>
              {error && (
                <Box mt={3}>
                  <Alert severity='error'>
                    <AlertTitle>Error al crear el paciente</AlertTitle>
                    El email ya existe
                  </Alert>
                </Box>
              )}
              <Box mt={4} textAlign='center' position='relative'>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  color='primary'
                  loading={loading}
                >
                  CREAR PACIENTE
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Drawer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='success'>
          PACIENTE CREADO EXITOSAMENTE
        </Alert>
      </Snackbar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    locationLoading: getLocationLoading(state),
    provinces: getProvinces(state),
    districts: getDistricts(state),
    success: getSuccess(state),
    loading: getLoading(state),
    patient: getPatient(state),
    error: getError(state),
  }
}

export default connect(mapStateToProps, {
  onRequestProvinces: provincesGetRequest,
  onRequestDistricts: districtsGetRequest,
  onPatientCreation: patientCreationRequest,
  onResetPatientCreation: resetPatientCreation,
})(NewPatientDrawer)
