import * as R from 'ramda'

import { v1 as uuidv1 } from 'uuid'

import Amplify, { Auth, API, Storage, PubSub } from 'aws-amplify'
import { convertToBase64Image } from 'common/utils/awsUtils'
import { put, call } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'

export const topics = {
  ER: 'provider/ER',
  CLIENT: 'client',
}

const providerTopicPrefix = async (provider) => {
  const info = await Auth.currentCredentials()
  return `client/${info.identityId}/provider/${provider}`
}

export const subscribeToTopic = async ({ topic, provider, errorHandler }) => {
  if (topic === topics.CLIENT) topic = await providerTopicPrefix(provider)

  return eventChannel((emit) => {
    const subscription = PubSub.subscribe(
      `${process.env.REACT_APP_ENV}/${topic}`
    ).subscribe({
      next: (data) => {
        emit(data.value)
      },
      error: (error) => {
        errorHandler(error)
      },
      complete: () => console.log('Done'),
    })

    return () => {
      subscription.unsubscribe()
    }
  })
}

export const publishToTopic = async (
  topic,
  provider,
  header,
  messageProps = {}
) => {
  if (topic === topics.CLIENT) topic = await providerTopicPrefix(provider)

  return new Promise((resolve) => {
    PubSub.publish(`${process.env.REACT_APP_ENV}/${topic}`, {
      header: header,
      ...messageProps,
    })
    resolve()
  })
}

export const getProvider = (idProvider) => {
  return callAWS({ entity: `provider/${idProvider}`, method: 'GET' })
}

export const validatePhoneNumber = (phoneNumber, countryCode) => {
  return callAWS({
    entity: `user/validate/phone/${phoneNumber}/${countryCode}`,
    method: 'GET',
  })
}

export const forgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    Auth.forgotPassword(data.email)
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  })
}

export const registerUser = (data) => {
  const attributes = {
    email: data.email,
  }

  if (data.name) {
    attributes.given_name = data.name.trim()
    attributes.family_name = data.lastName.trim()
    attributes.gender = data.gender
    attributes.birthdate = data.birthDate
    attributes['custom:createProvider'] = data.createProvider.toString()

    if (data.phone) attributes['custom:phoneNumber'] = data.phone.toString()

    if (data.country) {
      attributes['custom:country'] = data.country
      attributes['custom:province'] = data.province
      attributes['custom:city'] = data.district
    }

    if (data.providerId) attributes['custom:idProvider'] = data.providerId
  }

  if (data.company) attributes['custom:company'] = data.company

  return new Promise((resolve, reject) => {
    Auth.signUp({ username: data.email, password: data.password, attributes })
      .then((result) => resolve(result))
      .catch((err) => reject(err))
  })
}

export const loginUser = (data) => {
  const { email, password } = data

  return new Promise((resolve, reject) => {
    Auth.signIn(email, password)
      .then((response) => {
        if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
          reject({
            code: 'NewPasswordRequired',
            cognitoUser: response,
          })
          return
        }
        resolve({
          user: {
            username: response.username,
            groups:
              response.signInUserSession.idToken.payload['cognito:groups'],
            ...response.attributes,
          },
        })
      })
      .catch((err) => {
        console.log('error', err)
        reject({ ...err })
      })
  })
}

export const completePasswordChallenge = (data) => {
  const { newPassword, cognitoUser } = data

  return new Promise((resolve, reject) => {
    Auth.completeNewPassword(cognitoUser, newPassword)
      .then((response) => {
        resolve({ user: response })
      })
      .catch((err) => {
        reject({ ...err, cognitoUser })
      })
  })
}

export const getFromS3 = (file) => {
  return new Promise((resolve, reject) => {
    const isProvider = file.key.indexOf('provider/') > -1
    const prefix = isProvider ? 'provider/' : 'private/'
    Storage.configure({
      customPrefix: {
        public: prefix,
      },
    })

    Storage.get(file.key.replace(prefix, ''), {
      download: true,
    })
      .then(async (response) => {
        const base64String = await convertToBase64Image(response)
        resolve({ ...response, Key: file.key, Image: base64String })
      })
      .catch((err) => reject(err))
  })
}

export const downloadFromS3 = (file) => {
  return new Promise((resolve, reject) => {
    const signedUrlExpireSeconds = 60 * 5 // your expiry time in seconds.

    Storage.configure({
      customPrefix: {
        public: 'private/',
      },
    })
    Storage.get(file.key.replace('private/', '').replace('company/', ''), {
      download: true,
      expires: signedUrlExpireSeconds,
    })
      .then((data) => {
        resolve({
          data,
          url: (window.webkitURL || window.URL).createObjectURL(data.Body),
          Key: file.key,
        })
      })
      .catch((err) => reject(err))
  })
}

export const uploadToS3 = (data) => {
  const { file, privateId, folder, companyId, assistantBucket } = data

  if (assistantBucket) {
    Amplify.configure({
      Storage: {
        AWSS3: {
          bucket: process.env.REACT_APP_AWS_S3_ASS_BUCKET,
        },
      },
    })
  }

  const getPrefix = () => {
    if (privateId) return 'private/'
    if (companyId) return 'company/'
    return 'provider/'
  }

  Storage.configure({
    customPrefix: {
      public: getPrefix(),
    },
  })

  const getFilePrefix = () => {
    if (privateId) return `${privateId}/${folder}/`
    if (companyId) return `${companyId}/${folder}/`
    return ''
  }

  return Storage.put(
    `${getFilePrefix()}${uuidv1()}.${R.last(file.name.split('.'))}`,
    file
  )
}

export const callAWS = async ({ params, entity, method, body = null }) => {
  return new Promise(async (resolve, reject) => {
    const apiName = 'api'
    const path = `/${entity}`
    const myInit = {
      queryStringParameters: params,
      body,
    }

    let apiCall

    switch (method?.toLowerCase()) {
      case 'get':
        apiCall = API.get(apiName, path, myInit)
        break
      case 'download':
        apiCall = API.get(apiName, path, {
          ...myInit,
          responseType: 'blob',
          response: true,
        })
        break
      case 'post':
        apiCall = API.post(apiName, path, myInit)
        break
      case 'put':
        apiCall = API.put(apiName, path, myInit)
        break
      case 'delete':
        apiCall = API.del(apiName, path, myInit)
        break
      case 'patch':
        apiCall = API.patch(apiName, path, myInit)
        break
      default:
        throw new Error('Error')
    }

    apiCall
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        reject(error.response)
      })
  })
}
