import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import * as R from 'ramda'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import MaterialTable from 'material-table'

import {
  userGetRequest,
  userCreateRequest,
  userCreateReset,
} from 'common/actions'
import { getError, getLoading, getUsersApi, getSuccess } from 'common/reducers/user'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  inputBox: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  table: {
    minWidth: 650,
  },
  newButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  newUserDialog: {
    minWidth: '25rem',
  },
}))

const Users = ({
  users,
  onRequestUsers,
  onCreateUser,
  onUserCreateReset,
  success,
}) => {
  const classes = useStyles()
  const [newUserModal, setNewUserModal] = useState(false)

  useEffect(() => {
    onRequestUsers('assistant')
  }, [])

  const schema = yup.object().shape({
    name: yup.string().required('Este campo es requerido'),
    lastName: yup.string().required('Este campo es requerido'),
    email: yup
      .string()
      .required('Este campo es requerido')
      .email('Por favor ingrese un email válido')
      .nullable(),
  })

  const { control, errors, handleSubmit, triggerValidation } = useForm({
    defaultValues: {
      email: '',
      name: '',
      lastName: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = (data) => {
    setNewUserModal(false)
    onCreateUser({ type: 'assistant', data })
  }

  useEffect(() => {
    if (!success) return
    onRequestUsers('assistant')
    onUserCreateReset()
  }, [success])

  return (
    <>
        <MaterialTable
          columns={[
            { title: 'Nombre', field: 'name' },
            { title: 'Apellido', field: 'lastName' },
            { title: 'Dirección de Email', field: 'email' },
            {
              title: 'Activo',
              field: 'enabled',
              render: (rowData) => (rowData.enabled ? 'Si' : 'No'),
            },
          ]}
          data={users || []}
          title='Asistentes'
          options={{
            // actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <AddCircleIcon color='primary' />,
              tooltip: 'Nuevo Usuario',
              position: 'toolbar',
              onClick: () => {
                setNewUserModal(true)
              },
            },
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'filas',
              labelRowsPerPage: 'Filas por página: ',
            },
            toolbar: {
              searchPlaceholder: 'Búsqueda',
              searchTooltip: 'Búsqueda',
            },
            header: {
              actions: 'Acciones',
            },
            body: {
              emptyDataSourceMessage: 'No hay usuarios',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
              editTooltip: 'Editar',
            },
          }}
        />
        <Dialog open={newUserModal} onClose={() => setNewUserModal(false)}>
          <DialogTitle id='alert-dialog-title'>Crear Nuevo Asistente</DialogTitle>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.newUserDialog}>
              <Box>
                <Controller
                  control={control}
                  name='name'
                  error={!!errors.name}
                  helperText={errors.name && errors.name.message}
                  label='Nombre'
                  onBlur={() => triggerValidation('name')}
                  fullWidth
                  as={<TextField />}
                />
              </Box>
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='lastName'
                  error={!!errors.lastName}
                  helperText={errors.lastName && errors.lastName.message}
                  label='Apellido'
                  onBlur={() => triggerValidation('lastName')}
                  fullWidth
                  as={<TextField />}
                />
              </Box>
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='email'
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  label='Dirección de Email'
                  onBlur={() => triggerValidation('email')}
                  fullWidth
                  as={<TextField />}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={() => setNewUserModal(false)}>
                Volver
              </Button>
              <Button type='submit' color='primary' variant='contained'>
                CREAR
              </Button>
            </DialogActions>
          </form>
        </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    users: getUsersApi(state),
    success: getSuccess(state),
  }
}

export default connect(mapStateToProps, {
  onRequestUsers: userGetRequest,
  onCreateUser: userCreateRequest,
  onUserCreateReset: userCreateReset,
})(Users)
