import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import dayjs from 'dayjs'
import * as R from 'ramda'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useHistory } from 'react-router-dom'
import {
  Box,
  TextField,
  Grid,
  Button,
  CircularProgress,
  FormControl,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import getProvinceLabel from 'common/utils/getProvinceLabel'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { SelectField, PhoneField } from 'common/components'
import defaultPhoneNumbers from 'common/utils/defaultPhoneNumbers'

import { getCompany } from 'common/reducers/companies'

import { phoneNumberRegex } from 'common/utils/constants'

import {
  provincesGetRequest,
  districtsGetRequest,
  requestSpecialtiesGet,
} from 'common/actions'
import { getError, getLoading, getProviders } from 'common/reducers/providers'
import {
  getLoading as getLocationLoading,
  getCountries,
  getProvinces,
  getDistricts,
} from 'common/reducers/locations'
import { getSpecialties } from 'common/reducers/specialties'
import { validatePhoneNumber } from 'common/services/api'
import { getAllTimeZones } from 'common/utils/timezones'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  fullWidth: {
    width: '100%',
  },
  tab: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepContent: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
  },
}))

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const disabledFields = {
  true: {
    name: true,
    lastName: true,
    birthDate: true,
    gender: true,
    phone: true,
    specialties: true,
    country: true,
    province: true,
    district: true,
  },
  false: {},
}

const PersonalData = ({
  error,
  loading,
  provider,
  locationLoading,
  countries,
  provinces,
  districts,
  requestProvinces,
  requestDistricts,
  specialties,
  onRequestSpecialties,
  onSubmit,
  company,
  loggedUserCompany,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const [phoneCountry, setPhoneCountry] = useState(null)

  useEffect(() => {
    if (!specialties) onRequestSpecialties('all')
  }, [])

  const schema = yup.object().shape({
    name: yup.string().required('Este campo es requerido'),
    lastName: yup.string().required('Este campo es requerido'),
    birthDate: yup
      .mixed()
      .required('Este campo es requerido')
      .test(
        'is-legal',
        'Debe ser mayor de 18 años',
        (value) => dayjs().diff(dayjs(value), 'years') > 18
      ),
    gender: yup.string().required('Este campo es requerido'),
    phone: yup
      .string()
      .matches(phoneNumberRegex, 'El teléfono debe ser un número sin espacios')
      .required('Este campo es requerido'),
    country: yup.object().required('Este campo es requerido').nullable(),
    province: yup.object().required('Este campo es requerido').nullable(),
    district: yup.object().required('Este campo es requerido').nullable(),
    timezone: yup.string().required('Este campo es requerido').nullable(),
  })

  const {
    control,
    errors,
    handleSubmit,
    triggerValidation,
    reset,
    register,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      name: '',
      lastName: '',
      birthDate: '',
      gender: '',
      phone: '',
      specialties: [],
      country: null,
      province: null,
      district: null,
      timezone: browserTimezone,
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (!provider) return
    reset(provider)
    if (provider.country)
      requestProvinces(provider.country.id || provider.country)
    if (provider.province)
      requestDistricts({
        province: provider.province.id,
        country: provider.country.id,
      })
  }, [provider])

  const watchCountry = watch('country')

  useEffect(() => {
    if (!watchCountry) return
    requestProvinces(watchCountry.id)
    if (R.equals(watchCountry.id, provider.country.id)) return
    setValue('province', null, true)
    setValue('district', null, true)
  }, [watchCountry])

  const watchProvince = watch('province')

  const watchDistrict = watch('district')

  useEffect(() => {
    if (!watchProvince) return
    requestDistricts({
      province: watchProvince.id,
      country: watchCountry.id,
    })
    if (R.equals(watchProvince.id, provider.province.id)) return
    setValue('district', null, true)
  }, [watchProvince])

  useEffect(() => {
    register('country')
    register('province')
    register('district')
    register('timezone')
  }, [register])

  const submit = async (data) => {
    try {
      const validPhoneNumber = await validatePhoneNumber(
        data.phone,
        phoneCountry.countryCode
      )
      onSubmit({
        ...data,
        phone: validPhoneNumber.phoneNumber,
        country: data.country.id,
        province: data.province.id,
        district: data.district.id,
      })
    } catch (e) {
      console.error(e)
      setError('phone', { message: 'El número de teléfono no es válido' })
    }
  }

  useEffect(() => {
    if ((!company && !loggedUserCompany) || !!watch('phone')) return
    setValue(
      'phone',
      defaultPhoneNumbers[(company || loggedUserCompany)?.country?.shortName] ||
        defaultPhoneNumbers.AR
    )
  }, [company, loggedUserCompany])

  const timezones = getAllTimeZones()

  return (
    <Grid container>
      <Grid item xs={1} md={2} />
      <Grid item xs={10} md={8}>
        {(loading || locationLoading) && <CircularProgress />}
        <form noValidate onSubmit={handleSubmit(submit)}>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='name'
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              disabled={disabledFields[provider.enabled].name}
              label='Nombre'
              onBlur={() => triggerValidation('name')}
              fullWidth
              defaultValue=''
              as={<TextField />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='lastName'
              error={!!errors.lastName}
              helperText={errors.lastName && errors.lastName.message}
              disabled={disabledFields[provider.enabled].lastName}
              label='Apellido'
              onBlur={() => triggerValidation('lastName')}
              fullWidth
              defaultValue=''
              as={<TextField />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <SelectField
              error={errors.gender}
              label='Sexo'
              disabled={disabledFields[provider.enabled].gender}
              options={[
                { value: 'MALE', label: 'Masculino' },
                { value: 'FEMALE', label: 'Femenino' },
              ]}
              control={control}
              name='gender'
              helperText={errors.gender && errors.gender.message}
              onBlur={() => triggerValidation('gender')}
              fullWidth
              defaultValue=''
            />
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='phone'
              error={!!errors.phone}
              disabled={disabledFields[provider.enabled].phone}
              helperText={errors.phone && errors.phone.message}
              label='Teléfono'
              onBlur={() => triggerValidation('phone')}
              fullWidth
              defaultValue=''
              onCountrySelected={(country) => setPhoneCountry(country)}
              as={<PhoneField />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <FormControl className={classes.fullWidth}>
              <Controller
                control={control}
                name='birthDate'
                error={!!errors.birthDate}
                disabled={disabledFields[provider.enabled].birthDate}
                helperText={errors.birthDate && errors.birthDate.message}
                label='Fecha de Nacimiento'
                onBlur={() => triggerValidation('birthDate')}
                fullWidth
                InputLabelProps={{ shrink: true }}
                defaultValue=''
                as={<TextField type='date' />}
              />
            </FormControl>
          </Box>
          <Box className={classes.inputBox}>
            {countries && (
              <Autocomplete
                id='country'
                options={R.filter(
                  R.propEq('id', company?.country?.id),
                  countries
                )}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => {
                  setValue('country', v, true)
                }}
                defaultValue={watchCountry || ''}
                value={watchCountry}
                disabled={disabledFields[provider.enabled].country}
                renderInput={(params) => (
                  <TextField
                    name='country'
                    {...params}
                    error={!!errors.country}
                    helperText={errors.country && errors.country.message}
                    label='País'
                    fullWidth
                  />
                )}
              />
            )}
          </Box>
          <Box className={classes.inputBox}>
            {locationLoading && !watchProvince ? (
              <TextField
                disabled
                value='Cargando...'
                label={getProvinceLabel(
                  (company || loggedUserCompany)?.country?.shortName
                )}
                fullWidth
              />
            ) : (
              <Autocomplete
                id='province'
                options={provinces || []}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => {
                  setValue('province', v, true)
                }}
                defaultValue={watchProvince || ''}
                value={watchProvince}
                disabled={
                  !provinces ||
                  !watchCountry ||
                  disabledFields[provider.enabled].province
                }
                renderInput={(params) => (
                  <TextField
                    name='province'
                    {...params}
                    error={!!errors.province}
                    helperText={errors.province && errors.province.message}
                    label={getProvinceLabel(
                      (company || loggedUserCompany)?.country?.shortName
                    )}
                    fullWidth
                  />
                )}
              />
            )}
          </Box>
          <Box className={classes.inputBox}>
            {locationLoading ? (
              <TextField
                disabled
                value='Cargando...'
                label='Localidad'
                fullWidth
              />
            ) : (
              <Autocomplete
                id='district'
                options={districts || []}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => {
                  setValue('district', v, true)
                }}
                defaultValue={watchDistrict || ''}
                value={watchDistrict}
                disabled={
                  !districts ||
                  !watchProvince ||
                  disabledFields[provider.enabled].district
                }
                renderInput={(params) => (
                  <TextField
                    name='district'
                    {...params}
                    error={!!errors.district}
                    helperText={errors.district && errors.district.message}
                    label='Localidad'
                    fullWidth
                  />
                )}
              />
            )}
          </Box>
          <Box className={classes.inputBox}>
            <Autocomplete
              id='timezone'
              options={timezones}
              getOptionLabel={(option) => option}
              value={watch('timezone') || browserTimezone}
              onChange={(e, v) => {
                setValue('timezone', v || browserTimezone, true)
              }}
              renderInput={(params) => (
                <TextField
                  name='timezone'
                  {...params}
                  error={!!errors.timezone}
                  helperText={errors.timezone && errors.timezone.message}
                  label='Huso Horario'
                  fullWidth
                />
              )}
            />
          </Box>
          <Box mt={4} textAlign='center'>
            <Button type='submit' variant='contained' color='primary'>
              CONTINUAR
            </Button>
          </Box>
        </form>
      </Grid>
      <Grid item xs={1} md={2} />
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    providers: getProviders(state),
    locationLoading: getLocationLoading(state),
    countries: getCountries(state),
    provinces: getProvinces(state),
    districts: getDistricts(state),
    specialties: getSpecialties(state),
    company: getCompany(state),
  }
}

export default connect(mapStateToProps, {
  requestProvinces: provincesGetRequest,
  requestDistricts: districtsGetRequest,
  onRequestSpecialties: requestSpecialtiesGet,
})(PersonalData)
