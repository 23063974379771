import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import * as R from 'ramda'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  TextField,
  Grid,
  Link,
  Button,
  CircularProgress,
  Chip,
  Avatar,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { SelectField } from 'common/components'
import { convertToBase64Image } from 'common/utils/awsUtils'
import { isDateValid } from 'common/utils/datetime'
import getCurrency from 'common/utils/getCurrency'

import {
  requestSpecialtiesGet,
  fileUploadRequest,
  fileGetRequest,
} from 'common/actions'
import {
  getError,
  getLoading,
  getSpecialties,
} from 'common/reducers/specialties'

import {
  getError as getUploadError,
  getLoading as getUploadLoading,
  getUploadResponse,
  getFiles,
} from 'common/reducers/files'

const muiDateFormat = 'YYYY-MM-DD'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  checkboxBox: {
    textAlign: 'left',
  },
  selectBox: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  avatar: {
    width: '10rem',
    height: '10rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#ccc',
  },
  uploadBtn: {
    marginTop: theme.spacing(3),
  },
  fileInput: {
    display: 'none',
  },
  warning: {
    textAlign: 'left',
    color: '#ff9800',
  },
}))

const disabledFields = {
  true: {
    title: true,
    license: true,
    selectedSpecialties: true,
    profilePicture: false,
    experienceSince: true,
    tagLine: true,
    consultationPrice: false,
    allowPrivateConsultation: false,
    price: false,
    coefficient: false,
  },
  false: {},
  undefined: {},
}

const ProfessionalData = ({
  error,
  loading,
  specialties,
  onRequestSpecialties,
  onSubmit,
  onRequestUpload,
  fileUploadError,
  files,
  onRequestFile,
  uploadResponse,
  provider,
  onGoBack,
  changeImage,
  company,
  isAdmin,
}) => {
  const classes = useStyles()
  const fileInputRef = useRef(null)
  const [avatarImage, setAvatarImage] = useState(null)

  const schema = yup.object().shape({
    title: yup.string().required('Este campo es requerido'),
    license: yup.string(),
    selectedSpecialties: yup
      .array()
      .of(yup.object().shape({ id: yup.string(), name: yup.string() }))
      .required('Seleccione al menos una especialidad'),
    profilePicture: yup
      .object()
      .shape({ key: yup.string(), bucket: yup.string() }),
    experienceSince: yup
      .string()
      .required('Este campo es requerido')
      .test(
        'date-format',
        'La fecha no es válida. El formato es DD/MM/YYYY',
        isDateValid
      ),
    tagLine: yup.string().required('Este campo es requerido'),
    consultationPrice: yup
      .number()
      .nullable()
      .typeError('El precio debe ser un número')
      .min(0, 'El número no puede ser menor a 0')
      .test('consultationPrice-required', 'Este campo es requerido', (val) =>
        isAdmin ? !R.isEmpty(val) : true
      ),
    price: yup
      .number()
      .nullable()
      .typeError('El precio debe ser un número')
      .min(0, 'El número no puede ser menor a 0')
      .test('price-required', 'Este campo es requerido', (val) =>
        isAdmin ? true : !R.isEmpty(val)
      ),
    coefficient: yup
      .number()
      .nullable()
      .typeError('El precio debe ser un número')
      .min(0, 'El número no puede ser menor a 0')
      .test('coefficient-required', 'Este campo es requerido', (val) =>
        isAdmin ? true : !!val
      ),
    allowPrivateConsultation: yup.bool(),
  })

  const {
    register,
    control,
    errors,
    handleSubmit,
    triggerValidation,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      title: '',
      license: '',
      selectedSpecialties: [],
      profilePicture: '',
      experienceSince: '',
      tagLine: '',
      consultationPrice: '',
      allowPrivateConsultation: true,
      price: '0',
      coefficient: '1.0',
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (!provider) return
    reset({
      ...provider,
      price: isAdmin ? provider.consultationPrice : '',
      allowPrivateConsultation: provider.allowPrivateConsultation || true,
      coefficient: '1.0',
      experienceSince: provider.experienceSince
        ? dayjs(provider.experienceSince).format(muiDateFormat)
        : '',
    })
    if (provider.specialties)
      setValue(
        'selectedSpecialties',
        provider.specialties?.map((s) => ({ id: s }))
      )

    if (
      provider.profilePicture?.key &&
      changeImage &&
      !files[provider.profilePicture.key]
    ) {
      onRequestFile(provider.profilePicture)
    }
  }, [provider])

  useEffect(() => {
    if (
      avatarImage ||
      !R.keys(files).length ||
      !changeImage ||
      !files[watch('profilePicture')?.key || provider.profilePicture?.key]
    )
      return
    setAvatarImage(
      files[watch('profilePicture')?.key || provider.profilePicture?.key].Image
    )
  }, [files])

  useEffect(() => {
    if (!uploadResponse || !changeImage) return
    const fileObj = {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      key: `provider/${uploadResponse.key}`,
    }
    onRequestFile(fileObj)
    setValue('profilePicture', fileObj)
  }, [uploadResponse])

  useEffect(() => {
    if (!specialties) onRequestSpecialties('all')
  }, [])

  useEffect(() => {
    register('selectedSpecialties')
    register('profilePicture')
  }, [register])

  useEffect(() => {
    if (!specialties || !provider || !provider.specialties) return
    setValue(
      'selectedSpecialties',
      R.innerJoin(
        (s, i) => R.equals(s.id, i),
        specialties,
        provider.specialties
      )
    )
  }, [specialties, provider, register])

  const handleUpload = () => {
    const file = fileInputRef?.current?.files[0]
    setAvatarImage(null)
    setValue('profilePicture', null)
    onRequestUpload({ file })
  }

  if (loading) return <CircularProgress />

  return (
    <Grid container>
      <Grid item xs={1} md={2} />
      <Grid item xs={10} md={8}>
        <form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          {changeImage && (
            <Box className={classes.inputBox}>
              <Avatar src={avatarImage} className={classes.avatar} />
              <Button
                color='primary'
                className={classes.uploadBtn}
                onClick={() => fileInputRef.current.click()}
              >
                SUBIR IMAGEN DE PERFIL
              </Button>
              <TextField
                className={classes.fileInput}
                name='file'
                label='Foto de Perfil'
                fullWidth
                type='file'
                onChange={handleUpload}
                inputProps={{ ref: fileInputRef, accept: 'image/*' }}
                disabled={disabledFields[provider?.enabled].profilePicture}
              />
            </Box>
          )}
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='tagLine'
              defaultValue=''
              error={!!errors.tagLine}
              helperText={errors.tagLine && errors.tagLine.message}
              label='Título de Grado'
              onBlur={() => triggerValidation('tagLine')}
              fullWidth
              disabled={disabledFields[provider?.enabled].tagLine}
              as={<TextField />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <SelectField
              error={!!errors.title}
              label='Prefijo'
              options={[
                { value: 'Dr.', label: 'Dr.' },
                { value: 'Dra.', label: 'Dra.' },
                { value: 'Lic.', label: 'Lic.' },
              ]}
              control={control}
              name='title'
              defaultValue=''
              disabled={disabledFields[provider?.enabled].title}
              helperText={errors.title && errors.title.message}
              onBlur={() => triggerValidation('title')}
              fullWidth
            />
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='license'
              defaultValue=''
              error={!!errors.license}
              helperText={errors.license && errors.license.message}
              label='Matrícula'
              disabled={disabledFields[provider?.enabled].license}
              onBlur={() => triggerValidation('license')}
              fullWidth
              as={<TextField />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='experienceSince'
              defaultValue=''
              error={!!errors.experienceSince}
              helperText={
                errors.experienceSince && errors.experienceSince.message
              }
              label='Fecha Inicio Actividades'
              disabled={disabledFields[provider?.enabled].experienceSince}
              onBlur={() => triggerValidation('experienceSince')}
              fullWidth
              InputLabelProps={{ shrink: true }}
              as={<TextField type='date' />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Box className={classes.checkboxBox}>
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    name='allowPrivateConsultation'
                    error={!!errors.allowPrivateConsultation}
                    defaultValue
                    fullWidth
                    disabled={
                      disabledFields[provider?.enabled].allowPrivateConsultation
                    }
                    render={(props) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => {
                          setValue('consultationPrice', '0', true)
                          setValue('price', '0', true)
                          props.onChange(e.target.checked)
                        }}
                      />
                    )}
                  />
                }
                label='¿Atiende Particular?'
              />
            </Box>
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              disabled={
                !watch('allowPrivateConsultation') ||
                disabledFields[provider?.enabled].price
              }
              name='price'
              defaultValue=''
              error={!!errors.price}
              helperText={errors.price && errors.price.message}
              label={`Precio de la Consulta Particular (${getCurrency(
                company?.country?.shortName
              )})`}
              onBlur={() => triggerValidation('price')}
              fullWidth
              inputProps={{
                onChange: (e) =>
                  setValue(
                    'consultationPrice',
                    parseFloat(e.target.value) *
                      parseInt(watch('coefficient') || '1'),
                    true
                  ),
              }}
              as={<TextField type='number' />}
            />
            {!isAdmin &&
              watch('allowPrivateConsultation') &&
              R.equals(watch('price'), '0') && (
                <Box className={classes.warning}>
                  Si no establece un precio, las consultas particulares serán
                  gratuitas.
                </Box>
              )}
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              disabled={
                !watch('allowPrivateConsultation') ||
                disabledFields[provider?.enabled].coefficient
              }
              name='coefficient'
              defaultValue=''
              error={!!errors.coefficient}
              helperText={errors.coefficient && errors.coefficient.message}
              label={`Coeficiente`}
              onBlur={() => triggerValidation('coefficient')}
              fullWidth
              inputProps={{
                onChange: (e) =>
                  setValue(
                    'consultationPrice',
                    parseFloat(e.target.value) *
                      parseInt(watch('price') || '1'),
                    true
                  ),
              }}
              as={<TextField type='number' />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              disabled={
                !watch('allowPrivateConsultation') ||
                disabledFields[provider?.enabled].consultationPrice
              }
              name='consultationPrice'
              defaultValue=''
              error={!!errors.consultationPrice}
              helperText={
                errors.consultationPrice && errors.consultationPrice.message
              }
              label={`Precio Final`}
              onBlur={() => triggerValidation('consultationPrice')}
              fullWidth
              inputProps={{ readOnly: !isAdmin }}
              as={<TextField type='consultationPrice' />}
            />
            {isAdmin &&
              watch('allowPrivateConsultation') &&
              R.equals(watch('consultationPrice'), '0') && (
                <Box className={classes.warning}>
                  Si no establece un precio, las consultas particulares serán
                  gratuitas.
                </Box>
              )}
          </Box>

          <Box className={classes.inputBox}>
            {specialties && (
              <Autocomplete
                multiple
                id='selectedSpecialties'
                options={specialties}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                disableCloseOnSelect
                defaultValue={watch('selectedSpecialties') || []}
                value={watch('selectedSpecialties') || []}
                onChange={(e, v) => {
                  setValue('selectedSpecialties', v, true)
                }}
                disabled={disabledFields[provider?.enabled].selectedSpecialties}
                renderInput={(params) => (
                  <TextField
                    name='selectedSpecialties'
                    {...params}
                    error={!!errors.selectedSpecialties}
                    helperText={
                      errors.selectedSpecialties &&
                      errors.selectedSpecialties.message
                    }
                    label='Especialidades'
                    fullWidth
                  />
                )}
              />
            )}
          </Box>
          <Box mt={4} textAlign='center'>
            {onGoBack && (
              <Button onClick={() => onGoBack()} className={classes.backBtn}>
                VOLVER
              </Button>
            )}
            <Button type='submit' variant='contained' color='primary'>
              CONTINUAR
            </Button>
          </Box>
        </form>
      </Grid>
      <Grid item xs={1} md={2} />
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state) || getUploadLoading(state),
    specialties: getSpecialties(state),
    fileUploadError: getUploadError(state),
    files: getFiles(state),
    uploadResponse: getUploadResponse(state),
  }
}

export default connect(mapStateToProps, {
  onRequestSpecialties: requestSpecialtiesGet,
  onRequestUpload: fileUploadRequest,
  onRequestFile: fileGetRequest,
})(ProfessionalData)
