import * as ActionTypes from '../actions'

const initialState = {
  companies: null,
  company: null, // user's company
  loading: false,
  error: null,
  success: false,
}

export function companiesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.COMPANIES_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        companies: [],
      }
    case ActionTypes.COMPANY_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        company: null,
      }
    case ActionTypes.COMPANY_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      }
    case ActionTypes.COMPANIES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action.data,
      }
    case ActionTypes.COMPANY_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.data,
      }
    case ActionTypes.COMPANY_CREATE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        success: true,
      }
    case ActionTypes.COMPANY_CREATE_FAILURE:
    case ActionTypes.COMPANY_GET_FAILURE:
    case ActionTypes.COMPANIES_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export function getCompany(state) {
  return state.companiesReducer.company
}

export function getCompanies(state) {
  return state.companiesReducer.companies
}

export function getLoading(state) {
  return state.companiesReducer.loading
}

export function getError(state) {
  return state.companiesReducer.error
}

export function getSuccess(state) {
  return state.companiesReducer.success
}
