import { ER_Header, ER_Modes, ER_Status_Types } from './constants'
import * as dayjs from 'dayjs'

export const getERStatus = (msg) => {
  const { header, type, timestamp } = msg

  switch (header) {
    case ER_Header.ENABLED:
      if (timestamp && dayjs(timestamp * 1000).diff(dayjs(), 'seconds') > 0)
        return { mode: ER_Modes.SNOOZED, until: timestamp }
      else return { mode: ER_Modes.ENABLED }
    case ER_Header.DISABLED:
      switch (type) {
        case ER_Status_Types.IDLE:
          return { mode: ER_Modes.DISABLED_IDLE }
        case ER_Status_Types.CONN:
          return { mode: ER_Modes.DISABLED_CONN }
        default:
          return { mode: ER_Modes.DISABLED }
      }
    default:
      break
  }
}

export const isEREnabled = (msg) => {
  const { header } = msg
  return header !== ER_Header.DISABLED
}
