import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Box, Grid, Link, Button, TextField, Snackbar } from '@material-ui/core'

import { Alert } from '@material-ui/lab'

import { makeStyles } from '@material-ui/core/styles'

import { useHistory } from 'react-router-dom'
import { Header } from '../layout'

import {
  countryPutReset,
  countryPutRequest,
  companyGetRequest,
} from 'common/actions'
import { LoadingButton } from 'common/components'

import { getCompany } from 'common/reducers/companies'
import { getSuccess, getLoading } from 'common/reducers/countries'
import { positiveDecimalsRegex } from 'common/utils/constants'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  loginCard: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  loginCardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
  },
}))

const Configuration = ({
  company,
  onSaveCountry,
  countrySuccess,
  loading,
  onCountryPutReset,
  onRetrieveCompany,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const [erPrice, setErPrice] = useState(0.0)

  useEffect(() => {
    onRetrieveCompany()
  }, [])

  useEffect(() => {
    if (!company) return
    onCountryPutReset()
    setErPrice(company.country.erPrice || 0)
  }, [company])

  const erPriceValid = erPrice.toString().match(positiveDecimalsRegex)

  useEffect(() => {
    if (!countrySuccess) return
    setSnackbarOpen(true)
    onCountryPutReset()
  }, [countrySuccess])

  return (
    <Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='success'>
          Cambios guardados exitosamente
        </Alert>
      </Snackbar>
      <Header />
      <Grid container className={classes.container}>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          Configuracion de la aplicacion
          <Box className={classes.marginTop}>
            <Box display='flex' alignItems='center'>
              <TextField
                name='erPrice'
                label='Precio de la consulta de Guardia'
                fullWidth
                disabled={!company}
                value={erPrice}
                error={!erPriceValid}
                helperText={
                  !erPriceValid &&
                  'El precio debe ser un numero decimal mayor a cero'
                }
                onChange={(e) => setErPrice(e.target.value)}
              />
              <LoadingButton
                variant='contained'
                color='primary'
                loading={loading}
                className={classes.marginLeft}
                onClick={() =>
                  onSaveCountry({ id: company.country.id, erPrice })
                }
              >
                Guardar
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    company: getCompany(state),
    countrySuccess: getSuccess(state),
    loading: getLoading(state),
  }
}

export default connect(mapStateToProps, {
  onSaveCountry: countryPutRequest,
  onCountryPutReset: countryPutReset,
  onRetrieveCompany: companyGetRequest,
})(Configuration)
