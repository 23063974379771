import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import dayjs from 'dayjs'
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  TextField,
  MenuItem,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { useLocation } from 'react-router-dom'
import { ER_Header, Topics } from 'common/utils/constants'
import { LoadingOverlay } from 'common/components'
import { getCurrentProvider } from 'common/reducers/providers'
import { getAppointments } from 'common/reducers/appointment'
import { exitableAppointment } from 'common/utils/appointmentStatus'
import { getClientResponse, getERResponse } from 'common/reducers/pubsub'
import { pubSubPublishRequest } from 'common/actions'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}))

const timeSliderMarks = [
  {
    value: 5,
    label: '5 minutos',
  },
  {
    value: 10,
    label: '10 minutos',
  },
  {
    value: 15,
    label: '15 minutos',
  },
  {
    value: 20,
    label: '20 minutos',
  },
  {
    value: 25,
    label: '25 minutos',
  },
  {
    value: 30,
    label: '30 minutos',
  },
  {
    value: 45,
    label: '45 minutos',
  },
  {
    value: 60,
    label: '60 minutos',
  },
]

const timeout = 20000 // 20 seconds
let interval

const NewPatient = ({
  provider,
  appointments,
  clientResponse,
  ERResponse,
  publishToTopic,
}) => {
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [delayedModal, setDelayedModal] = useState(false)
  const [selectedDelayed, setSelectedDelayed] = useState(5)
  const [showLoader, setShowLoader] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [notificationArrivedTime, setNotificationArrivedTime] = useState(null)
  const [timeLeft, setTimeLeft] = useState(0)

  const isInVideocall = location.pathname.includes('videocall')

  const currentAppointment = isInVideocall
    ? appointments[R.last(location.pathname.split('/'))]
    : null

  const onClose = () => {
    clearInterval(interval)
    setIsOpen(false)
  }

  useEffect(() => {
    if (!ERResponse) return
    const { header } = ERResponse
    if (R.equals(header, ER_Header.PATIENT_NEW) && !isOpen && !delayedModal) {
      if (isInVideocall) setSnackbarOpen(true)
      else {
        setIsOpen(true)
        setNotificationArrivedTime(new Date())
      }
      setDelayedModal(false)
      setSelectedDelayed(5)
    }
  }, [ERResponse])

  useEffect(() => {
    if (!clientResponse) return
    const { header } = clientResponse

    if (R.equals(header, ER_Header.PATIENT_TAKEN)) {
      setShowLoader(false)
    }
  }, [clientResponse])

  useEffect(() => {
    const tl = dayjs(notificationArrivedTime).add(timeout, 'milliseconds')
    if (!notificationArrivedTime || dayjs(notificationArrivedTime).isAfter(tl))
      return

    interval = setInterval(() => {
      const secondsLeft = tl.diff(dayjs(), 'seconds')
      if (secondsLeft <= 0) {
        setTimeLeft('0')
        setIsOpen(false)
        onClose()
        setDelayedModal(false)
        publishToTopic({
          provider: provider.id,
          header: ER_Header.PROVIDER_IGNORE,
        })
        return
      }
      setTimeLeft(secondsLeft)
    }, 1000)
    return () => clearInterval(interval)
  }, [notificationArrivedTime])

  const acceptNow = () => {
    clearInterval(interval)
    setDelayedModal(false)
    onClose()
    if (
      (currentAppointment && exitableAppointment(currentAppointment.status)) ||
      !currentAppointment
    ) {
      publishToTopic({
        provider: provider.id,
        header: ER_Header.PROVIDER_ACCEPT,
      })
      setShowLoader(true)
    } else setErrorModal(true)
  }

  return (
    <>
      {showLoader && <LoadingOverlay />}
      <Snackbar
        direction='left'
        open={snackbarOpen}
        autoHideDuration={timeout}
        onClose={() => {
          setSnackbarOpen(false)
          publishToTopic({
            provider: provider.id,
            header: ER_Header.PROVIDER_IGNORE,
          })
        }}
      >
        <SnackbarContent
          message='GUARDIA: Nuevo paciente en espera'
          action={
            <Button
              color='secondary'
              size='small'
              onClick={() => {
                setIsOpen(true)
                setNotificationArrivedTime(new Date())
                setSnackbarOpen(false)
              }}
            >
              Ver
            </Button>
          }
        />
      </Snackbar>
      <Dialog open={errorModal}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Es necesario finalizar la llamada en curso para iniciar la atención.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorModal(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpen}
        onClose={() => {
          publishToTopic({
            provider: provider.id,
            header: ER_Header.PROVIDER_IGNORE,
          })
          onClose()
        }}
      >
        <DialogTitle>
          GUARDIA: Nuevo paciente en espera ({timeLeft})
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Un nuevo paciente ha sido agregado a la lista de espera.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => acceptNow()}
            color='primary'
            variant='contained'
          >
            Atender Ahora
          </Button>
          <Button
            onClick={() => {
              clearInterval(interval)
              setDelayedModal(true)
              setIsOpen(false)
            }}
            color='secondary'
          >
            Atender en unos minutos
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={delayedModal}
        onClose={() => {
          setDelayedModal(false)
          publishToTopic({
            provider: provider.id,
            header: ER_Header.PROVIDER_IGNORE,
          })
        }}
      >
        <DialogTitle>Nuevo paciente en espera</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor seleccione en cuanto tiempo puede atender el paciente:
          </DialogContentText>
          <TextField
            select
            value={selectedDelayed}
            onChange={(e) => setSelectedDelayed(e.target.value)}
          >
            {timeSliderMarks.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              publishToTopic({
                provider: provider.id,
                header: ER_Header.PROVIDER_ACCEPT_DELAYED,
                messageProps: {
                  timestamp: dayjs().add(selectedDelayed, 'minutes').unix(),
                },
              })
              setDelayedModal(false)
              setShowLoader(false)
            }}
            color='primary'
            variant='contained'
          >
            Recordarme en {selectedDelayed} minutos
          </Button>
          <Button onClick={() => acceptNow()} color='secondary'>
            Atender ahora
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    provider: getCurrentProvider(state),
    appointments: getAppointments(state),
    clientResponse: getClientResponse(state),
    ERResponse: getERResponse(state),
  }
}

export default connect(mapStateToProps, {
  publishToTopic: pubSubPublishRequest,
})(NewPatient)
