import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import {
  Container,
  FormControl,
  Box,
  TextField,
  Button,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import MaterialTable, { MTableToolbar } from 'material-table'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { LoadingOverlay } from 'common/components'
import { reportsGetRequest, reportResultsRequest } from 'common/actions'
import { getError, getLoading, getReports } from 'common/reducers/reports'
import { Header } from '../layout'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  filterBar: {
    display: 'flex',
    alignItems: 'baseline',
    paddingLeft: theme.spacing(3),
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}))

const reduceWithIndex = R.addIndex(R.reduce)

const Reports = ({
  error,
  loading,
  reports,
  onRequestReports,
  success,
  onRequestResults,
}) => {
  const classes = useStyles()
  const { id } = useParams()
  const history = useHistory()
  const [currentReport, setCurrentReport] = useState(null)
  const [filterParams, setFilterParams] = useState({})

  useEffect(() => {
    if (!reports?.length) onRequestReports()
  }, [])

  useEffect(() => {
    if (!reports?.length) return
    const report = R.find(R.propEq('id', id), reports)
    setCurrentReport(report)
    setFilterParams(
      R.reduce(
        (acc, val) => ({ ...acc, [val.id]: filterParams[val.id] || null }),
        {},
        report.params
      )
    )
  }, [reports])

  const submit = () => {
    onRequestResults({ id, params: filterParams })
  }

  return (
    <>
      {loading && <LoadingOverlay />}
      <Header />
      <Container className={classes.container}>
        <Button
          onClick={() => history.goBack()}
          color='primary'
          variant='outlined'
          className={classes.marginBottom}
        >
          Volver
        </Button>
        <MaterialTable
          columns={currentReport?.results?.headers.map((h) => ({
            title: h,
            field: h,
          }))}
          data={
            currentReport?.results?.rows.map((r) =>
              reduceWithIndex(
                (acc, val, index) => ({ ...acc, [val]: r[index] }),
                {},
                currentReport.results.headers
              )
            ) || []
          }
          components={{
            Toolbar: (props) => (
              <Box>
                <MTableToolbar {...props}></MTableToolbar>
                {currentReport?.params.length && (
                  <Box className={classes.filterBar}>
                    {currentReport.params.map((p) => (
                      <FormControl className={classes.marginRight} key={p.id}>
                        {R.equals(p.type, 'date') ? (
                          <KeyboardDatePicker
                            id={p.id}
                            name={p.id}
                            label={p.name}
                            value={filterParams[p.id]}
                            onChange={(date) => {
                              setFilterParams({
                                ...filterParams,
                                [p.id]: date.format('YYYY-MM-DD'),
                              })
                              return { value: date }
                            }}
                            format='DD/MM/YYYY'
                            autoOk
                            variant='inline'
                            margin='normal'
                          />
                        ) : (
                          <TextField
                            type={p.type}
                            name={p.id}
                            label={p.name}
                            value={filterParams[p.id]}
                            fullWidth
                            onChange={(e) =>
                              setFilterParams({
                                ...filterParams,
                                [p.id]: e.target.value,
                              })
                            }
                          />
                        )}
                      </FormControl>
                    ))}
                    <FormControl className={classes.marginRight}>
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={R.any(
                          (p) => !filterParams[p],
                          R.keys(filterParams)
                        )}
                        onClick={submit}
                      >
                        CONSULTAR
                      </Button>
                    </FormControl>
                  </Box>
                )}
              </Box>
            ),
          }}
          title={currentReport?.name}
          options={{
            actionsColumnIndex: -1,
            search: !!currentReport?.results,
            exportButton: !!currentReport?.results,
          }}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'filas',
              labelRowsPerPage: 'Filas por página: ',
            },
            toolbar: {
              searchPlaceholder: 'Búsqueda',
              searchTooltip: 'Búsqueda',
            },
            header: {
              actions: 'Acciones',
            },
            body: {
              emptyDataSourceMessage:
                'No hay registros. Pruebe cambiando los filtros',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
              editTooltip: 'Editar',
            },
          }}
        />
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    reports: getReports(state),
  }
}

export default connect(mapStateToProps, {
  onRequestReports: reportsGetRequest,
  onRequestResults: reportResultsRequest,
})(Reports)
