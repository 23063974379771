import React from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import * as Sentry from '@sentry/react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getError as appointmentError } from 'common/reducers/appointment'
import { getError as fileError } from 'common/reducers/files'
import { getError as locationError } from 'common/reducers/locations'
import { getError as patientError } from 'common/reducers/patients'
import { getError as providerError } from 'common/reducers/providers'
import { getError as specialtyError } from 'common/reducers/specialties'
import { getError as userError } from 'common/reducers/user'

const useStyles = makeStyles((theme) => ({
  inputBox: {
    marginBottom: theme.spacing(3),
  },
}))

const allowedErrors = [
  'PasswordResetRequiredException',
  'UserNotConfirmedException',
  'UserNotFoundException',
  'InvalidParameterException',
  'NotAuthorizedException',
  'UsernameExistsException',
  'InvalidPasswordException',
  'NewPasswordRequired',
  'Not Found',
  'Bad Request',
]

const ErrorHappenedModal = ({ error }) => {
  const classes = useStyles()

  const reload = () => window.location.reload(true)

  if (error) {
    // console.error(error)
    Sentry.captureMessage(
      JSON.stringify(error, Object.getOwnPropertyNames(error))
    )
  }

  return (
    <Dialog
      open={
        !!error && !R.includes(error.code || error.data?.error, allowedErrors)
      }
      onClose={() => reload()}
    >
      <DialogTitle>Ha ocurrido un error</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ha ocurrido un error inesperado. Por favor refresque la página para
          poder continuar utilizando TeleMeditar.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => reload()} color='primary'>
          Refrescar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    error:
      appointmentError(state) ||
      fileError(state) ||
      locationError(state) ||
      patientError(state) ||
      providerError(state) ||
      specialtyError(state) ||
      userError(state),
  }
}

export default connect(mapStateToProps, {})(ErrorHappenedModal)
