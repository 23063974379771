import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { useQueryParam, StringParam } from 'use-query-params'
import { Box, Container, Tabs, Tab, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LoadingOverlay } from 'common/components'
import { Header } from '../layout'
import { getError, getLoading } from 'common/reducers/user'
import { getCompany } from 'common/reducers/companies'
import Administrators from './administrators'
import Assistants from './assistants'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}))

const TABS = {
  ADMINISTRATORS: 'administrators',
  ASSISTANTS: 'assistants',
}

const Users = ({ loading, company }) => {
  const classes = useStyles()
  const [currentTab, setCurrentTab] = useQueryParam('currentTab', StringParam)

  useEffect(() => {
    if (!currentTab) setCurrentTab(TABS.ADMINISTRATORS)
  }, [])

  return (
    <>
      {loading && <LoadingOverlay />}
      <Header />
      <Container className={classes.container}>
        <Paper>
          {company?.canCreateAssistant && (
            <Tabs
              value={currentTab || TABS.ADMINISTRATORS}
              onChange={(e, t) => setCurrentTab(t)}
              aria-label='simple tabs example'
            >
              <Tab label='Administradores' value={TABS.ADMINISTRATORS} />
              <Tab label='Asistentes' value={TABS.ASSISTANTS} />
            </Tabs>
          )}
          {R.equals(currentTab, TABS.ADMINISTRATORS) && (
            <Box>
              <Administrators />
            </Box>
          )}
          {R.equals(currentTab, TABS.ASSISTANTS) && (
            <Box>
              <Assistants />
            </Box>
          )}
        </Paper>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    company: getCompany(state),
  }
}

export default connect(mapStateToProps, {})(Users)
