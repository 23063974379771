import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Link,
  Button,
  Menu,
  MenuItem,
  Box,
  ButtonBase,
  Avatar,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import { getCompany } from 'common/reducers/companies'
import { getSession } from 'common/utils/awsConfig'

import { CompanyTypes } from 'common/utils/constants'

import logo from 'assets/TeleMeditar_Logo_SM.png'

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  link: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  btnLogo: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  btnText: {
    textAlign: 'right',
    color: theme.palette.primary.main,
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
  },
  logo: {
    width: '10rem',
  },
}))

const Header = ({ company }) => {
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [loggedUser, setLoggedUser] = useState(null)
  const location = useLocation()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      const groups = user.signInUserSession.idToken.payload['cognito:groups']
      setLoggedUser({ username: user.username, ...user.attributes, groups })
    })
  }, [location])

  console.log(company)

  return (
    <AppBar position='static'>
      <Toolbar>
        <Box className={classes.flexGrow}>
          <img src={logo} className={classes.logo} />
          {company && loggedUser && (
            <>
              {!R.equals(company?.companyType, CompanyTypes.CLIENT) && (
                <Link
                  className={classes.link}
                  onClick={() => history.push('/clients')}
                >
                  Clientes
                </Link>
              )}
              {company && (
                <>
                  <Link
                    className={classes.link}
                    onClick={() => history.push('/deals')}
                  >
                    Convenios
                  </Link>
                  <Link
                    className={classes.link}
                    onClick={() => history.push('/users')}
                  >
                    Usuarios
                  </Link>
                </>
              )}
              {R.includes('administrators', loggedUser.groups) && (
                <Link
                  className={classes.link}
                  onClick={() => history.push('/specialties')}
                >
                  Especialidades
                </Link>
              )}
              {company && (
                <Link
                  className={classes.link}
                  onClick={() => history.push('/providers')}
                >
                  Profesionales
                </Link>
              )}
              {(R.includes('administrators', loggedUser.groups) ||
                R.includes('clientAdministrator', loggedUser.groups)) &&
                company.companyType == CompanyTypes.MEDITAR && (
                  <Link
                    className={classes.link}
                    onClick={() => history.push('/reports')}
                  >
                    Reportes
                  </Link>
                )}
            </>
          )}
        </Box>
        <Box>
          <ButtonBase onClick={handleMenu} color='primary'>
            <Box className={classes.btnText}>
              <Typography variant='body2'>{loggedUser?.given_name}</Typography>
              <Typography variant='button'>{company?.name}</Typography>
            </Box>
            <Box className={classes.btnLogo}>
              <Avatar className={classes.avatar}>
                {loggedUser?.given_name[0]}
                {loggedUser?.family_name[0]}
              </Avatar>
            </Box>
          </ButtonBase>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            {R.includes(company?.companyType, [
              CompanyTypes.MEDITAR,
              CompanyTypes.PARTNER,
            ]) && (
              <MenuItem onClick={() => history.push('/configuration')}>
                Configuración
              </MenuItem>
            )}
            <MenuItem onClick={() => (window.location.href = '/logout')}>
              Cerrar Sesión
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => {
  return { company: getCompany(state) }
}

export default connect(mapStateToProps, {})(Header)
