import React, { useEffect } from 'react'
import { Switch, BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Wizard from './wizard'
import Videocall from './videocall'
import Dashboard from './dashboard'
import Appointments from './appointments'
import Schedules from './schedules'
import Profile from './profile'
import PrivateRoute from './privateRoute'

import {
  requestUpcomingAppointments,
  providerEditRequest,
  pubSubClientSubscribeRequest,
} from 'common/actions'
import { getToken } from 'common/utils/firebase'
import { getCurrentProvider } from 'common/reducers/providers'
import PatientTaken from './emergencyRoom/patientTaken'
import NewPatient from './emergencyRoom/newPatient'
import AppointmentStart from './emergencyRoom/appointmentStart'
import Configuration from './configuration'

const Providers = ({
  provider,
  onProviderUpdate,
  requestAppointments,
  onSubscribeToTopic,
}) => {
  useEffect(() => {
    requestAppointments()
  }, [])

  useEffect(() => {
    if (!provider) return
    getToken().then((token) => {
      onProviderUpdate({ id: provider.id, pushId: token })
    })
    onSubscribeToTopic({ provider: provider.id })
  }, [provider])

  return (
    <BrowserRouter basename='/providers'>
      <PatientTaken />
      <NewPatient />
      <AppointmentStart />
      <Switch>
        <PrivateRoute requiresWizard={false} path='/wizard'>
          <Wizard />
        </PrivateRoute>
        <PrivateRoute path='/schedules'>
          <Schedules />
        </PrivateRoute>
        <PrivateRoute path='/videocall/:id'>
          <Videocall />
        </PrivateRoute>
        <PrivateRoute path='/appointments'>
          <Appointments />
        </PrivateRoute>
        <PrivateRoute path='/configuration'>
          <Configuration />
        </PrivateRoute>
        <PrivateRoute path='/'>
          <Dashboard />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({
  provider: getCurrentProvider(state),
})

export default connect(mapStateToProps, {
  requestAppointments: requestUpcomingAppointments,
  onProviderUpdate: providerEditRequest,
  onSubscribeToTopic: pubSubClientSubscribeRequest,
})(Providers)
