import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import dayjs from 'dayjs'
import {
  Box,
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableHead,
  TableContainer,
  Paper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import SpecialtyLicenseModal from './SpecialtyLicenseModal'
import { provincesGetRequest } from 'common/actions'
import { getLoading, getProvinces } from 'common/reducers/locations'
import getProvinceLabel from 'common/utils/getProvinceLabel'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  selectBox: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  specialtyCard: {
    marginBottom: theme.spacing(2),
  },
  specialtyName: {
    marginBottom: theme.spacing(2),
  },
  backBtn: {
    marginRight: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  province: {
    width: '30%',
  },
  entity: {
    width: '20%',
  },
  date: {
    width: '20%',
  },
  license: {
    width: '20%',
  },
  actions: {
    width: '10%',
  },
}))

const disabledFields = {
  true: {
    addLicense: true,
    deleteLicense: true,
  },
  false: {},
}

const Specialties = ({
  selectedSpecialties,
  onSubmit,
  onGoBack,
  requestProvinces,
  provinces,
  loading,
  provider,
  preloadedLicenses,
  continueBtnLabel,
  company,
  loggedUserCompany,
}) => {
  const classes = useStyles()
  const [newLicenseModal, setNewLicenseModal] = useState(false)
  const [selectedSpecialty, setSelectedSpecialty] = useState(null)
  const [licenses, setLicenses] = useState(preloadedLicenses || {})
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!provider) return
    if (!provinces) requestProvinces(provider.country.id || provider.country)
  }, [provider])

  const onAddLicense = (data) => {
    setError(null)
    setLicenses({
      ...licenses,
      [selectedSpecialty.id]: R.append(data, licenses[selectedSpecialty.id]),
    })
    setNewLicenseModal(false)
  }

  const continueBtn = () => {
    setError(null)
    if (
      R.keys(licenses).length < selectedSpecialties.length ||
      R.any((l) => !l)(R.map((l) => l.length, R.valuesIn(licenses)))
    ) {
      setError(
        'Por favor ingrese al menos una matrícula por cada especialidad.'
      )
      return
    }
    onSubmit(licenses)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          {selectedSpecialties.map((specialty) => (
            <Card
              key={specialty.id}
              variant='outlined'
              className={classes.specialtyCard}
            >
              <CardContent>
                <Typography color='primary' className={classes.specialtyName}>
                  {specialty.name}
                </Typography>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={disabledFields[provider.enabled].addLicense}
                  onClick={() => {
                    setNewLicenseModal(true)
                    setSelectedSpecialty(specialty)
                  }}
                >
                  Agregar matrícula
                </Button>
                {licenses[specialty.id] && (
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.province}>
                            {getProvinceLabel(
                              (company || loggedUserCompany)?.country?.shortName
                            )}
                          </TableCell>
                          <TableCell className={classes.entity}>
                            Entidad
                          </TableCell>
                          <TableCell className={classes.date}>Fecha</TableCell>
                          <TableCell className={classes.license}>
                            Matrícula
                          </TableCell>
                          <TableCell className={classes.actions} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {licenses[specialty.id].map((l, i) => (
                          <TableRow key={`${specialty.id}_${i}`}>
                            <TableCell className={classes.province}>
                              {l.province.name}
                            </TableCell>
                            <TableCell className={classes.entity}>
                              {l.entity}
                            </TableCell>
                            <TableCell className={classes.date}>
                              {dayjs(l.date).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell className={classes.license}>
                              {l.license}
                            </TableCell>
                            <TableCell className={classes.actions}>
                              <IconButton
                                aria-label='delete'
                                disabled={
                                  disabledFields[provider.enabled].deleteLicense
                                }
                                onClick={() => {
                                  setLicenses({
                                    ...licenses,
                                    [specialty.id]: R.remove(
                                      i,
                                      1,
                                      licenses[specialty.id]
                                    ),
                                  })
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </CardContent>
            </Card>
          ))}
          {error && <Typography color='red'>{error}</Typography>}
          <Box mt={4} textAlign='center' position='relative'>
            <Button onClick={() => onGoBack()} className={classes.backBtn}>
              VOLVER
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={() => continueBtn()}
            >
              {continueBtnLabel}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
      <SpecialtyLicenseModal
        selectedSpecialty={selectedSpecialty}
        isOpen={newLicenseModal}
        provinces={provinces}
        onClose={() => {
          setSelectedSpecialty(null)
          setNewLicenseModal(false)
        }}
        onSubmit={onAddLicense}
        company={company}
        loggedUserCompany={loggedUserCompany}
      />
    </>
  )
}

Specialties.defaultProps = {
  continueBtnLabel: 'FINALIZAR REGISTRO',
}

const mapStateToProps = (state) => {
  return {
    loading: getLoading(state),
    provinces: getProvinces(state),
  }
}

export default connect(mapStateToProps, {
  requestProvinces: provincesGetRequest,
})(Specialties)
