import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import * as R from 'ramda'
import MaterialTable from 'material-table'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  TextField,
  Grid,
  Link,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Container,
  Typography,
  InputAdornment,
  IconButton,
  Paper,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { LoadingButton, SelectField } from 'common/components'
import { phoneNumberRegex } from 'common/utils/constants'
import { Header } from '../layout'

import {
  companyCreateRequest,
  provincesGetRequest,
  districtsGetRequest,
  dealsGetRequest,
} from 'common/actions'
import {
  getError,
  getLoading,
  getSuccess,
  getCompany,
} from 'common/reducers/companies'
import {
  getError as getDealError,
  getLoading as getDealLoading,
  getDeals,
} from 'common/reducers/deals'
import {
  getError as getLocationError,
  getLoading as getLocationLoading,
  getCountries,
  getProvinces,
  getDistricts,
} from 'common/reducers/locations'
import { CompanyTypes } from 'common/utils/constants'
import getCompanyTypeLabel from 'common/utils/getCompanyTypeLabel'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'left',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  fullWidth: {
    width: '100%',
  },
  centered: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  companyName: {
    fontWeight: '600',
  },
  formBox: {
    padding: theme.spacing(3),
    textAlign: 'center',
    marginTop: theme.spacing(3),
  },
  radioButtons: {
    '&$checked': {
      color: 'darkgreen',
    },
  },
}))

const permissions = [
  'canCreateAssistant',
  'canCreatePatient',
  'canGiveAppointmentOutsideRegistry',
  'canSetAppointmentCode',
  'cantUploadPrescriptionInFinishedAppointment',
  'assistantCanSelectCountry',
  'assistantLinkAppointmentOnly',
  'assistantMustAttachCoc',
]

const permissionLabels = [
  '¿Puede crear asistentes?',
  '¿Puede crear pacientes?',
  '¿Puede crear turnos particulares (fuera de convenio)?',
  '¿Puede agregar número de referencia?',
  '¿Puede subir receta post-llamada?',
  '¿Asistente puede seleccionar pais?',
  '¿Asistente solo puede crear turnos con enlace?',
  '¿Asistente debe subir archivo al crear turno?',
]

const New = ({
  error,
  loading,
  success,
  onCreateCompany,
  locationLoading,
  countries,
  provinces,
  districts,
  requestProvinces,
  requestDistricts,
  company,
  deals,
  requestDeals,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [successModal, setSuccessModal] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const tableRef = useRef()

  const permissionsSchema = R.reduce(
    (acc, val) => ({
      ...acc,
      [val]: yup.bool(),
    }),
    {},
    permissions
  )

  const schema = yup.object().shape({
    name: yup.string().required('Este campo es requerido'),
    contactEmail: yup
      .string()
      .required('Este campo es requerido')
      .email('Por favor ingrese un email válido')
      .nullable(),
    contactName: yup.string().required('Este campo es requerido'),
    contactLastName: yup.string().required('Este campo es requerido'),
    contactPhone: yup
      .string()
      .matches(phoneNumberRegex, 'El teléfono debe ser un número sin espacios')
      .required('Este campo es requerido'),
    country: yup
      .object()
      .test(
        'country-required',
        'Este campo es requerido',
        (val) => !R.equals(company.companyType, CompanyTypes.MEDITAR) || !!val
      )
      .nullable(),
    province: yup.object().required('Este campo es requerido').nullable(),
    district: yup.object().required('Este campo es requerido').nullable(),
    mpPublicKey: yup.string().when('companyType', {
      is: CompanyTypes.PARTNER,
      then: (mp) => mp.required('Este campo es requerido'),
    }),
    mpPrivateKey: yup.string().when('companyType', {
      is: CompanyTypes.PARTNER,
      then: (mp) => mp.required('Este campo es requerido'),
    }),
    companyType: yup
      .string()
      .required('Este campo es requerido')
      .oneOf(R.valuesIn(CompanyTypes), 'Este campo es requerido')
      .nullable(),
    ...permissionsSchema,
  })

  const permissionsDefaultValue = R.reduce(
    (acc, val) => ({
      ...acc,
      [val]: false,
    }),
    {},
    permissions
  )

  const {
    control,
    errors,
    handleSubmit,
    triggerValidation,
    register,
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      name: '',
      contactName: '',
      contactLastName: '',
      contactPhone: '',
      contactEmail: '',
      country: null,
      province: null,
      district: null,
      mpPublicKey: '',
      mpPrivateKey: '',
      companyType: null,
      ...permissionsDefaultValue,
    },
    resolver: yupResolver(schema),
  })

  const getErrorMessage = () => {
    switch (error.code) {
      case 'UsernameExistsException':
        return 'El email ya existe'
      case 'InvalidPasswordException':
        return 'La contraseña debe tener al menos 8 caracteres, una mayúscula y una minúscula.'
      default:
        return `Hubo un error desconocido al intentar registrarse. Detalles: ${error.message}`
    }
  }

  const submit = () => {
    setConfirmationModal(false)
    const data = getValues()
    const selectedDeals = R.filter(
      (d) => d.tableData.checked,
      tableRef.current.dataManager.data
    )

    onCreateCompany({
      ...data,
      country: (data.country || company.country).id,
      province: data.province.id,
      district: data.district.id,
      deals: selectedDeals.map((d) => d.id),
    })
  }

  const watchCountry = watch('country')

  useEffect(() => {
    if (!watchCountry) return
    requestProvinces(watchCountry.id)
    setValue('province', null, true)
    setValue('district', null, true)
  }, [watchCountry])

  useEffect(() => {
    if (!company || R.equals(company.companyType, CompanyTypes.MEDITAR)) return
    requestProvinces(company.country.id)
  }, [company])

  const watchProvince = watch('province')

  useEffect(() => {
    if (!watchProvince) return
    requestDistricts({
      province: watchProvince.id,
      country: watchCountry.id,
    })
    setValue('district', null, true)
  }, [watchProvince])

  useEffect(() => {
    if (!success) return
    setSuccessModal(true)
  }, [success])

  useEffect(() => {
    register('country')
    register('province')
    register('district')
    register('companyType')
    permissions.map((p) => register(p))
  }, [register])

  useEffect(() => {
    if (!company || R.equals(company?.companyType, CompanyTypes.MEDITAR)) return
    setValue('country', company.country, true)
  }, [company])

  const watchCompanyType = watch('companyType')

  useEffect(() => {
    setSuccessModal(false)
    requestDeals({ params: { available: true } })
  }, [])

  return (
    <>
      <Header />
      <Container className={classes.container}>
        <Box className={classes.centered}>
          <Typography variant='h5'>Crear nuevo Cliente</Typography>
          <Button
            onClick={() => history.goBack()}
            color='primary'
            variant='outlined'
          >
            Volver
          </Button>
        </Box>
        <form
          noValidate
          onSubmit={handleSubmit(() => setConfirmationModal(true))}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Paper className={classes.formBox}>
                <Typography variant='h4'>Empresa</Typography>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='name'
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                    label='Nombre de la Empresa'
                    onBlur={() => triggerValidation('name')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                {R.equals(company?.companyType, CompanyTypes.MEDITAR) && (
                  <Box className={classes.inputBox}>
                    {countries && (
                      <Autocomplete
                        id='country'
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, v) => {
                          setValue('country', v, true)
                        }}
                        renderInput={(params) => (
                          <TextField
                            name='country'
                            {...params}
                            error={!!errors.country}
                            helperText={
                              errors.country && errors.country.message
                            }
                            label='País'
                            fullWidth
                          />
                        )}
                      />
                    )}
                  </Box>
                )}
                <Box className={classes.inputBox}>
                  {locationLoading && !watchProvince ? (
                    <TextField
                      disabled
                      value='Cargando...'
                      label='Provincia'
                      fullWidth
                    />
                  ) : (
                    <Autocomplete
                      id='province'
                      disabled={!provinces || !watchCountry}
                      options={provinces || []}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => {
                        setValue('province', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='province'
                          {...params}
                          error={!!errors.province}
                          helperText={
                            errors.province && errors.province.message
                          }
                          label='Provincia'
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Box>
                <Box className={classes.inputBox}>
                  {locationLoading ? (
                    <TextField
                      disabled
                      value='Cargando...'
                      label='Localidad'
                      fullWidth
                    />
                  ) : (
                    <Autocomplete
                      disabled={!districts || !watchProvince}
                      id='district'
                      options={districts || []}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => {
                        setValue('district', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='district'
                          {...params}
                          error={!!errors.district}
                          helperText={
                            errors.district && errors.district.message
                          }
                          label='Localidad'
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Box>
                <Box className={classes.inputBox}>
                  <FormControl component='fieldset'>
                    <FormLabel component='legend'>Tipo de Cliente</FormLabel>
                    <RadioGroup
                      onChange={(e) => {
                        setValue('companyType', e.target.value, true)
                        setValue('mpPublicKey', null)
                        setValue('mpPrivateKey', null)
                      }}
                      value={watchCompanyType}
                    >
                      {R.equals(company?.companyType, CompanyTypes.MEDITAR) && (
                        <FormControlLabel
                          value={CompanyTypes.PARTNER}
                          control={<Radio />}
                          label={getCompanyTypeLabel(CompanyTypes.PARTNER)}
                        />
                      )}
                      {R.includes(company?.companyType, [
                        CompanyTypes.MEDITAR,
                        CompanyTypes.PARTNER,
                      ]) && (
                        <FormControlLabel
                          value={CompanyTypes.RESELLER}
                          control={<Radio />}
                          label={getCompanyTypeLabel(CompanyTypes.RESELLER)}
                        />
                      )}
                      <FormControlLabel
                        value={CompanyTypes.CLIENT}
                        control={<Radio />}
                        label={getCompanyTypeLabel(CompanyTypes.CLIENT)}
                      />
                    </RadioGroup>

                    {errors.companyType && (
                      <Typography color='red'>
                        {errors.companyType.message}
                      </Typography>
                    )}
                  </FormControl>
                </Box>
                {R.equals(getValues('companyType'), CompanyTypes.PARTNER) && (
                  <>
                    <Box className={classes.inputBox}>
                      <Controller
                        control={control}
                        name='mpPublicKey'
                        error={!!errors.mpPublicKey}
                        helperText={
                          errors.mpPublicKey && errors.mpPublicKey.message
                        }
                        label='Mercado Pago - Clave Pública'
                        onBlur={() => triggerValidation('mpPublicKey')}
                        fullWidth
                        as={<TextField />}
                      />
                    </Box>
                    <Box className={classes.inputBox}>
                      <Controller
                        control={control}
                        name='mpPrivateKey'
                        error={!!errors.mpPrivateKey}
                        helperText={
                          errors.mpPrivateKey && errors.mpPrivateKey.message
                        }
                        label='Mercado Pago - Clave Privada'
                        onBlur={() => triggerValidation('mpPrivateKey')}
                        fullWidth
                        as={<TextField />}
                      />
                    </Box>
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.formBox}>
                <Typography variant='h4'>Responsable</Typography>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='contactName'
                    error={!!errors.contactName}
                    helperText={
                      errors.contactName && errors.contactName.message
                    }
                    label='Nombre'
                    onBlur={() => triggerValidation('contactName')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='contactLastName'
                    error={!!errors.contactLastName}
                    helperText={
                      errors.contactLastName && errors.contactLastName.message
                    }
                    label='Apellido'
                    onBlur={() => triggerValidation('contactLastName')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='contactPhone'
                    error={!!errors.contactPhone}
                    helperText={
                      errors.contactPhone && errors.contactPhone.message
                    }
                    label='Teléfono'
                    onBlur={() => triggerValidation('contactPhone')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='contactEmail'
                    error={!!errors.contactEmail}
                    helperText={
                      errors.contactEmail && errors.contactEmail.message
                    }
                    label='Dirección de Email'
                    onBlur={() => triggerValidation('contactEmail')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
              </Paper>
            </Grid>
            {R.equals(company?.companyType, CompanyTypes.MEDITAR) && (
              <Grid item xs={12}>
                <Paper className={classes.formBox}>
                  <Typography variant='h4'>Permisos</Typography>
                  <Grid container>
                    {permissions.map((p, i) => (
                      <Grid item xs={12} md={4} key={p}>
                        <Box className={classes.inputBox}>
                          <FormControl component='fieldset'>
                            <FormLabel component='legend'>
                              {permissionLabels[i]}
                            </FormLabel>
                            <RadioGroup
                              onChange={(e) => {
                                setValue(p, e.target.value === 'true', true)
                              }}
                              value={watch(p)}
                            >
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label='Si'
                              />
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label='No'
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12}>
              <MaterialTable
                tableRef={tableRef}
                columns={[{ title: 'Nombre', field: 'name' }]}
                data={deals || []}
                title='Asociar Convenios'
                options={{
                  actionsColumnIndex: -1,
                  selection: true,
                }}
                localization={{
                  pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'filas',
                    labelRowsPerPage: 'Filas por página: ',
                  },
                  toolbar: {
                    nRowsSelected: '{0} convenios seleccionados',
                    searchPlaceholder: 'Búsqueda',
                    searchTooltip: 'Búsqueda',
                  },
                  header: {
                    actions: 'Acciones',
                  },
                  body: {
                    emptyDataSourceMessage: 'No hay convenios',
                    filterRow: {
                      filterTooltip: 'Filtrar',
                    },
                    editTooltip: 'Editar',
                  },
                }}
              />
            </Grid>
          </Grid>
          {error && (
            <Box mt={3}>
              <Alert severity='error'>
                <AlertTitle>Error al registrar nuevo cliente</AlertTitle>
                {getErrorMessage()}
              </Alert>
            </Box>
          )}
          <Box mt={4} textAlign='center' position='relative'>
            <LoadingButton
              type='submit'
              variant='contained'
              color='primary'
              loading={loading}
            >
              CREAR CLIENTE
            </LoadingButton>
          </Box>
        </form>
      </Container>
      <Dialog
        open={successModal}
        onClose={() => history.push('/clients')}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Cliente creado exitosamente
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            El cliente fue creado exitosamente. Se ha enviado un email con una
            contraseña temporal al responsable de la compañia.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            autoFocus
            variant='contained'
            onClick={() => history.push('/clients')}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confirmación de Creación de cliente
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Por favor verifique que los datos ingresados sean correctos ya que
            la creación de un cliente es irreversible.
          </DialogContentText>
          <DialogContent>
            <Card>
              <CardContent>
                <Typography component='h2' className={classes.companyName}>
                  {getValues('name')}
                </Typography>
                <Box>
                  Responsable: {getValues('contactName')}{' '}
                  {getValues('contactLastName')}
                </Box>
                <Box>
                  Contacto del Responsable: {getValues('contactPhone')} -{' '}
                  {getValues('contactEmail')}
                </Box>
                <Box>
                  Ubicación: {getValues('district')?.name},{' '}
                  {getValues('province')?.name} - {getValues('country')?.name}
                </Box>
                {R.equals(company?.companyType, CompanyTypes.MEDITAR) && (
                  <>
                    <Box>
                      ¿Puede crear asistentes?:{' '}
                      {getValues('canCreateAssistant') ? 'Si' : 'No'}
                    </Box>
                    <Box>
                      ¿Puede crear pacientes?:{' '}
                      {getValues('canCreatePatient') ? 'Si' : 'No'}
                    </Box>
                    <Box>
                      ¿Puede crear turnos particulares (fuera de convenio)?:{' '}
                      {getValues('canGiveAppointmentOutsideRegistry')
                        ? 'Si'
                        : 'No'}
                    </Box>
                  </>
                )}
                <Box>
                  Tipo de Cliente:{' '}
                  {getCompanyTypeLabel(getValues('companyType'))}
                </Box>
                {R.equals(getValues('companyType'), CompanyTypes.PARTNER) && (
                  <>
                    <Box>Clave Pública MP: {getValues('mpPublicKey')}</Box>
                    <Box>Clave Privada MP: {getValues('mpPrivateKey')}</Box>
                  </>
                )}
              </CardContent>
            </Card>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setConfirmationModal(false)}>
            Volver
          </Button>
          <Button
            color='primary'
            autoFocus
            variant='contained'
            onClick={() => submit()}
          >
            CREAR CLIENTE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    success: getSuccess(state),
    locationLoading: getLocationLoading(state),
    countries: getCountries(state),
    provinces: getProvinces(state),
    districts: getDistricts(state),
    company: getCompany(state),
    deals: getDeals(state),
  }
}

export default connect(mapStateToProps, {
  onCreateCompany: companyCreateRequest,
  requestProvinces: provincesGetRequest,
  requestDistricts: districtsGetRequest,
  requestDeals: dealsGetRequest,
})(New)
