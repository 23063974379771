import * as ActionTypes from '../actions'

const initialState = {
  deals: null,
  loading: false,
  error: null,
  success: false,
  deal: null,
}

export function dealsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.DEALS_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
        deals: [],
      }
    case ActionTypes.DEAL_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
        deal: null,
      }
    case ActionTypes.DEAL_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      }
    case ActionTypes.DEALS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        deals: action.data.items,
      }
    case ActionTypes.DEAL_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        deal: action.data,
      }
    case ActionTypes.DEAL_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case ActionTypes.DEAL_GET_FAILURE:
    case ActionTypes.DEAL_EDIT_FAILURE:
    case ActionTypes.DEALS_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case ActionTypes.DEAL_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      }
    case ActionTypes.DEAL_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.data,
      }
    case ActionTypes.DEAL_CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export function getSuccess(state) {
  return state.dealsReducer.success
}

export function getDeals(state) {
  return state.dealsReducer.deals
}

export function getDeal(state) {
  return state.dealsReducer.deal
}

export function getLoading(state) {
  return state.dealsReducer.loading
}

export function getError(state) {
  return state.dealsReducer.error
}
