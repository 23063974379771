import * as R from 'ramda'

const APPOINTMENT_STATUSES = {
  PENDING: 'PENDING',
  AWAITING_CUSTOMER: 'AWAITING_CUSTOMER',
  IN_PROGRESS: 'IN_PROGRESS',
  AWAITING_RATING: 'AWAITING_RATING',
  COMPLETED: 'COMPLETED',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  CANCELLED: 'CANCELLED',
}

export const appointmentStatusStrings = {
  [APPOINTMENT_STATUSES.PENDING]: 'Pendiente',
  [APPOINTMENT_STATUSES.AWAITING_CUSTOMER]: 'En espera',
  [APPOINTMENT_STATUSES.IN_PROGRESS]: 'En progreso',
  [APPOINTMENT_STATUSES.AWAITING_RATING]: 'Pendiente de Calificación',
  [APPOINTMENT_STATUSES.COMPLETED]: 'Completado',
  [APPOINTMENT_STATUSES.AWAITING_PAYMENT]: 'Pendiente de Pago',
  [APPOINTMENT_STATUSES.CANCELLED]: 'Cancelado',
}

export const joineableCall = (status) =>
  R.includes(status, [
    APPOINTMENT_STATUSES.PENDING,
    APPOINTMENT_STATUSES.AWAITING_CUSTOMER,
    APPOINTMENT_STATUSES.IN_PROGRESS,
  ])

export const exitableAppointment = (status) =>
  !R.includes(status, [
    APPOINTMENT_STATUSES.AWAITING_CUSTOMER,
    APPOINTMENT_STATUSES.IN_PROGRESS,
  ])

export const cancellableAppointment = (status) =>
  R.includes(status, [APPOINTMENT_STATUSES.PENDING])

export const canUploadPrescription = (
  status,
  cantUploadPrescriptionInFinishedAppointment
) =>
  !R.includes(status, [
    APPOINTMENT_STATUSES.COMPLETED,
    APPOINTMENT_STATUSES.AWAITING_RATING,
  ]) ||
  (R.includes(status, [
    APPOINTMENT_STATUSES.COMPLETED,
    APPOINTMENT_STATUSES.AWAITING_RATING,
  ]) &&
    !cantUploadPrescriptionInFinishedAppointment)
