import * as ActionTypes from '../actions'
import * as R from 'ramda'

const initialState = {
  reports: null,
  loading: false,
  error: null,
}

export function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REPORTS_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        reports: [],
      }
    case ActionTypes.REPORTS_RESULTS_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case ActionTypes.REPORTS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: action.data.items,
      }
    case ActionTypes.REPORTS_RESULTS_SUCCESS:
      const index = R.findIndex(R.propEq('id', action.data.id), state.reports)
      const report = { ...state.reports[index], results: action.data }
      return {
        ...state,
        loading: false,
        reports: R.update(index, report, state.reports),
      }
    case ActionTypes.REPORTS_GET_FAILURE:
    case ActionTypes.REPORTS_RESULTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export function getReports(state) {
  return state.reportsReducer.reports
}

export function getLoading(state) {
  return state.reportsReducer.loading
}

export function getError(state) {
  return state.reportsReducer.error
}
