import React, { useEffect } from 'react'
import { Switch, BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Dashboard from './dashboard'
import Appointments from './appointments'
import Schedules from './schedules'
import PrivateRoute from './privateRoute'
import { getToken } from 'common/utils/firebase'
import { userUpdateRequest } from 'common/actions'

const Assistants = ({ onUserUpdate }) => {
  useEffect(() => {
    getToken().then((token) => {
      onUserUpdate({ pushId: token })
    })
  }, [])

  return (
    <BrowserRouter basename='/assistants'>
      <Switch>
        <PrivateRoute path='/appointments'>
          <Appointments />
        </PrivateRoute>
        <PrivateRoute path='/schedules'>
          <Schedules />
        </PrivateRoute>
        <PrivateRoute path='/' exact>
          <Dashboard />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {
  onUserUpdate: userUpdateRequest,
})(Assistants)
