import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import * as R from 'ramda'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useHistory } from 'react-router-dom'
import {
  Box,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Container,
  Typography,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { PhoneField, LoadingButton, SelectField } from 'common/components'
import defaultPhoneNumbers from 'common/utils/defaultPhoneNumbers'
import { phoneNumberRegex } from 'common/utils/constants'
import getProvinceLabel from 'common/utils/getProvinceLabel'
import { validatePhoneNumber } from 'common/services/api'
import { Header } from '../layout'

import {
  providerCreateRequest,
  provincesGetRequest,
  districtsGetRequest,
} from 'common/actions'
import { getError, getLoading, getSuccess } from 'common/reducers/providers'
import { getCompany } from 'common/reducers/companies'
import {
  getLoading as getLocationLoading,
  getCountries,
  getProvinces,
  getDistricts,
} from 'common/reducers/locations'
import { getAllTimeZones } from 'common/utils/timezones'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  fullWidth: {
    width: '100%',
  },
}))

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const New = ({
  error,
  loading,
  success,
  onCreateProvider,
  locationLoading,
  countries,
  provinces,
  districts,
  requestProvinces,
  requestDistricts,
  company,
  loggedUserCompany,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [successModal, setSuccessModal] = useState(false)

  const [phoneCountry, setPhoneCountry] = useState(null)

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Este campo es requerido')
      .email('Por favor ingrese un email válido')
      .nullable(),
    name: yup.string().required('Este campo es requerido'),
    lastName: yup.string().required('Este campo es requerido'),
    birthDate: yup
      .mixed()
      .required('Este campo es requerido')
      .test(
        'is-legal',
        'Debe ser mayor de 18 años',
        (value) => dayjs().diff(dayjs(value), 'years') > 18
      ),
    gender: yup.string().required('Este campo es requerido'),
    phone: yup
      .string()
      .matches(phoneNumberRegex, 'El teléfono debe ser un número sin espacios')
      .required('Este campo es requerido'),
    country: yup.object().required('Este campo es requerido').nullable(),
    province: yup.object().required('Este campo es requerido').nullable(),
    district: yup.object().required('Este campo es requerido').nullable(),
    timezone: yup.string().required('Este campo es requerido').nullable(),
  })

  const {
    control,
    errors,
    handleSubmit,
    triggerValidation,
    register,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      lastName: '',
      birthDate: '',
      gender: '',
      phone: '',
      specialties: [],
      country: null,
      province: null,
      district: null,
      timezone: browserTimezone,
    },
    resolver: yupResolver(schema),
  })

  const getErrorMessage = () => {
    switch (error.code) {
      case 'UsernameExistsException':
        return 'El email ya existe'
      case 'InvalidPasswordException':
        return 'La contraseña debe tener al menos 8 caracteres, una mayúscula y una minúscula.'
      default:
        return `Hubo un error desconocido al intentar registrarse. Detalles: ${error.message}`
    }
  }

  const onSubmit = async (data) => {
    try {
      const validPhoneNumber = await validatePhoneNumber(
        data.phone,
        phoneCountry.countryCode
      )
      onCreateProvider({
        ...data,
        phone: validPhoneNumber.phoneNumber,
        country: data.country.id,
        province: data.province.id,
        district: data.district.id,
      })
    } catch (e) {
      setError('phone', { message: 'El número de teléfono no es válido' })
    }
  }

  const watchCountry = watch('country')

  useEffect(() => {
    if (!watchCountry) return
    requestProvinces(watchCountry.id)
    setValue('province', null, true)
    setValue('district', null, true)
  }, [watchCountry])

  const watchProvince = watch('province')

  useEffect(() => {
    if (!watchProvince) return
    requestDistricts({
      province: watchProvince.id,
      country: watchCountry.id,
    })
    setValue('district', null, true)
  }, [watchProvince])

  useEffect(() => {
    if (!success) return
    setSuccessModal(true)
  }, [success])

  useEffect(() => {
    register('country')
    register('province')
    register('district')
    register('timezone')
  }, [register])

  useEffect(() => {
    if ((!company && !loggedUserCompany) || !!watch('phone')) return
    setValue(
      'phone',
      defaultPhoneNumbers[(company || loggedUserCompany)?.country?.shortName] ||
        defaultPhoneNumbers.AR
    )
  }, [company, loggedUserCompany])

  const timezones = getAllTimeZones()

  return (
    <>
      <Header />
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={1} md={2} />
          <Grid item xs={10} md={8}>
            <Typography variant='h5'>Crear nuevo profesional</Typography>
            <Button
              onClick={() => history.goBack()}
              color='primary'
              variant='outlined'
            >
              Volver
            </Button>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='name'
                  error={!!errors.name}
                  helperText={errors.name && errors.name.message}
                  label='Nombre'
                  onBlur={() => triggerValidation('name')}
                  fullWidth
                  as={<TextField />}
                />
              </Box>
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='lastName'
                  error={!!errors.lastName}
                  helperText={errors.lastName && errors.lastName.message}
                  label='Apellido'
                  onBlur={() => triggerValidation('lastName')}
                  fullWidth
                  as={<TextField />}
                />
              </Box>
              <Box className={classes.inputBox}>
                <SelectField
                  error={!!errors.gender}
                  label='Sexo'
                  options={[
                    { value: 'MALE', label: 'Masculino' },
                    { value: 'FEMALE', label: 'Femenino' },
                  ]}
                  control={control}
                  name='gender'
                  helperText={errors.gender && errors.gender.message}
                  onBlur={() => triggerValidation('gender')}
                  fullWidth
                />
              </Box>
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='phone'
                  error={!!errors.phone}
                  helperText={errors.phone && errors.phone.message}
                  label='Teléfono'
                  onBlur={() => triggerValidation('phone')}
                  fullWidth
                  onCountrySelected={(country) => setPhoneCountry(country)}
                  as={<PhoneField />}
                />
              </Box>
              <Box className={classes.inputBox}>
                <FormControl className={classes.fullWidth}>
                  <Controller
                    control={control}
                    name='birthDate'
                    error={!!errors.birthDate}
                    helperText={errors.birthDate && errors.birthDate.message}
                    label='Fecha de Nacimiento'
                    onBlur={() => triggerValidation('birthDate')}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    as={<TextField type='date' />}
                  />
                </FormControl>
              </Box>
              <Box className={classes.inputBox}>
                {countries && (
                  <Autocomplete
                    id='country'
                    options={R.filter(
                      R.propEq('id', company?.country?.id),
                      countries
                    )}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, v) => {
                      setValue('country', v, true)
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='country'
                        {...params}
                        error={!!errors.country}
                        helperText={errors.country && errors.country.message}
                        label='País'
                        fullWidth
                      />
                    )}
                  />
                )}
              </Box>
              <Box className={classes.inputBox}>
                {locationLoading && !watchProvince ? (
                  <TextField
                    disabled
                    value='Cargando...'
                    label={getProvinceLabel(
                      loggedUserCompany?.country?.shortName
                    )}
                    fullWidth
                  />
                ) : (
                  <Autocomplete
                    id='province'
                    disabled={!provinces || !watchCountry}
                    options={provinces || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, v) => {
                      setValue('province', v, true)
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='province'
                        {...params}
                        error={!!errors.province}
                        helperText={errors.province && errors.province.message}
                        label={getProvinceLabel(
                          loggedUserCompany?.country?.shortName
                        )}
                        fullWidth
                      />
                    )}
                  />
                )}
              </Box>
              <Box className={classes.inputBox}>
                {locationLoading ? (
                  <TextField
                    disabled
                    value='Cargando...'
                    label='Localidad'
                    fullWidth
                  />
                ) : (
                  <Autocomplete
                    disabled={!districts || !watchProvince}
                    id='district'
                    options={districts || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, v) => {
                      setValue('district', v, true)
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='district'
                        {...params}
                        error={!!errors.district}
                        helperText={errors.district && errors.district.message}
                        label='Localidad'
                        fullWidth
                      />
                    )}
                  />
                )}
              </Box>
              <Box className={classes.inputBox}>
                <Autocomplete
                  id='timezone'
                  options={timezones}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  value={watch('timezone') || browserTimezone}
                  onChange={(e, v) => {
                    setValue('timezone', v || browserTimezone, true)
                  }}
                  renderInput={(params) => (
                    <TextField
                      name='timezone'
                      {...params}
                      error={!!errors.timezone}
                      helperText={errors.timezone && errors.timezone.message}
                      label='Huso Horario'
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box className={classes.inputBox}>
                <Controller
                  control={control}
                  name='email'
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  label='Dirección de Email'
                  onBlur={() => triggerValidation('email')}
                  fullWidth
                  as={<TextField />}
                />
              </Box>
              {error && (
                <Box mt={3}>
                  <Alert severity='error'>
                    <AlertTitle>Error al registrarse</AlertTitle>
                    {getErrorMessage()}
                  </Alert>
                </Box>
              )}
              <Box mt={4} textAlign='center' position='relative'>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  color='primary'
                  loading={loading}
                >
                  CREAR CUENTA
                </LoadingButton>
              </Box>
            </form>
          </Grid>
          <Grid item xs={1} md={2} />
        </Grid>
      </Container>
      <Dialog
        open={successModal}
        onClose={() => history.push('/providers')}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Profesional creado exitosamente
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Para seguir completando los datos del profesional, dirijase a la
            pestaña pendientes de validar y edite el profesional
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            autoFocus
            variant='contained'
            onClick={() => history.push('/providers')}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    success: getSuccess(state),
    locationLoading: getLocationLoading(state),
    countries: getCountries(state),
    provinces: getProvinces(state),
    districts: getDistricts(state),
    company: getCompany(state),
  }
}

export default connect(mapStateToProps, {
  onCreateProvider: providerCreateRequest,
  requestProvinces: provincesGetRequest,
  requestDistricts: districtsGetRequest,
})(New)
