import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Paper,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

import {
  ProfessionalData,
  Specialties,
  PersonalData,
} from 'common/components/partials'

import { Header } from '../layout'

import {
  providerGetRequest,
  providerEditRequest,
  provincesGetRequest,
  districtsGetRequest,
  requestSpecialtiesGet,
} from 'common/actions'
import {
  getError,
  getLoading,
  getProviders,
  getSuccess,
} from 'common/reducers/providers'
import {
  getLoading as getLocationLoading,
  getCountries,
  getProvinces,
  getDistricts,
} from 'common/reducers/locations'
import { getSpecialties } from 'common/reducers/specialties'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  fullWidth: {
    width: '100%',
  },
  tab: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  stepContent: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
  },
}))

function getSteps() {
  return ['Datos Personales', 'Datos Profesionales', 'Especialidades']
}

const Edit = ({
  error,
  loading,
  providers,
  getProvider,
  success,
  updateProvider,
  locationLoading,
  countries,
  provinces,
  districts,
  requestProvinces,
  requestDistricts,
  specialties,
  onRequestSpecialties,
  company,
  loggedUserCompany,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [provider, setProvider] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  const { id } = useParams()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    getProvider({ id })
  }, [])

  useEffect(() => {
    if (!providers || !providers.length) return
    setProvider(providers[0])
  }, [providers])

  const getErrorMessage = () => {
    switch (error.code) {
      case 'UsernameExistsException':
        return 'El email ya existe'
      case 'InvalidPasswordException':
        return 'La contraseña debe tener al menos 8 caracteres, una mayúscula y una minúscula.'
      default:
        return `Hubo un error desconocido al intentar registrarse. Detalles: ${error.message}`
    }
  }

  useEffect(() => {
    if (!success) return
    history.push('/providers')
  }, [success])

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <PersonalData
            provider={provider}
            onSubmit={(data) => {
              setProvider({ ...provider, ...data })
              handleNext()
            }}
            onGoBack={() => handleBack()}
            company={company || loggedUserCompany}
            isAdmin
          />
        )
      case 1:
        return (
          <ProfessionalData
            provider={provider}
            changeImage
            isAdmin
            onSubmit={(data) => {
              setProvider({
                ...provider,
                ...data,
                specialties: data.selectedSpecialties.map((s) => s.id),
              })
              handleNext()
            }}
            onGoBack={() => handleBack()}
            company={company || loggedUserCompany}
          />
        )
      case 2:
        return (
          <>
            {provinces?.length && (
              <Specialties
                isAdmin
                provider={provider}
                preloadedLicenses={R.reduce(
                  (acc, val) => ({
                    ...acc,
                    [val.specialty]: [
                      {
                        ...val,
                        province: R.find(
                          R.propEq('id', val.province),
                          provinces
                        ),
                      },
                    ],
                  }),
                  {},
                  provider.validationInformation?.licenseInformation || []
                )}
                selectedSpecialties={R.innerJoin(
                  (s, i) => R.equals(s.id, i),
                  specialties,
                  provider.specialties
                )}
                company={company || loggedUserCompany}
                onSubmit={(data) => {
                  updateProvider({
                    ...provider,
                    country: provider.country.id,
                    province: provider.province.id,
                    district: provider.district.id,
                    validationInformation: {
                      licenseInformation: R.flatten(
                        R.keysIn(data).map((k) =>
                          data[k].map((l) => ({
                            specialty: k,
                            license: l.license,
                            province: l.province.id,
                            entity: l.entity,
                            date: l.date,
                          }))
                        )
                      ),
                    },
                    id,
                  })
                  handleNext()
                }}
                onGoBack={() => handleBack()}
                continueBtnLabel='GUARDAR PROFESIONAL'
              />
            )}
          </>
        )
      default:
        return <CircularProgress />
    }
  }

  return (
    <>
      <Header />
      <Container className={classes.container}>
        <Box className={classes.title}>
          <Typography variant='h5'>Editar profesional</Typography>
          <Button
            onClick={() => history.goBack()}
            color='primary'
            variant='outlined'
            className={classes.marginTop}
          >
            Volver
          </Button>
        </Box>
        <Box className={classes.marginTop}>
          {provider && (
            <Paper square className={classes.secondaryCard}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box>
                <Box className={classes.stepContent}>
                  {getStepContent(activeStep)}
                </Box>
              </Box>
            </Paper>
          )}
          {error && (
            <Box mt={3}>
              <Alert severity='error'>
                <AlertTitle>Error al registrarse</AlertTitle>
                {getErrorMessage()}
              </Alert>
            </Box>
          )}
        </Box>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    providers: getProviders(state),
    success: getSuccess(state),
    locationLoading: getLocationLoading(state),
    countries: getCountries(state),
    provinces: getProvinces(state),
    districts: getDistricts(state),
    specialties: getSpecialties(state),
  }
}

export default connect(mapStateToProps, {
  getProvider: providerGetRequest,
  updateProvider: providerEditRequest,
  requestProvinces: provincesGetRequest,
  requestDistricts: districtsGetRequest,
  onRequestSpecialties: requestSpecialtiesGet,
})(Edit)
