import * as ActionTypes from '../actions'

const initialState = {
  countries: null,
  provinces: null,
  districts: null,
  loading: false,
  error: null,
}

export function locationsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.COUNTRIES_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        countries: [],
        provinces: [],
        districts: [],
      }
    case ActionTypes.PROVINCES_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        provinces: [],
        districts: [],
      }
    case ActionTypes.DISTRICTS_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        districts: [],
      }
    case ActionTypes.COUNTRIES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.data.items,
      }
    case ActionTypes.PROVINCES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        provinces: action.data.items,
      }
    case ActionTypes.DISTRICTS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        districts: action.data.items,
      }
    case ActionTypes.COUNTRIES_GET_FAILURE:
    case ActionTypes.PROVINCES_GET_FAILURE:
    case ActionTypes.DISTRICTS_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export function getDistricts(state) {
  return state.locationsReducer.districts
}

export function getProvinces(state) {
  return state.locationsReducer.provinces
}

export function getCountries(state) {
  return state.locationsReducer.countries
}

export function getLoading(state) {
  return state.locationsReducer.loading
}

export function getError(state) {
  return state.locationsReducer.error
}
