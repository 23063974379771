import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  TextField,
  Grid,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import {
  BackgroundImage,
  LoadingButton,
  SelectField,
  PhoneField,
} from 'common/components'
import { phoneNumberRegex } from 'common/utils/constants'
import { validatePhoneNumber } from 'common/services/api'

import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  registration,
  registrationReset,
  provincesGetRequest,
  districtsGetRequest,
} from 'common/actions'
import { getError, getLoading, getSuccess } from 'common/reducers/user'
import {
  getError as getLocationError,
  getLoading as getLocationLoading,
  getCountries,
  getProvinces,
  getDistricts,
} from 'common/reducers/locations'
import { isDateValid } from 'common/utils/datetime'

import background from 'assets/94772307-doctor-in-hospital-background-with-copy-space-healthcare-and-medical-concept-.png'
import logo from 'assets/TeleMeditar_Logo.png'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  fullWidth: {
    width: '100%',
  },
}))

const Registration = ({
  error,
  loading,
  success,
  onRegistration,
  locationLoading,
  countries,
  provinces,
  districts,
  requestProvinces,
  requestDistricts,
}) => {
  const classes = useStyles()
  const [phoneCountry, setPhoneCountry] = useState(null)

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Este campo es requerido')
      .email('Por favor ingrese un email válido')
      .nullable(),
    name: yup.string().required('Este campo es requerido'),
    lastName: yup.string().required('Este campo es requerido'),
    birthDate: yup
      .mixed()
      .required('Este campo es requerido')
      .test(
        'is-legal',
        'Debe ser mayor de 18 años',
        (value) => dayjs().diff(dayjs(value), 'years') > 18
      )
      .test(
        'date-format',
        'La fecha no es válida. El formato es DD/MM/YYYY',
        isDateValid
      ),
    gender: yup.string().required('Este campo es requerido'),
    phone: yup
      .string()
      .matches(phoneNumberRegex, 'El teléfono debe ser un número sin espacios')
      .required('Este campo es requerido'),
    password: yup
      .string()
      .required('Este campo es requerido')
      .min(8, 'Este campo debe tener por lo menos 8 caracteres'),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden'),
    country: yup.object().required('Este campo es requerido').nullable(),
    province: yup.object().required('Este campo es requerido').nullable(),
    district: yup.object().required('Este campo es requerido').nullable(),
  })

  const {
    control,
    errors,
    handleSubmit,
    triggerValidation,
    register,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      lastName: '',
      password: '',
      confirmpassword: '',
      birthDate: '',
      gender: '',
      phone: '54',
      country: null,
      province: null,
      district: null,
    },
    resolver: yupResolver(schema),
  })

  const watchCountry = watch('country')

  useEffect(() => {
    if (!watchCountry) return
    requestProvinces(watchCountry.id)
    setValue('province', null, true)
    setValue('district', null, true)
  }, [watchCountry])

  const watchProvince = watch('province')

  useEffect(() => {
    if (!watchProvince) return
    requestDistricts({
      province: watchProvince.id,
      country: watchCountry.id,
    })
    setValue('district', null, true)
  }, [watchProvince])

  const getErrorMessage = () => {
    switch (error.code) {
      case 'UsernameExistsException':
        return 'El email ya existe'
      case 'InvalidPasswordException':
        return 'La contraseña debe tener al menos 8 caracteres, una mayúscula y una minúscula.'
      default:
        return `Hubo un error desconocido al intentar registrarse. Detalles: ${error.message}`
    }
  }

  const onSubmit = async (data) => {
    try {
      const validPhoneNumber = await validatePhoneNumber(
        data.phone,
        phoneCountry.countryCode
      )
      onRegistration({
        ...data,
        phone: validPhoneNumber.phoneNumber,
        country: data.country.id,
        province: data.province.id,
        district: data.district.id,
        company: data.country.mainPartner,
      })
    } catch (e) {
      setError('phone', { message: 'El número de teléfono no es válido' })
    }
  }

  useEffect(() => {
    register('country')
    register('province')
    register('district')
  }, [register])

  return (
    <BackgroundImage image={background}>
      <Grid container className={classes.container}>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          <Card className={classes.card}>
            <CardMedia className={classes.logo} image={logo} />
            <CardContent className={classes.cardContent}>
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                autoComplete='off'
              >
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='name'
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                    label='Nombre'
                    onBlur={() => triggerValidation('name')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='lastName'
                    error={!!errors.lastName}
                    helperText={errors.lastName && errors.lastName.message}
                    label='Apellido'
                    onBlur={() => triggerValidation('lastName')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <SelectField
                    error={!!errors.gender}
                    label='Sexo'
                    options={[
                      { value: 'MALE', label: 'Masculino' },
                      { value: 'FEMALE', label: 'Femenino' },
                    ]}
                    control={control}
                    name='gender'
                    helperText={errors.gender && errors.gender.message}
                    onBlur={() => triggerValidation('gender')}
                    fullWidth
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='phone'
                    error={!!errors.phone}
                    helperText={errors.phone && errors.phone.message}
                    label='Teléfono'
                    onBlur={() => triggerValidation('phone')}
                    fullWidth
                    onCountrySelected={(country) => setPhoneCountry(country)}
                    as={<PhoneField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <FormControl className={classes.fullWidth}>
                    <Controller
                      control={control}
                      name='birthDate'
                      error={!!errors.birthDate}
                      helperText={errors.birthDate && errors.birthDate.message}
                      label='Fecha de Nacimiento'
                      onBlur={() => triggerValidation('birthDate')}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      as={<TextField type='date' />}
                    />
                  </FormControl>
                </Box>
                <Box className={classes.inputBox}>
                  {countries && (
                    <Autocomplete
                      id='country'
                      options={countries}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => {
                        setValue('country', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='country'
                          {...params}
                          error={!!errors.country}
                          helperText={errors.country && errors.country.message}
                          label='País'
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />
                      )}
                    />
                  )}
                </Box>
                <Box className={classes.inputBox}>
                  {locationLoading && !watchProvince ? (
                    <TextField
                      disabled
                      value='Cargando...'
                      label='Provincia o Departamento'
                      fullWidth
                    />
                  ) : (
                    <Autocomplete
                      id='province'
                      disabled={!provinces || !watchCountry}
                      options={provinces || []}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => {
                        setValue('province', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='province'
                          {...params}
                          error={!!errors.province}
                          helperText={
                            errors.province && errors.province.message
                          }
                          label='Provincia o Departamento'
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />
                      )}
                    />
                  )}
                </Box>
                <Box className={classes.inputBox}>
                  {locationLoading ? (
                    <TextField
                      disabled
                      value='Cargando...'
                      label='Localidad'
                      fullWidth
                    />
                  ) : (
                    <Autocomplete
                      disabled={!districts || !watchProvince}
                      id='district'
                      options={districts || []}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => {
                        setValue('district', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='district'
                          {...params}
                          error={!!errors.district}
                          helperText={
                            errors.district && errors.district.message
                          }
                          label='Localidad'
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                        />
                      )}
                    />
                  )}
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='email'
                    error={!!errors.email}
                    helperText={errors.email && errors.email.message}
                    label='Dirección de Email'
                    onBlur={() => triggerValidation('email')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='password'
                    error={!!errors.password}
                    helperText={
                      (errors.password && errors.password.message) ||
                      'La contraseña debe tener al menos 8 caracteres, una mayúscula y una minúscula.'
                    }
                    label='Contraseña'
                    onBlur={() => triggerValidation('password')}
                    fullWidth
                    as={<TextField type='password' />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='confirmpassword'
                    error={!!errors.confirmpassword}
                    helperText={
                      errors.confirmpassword && errors.confirmpassword.message
                    }
                    label='Confirmar Contraseña'
                    onBlur={() => triggerValidation('confirmpassword')}
                    fullWidth
                    as={<TextField type='password' />}
                  />
                </Box>
                {error && (
                  <Box mt={3}>
                    <Alert severity='error'>
                      <AlertTitle>Error al registrarse</AlertTitle>
                      {getErrorMessage()}
                    </Alert>
                  </Box>
                )}
                <Box mt={4} textAlign='center' position='relative'>
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    loading={loading}
                  >
                    CREAR CUENTA
                  </LoadingButton>
                </Box>
                <Box mt={3}>
                  <Link href='login'>Ya tengo cuenta</Link>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
      <Dialog
        open={success}
        onClose={() => {
          window.location = 'login'
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Cuenta creada exitosamente
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Por favor, verifique su mail para poder continuar con el proceso
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            autoFocus
            variant='contained'
            component='a'
            href='login'
          >
            Ir a Iniciar Sesión
          </Button>
        </DialogActions>
      </Dialog>
    </BackgroundImage>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    success: getSuccess(state),
    locationLoading: getLocationLoading(state),
    countries: getCountries(state),
    provinces: getProvinces(state),
    districts: getDistricts(state),
  }
}

export default connect(mapStateToProps, {
  onRegistration: registration,
  initialize: registrationReset,
  requestProvinces: provincesGetRequest,
  requestDistricts: districtsGetRequest,
})(Registration)
