import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Link,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { ER_Header } from 'common/utils/constants'
import { getClientResponse } from 'common/reducers/pubsub'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}))

const PatientTaken = ({ clientResponse }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => setIsOpen(false)

  useEffect(() => {
    if (!clientResponse) return
    const { header } = clientResponse
    if (R.equals(header, ER_Header.PATIENT_TAKEN)) {
      setIsOpen(true)
    }
  }, [clientResponse])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Consulta de Guardia</DialogTitle>
      <DialogContent>
        <DialogContentText>
          La consulta de guardia ha sido tomada por otro profesional.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    clientResponse: getClientResponse(state),
  }
}

export default connect(mapStateToProps, {})(PatientTaken)
