import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Container,
  Typography,
  CircularProgress,
  Snackbar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

import { providerEditRequest, provincesGetRequest } from 'common/actions'
import { getLoading, getSuccess } from 'common/reducers/providers'
import { getProvinces } from 'common/reducers/locations'
import { ProfessionalData, Specialties } from 'common/components/partials'

import { Header } from '../layout'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  selectBox: {
    width: '100%',
  },
  stepContent: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
  },
}))

function getSteps() {
  return ['Datos Profesionales', 'Especialidades']
}

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const Profile = ({
  provider,
  updateProvider,
  createMultipleSchedule,
  loading,
  success,
  provinces,
  requestProvinces,
  company,
}) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [profileData, setProfileData] = useState({})
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const steps = getSteps()
  const history = useHistory()

  useEffect(() => {
    if (!provider) return
    if (provider.enabled) window.history.back()
    requestProvinces(provider.country.id || provider.country)
  }, [provider])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    if (activeStep < steps.length) return
    updateProvider({
      ...provider,
      ...profileData,
      specialties: profileData.selectedSpecialties.map((s) => s.id),
    })
  }, [profileData])

  useEffect(() => {
    if (!success) return
    setSnackbarOpen(true)
    setActiveStep(0)
  }, [success])

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <ProfessionalData
            provider={provider}
            changeImage
            onSubmit={(data) => {
              setProfileData({ ...profileData, ...data })
              setActiveStep(1)
            }}
            company={company}
          />
        )
      case 1:
        return (
          <Specialties
            provider={provider}
            company={company}
            preloadedLicenses={R.reduce(
              (acc, val) => ({
                ...acc,
                [val.specialty]: [
                  {
                    ...val,
                    province: R.find(R.propEq('id', val.province), provinces),
                  },
                ],
              }),
              {},
              provider.validationInformation?.licenseInformation || []
            )}
            selectedSpecialties={profileData.selectedSpecialties}
            onSubmit={(data) => {
              setProfileData({
                ...profileData,
                validationInformation: {
                  licenseInformation: R.flatten(
                    R.keysIn(data).map((k) =>
                      data[k].map((l) => ({
                        specialty: k,
                        license: l.license,
                        province: l.province.id,
                        entity: l.entity,
                        date: l.date,
                      }))
                    )
                  ),
                },
              })
              setActiveStep(2)
            }}
            onGoBack={() => setActiveStep(0)}
            continueBtnLabel='GUARDAR MI PERFIL'
          />
        )
    }
  }

  return (
    <>
      <Header />
      <Container className={classes.container}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box>
              <Box className={classes.stepContent}>
                {getStepContent(activeStep)}
              </Box>
            </Box>
          </>
        )}
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='success'>
          PERFIL GUARDADO CORRECTAMENTE
        </Alert>
      </Snackbar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: getLoading(state),
    success: getSuccess(state),
    provinces: getProvinces(state),
  }
}

export default connect(mapStateToProps, {
  updateProvider: providerEditRequest,
  requestProvinces: provincesGetRequest,
})(Profile)
