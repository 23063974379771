import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import * as R from 'ramda'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as Bowser from 'bowser'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Link,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Email, Lock } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import {
  BackgroundImage,
  IconTextField,
  LoadingButton,
} from 'common/components'
import { login, logout, userPasswordChallengeRequest } from 'common/actions'
import { getUser, getError, getLoading } from 'common/reducers/user'
import { userLogout } from 'common/utils/awsConfig'

import background from 'assets/94772307-doctor-in-hospital-background-with-copy-space-healthcare-and-medical-concept-.png'
import logo from 'assets/TeleMeditar_Logo.png'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  loginCard: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  loginCardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
  },
  browserAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const Login = ({
  error,
  user,
  loading,
  onLogin,
  onLogout,
  onCompletePasswordChallenge,
  success,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [newPasswordModal, setNewPasswordModal] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')

  const schema = yup.object().shape({
    password: yup.string().required('Este campo es requerido'),
    email: yup
      .string()
      .required('Este campo es requerido')
      .email('Por favor ingrese un email válido')
      .nullable(),
  })

  const { control, errors, handleSubmit, triggerValidation, setValue } =
    useForm({
      defaultValues: {
        email: '',
        password: '',
      },
      resolver: yupResolver(schema),
    })

  const onSubmit = (data) => {
    onLogin(data)
  }

  const getErrorMessage = () => {
    switch (error.code) {
      case 'UserNotConfirmedException':
        return 'El email no está confirmado. Por favor revise su bandeja de correo.'
      case 'NotAuthorizedException':
        if (R.equals(error.message, 'User is disabled.'))
          return 'El usuario fue deshabilitado por un administrador del sistema.'
        else
          return 'Las credenciales no son válidas. Por favor verifique que haya escrito bien su email y contraseña.'
      case 'UserNotFoundException':
        return 'La dirección de email ingresada no existe.'
      case 'NewPasswordRequired':
      case 'InvalidParameterException':
        return null
      case 'InvalidPasswordException':
        return 'La contraseña debe tener al menos 8 caracteres, una mayúscula y una minúscula.'
      default:
        return `Hubo un error desconocido al intentar iniciar sesión. Detalles: ${error.message}`
    }
  }
  useEffect(() => {
    setSuccessMessage(null)
    if (!error) setErrorMessage(null)
    else setErrorMessage(getErrorMessage())
    if (
      R.includes(error?.code, [
        'NewPasswordRequired',
        'InvalidPasswordException',
      ])
    )
      setNewPasswordModal(true)
    if (R.equals(error?.code, 'InvalidParameterException')) {
      setSuccessMessage(
        'Su contraseña fue modificada correctamente. Ahora puede iniciar sesión con su nueva contraseña'
      )
      setValue('password', '', false)
    }
  }, [error])

  const completePasswordChallenge = () => {
    setNewPasswordModal(false)
    onCompletePasswordChallenge({
      newPassword,
      cognitoUser: error.cognitoUser,
    })
  }

  useEffect(() => {
    if (!user) return

    if (user.user.provider) {
      // if not enabled and wizard complete, then logout
      if (!user.user.provider.enabled && user.user.provider.wizardComplete) {
        setErrorMessage(
          'Su cuenta esta siendo verificada. Por favor aguarde a que un administrador lo valide.'
        )
        userLogout()
        return
      }

      // if user enabled and not wizard complete, error out as this shouldn't happen.
      if (user.user.provider.enabled && !user.user.provider.wizardComplete) {
        setErrorMessage(
          'Por favor contacte a su administrador ya que hay algun problema con su cuenta.'
        )
        userLogout()
        return
      }
    }

    const groups = user.user.groups

    if (!groups) {
      setErrorMessage(
        'Las credenciales no son válidas. Por favor verifique que haya escrito bien su email y contraseña.'
      )
      userLogout()
      return
    }

    let url = 'providers/appointments'

    if (R.includes('administrators', groups)) url = 'admin'

    if (R.includes('clientAdministrator', groups)) url = 'admin'

    if (R.includes('assistant', groups)) url = 'assistants/appointments'

    if (user.user.provider && !user.user.provider.wizardComplete)
      url = 'providers/wizard'

    history.push(`/${url}`)
  }, [user])

  const browser = Bowser.getParser(window.navigator.userAgent)
  const isValidBrowser = !!browser.satisfies({
    chrome: `>${process.env.REACT_APP_CHROME_VERSION_ALLOWED}`,
  })

  return (
    <BackgroundImage image={background}>
      <Grid container className={classes.container}>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          <Card className={classes.loginCard}>
            <CardMedia className={classes.logo} image={logo} />
            <CardContent className={classes.loginCardContent}>
              {!isValidBrowser && (
                <Alert severity='warning' className={classes.browserAlert}>
                  <AlertTitle>Navegador no soportado</AlertTitle>
                  Para que la página funcione correctamente es necesario que
                  utilice Google Chrome Versión{' '}
                  {process.env.REACT_APP_CHROME_VERSION_ALLOWED} o superior.
                  <br />
                  <Link href='https://www.google.com/chrome/'>
                    Haga click aquí para descargarla.
                  </Link>
                </Alert>
              )}
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <IconTextField
                    icon={<Email />}
                    control={control}
                    name='email'
                    errors={errors}
                    label='Dirección de Email'
                    onBlur={() => triggerValidation('email')}
                    fullWidth
                  />
                </Box>
                <Box mt={2}>
                  <IconTextField
                    icon={<Lock />}
                    control={control}
                    name='password'
                    errors={errors}
                    label='Contraseña'
                    onBlur={() => triggerValidation('password')}
                    fullWidth
                    type='password'
                  />
                </Box>
                <Box mt={1} textAlign='right'>
                  <Grid container justify='center'>
                    <Grid item xs={10} md={7}>
                      <Link fontSize='0.875rem' href='forgotpassword'>
                        Olvidé mi contraseña
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
                {errorMessage && (
                  <Box mt={3}>
                    <Alert severity='error'>
                      <AlertTitle>Error al intentar iniciar sesión</AlertTitle>
                      {errorMessage}
                    </Alert>
                  </Box>
                )}
                {successMessage && (
                  <Box mt={3}>
                    <Alert severity='success'>
                      <AlertTitle>Éxito</AlertTitle>
                      {successMessage}
                    </Alert>
                  </Box>
                )}
                <Box mt={4} textAlign='center' position='relative'>
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    loading={loading}
                  >
                    INICIAR SESIÓN
                  </LoadingButton>
                </Box>
                <Box mt={2} textAlign='center'>
                  <Link href='/registration'>REGISTRO</Link>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
      <Dialog
        open={newPasswordModal}
        onClose={() => setNewPasswordModal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Nueva Contraseña Requerida
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Por favor ingrese su nueva contraseña
          </DialogContentText>
          <Box className={classes.inputBox}>
            <TextField
              label='Contraseña'
              type='password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Box>
          <Box className={classes.inputBox}>
            <TextField
              label='Confirmar Contraseña'
              type='password'
              value={newPasswordConfirmation}
              onChange={(e) => setNewPasswordConfirmation(e.target.value)}
            />
          </Box>
          {errorMessage && (
            <Box mt={3}>
              <Alert severity='error'>{errorMessage}</Alert>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            autoFocus
            disabled={
              !newPassword || !R.equals(newPassword, newPasswordConfirmation)
            }
            variant='contained'
            onClick={() => completePasswordChallenge()}
          >
            ENVIAR
          </Button>
        </DialogActions>
      </Dialog>
    </BackgroundImage>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    user: getUser(state),
    loading: getLoading(state),
  }
}

export default connect(mapStateToProps, {
  onLogin: login,
  onLogout: logout,
  onCompletePasswordChallenge: userPasswordChallengeRequest,
})(Login)
