import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  Box,
  ButtonBase,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { useLocation, Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { getFiles } from 'common/reducers/files'
import { getCompany } from 'common/reducers/companies'

import { makeStyles } from '@material-ui/core/styles'

import logo from 'assets/TeleMeditar_Logo_SM.png'

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  link: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  btnLogo: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  btnText: {
    textAlign: 'right',
    color: theme.palette.primary.main,
  },
  logo: {
    width: '10rem',
  },
}))

const Header = ({ company }) => {
  const classes = useStyles()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [loggedUser, setLoggedUser] = useState(null)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      const groups = user.signInUserSession.idToken.payload['cognito:groups']
      setLoggedUser({ username: user.username, ...user.attributes, groups })
    })
  }, [location])

  return (
    <AppBar position='static'>
      <Toolbar>
        <Box className={classes.flexGrow}>
          <img src={logo} className={classes.logo} />
          <Link className={classes.link} to='/appointments'>
            Agenda
          </Link>
          <Link className={classes.link} to='/schedules'>
            Horarios
          </Link>
        </Box>
        {loggedUser && (
          <Box>
            <ButtonBase onClick={handleMenu} color='inherit'>
              <Box className={classes.btnText}>
                <Typography variant='body2'>
                  {loggedUser?.given_name} {loggedUser?.family_name}
                </Typography>
                <Typography variant='button'>{company?.name}</Typography>
              </Box>
              <Box className={classes.btnLogo}>
                <AccountCircle />
              </Box>
            </ButtonBase>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => (window.location.href = '/logout')}>
                Cerrar Sesión
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => {
  return {
    files: getFiles(state),
    company: getCompany(state),
  }
}

export default connect(mapStateToProps, {})(Header)
