import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  Card,
  CardHeader,
  Typography,
  Avatar,
  Link,
  Button,
  TextareaAutosize,
  Paper,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  CardContent,
} from '@material-ui/core'
import FileDownloadIcon from '@material-ui/icons/GetApp'
import AttachFile from '@material-ui/icons/AttachFile'
import Save from '@material-ui/icons/Save'
import { LoadingButton } from 'common/components'

import { makeStyles } from '@material-ui/core/styles'
import {
  fileDownloadGetRequest,
  patientHealthRecordCreateRequest,
  patientHealthRecordGetRequest,
  patientHealthRecordDownloadReset,
  patientHealthRecordDownloadRequest,
} from 'common/actions'
import {
  getLoading as getFileLoading,
  getDownloadFile,
} from 'common/reducers/files'
import {
  getLoading as getPatientLoading,
  getSuccess,
  getHealthRecords,
  getHealthRecordDownload,
  getPatient,
} from 'common/reducers/patients'

const useStyles = makeStyles((theme) => ({
  secondaryCard: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    marginRight: theme.spacing(3),
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  bold: {
    fontWeight: '600',
  },
  tab: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  textarea: {
    width: '100%',
    height: 'auto !important',
    marginTop: theme.spacing(1),
  },
  patientCard: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
  },
  historic: {
    // padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  historicTitle: {
    marginBottom: theme.spacing(2),
  },
  boldSpan: {
    fontWeight: '600',
    display: 'inline',
  },
  noPaddingTop: {
    paddingTop: '0px !important',
  },
}))

const HealthRecord = ({
  appointment,
  patient,
  onFileDownload,
  downloadFile,
  fileLoading,
  healthRecords,
  patientLoading,
  success,
  onRequestHealthRecords,
  onCreateHealthRecord,
  onRequestHealthRecordDownload,
  onResetHealthRecordDownload,
  healthRecordDownload,
}) => {
  const classes = useStyles()
  const [formData, setFormData] = useState({})
  const [confirmationModal, setConfirmationModal] = useState(false)

  const schema = yup.object().shape({
    treatment: yup.string().required('Este campo es requerido'),
    reason: yup.string().required('Este campo es requerido'),
    diagnostic: yup.string().required('Este campo es requerido'),
    indication: yup.string().required('Este campo es requerido'),
  })

  const { control, errors, handleSubmit, triggerValidation, register, reset } =
    useForm({
      defaultValues: {
        treatment: '',
        reason: '',
        diagnostic: '',
        indication: '',
      },
      resolver: yupResolver(schema),
    })

  useEffect(() => {
    if (!success) return
    onRequestHealthRecords(patient.id)
  }, [success])

  useEffect(() => {
    if (!healthRecordDownload) return

    const process = async () => {
      const filename = `HistoriaClinica_${patient.name}_${
        patient.lastName
      }.${healthRecordDownload.headers['content-type'].replace(
        'application/',
        ''
      )}`

      const text = await healthRecordDownload.data.text()

      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = text
      tempLink.setAttribute('download', filename)

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }

      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(text)

      onResetHealthRecordDownload()
    }
    process()
  }, [healthRecordDownload])

  const onSubmit = (data) => {
    setFormData(data)
    setConfirmationModal(true)
  }

  const createHealthRecord = () => {
    onCreateHealthRecord({
      healthRecord: formData,
      timestamp: appointment.timestamp,
      patient: patient.id,
    })
    setConfirmationModal(false)
  }

  return (
    <>
      {patientLoading && <CircularProgress />}
      {!R.find(
        (hr) => R.equals(hr.timestamp, appointment?.timestamp),
        healthRecords || []
      ) && (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='reason'
              label='Motivo'
              error={!!errors.reason}
              helperText={errors.reason && errors.reason.message}
              onBlur={() => triggerValidation('reason')}
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              as={<TextField />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='diagnostic'
              label='Diagnóstico'
              error={!!errors.diagnostic}
              helperText={errors.diagnostic && errors.diagnostic.message}
              onBlur={() => triggerValidation('diagnostic')}
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              as={<TextField />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='treatment'
              label='Tratamiento'
              error={!!errors.treatment}
              helperText={errors.treatment && errors.treatment.message}
              onBlur={() => triggerValidation('treatment')}
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              as={<TextField />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='indication'
              label='Indicaciones'
              error={!!errors.indication}
              helperText={errors.indication && errors.indication.message}
              onBlur={() => triggerValidation('indication')}
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
              as={<TextField />}
            />
          </Box>
          <Box mt={4}>
            <LoadingButton
              type='submit'
              variant='contained'
              color='primary'
              loading={patientLoading}
            >
              GUARDAR
            </LoadingButton>
          </Box>
        </form>
      )}
      <Box mt={4}>
        <Typography color='primary' as='h4' className={classes.historicTitle}>
          Histórico
        </Typography>
        {!healthRecords?.length ? (
          <Box>No hay datos anteriores a la fecha</Box>
        ) : (
          <>
            {healthRecords?.map((hr) => (
              <Card key={hr.timestamp} className={classes.historic}>
                <CardHeader
                  action={
                    <IconButton
                      aria-label='settings'
                      onClick={() =>
                        onRequestHealthRecordDownload({
                          patient: patient.id,
                          timestamp: hr.timestamp,
                        })
                      }
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  }
                  title={`Turno: ${dayjs
                    .utc(hr.timestamp * 1000)
                    .local()
                    .format('DD [de] MMMM YYYY [a las] HH:mm')}`}
                  subheader={`Registro enviado: ${dayjs
                    .utc(hr.createdAt)
                    .local()
                    .format('DD/MM/YYYY HH:mm')}`}
                />
                <CardContent className={classes.noPaddingTop}>
                  <Box>
                    <Box className={classes.boldSpan}>Motivo:</Box> {hr.reason}
                  </Box>
                  <Box>
                    <Box className={classes.boldSpan}>Diagnóstico:</Box>{' '}
                    {hr.diagnostic}
                  </Box>
                  <Box>
                    <Box className={classes.boldSpan}>Tratamiento:</Box>{' '}
                    {hr.treatment}
                  </Box>
                  <Box>
                    <Box className={classes.boldSpan}>Indicaciones:</Box>{' '}
                    {hr.indication}
                  </Box>
                </CardContent>
              </Card>
            ))}{' '}
          </>
        )}
      </Box>
      <Dialog
        open={confirmationModal}
        onClose={() => setConfirmationModal(false)}
      >
        <DialogTitle id='alert-dialog-title'>Historia Clínica</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Recuerde que una vez enviada la historia clínica no podrá editar ni
            volver a enviar otro registro hasta la próxima consulta.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={() => setConfirmationModal(false)}>
            Volver
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => createHealthRecord()}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    fileLoading: getFileLoading(state),
    downloadFile: getDownloadFile(state),
    healthRecords: getHealthRecords(state),
    patientLoading: getPatientLoading(state),
    success: getSuccess(state),
    // appointment: getAppointment(state),
    patient: getPatient(state),
    healthRecordDownload: getHealthRecordDownload(state),
  }
}

export default connect(mapStateToProps, {
  onFileDownload: fileDownloadGetRequest,
  onRequestHealthRecords: patientHealthRecordGetRequest,
  onCreateHealthRecord: patientHealthRecordCreateRequest,
  onResetHealthRecordDownload: patientHealthRecordDownloadReset,
  onRequestHealthRecordDownload: patientHealthRecordDownloadRequest,
})(HealthRecord)
