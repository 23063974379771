import * as ct from 'countries-and-timezones'

export const getAllTimeZones = () => Object.keys(ct.getAllTimezones())
export const getAllCountries = () => ct.getAllCountries()

export const getHardCodedCountries = () => [
  {
    name: 'Argentina',
    provinces: [
      {
        complete_name: 'Provincia de Misiones',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-N',
        name: 'Misiones',
        id: '54',
        iso_name: 'Misiones',
        centroide: {
          lat: -26.8753965086829,
          lon: -54.6516966230371,
        },
      },
      {
        complete_name: 'Provincia de San Luis',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-D',
        name: 'San Luis',
        id: '74',
        iso_name: 'San Luis',
        centroide: {
          lat: -33.7577257449137,
          lon: -66.0281298195836,
        },
      },
      {
        complete_name: 'Provincia de San Juan',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-J',
        name: 'San Juan',
        id: '70',
        iso_name: 'San Juan',
        centroide: {
          lat: -30.8653679979618,
          lon: -68.8894908486844,
        },
      },
      {
        complete_name: 'Provincia de Entre Ríos',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-E',
        name: 'Entre Ríos',
        id: '30',
        iso_name: 'Entre Ríos',
        centroide: {
          lat: -32.0588735436448,
          lon: -59.2014475514635,
        },
      },
      {
        complete_name: 'Provincia de Santa Cruz',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-Z',
        name: 'Santa Cruz',
        id: '78',
        iso_name: 'Santa Cruz',
        centroide: {
          lat: -48.8154851827063,
          lon: -69.9557621671973,
        },
      },
      {
        complete_name: 'Provincia de Río Negro',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-R',
        name: 'Río Negro',
        id: '62',
        iso_name: 'Río Negro',
        centroide: {
          lat: -40.4057957178801,
          lon: -67.229329893694,
        },
      },
      {
        complete_name: 'Provincia del Chubut',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-U',
        name: 'Chubut',
        id: '26',
        iso_name: 'Chubut',
        centroide: {
          lat: -43.7886233529878,
          lon: -68.5267593943345,
        },
      },
      {
        complete_name: 'Provincia de Córdoba',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-X',
        name: 'Córdoba',
        id: '14',
        iso_name: 'Córdoba',
        centroide: {
          lat: -32.142932663607,
          lon: -63.8017532741662,
        },
      },
      {
        complete_name: 'Provincia de Mendoza',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-M',
        name: 'Mendoza',
        id: '50',
        iso_name: 'Mendoza',
        centroide: {
          lat: -34.6298873058957,
          lon: -68.5831228183798,
        },
      },
      {
        complete_name: 'Provincia de La Rioja',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-F',
        name: 'La Rioja',
        id: '46',
        iso_name: 'La Rioja',
        centroide: {
          lat: -29.685776298315,
          lon: -67.1817359694432,
        },
      },
      {
        complete_name: 'Provincia de Catamarca',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-K',
        name: 'Catamarca',
        id: '10',
        iso_name: 'Catamarca',
        centroide: {
          lat: -27.3358332810217,
          lon: -66.9476824299928,
        },
      },
      {
        complete_name: 'Provincia de La Pampa',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-L',
        name: 'La Pampa',
        id: '42',
        iso_name: 'La Pampa',
        centroide: {
          lat: -37.1315537735949,
          lon: -65.4466546606951,
        },
      },
      {
        complete_name: 'Provincia de Santiago del Estero',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-G',
        name: 'Santiago del Estero',
        id: '86',
        iso_name: 'Santiago del Estero',
        centroide: {
          lat: -27.7824116550944,
          lon: -63.2523866568588,
        },
      },
      {
        complete_name: 'Provincia de Corrientes',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-W',
        name: 'Corrientes',
        id: '18',
        iso_name: 'Corrientes',
        centroide: {
          lat: -28.7743047046407,
          lon: -57.8012191977913,
        },
      },
      {
        complete_name: 'Provincia de Santa Fe',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-S',
        name: 'Santa Fe',
        id: '82',
        iso_name: 'Santa Fe',
        centroide: {
          lat: -30.7069271588117,
          lon: -60.9498369430241,
        },
      },
      {
        complete_name: 'Provincia de Tucumán',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-T',
        name: 'Tucumán',
        id: '90',
        iso_name: 'Tucumán',
        centroide: {
          lat: -26.9478001830786,
          lon: -65.3647579441481,
        },
      },
      {
        complete_name: 'Provincia del Neuquén',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-Q',
        name: 'Neuquén',
        id: '58',
        iso_name: 'Neuquén',
        centroide: {
          lat: -38.6417575824599,
          lon: -70.1185705180601,
        },
      },
      {
        complete_name: 'Provincia de Salta',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-A',
        name: 'Salta',
        id: '66',
        iso_name: 'Salta',
        centroide: {
          lat: -24.2991344492002,
          lon: -64.8144629600627,
        },
      },
      {
        complete_name: 'Provincia del Chaco',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-H',
        name: 'Chaco',
        id: '22',
        iso_name: 'Chaco',
        centroide: {
          lat: -26.3864309061226,
          lon: -60.7658307438603,
        },
      },
      {
        complete_name: 'Provincia de Formosa',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-P',
        name: 'Formosa',
        id: '34',
        iso_name: 'Formosa',
        centroide: {
          lat: -24.894972594871,
          lon: -59.9324405800872,
        },
      },
      {
        complete_name: 'Provincia de Jujuy',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-Y',
        name: 'Jujuy',
        id: '38',
        iso_name: 'Jujuy',
        centroide: {
          lat: -23.3200784211351,
          lon: -65.7642522180337,
        },
      },
      {
        complete_name: 'Ciudad Autónoma de Buenos Aires',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-C',
        name: 'Ciudad Autónoma de Buenos Aires',
        id: '02',
        iso_name: 'Ciudad Autónoma de Buenos Aires',
        centroide: {
          lat: -34.6144934119689,
          lon: -58.4458563545429,
        },
      },
      {
        complete_name: 'Provincia de Buenos Aires',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-B',
        name: 'Buenos Aires',
        id: '06',
        iso_name: 'Buenos Aires',
        centroide: {
          lat: -36.6769415180527,
          lon: -60.5588319815719,
        },
      },
      {
        complete_name:
          'Provincia de Tierra del Fuego, Antártida e Islas del Atlántico Sur',
        timezone: 'America/Argentina/Buenos_Aires',
        iso_id: 'AR-V',
        name: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
        id: '94',
        iso_name: 'Tierra del Fuego',
        centroide: {
          lat: -82.52151781221,
          lon: -50.7427486049785,
        },
      },
    ],
  },
  {
    name: 'Mexico',
    provinces: [
      { id: 'AGS', name: 'AGUASCALIENTES', timezone: 'America/Mexico_City' },
      { id: 'BC', name: 'BAJA CALIFORNIA', timezone: 'America/Tijuana' },
      {
        id: 'BCS',
        name: 'BAJA CALIFORNIA SUR',
        timezone: 'America/Hermosillo',
      },
      { id: 'CHI', name: 'CHIHUAHUA', timezone: 'America/Chihuahua' },
      { id: 'CHS', name: 'CHIAPAS', timezone: 'America/Mexico_City' },
      { id: 'CMP', name: 'CAMPECHE', timezone: 'America/Mexico_City' },
      { id: 'CMX', name: 'CIUDAD DE MEXICO', timezone: 'America/Mexico_City' },
      { id: 'COA', name: 'COAHUILA', timezone: 'America/Mexico_City' },
      { id: 'COL', name: 'COLIMA', timezone: 'America/Mexico_City' },
      { id: 'DGO', name: 'DURANGO', timezone: 'America/Mexico_City' },
      { id: 'GRO', name: 'GUERRERO', timezone: 'America/Mexico_City' },
      { id: 'GTO', name: 'GUANAJUATO', timezone: 'America/Mexico_City' },
      { id: 'HGO', name: 'HIDALGO', timezone: 'America/Mexico_City' },
      { id: 'JAL', name: 'JALISCO', timezone: 'America/Mexico_City' },
      { id: 'MCH', name: 'MICHOACAN', timezone: 'America/Mexico_City' },
      { id: 'MOR', name: 'MORELOS', timezone: 'America/Mexico_City' },
      { id: 'NAY', name: 'NAYARIT', timezone: 'America/Hermosillo' },
      { id: 'NL', name: 'NUEVO LEON', timezone: 'America/Mexico_City' },
      { id: 'OAX', name: 'OAXACA', timezone: 'America/Mexico_City' },
      { id: 'PUE', name: 'PUEBLA', timezone: 'America/Mexico_City' },
      { id: 'QR', name: 'QUINTANA ROO', timezone: 'America/Cancun' },
      { id: 'QRO', name: 'QUERETARO', timezone: 'America/Mexico_City' },
      { id: 'SIN', name: 'SINALOA', timezone: 'America/Hermosillo' },
      { id: 'SLP', name: 'SAN LUIS POTOSI', timezone: 'America/Mexico_City' },
      { id: 'SON', name: 'SONORA', timezone: 'America/Hermosillo' },
      { id: 'TAB', name: 'TABASCO', timezone: 'America/Mexico_City' },
      { id: 'TLX', name: 'TLAXCALA', timezone: 'America/Mexico_City' },
      { id: 'TMS', name: 'TAMAULIPAS', timezone: 'America/Mexico_City' },
      { id: 'VER', name: 'VERACRUZ', timezone: 'America/Mexico_City' },
      { id: 'YUC', name: 'YUCATAN', timezone: 'America/Mexico_City' },
      { id: 'ZAC', name: 'ZACATECAS', timezone: 'America/Mexico_City' },
    ],
  },
]
