import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import { Route, useLocation, Redirect } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'

import { companyGetRequest } from 'common/actions'
import { getCompany } from 'common/reducers/companies'
import getCurrentUser from 'common/utils/getCurrentUser'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, company, retrieveCompany, ...rest }) {
  const [isAuth, setIsAuth] = useState(true)
  const [loggedUser, setLoggedUser] = useState(null)

  const location = useLocation()

  useEffect(() => {
    const getUser = async () => {
      const user = await getCurrentUser()
      if (
        !user ||
        (!R.includes('administrators', user.groups) &&
          !R.includes('clientAdministrator', user.groups))
      ) {
        setIsAuth(false)
        return
      }
      setLoggedUser(user)
    }
    getUser()
  }, [location])

  useEffect(() => {
    if (company) return
    retrieveCompany()
  }, [company])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth
          ? React.cloneElement(children, {
              loggedUser,
              loggedUserCompany: company,
            })
          : (window.location = `${window.location.origin}/login`)
      }
    />
  )
}

const mapStateToProps = (state) => ({
  company: getCompany(state),
})

export default connect(mapStateToProps, {
  retrieveCompany: companyGetRequest,
})(PrivateRoute)
