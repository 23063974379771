import { combineReducers } from 'redux'
import { appointmentReducer } from './appointment'
import { userReducer } from './user'
import { specialtiesReducer } from './specialties'
import { filesReducer } from './files'
import { providersReducer } from './providers'
import { locationsReducer } from './locations'
import { awsReducer } from './aws'
import { patientsReducer } from './patients'
import { coveragesReducer } from './coverages'
import { companiesReducer } from './companies'
import { dealsReducer } from './deals'
import { reportsReducer } from './reports'
import { assistantReducer } from './assistant'
import { countriesReducer } from './countries'
import { pubSubReducer } from './pubsub'

const rootReducer = combineReducers({
  userReducer,
  appointmentReducer,
  specialtiesReducer,
  filesReducer,
  providersReducer,
  locationsReducer,
  awsReducer,
  patientsReducer,
  coveragesReducer,
  companiesReducer,
  dealsReducer,
  reportsReducer,
  assistantReducer,
  countriesReducer,
  pubSubReducer,
})

export default rootReducer
