import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import * as R from 'ramda'
import accent from 'accents'
import {
  Box,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Typography,
  Avatar,
  Toolbar,
  Chip,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import BlockIcon from '@material-ui/icons/Block'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import Person from '@material-ui/icons/PersonOutline'

import { convertToBase64Image } from 'common/utils/awsUtils'
import { useHistory } from 'react-router-dom'
import { Header } from '../layout'
import {
  providerGetRequest,
  multipleFileGetRequest,
  requestSpecialtiesGet,
  providerDeleteRequest,
} from 'common/actions'
import {
  getError,
  getLoading,
  getProviders,
  getSuccess,
} from 'common/reducers/providers'
import { getSpecialties } from 'common/reducers/specialties'
import { getCountries } from 'common/reducers/locations'
import { getFiles } from 'common/reducers/files'
import theme from 'common/theme'

import { LoadingOverlay } from 'common/components'

import MaterialTable, { MTableToolbar } from 'material-table'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  fullWidth: {
    width: '100%',
  },
  newButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    padding: theme.spacing(2),
  },
  licenseCol: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'table-cell',
    },
  },
  noResults: {
    padding: theme.spacing(3),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  specialties: {
    marginTop: theme.spacing(1),
    display: 'block',
  },
}))

const ProvidersNew = ({
  error,
  loading,
  providers,
  onRequestProviders,
  multipleFileGet,
  files,
  success,
  onRequestSpecialties,
  countries,
  specialties,
  onDeleteProvider,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
  const [selectedProvider, setSelectedProvider] = useState(null)
  const [pendingFilter, setPendingFilter] = useState(false)

  useEffect(() => {
    onRequestSpecialties('all')
  }, [])

  useEffect(() => {
    onRequestProviders({ params: { pendingValidation: pendingFilter } })
  }, [pendingFilter])

  useEffect(() => {
    if (!providers) return
    multipleFileGet(
      R.filter(
        (p) =>
          p.profilePicture &&
          p.profilePicture.key &&
          !files[p.profilePicture.key],
        providers
      ).map((p) => p.profilePicture)
    )
  }, [providers])

  useEffect(() => {
    if (!success) return
    onRequestProviders({ params: { pendingValidation: pendingFilter } })
  }, [success])

  const deleteProvider = () => {
    onDeleteProvider(selectedProvider.id)
    setDeleteConfirmationModal(false)
    setSelectedProvider(null)
  }

  return (
    <>
      {loading && <LoadingOverlay />}
      <Header />
      <Container className={classes.container}>
        <MaterialTable
          components={{
            Toolbar: (props) => (
              <Box>
                <MTableToolbar {...props}></MTableToolbar>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!pendingFilter}
                      onChange={() => setPendingFilter(!pendingFilter)}
                      color='primary'
                      name='pendingFilter'
                    />
                  }
                  label={!pendingFilter ? 'Validados' : 'Pendientes'}
                  className={classes.marginLeft}
                />
              </Box>
            ),
          }}
          columns={[
            {
              title: 'Nombre y Apellido',
              render: (rowData) => (
                <Grid container spacing={2} alignItems='center' wrap='nowrap'>
                  <Grid item>
                    <Avatar src={files[rowData.profilePicture?.key]?.Image} />
                  </Grid>
                  <Grid item>
                    {rowData.name} {rowData.lastName}
                    {rowData.email && (
                      <Typography variant='body2'>{rowData.email}</Typography>
                    )}
                  </Grid>
                </Grid>
              ),
              customFilterAndSearch: (value, rowData) => {
                return (
                  R.includes(
                    accent(R.toLower(value)),
                    accent(R.toLower(rowData.name))
                  ) ||
                  R.includes(
                    accent(R.toLower(value)),
                    accent(R.toLower(rowData.lastName))
                  ) ||
                  R.includes(
                    accent(R.toLower(value)),
                    accent(R.toLower(rowData.email))
                  )
                )
              },
            },
            {
              title: 'Matrícula',
              field: 'license',
            },
            {
              title: 'Creación',
              render: (rowData) =>
                dayjs(rowData.createdAt).format('DD/MM/YYYY'),
            },
          ]}
          data={providers || []}
          title='Profesionales'
          options={{
            actionsColumnIndex: -1,
          }}
          actions={[
            {
              icon: () => <AddCircleIcon color='primary' />,
              tooltip: 'Nuevo Profesional',
              position: 'toolbar',
              isFreeAction: true,
              onClick: () => {
                history.push('/providers/new')
              },
            },
            (rowData) => ({
              icon: () => <Person color='primary' />,
              tooltip: 'Ver Profesional',
              onClick: (event) => {
                history.push(`/providers/${rowData.id}/view`)
              },
            }),
            (rowData) => ({
              icon: () => <EditIcon color='primary' />,
              tooltip: 'Editar Profesional',
              onClick: (event) => {
                history.push(`/providers/${rowData.id}`)
              },
            }),
            (rowData) => ({
              icon: () => <BlockIcon style={theme.danger} />,
              tooltip: 'Deshabilitar Profesional',
              onClick: (event) => {
                setDeleteConfirmationModal(true)
                setSelectedProvider(rowData)
              },
            }),
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'filas',
              labelRowsPerPage: 'Filas por página: ',
            },
            toolbar: {
              searchPlaceholder: 'Búsqueda',
              searchTooltip: 'Búsqueda',
            },
            header: {
              actions: 'Acciones',
            },
            body: {
              emptyDataSourceMessage: 'No hay profesionales',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
              editTooltip: 'Editar',
            },
          }}
        />
        <Dialog
          open={deleteConfirmationModal}
          onClose={() => {
            setDeleteConfirmationModal(false)
            setSelectedProvider(null)
          }}
        >
          {selectedProvider && (
            <>
              <DialogTitle id='alert-dialog-title'>
                Deshabilitar {selectedProvider.name} {selectedProvider.lastName}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  ¿Está seguro que desea deshabilitar el profesional{' '}
                  {selectedProvider.name} {selectedProvider.lastName}?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setDeleteConfirmationModal(false)
                    setSelectedProvider(null)
                  }}
                >
                  Volver
                </Button>
                <Button style={theme.danger} onClick={() => deleteProvider()}>
                  Si, deshabilitar
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    providers: getProviders(state),
    files: getFiles(state),
    success: getSuccess(state),
    specialties: getSpecialties(state),
    countries: getCountries(state),
  }
}

export default connect(mapStateToProps, {
  onRequestProviders: providerGetRequest,
  multipleFileGet: multipleFileGetRequest,
  onRequestSpecialties: requestSpecialtiesGet,
  onDeleteProvider: providerDeleteRequest,
})(ProvidersNew)
