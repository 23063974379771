import * as ActionTypes from '../actions'

const initialState = {
  specialties: null,
  loading: false,
  error: null,
  success: false,
}

export function specialtiesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SPECIALTIES_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        specialties: [],
      }
    case ActionTypes.SPECIALTIES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        specialties: action.data.items,
      }
    case ActionTypes.SPECIALTIES_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case ActionTypes.SPECIALTIES_DELETE_REQUEST:
    case ActionTypes.SPECIALTIES_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      }
    case ActionTypes.SPECIALTIES_DELETE_SUCCESS:
      return { ...state, loading: false, success: true }
    case ActionTypes.SPECIALTIES_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        specialties: [...state.specialties, action.data],
      }
    case ActionTypes.SPECIALTIES_DELETE_FAILURE:
    case ActionTypes.SPECIALTIES_CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export function getSuccess(state) {
  return state.specialtiesReducer.success
}

export function getSpecialties(state) {
  return state.specialtiesReducer.specialties
}

export function getLoading(state) {
  return state.specialtiesReducer.loading
}

export function getError(state) {
  return state.specialtiesReducer.error
}
