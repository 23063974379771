import * as ActionTypes from '../actions'

const initialState = {
  loading: false,
  error: null,
  success: false,
}

export function countriesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.COUNTRY_PUT_RESET:
      return {
        ...state,
        error: null,
        loading: false,
        success: false,
      }
    case ActionTypes.COUNTRY_PUT_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      }
    case ActionTypes.COUNTRY_PUT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case ActionTypes.COUNTRY_PUT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export function getLoading(state) {
  return state.countriesReducer.loading
}

export function getError(state) {
  return state.countriesReducer.error
}

export function getSuccess(state) {
  return state.countriesReducer.success
}
