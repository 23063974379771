import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container } from '@material-ui/core'
import AssessmentIcon from '@material-ui/icons/Assessment'
import { makeStyles } from '@material-ui/core/styles'
import { LoadingOverlay } from 'common/components'
import { useHistory } from 'react-router-dom'

import MaterialTable from 'material-table'

import { Header } from '../layout'
import { reportsGetRequest } from 'common/actions'
import { getError, getLoading, getReports } from 'common/reducers/reports'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}))

const Reports = ({ error, loading, reports, onRequestReports, success }) => {
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    onRequestReports()
  }, [])

  return (
    <>
      {loading && <LoadingOverlay />}
      <Header />
      <Container className={classes.container}>
        <MaterialTable
          columns={[{ title: 'Nombre', field: 'name', editable: 'never' }]}
          data={reports || []}
          title='Reportes'
          options={{
            actionsColumnIndex: -1,
          }}
          actions={[
            (rowData) => ({
              icon: () => <AssessmentIcon color='primary' />,
              tooltip: 'Ver reporte',
              onClick: () => {
                history.push(`/reports/${rowData.id}`)
              },
            }),
          ]}
          localization={{
            pagination: {
              labelDisplayedRows: '{from}-{to} de {count}',
              labelRowsSelect: 'filas',
              labelRowsPerPage: 'Filas por página: ',
            },
            toolbar: {
              searchPlaceholder: 'Búsqueda',
              searchTooltip: 'Búsqueda',
            },
            header: {
              actions: 'Acciones',
            },
            body: {
              emptyDataSourceMessage: 'No hay reportes',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
              editTooltip: 'Editar',
            },
          }}
        />
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    reports: getReports(state),
  }
}

export default connect(mapStateToProps, {
  onRequestReports: reportsGetRequest,
})(Reports)
