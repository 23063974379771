import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import dayjs from 'dayjs'
import {
  Box,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import {
  requestAppointments,
  requestAppointmentReasons,
  fileDownloadGetRequest,
  requestAssistantSpecialtiesGet,
  providerScheduleGetRequest,
  patientSearchRequest,
  fileGetRequest,
  fileUploadRequest,
  fileUploadReset,
  createAppointment,
  resetCreateAppointment,
  resetPatientSearch,
  dealsGetRequest,
} from 'common/actions'

import {
  getError,
  getLoading as getAppointmentsLoading,
  getAppointments,
  getReasons,
  getSuccess,
} from 'common/reducers/appointment'
import {
  getLoading as getFileLoading,
  getDownloadFile,
  getUploadResponse,
  getFiles,
} from 'common/reducers/files'
import { getSpecialties, getProviders } from 'common/reducers/assistant'
import {
  getPatientSearch,
  getLoading as getPatientLoading,
  getError as getPatientError,
} from 'common/reducers/patients'

import {
  getLoading as getSchedulesLoading,
  getSchedules,
} from 'common/reducers/providers'
import { AttachFile, Search } from '@material-ui/icons'
import { LoadingButton, SelectField } from 'common/components'
import { getDeals } from 'common/reducers/deals'
import { getCompany } from 'common/reducers/companies'

const useStyles = makeStyles((theme) => ({
  inputBox: {
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  fileInput: {
    display: 'none',
  },
}))

const NewAppointmentModal = ({
  onRetrieveAppointmentReasons,
  reasons,
  onPatientSearch,
  patientSearch,
  patientLoading,
  appointmentReasons,
  selectedSpecialty,
  selectedSchedule,
  selectedProvider,
  onClose,
  loading,
  files,
  onResetFileUpload,
  onRequestUpload,
  fileLoading,
  uploadResponse,
  onCreateAppointment,
  appointmentSuccess,
  onCreateAppointmentReset,
  onResetPatientSearch,
  deals,
  onGetDeals,
  defaultPatient,
  patientError,
  isOpen,
  company,
  selectedTimezone,
}) => {
  const classes = useStyles()
  const [documentSearch, setDocumentSearch] = useState('')
  const [emailSearch, setEmailSearch] = useState('')
  const [searched, setSearched] = useState(false)
  const [emailSearched, setEmailSearched] = useState(false)
  const [selectedReasons, setSelectedReasons] = useState([])
  const [attachments, setAttachments] = useState([])
  const [otherReason, setOtherReason] = useState()
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [selectedDeal, setSelectedDeal] = useState(null)
  const [appointmentCode, setAppointmentCode] = useState('')
  const fileInputRef = useRef(null)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [savedResult, setSavedResult] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    setSelectedReasons([])
    setAttachments([])
    setOtherReason([])
    setDocumentSearch('')
    setAppointmentCode('')
    setEmailSearch('')
    setEmailSearched(false)
    onResetPatientSearch()
    setError(null)

    if (defaultPatient) {
      setSearched(true)
      setSelectedPatient(defaultPatient)
    } else {
      setSearched(false)
      setSelectedPatient(null)
    }
  }, [selectedSchedule])

  useEffect(() => {
    if (!uploadResponse) return
    const fileObj = {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      key: `private/${uploadResponse.key}`,
    }
    setAttachments(R.append(fileObj, attachments))
    onResetFileUpload()
  }, [uploadResponse])

  useEffect(() => {
    setError(
      patientError
        ? 'Se encontró el email registrado pero no pertenece a un paciente'
        : null
    )
  }, [patientError])

  const search = () => {
    if (!documentSearch) return
    setError(null)
    setEmailSearch('')
    setSearched(false)
    setEmailSearched(false)
    setSavedResult(null)
    onPatientSearch({ document: documentSearch })
    setSearched(true)
  }

  const searchEmail = () => {
    if (!emailSearch) return
    setError(null)
    onPatientSearch({
      document: documentSearch,
      email: emailSearch,
      idDeal: selectedDeal,
    })
    setEmailSearched(true)
  }

  useEffect(() => {
    setSelectedDeal(null)
    if (!patientSearch?.length && !savedResult) {
      setSelectedPatient(null)
      return
    }
    const syncedPatient = R.find((p) => p.id, patientSearch || [])
    if (
      patientSearch &&
      !R.any((p) => R.has('docNumberMatch', p), patientSearch || [])
    ) {
      setSavedResult(patientSearch)
    }
    const matchedPatientDeals = R.filter(
      (patient) => R.includes(patient.deal, selectedProvider?.deals || []),
      patientSearch || savedResult || []
    )
    if (
      matchedPatientDeals.length > 0 ||
      company.canGiveAppointmentOutsideRegistry
    ) {
      setSelectedPatient(syncedPatient)
    } else if (syncedPatient)
      console.error('Error 543: Patient found but no permission')
    if (matchedPatientDeals.length == 1)
      setSelectedDeal(R.head(matchedPatientDeals).deal)
  }, [patientSearch])

  const handleUpload = () => {
    const file = fileInputRef.current.files[0]
    onRequestUpload({
      file,
      privateId: selectedPatient.user,
      folder: 'attachment',
    })
  }

  useEffect(() => {
    if (!appointmentSuccess || !isOpen) return
    onCreateAppointmentReset()
    onClose({ refresh: true })
    setSnackbarOpen(true)
  }, [appointmentSuccess])

  useEffect(() => {
    if (!reasons) onRetrieveAppointmentReasons()
    if (!deals) onGetDeals()
  }, [])

  const matchedDeals = R.uniq(
    R.filter(
      (patient) => R.includes(patient.deal, selectedProvider?.deals || []),
      selectedPatient && selectedPatient.deal
        ? R.append(selectedPatient, savedResult || patientSearch)
        : savedResult || patientSearch || []
    ).map((d) => d.deal)
  )

  const submit = () => {
    const actualPatient =
      selectedDeal &&
      R.find(R.propEq('deal', selectedDeal), patientSearch || [])
    onCreateAppointment({
      shouldLink:
        !!selectedDeal &&
        R.equals(actualPatient?.deal, selectedDeal) &&
        !actualPatient.id,
      patientDocNumber: documentSearch,
      deal: selectedDeal || R.head(matchedDeals) || null,
      patient: actualPatient?.id || selectedPatient.id,
      provider: selectedProvider.id,
      reason: selectedReasons,
      specialty: selectedSpecialty.id,
      timestamp: selectedSchedule.timestamp,
      reasonOther: otherReason,
      attachments,
      appointmentCode,
      timezone: selectedTimezone,
    })
  }

  const PatientResult = () => {
    if (error || !searched || patientLoading) return null

    if (R.any((p) => R.has('profileIncomplete', p), patientSearch || [])) {
      return (
        <Alert severity='error'>
          <AlertTitle>Error</AlertTitle>El paciente {emailSearch} no tiene el
          perfil completo en la app.
          <br />
          Por favor completar el perfil y vuelva a intentar.
        </Alert>
      )
    }

    if (!emailSearched && selectedPatient)
      return (
        <Alert severity='success'>
          <AlertTitle>
            Paciente encontrado:{' '}
            {matchedDeals.length == 0
              ? 'El turno se dará de forma particular'
              : ''}
          </AlertTitle>
          {selectedPatient.name} {selectedPatient.lastName}
        </Alert>
      )

    if (selectedPatient)
      return (
        <>
          <Alert severity='success'>
            <AlertTitle>
              Paciente encontrado:{' '}
              {matchedDeals.length == 0
                ? 'El turno se dará de forma particular'
                : ''}
            </AlertTitle>
            {selectedPatient.name} {selectedPatient.lastName} ({emailSearch})
          </Alert>
          {selectedDeal && selectedPatient.docNumberMatch && (
            <Box className={classes.inputBox}>
              <Alert severity='error'>
                Indicar al paciente que debe actualizar su perfil seleccionando
                el convenio correspondiente
              </Alert>
            </Box>
          )}
        </>
      )

    return null
  }

  return (
    <>
      <Dialog open={isOpen} onClose={() => onClose({ refresh: false })}>
        {selectedSchedule && (
          <>
            <DialogTitle id='alert-dialog-title'>
              Nuevo Turno:{' '}
              {dayjs(selectedSchedule.timestamp * 1000)
                .tz(selectedTimezone)
                .format('DD/MM/YYYY HH:mm[hs]')}
            </DialogTitle>
            <DialogContent style={{ minWidth: '900px' }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  {company.canSetAppointmentCode && (
                    <Box className={classes.inputBox}>
                      <TextField
                        fullWidth
                        onChange={(e) => setAppointmentCode(e.target.value)}
                        value={appointmentCode}
                        label='Número de referencia'
                      />
                    </Box>
                  )}
                  <Box className={classes.inputBox}>
                    <TextField
                      disabled
                      fullWidth
                      onChange={R.T}
                      value={`${selectedProvider?.title} ${selectedProvider?.name} ${selectedProvider?.lastName}`}
                      label='Profesional'
                    />
                  </Box>
                  <Box className={classes.inputBox}>
                    <TextField
                      disabled
                      fullWidth
                      onChange={R.T}
                      value={selectedSpecialty.name}
                      label='Especialidad'
                    />
                  </Box>
                  <Box className={classes.inputBox}>
                    {!defaultPatient && (
                      <TextField
                        name='patientSearch'
                        disabled={patientLoading}
                        fullWidth
                        label='Búsqueda de Paciente'
                        helperText='Ingrese Número de Documento y haga click sobre la lupa (o presione Enter)'
                        onChange={(e) => {
                          setDocumentSearch(e.target.value)
                          setEmailSearch('')
                          setSearched(false)
                          setEmailSearched(false)
                          setSavedResult(null)
                          setError(null)
                        }}
                        onKeyUp={(e) => {
                          if (e.key === 'Enter' || e.keyCode === 13) search()
                        }}
                        InputProps={{
                          endAdornment: (
                            <React.Fragment>
                              {patientLoading ? (
                                <CircularProgress size={20} />
                              ) : (
                                <IconButton color='primary' onClick={search}>
                                  <Search />
                                </IconButton>
                              )}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    <PatientResult />
                    {matchedDeals.length > 1 ? (
                      <Box className={classes.inputBox}>
                        <TextField
                          select
                          label='Seleccione un convenio'
                          name='selectedDeal'
                          fullWidth
                          defaultValue=''
                          onChange={(e) => setSelectedDeal(e.target.value)}
                        >
                          {R.filter(
                            (d) => R.includes(d.id, matchedDeals),
                            deals || []
                          ).map((opt) => (
                            <MenuItem key={opt.id} value={opt.id}>
                              {opt.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                    ) : (
                      ''
                    )}
                    {searched &&
                    !patientLoading &&
                    !selectedPatient &&
                    !R.any(
                      (p) => R.has('profileIncomplete', p),
                      patientSearch || []
                    ) ? (
                      <>
                        <Alert severity='warning'>
                          {patientSearch ? (
                            <>
                              El afiliado aún no se encuentra asociado en
                              TeleMeditar. Por favor ingrese el email del
                              usuario
                            </>
                          ) : (
                            <>
                              <AlertTitle>Paciente no encontrado</AlertTitle>
                              Por favor, proceda a buscarlo por correo
                              electrónico
                            </>
                          )}
                        </Alert>
                        <Box className={classes.marginTop}>
                          <TextField
                            name='patientSearch'
                            disabled={
                              patientLoading ||
                              (matchedDeals.length > 1 && !selectedDeal)
                            }
                            fullWidth
                            label='Búsqueda de Paciente por Email'
                            helperText='Ingrese el correo electrónico y haga click sobre la lupa (o presione Enter)'
                            onChange={(e) => {
                              setEmailSearch(e.target.value)
                              setEmailSearched(false)
                              setError(null)
                            }}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter' || e.keyCode === 13)
                                searchEmail()
                            }}
                            value={emailSearch}
                            InputProps={{
                              endAdornment: (
                                <React.Fragment>
                                  {patientLoading ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    <IconButton
                                      color='primary'
                                      onClick={searchEmail}
                                    >
                                      <Search />
                                    </IconButton>
                                  )}
                                </React.Fragment>
                              ),
                            }}
                          />
                        </Box>
                      </>
                    ) : (
                      ''
                    )}
                    {!error &&
                    searched &&
                    emailSearched &&
                    !patientLoading &&
                    !selectedPatient &&
                    !R.any(
                      (p) => R.has('profileIncomplete', p),
                      patientSearch || []
                    ) ? (
                      <Alert
                        severity={
                          R.any(
                            R.propEq('docNumberMatch', false),
                            patientSearch || []
                          )
                            ? 'warning'
                            : 'error'
                        }
                        action={
                          (company.canCreatePatient &&
                            company.canGiveAppointmentOutsideRegistry) ||
                          (company.canCreatePatient &&
                            !company.canGiveAppointmentOutsideRegistry &&
                            matchedDeals.length) ? (
                            <Button
                              variant='contained'
                              color='primary'
                              onClick={() => {
                                setSavedResult(null)
                                onClose({
                                  newPatient: {
                                    schedule: selectedSchedule,
                                    ...R.last(
                                      R.sort(
                                        (a, b) =>
                                          R.filter((v) => v, R.valuesIn(a))
                                            .length -
                                          R.filter((v) => v, R.valuesIn(b))
                                            .length,
                                        savedResult || []
                                      )
                                    ),
                                    documentNumber: documentSearch,
                                    email: R.any(
                                      R.propEq('docNumberMatch', false),
                                      patientSearch || []
                                    )
                                      ? null
                                      : emailSearch,
                                    deal:
                                      selectedDeal ||
                                      R.head(matchedDeals) ||
                                      null,
                                  },
                                })
                              }}
                            >
                              Crear Paciente
                            </Button>
                          ) : null
                        }
                      >
                        {R.any(
                          R.propEq('docNumberMatch', false),
                          patientSearch || []
                        ) ? (
                          <>
                            Es necesario que el afiliado actualice el grupo
                            familiar en la aplicación o se deberá crear un nuevo
                            paciente
                          </>
                        ) : (
                          <>
                            <AlertTitle>Paciente no encontrado</AlertTitle>
                            {(company.canGiveAppointmentOutsideRegistry &&
                              company.canCreatePatient) ||
                            (company.canCreatePatient &&
                              !company.canGiveAppointmentOutsideRegistry &&
                              matchedDeals.length)
                              ? '¿Quiere crear un nuevo paciente?'
                              : 'No se encontró el correo electrónico.'}
                          </>
                        )}
                      </Alert>
                    ) : (
                      ''
                    )}
                    {error && (
                      <Box className={classes.inputBox}>
                        <Alert severity='error'>
                          <AlertTitle>Error</AlertTitle>
                          {error}
                        </Alert>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className={classes.inputBox}>
                    Seleccione los motivos de consulta:
                    {appointmentReasons.map((reason) => (
                      <Box key={reason.id}>
                        <FormControlLabel
                          label={reason.name}
                          control={
                            <Checkbox
                              checked={R.includes(reason.id, selectedReasons)}
                              onChange={(e) =>
                                e.target.checked
                                  ? setSelectedReasons(
                                      R.append(reason.id, selectedReasons)
                                    )
                                  : setSelectedReasons(
                                      R.without([reason.id], selectedReasons)
                                    )
                              }
                              name='selectedAppointmentReasons'
                            />
                          }
                        />
                      </Box>
                    ))}
                  </Box>
                  <Box className={classes.inputBox}>
                    <TextField
                      label='Otro Motivo de consulta'
                      multiline
                      rows={5}
                      cols={10}
                      onChange={(e) => setOtherReason(e.target.value)}
                      value={otherReason}
                      variant='outlined'
                      fullWidth
                    />
                  </Box>
                  <Box className={classes.inputBox}>
                    <Typography variant='body2'>
                      Archivos Adjuntos: {attachments.length}
                    </Typography>
                    <LoadingButton
                      variant='outlined'
                      color='primary'
                      disabled={!patientSearch && !selectedPatient}
                      loading={fileLoading}
                      onClick={() => fileInputRef.current.click()}
                    >
                      <AttachFile /> Adjuntar Archivo
                    </LoadingButton>
                    <Box className={classes.fileInput}>
                      <TextField
                        name='file'
                        type='file'
                        onChange={handleUpload}
                        inputProps={{ ref: fileInputRef }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                onClick={() => onClose({ refresh: false })}
              >
                Volver
              </Button>
              <LoadingButton
                color='primary'
                variant='contained'
                disabled={
                  !selectedReasons.length ||
                  !selectedPatient ||
                  R.any(
                    (p) => R.has('profileIncomplete', p),
                    patientSearch || []
                  ) ||
                  !selectedPatient.id ||
                  (matchedDeals.length > 1 && !selectedDeal) ||
                  (selectedDeal && selectedPatient.docNumberMatch) ||
                  fileLoading ||
                  loading
                }
                onClick={submit}
                loading={loading}
              >
                Crear Turno
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='success'>
          TURNO CREADO EXITOSAMENTE
        </Alert>
      </Snackbar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getAppointmentsLoading(state) || getSchedulesLoading(state),
    appointments: getAppointments(state),
    reasons: getReasons(state),
    fileLoading: getFileLoading(state),
    downloadFile: getDownloadFile(state),
    specialties: getSpecialties(state),
    providers: getProviders(state),
    schedules: getSchedules(state),
    patientSearch: getPatientSearch(state),
    patientLoading: getPatientLoading(state),
    appointmentReasons: getReasons(state),
    files: getFiles(state),
    uploadResponse: getUploadResponse(state),
    appointmentSuccess: getSuccess(state),
    deals: getDeals(state),
    patientError: getPatientError(state),
    company: getCompany(state),
  }
}

export default connect(mapStateToProps, {
  onRetrieveSpecialties: requestAssistantSpecialtiesGet,
  onRetrieveAppointments: requestAppointments,
  onRetrieveAppointmentReasons: requestAppointmentReasons,
  onFileDownload: fileDownloadGetRequest,
  onRetrieveSchedules: providerScheduleGetRequest,
  onPatientSearch: patientSearchRequest,
  onRequestUpload: fileUploadRequest,
  onResetFileUpload: fileUploadReset,
  onRequestFile: fileGetRequest,
  onCreateAppointment: createAppointment,
  onCreateAppointmentReset: resetCreateAppointment,
  onResetPatientSearch: resetPatientSearch,
  onGetDeals: dealsGetRequest,
})(NewAppointmentModal)
