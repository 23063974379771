import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
// import { getUserPool, getSession } from 'common/utils/awsConfig'
import { getCurrentProvider } from 'common/reducers/providers'
import { getCoverages } from 'common/reducers/coverages'
import { getFiles } from 'common/reducers/files'
import {
  providerCurrentGetRequest,
  coveragesGetRequest,
  fileGetRequest,
} from 'common/actions'

import { companyGetRequest } from 'common/actions'
import getCurrentUser from 'common/utils/getCurrentUser'
import { getCompany } from 'common/reducers/companies'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
// Or the wizard if you haven't completed it.
const PrivateRoute = ({
  children,
  requiresWizard,
  retrieveProvider,
  currentProvider,
  coverages,
  requestCoverages,
  retrieveFile,
  files,
  company,
  retrieveCompany,
  ...rest
}) => {
  const [isAuth, setIsAuth] = useState(true)
  const location = useLocation()

  useEffect(() => {
    if (!coverages?.length) requestCoverages()

    if (
      currentProvider &&
      requiresWizard &&
      !currentProvider.wizardComplete
    ) {
      console.log('should redirect to wizard')
      window.location = `${window.location.origin}/providers/wizard`
    }

    if (
      !currentProvider?.profilePicture?.key ||
      files[currentProvider?.profilePicture.key]
    )
      return
    retrieveFile(currentProvider.profilePicture)
  }, [currentProvider, children])

  useEffect(() => {
    const getUser = async () => {
      const user = await getCurrentUser()
      if (!user || !R.includes('provider', user.groups)) {
        setIsAuth(false)
        return
      }
      if (!currentProvider) retrieveProvider(user['custom:idProvider'])
    }
    getUser()
  }, [location])

  useEffect(() => {
    if (company) return
    retrieveCompany()
  }, [company])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth
          ? React.cloneElement(children, { provider: currentProvider, company })
          : (window.location = `${window.location.origin}/login`)
      }
    />
  )
}
PrivateRoute.defaultProps = {
  requiresWizard: true,
}

const mapStateToProps = (state) => ({
  currentProvider: getCurrentProvider(state),
  coverages: getCoverages(state),
  files: getFiles(state),
  company: getCompany(state),
})

export default connect(mapStateToProps, {
  retrieveProvider: providerCurrentGetRequest,
  requestCoverages: coveragesGetRequest,
  retrieveFile: fileGetRequest,
  retrieveCompany: companyGetRequest,
})(PrivateRoute)
