import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import * as R from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import clsx from 'clsx'
import {
  Box,
  Grid,
  Link,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  Typography,
  Paper,
  Avatar,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'common/theme'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'

import getProvinceLabel from 'common/utils/getProvinceLabel'

import { getFiles } from 'common/reducers/files'

import { Header } from '../layout'

import {
  providerGetRequest,
  provincesGetRequest,
  districtsGetRequest,
  fileGetRequest,
  requestSpecialtiesGet,
  providerValidateRequest,
  providerEditRequest,
} from 'common/actions'
import { getLoading, getProviders, getSuccess } from 'common/reducers/providers'
import {
  getLoading as getLocationLoading,
  getCountries,
  getProvinces,
  getDistricts,
} from 'common/reducers/locations'
import { getSpecialties } from 'common/reducers/specialties'
import getCurrency from 'common/utils/getCurrency'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  fullWidth: {
    width: '100%',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatar: {
    width: '10rem',
    height: '10rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#ccc',
  },
  label: {
    fontWeight: '600',
    fontSize: '12px',
    textTransform: 'uppercase',
  },
  value: {
    display: 'block',
  },
  grayDivider: {
    borderBottom: '1px solid #ccc',
  },
  licensesContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  changeLink: {
    fontSize: '14px',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  paper: {
    padding: theme.spacing(3),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  validateBtn: {
    marginRight: theme.spacing(2),
  },
  oddRow: {
    backgroundColor: '#fafafa',
  },
  evenRow: {
    backgroundColor: '#fff',
  },
}))

const View = ({
  loading,
  providers,
  getProvider,
  locationLoading,
  provinces,
  requestProvinces,
  specialties,
  onRequestSpecialties,
  onRequestFile,
  countries,
  districts,
  requestDistricts,
  files,
  onValidateProvider,
  success,
  onUpdateProvider,
  loggedUserCompany,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [provider, setProvider] = useState(null)
  const [validationModal, setValidationModal] = useState(false)
  const [allowPrivateConsultationModal, setAllowPrivateConsultationModal] =
    useState(false)
  const [disallowingPrivateConsultation, setDisallowingPrivateConsultation] =
    useState(false)

  const { id } = useParams()

  useEffect(() => {
    getProvider({ id })
    onRequestSpecialties('all')
  }, [])

  useEffect(() => {
    if (!providers || !providers.length) return
    setProvider(providers[0])
    if (
      providers[0].profilePicture?.key &&
      !files[providers[0].profilePicture.key]
    )
      onRequestFile(providers[0].profilePicture)
    if (providers[0].country)
      requestProvinces(providers[0].country.id || providers[0].country)
    if (providers[0].province)
      requestDistricts({
        province: providers[0].province.id || providers[0].province,
        country: providers[0].country.id || providers[0].country,
      })
  }, [providers])

  const validateProvider = () => {
    onValidateProvider(provider.id)
    setValidationModal(false)
  }

  useEffect(() => {
    if (!success) return
    if (disallowingPrivateConsultation) {
      setProvider({ ...provider, allowPrivateConsultation: false })
      setDisallowingPrivateConsultation(false)
    } else history.push('/providers')
  }, [success])

  const disallowPrivateConsultation = () => {
    onUpdateProvider({
      ...provider,
      country: provider.country.id,
      province: provider.province.id,
      district: provider.district.id,
      allowPrivateConsultation: false,
    })
    setAllowPrivateConsultationModal(false)
    setDisallowingPrivateConsultation(true)
  }

  const renderProp = (label, value) => (
    <Box>
      <Typography className={classes.label}>{label}</Typography>
      {typeof value === 'string' ? (
        <Typography variant='body2' className={classes.value}>
          {value}
        </Typography>
      ) : (
        <>{value}</>
      )}
    </Box>
  )

  return (
    <>
      <Header />
      <Container className={classes.container}>
        <Paper className={classes.paper}>
          {loading || !provider ? (
            <CircularProgress />
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Box textAlign='center'>
                    <Avatar
                      src={files[provider.profilePicture?.key]?.Image}
                      className={classes.avatar}
                    />
                  </Box>
                  <Box textAlign='center' className={classes.marginTop}>
                    <Typography variant='h4'>
                      {provider.title} {provider.name} {provider.lastName}{' '}
                      {provider.enabled ? (
                        <VerifiedUserIcon
                          title='Profesional validado'
                          color='secondary'
                        />
                      ) : (
                        ''
                      )}
                    </Typography>
                    <Typography variant='body1'>{provider.tagLine}</Typography>
                    {provider.email && (
                      <Typography variant='body2'>{provider.email}</Typography>
                    )}
                  </Box>
                </Grid>
                <Grid container item xs={12} md={8}>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className={classes.grayDivider}
                    alignItems='center'
                  >
                    <Grid item xs>
                      {renderProp('Matrícula', provider.license || '-')}
                    </Grid>
                    <Grid item xs>
                      {renderProp(
                        'Experiencia desde',
                        provider.experienceSince
                          ? dayjs(provider.experienceSince).format('DD-MM-YYYY')
                          : '-'
                      )}
                    </Grid>
                    <Grid item xs>
                      {renderProp(
                        '¿Atiende Particular?',
                        provider.allowPrivateConsultation ? (
                          <Box display='flex' alignItems='center'>
                            <Box>Si </Box>
                            <Link
                              className={classes.changeLink}
                              color='primary'
                              onClick={() =>
                                setAllowPrivateConsultationModal(true)
                              }
                            >
                              CAMBIAR
                            </Link>
                          </Box>
                        ) : (
                          'No'
                        )
                      )}
                    </Grid>
                    {typeof provider.consultationPrice !== 'undefined' &&
                      provider.allowPrivateConsultation && (
                        <Grid item xs>
                          {renderProp(
                            'Precio de Consulta',
                            `${getCurrency(
                              loggedUserCompany?.country?.shortName
                            )}${provider.consultationPrice}`
                          )}
                        </Grid>
                      )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className={clsx(classes.grayDivider, classes.marginTop)}
                    alignItems='center'
                  >
                    <Grid item xs>
                      {renderProp(
                        'Fecha de Nacimiento',
                        dayjs(provider.birthDate).format('DD-MM-YYYY')
                      )}
                    </Grid>
                    <Grid item xs>
                      {renderProp(
                        'Género',
                        R.equals(provider.gender, 'MALE')
                          ? 'Masculino'
                          : 'Femenino'
                      )}
                    </Grid>
                    <Grid item xs>
                      {provider.phone && renderProp('Teléfono', provider.phone)}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={2}
                    className={clsx(classes.marginTop)}
                    alignItems='center'
                  >
                    <Grid item xs>
                      {countries?.length &&
                        renderProp(
                          'País',
                          provider.country?.name ||
                            R.find(R.propEq('id', provider.country), countries)
                              .name
                        )}
                    </Grid>
                    <Grid item xs>
                      {provinces?.length &&
                        renderProp(
                          getProvinceLabel(
                            loggedUserCompany?.country?.shortName
                          ),
                          provider.province?.name ||
                            R.find(R.propEq('id', provider.province), provinces)
                              .name
                        )}
                    </Grid>
                    <Grid item xs>
                      {districts?.length &&
                        renderProp(
                          'Localidad',
                          provider.district?.name ||
                            R.find(R.propEq('id', provider.district), districts)
                              .name
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {specialties?.length &&
                provider.validationInformation?.licenseInformation && (
                  <Box className={classes.licensesContainer}>
                    <Grid container>
                      {provider.validationInformation.licenseInformation.map(
                        (l, i) => (
                          <Grid
                            item
                            xs={12}
                            container
                            key={`${l.specialty}_${i}`}
                            spacing={2}
                            className={clsx(
                              i > 0 ? classes.marginTop : null,
                              i % 2 == 0 ? classes.oddRow : classes.evenRow
                            )}
                          >
                            <Grid item xs={2}>
                              {renderProp(
                                'Especialidad',
                                R.find(R.propEq('id', l.specialty), specialties)
                                  ?.name
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              {renderProp(
                                getProvinceLabel(
                                  loggedUserCompany?.country?.shortName
                                ),
                                R.find(R.propEq('id', l.province), provinces)
                                  ?.name
                              )}
                            </Grid>
                            <Grid item xs={3}>
                              {renderProp('Entidad', l.entity)}
                            </Grid>
                            <Grid item xs={1} align='center'>
                              {renderProp(
                                'Fecha',
                                dayjs(l.date).format('DD/MM/YYYY')
                              )}
                            </Grid>
                            <Grid item xs={2} align='center'>
                              {renderProp('Matrícula', l.license)}
                            </Grid>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                )}

              <Box
                className={clsx(classes.buttonGroup, classes.marginTop)}
                textAlign='center'
              >
                {!provider.enabled && (
                  <Button
                    disabled={!provider.wizardComplete}
                    color='primary'
                    variant='contained'
                    onClick={() => setValidationModal(true)}
                    className={classes.validateBtn}
                  >
                    VALIDAR PROFESIONAL
                  </Button>
                )}
                <Button
                  onClick={() => history.push('/providers')}
                  color='primary'
                  variant='outlined'
                >
                  VOLVER
                </Button>
              </Box>
              {!provider.wizardComplete && (
                <Box className={classes.marginTop} textAlign='center'>
                  <Typography
                    style={theme.danger}
                    className={classes.marginTop}
                  >
                    El profesional todavía no completó la registración.
                  </Typography>
                </Box>
              )}
            </>
          )}
          <Dialog
            open={validationModal}
            onClose={() => {
              setValidationModal(false)
            }}
          >
            {provider && (
              <>
                <DialogTitle id='alert-dialog-title'>
                  Validar profesional
                </DialogTitle>
                <DialogContent>
                  <DialogContentText color='primary' fontWeight='600'>
                    ¿Está seguro que desea validar a {provider.title}{' '}
                    {provider.name} {provider.lastName}?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color='primary'
                    onClick={() => {
                      setValidationModal(false)
                    }}
                  >
                    CANCELAR
                  </Button>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => validateProvider()}
                  >
                    VALIDAR
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
          <Dialog
            open={allowPrivateConsultationModal}
            onClose={() => setAllowPrivateConsultationModal(false)}
          >
            {provider && (
              <>
                <DialogTitle id='alert-dialog-title'>
                  Deshabilitar Consulta Privada
                </DialogTitle>
                <DialogContent>
                  <DialogContentText color='primary' fontWeight='600'>
                    ¿Está seguro que desea deshabilitarle la consulta privada al
                    profesional {provider.title} {provider.name}{' '}
                    {provider.lastName}?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      setAllowPrivateConsultationModal(false)
                    }}
                  >
                    CANCELAR
                  </Button>
                  <Button
                    onClick={() => disallowPrivateConsultation()}
                    style={theme.danger}
                  >
                    SI, DESHABILITAR
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        </Paper>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: getLoading(state),
    providers: getProviders(state),
    success: getSuccess(state),
    locationLoading: getLocationLoading(state),
    countries: getCountries(state),
    provinces: getProvinces(state),
    districts: getDistricts(state),
    specialties: getSpecialties(state),
    files: getFiles(state),
  }
}

export default connect(mapStateToProps, {
  getProvider: providerGetRequest,
  requestProvinces: provincesGetRequest,
  requestDistricts: districtsGetRequest,
  onRequestSpecialties: requestSpecialtiesGet,
  onRequestFile: fileGetRequest,
  onValidateProvider: providerValidateRequest,
  onUpdateProvider: providerEditRequest,
})(View)
