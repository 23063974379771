import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import dayjs from 'dayjs'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SelectField } from 'common/components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { isDateValid } from 'common/utils/datetime'
import getProvinceLabel from 'common/utils/getProvinceLabel'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
  },
  selectBox: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  specialtyCard: {
    marginBottom: theme.spacing(2),
  },
  specialtyName: {
    marginBottom: theme.spacing(2),
  },
  backBtn: {
    marginRight: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
}))

const muiDateFormat = 'YYYY-MM-DD'

const SpecialtyLicenseModal = ({
  isOpen,
  onSubmit,
  onClose,
  provinces,
  selectedSpecialty,
  company,
  loggedUserCompany,
}) => {
  const classes = useStyles()

  const schema = yup.object().shape({
    date: yup
      .string()
      .required('Este campo es requerido')
      .test(
        'date-format',
        'La fecha no es válida. El formato es DD/MM/YYYY',
        isDateValid
      ),
    province: yup.object().required('Este campo es requerido').nullable(),
    entity: yup.string().required('Este campo es requerido'),
    license: yup.string().required('Este campo es requerido'),
  })

  const {
    control,
    errors,
    handleSubmit,
    triggerValidation,
    setValue,
    register,
    watch,
  } = useForm({
    defaultValues: {
      province: null,
      entity: '',
      license: '',
      date: '',
    },
    resolver: yupResolver(schema),
  })

  const watchProvince = watch('province')

  const submit = (data) => {
    onSubmit({ ...data, date: dayjs(data.date).format(muiDateFormat) })
  }

  useEffect(() => {
    register('province')
  }, [register])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <form noValidate onSubmit={handleSubmit(submit)}>
        <DialogTitle id='alert-dialog-title'>
          Nueva matrícula para: {selectedSpecialty && selectedSpecialty.name}
        </DialogTitle>
        <DialogContent>
          <Box className={classes.inputBox}>
            {provinces && (
              <Autocomplete
                id='province'
                options={provinces}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => {
                  setValue('province', v, true)
                }}
                renderInput={(params) => (
                  <TextField
                    name='province'
                    {...params}
                    error={!!errors.province}
                    helperText={errors.province && errors.province.message}
                    label={getProvinceLabel(
                      (company || loggedUserCompany)?.country?.shortName
                    )}
                    fullWidth
                  />
                )}
              />
            )}
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='entity'
              error={!!errors.entity}
              helperText={errors.entity && errors.entity.message}
              label='Colegio/Entidad'
              onBlur={() => triggerValidation('entity')}
              fullWidth
              as={<TextField />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='license'
              error={!!errors.license}
              helperText={errors.license && errors.license.message}
              label='Matrícula'
              onBlur={() => triggerValidation('license')}
              fullWidth
              as={<TextField />}
            />
          </Box>
          <Box className={classes.inputBox}>
            <Controller
              control={control}
              name='date'
              defaultValue=''
              error={!!errors.date}
              helperText={errors.date && errors.date.message}
              label='Fecha de Otorgamiento'
              onBlur={() => triggerValidation('date')}
              fullWidth
              InputLabelProps={{ shrink: true }}
              as={<TextField type='date' />}
            />
          </Box>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button color='primary' onClick={onClose}>
            Cancelar
          </Button>
          <Button type='submit' color='primary' variant='contained'>
            Agregar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {})(SpecialtyLicenseModal)
