import * as ActionTypes from '../actions'
import * as R from 'ramda'

const initialState = {
  appointments: {},
  error: null,
  loading: false,
  reasons: null,
  success: false,
}

export function appointmentReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APPOINTMENT_REASON_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        reasons: null,
      }
    case ActionTypes.APPOINTMENT_UPCOMING_REQUEST:
    case ActionTypes.APPOINTMENT_GET_REQUEST:
    case ActionTypes.APPOINTMENT_CANCEL_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case ActionTypes.APPOINTMENT_JOIN_REQUEST:
    case ActionTypes.APPOINTMENT_END_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case ActionTypes.APPOINTMENT_CREATE_RESET:
    case ActionTypes.APPOINTMENT_CANCEL_RESET:
      return {
        ...state,
        error: null,
        loading: false,
        success: false,
      }
    case ActionTypes.APPOINTMENT_PRESCRIPTION_REQUEST:
    case ActionTypes.APPOINTMENT_WHATSAPP_PRESCRIPTION_REQUEST:
    case ActionTypes.APPOINTMENT_CREATE_REQUEST:
    case ActionTypes.APPOINTMENT_PRESCRIPTION_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      }
    case ActionTypes.APPOINTMENT_CREATE_SUCCESS:
    case ActionTypes.APPOINTMENT_WHATSAPP_PRESCRIPTION_SUCCESS:
    case ActionTypes.APPOINTMENT_CANCEL_SUCCESS:
    case ActionTypes.APPOINTMENT_PRESCRIPTION_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case ActionTypes.APPOINTMENT_REASON_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        reasons: action.data.items,
      }
    case ActionTypes.APPOINTMENT_PRESCRIPTION_SUCCESS:
      const updated = action.data[0]
      return {
        ...state,
        loading: false,
        success: true,
        appointments: {
          ...state.appointments,
          [updated.timestamp]: {
            ...state.appointments[updated.timestamp],
            prescription: updated.prescription,
          },
        },
      }
    case ActionTypes.APPOINTMENT_UPCOMING_SUCCESS:
    case ActionTypes.APPOINTMENT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: R.reduce(
          (acc, val) => ({ ...acc, [val.timestamp]: val }),
          {},
          action.data.items || [action.data]
        ),
      }
    case ActionTypes.APPOINTMENT_END_SUCCESS:
    case ActionTypes.APPOINTMENT_JOIN_SUCCESS:
      return {
        ...state,
        loading: false,
        appointments: {
          ...state.appointments,
          [action.data.timestamp]: action.data,
        },
      }
    case ActionTypes.APPOINTMENT_REASON_GET_FAILURE:
    case ActionTypes.APPOINTMENT_JOIN_FAILURE:
    case ActionTypes.APPOINTMENT_GET_FAILURE:
    case ActionTypes.APPOINTMENT_UPCOMING_FAILURE:
    case ActionTypes.APPOINTMENT_END_FAILURE:
    case ActionTypes.APPOINTMENT_CREATE_FAILURE:
    case ActionTypes.APPOINTMENT_CANCEL_FAILURE:
    case ActionTypes.APPOINTMENT_PRESCRIPTION_FAILURE:
    case ActionTypes.APPOINTMENT_WHATSAPP_PRESCRIPTION_FAILURE:
    case ActionTypes.APPOINTMENT_PRESCRIPTION_DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export function getReasons(state) {
  return state.appointmentReducer.reasons
}

export function getAppointments(state) {
  return state.appointmentReducer.appointments
}

export function getError(state) {
  return state.appointmentReducer.error
}

export function getLoading(state) {
  return state.appointmentReducer.loading
}

export function getSuccess(state) {
  return state.appointmentReducer.success
}
