import React, { useState, useEffect, useRef } from 'react'
import * as R from 'ramda'
import dayjs from 'dayjs'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Link,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  DialogContentText,
} from '@material-ui/core'
import VideoCall from '@material-ui/icons/VideoCall'
import PhoneDisabled from '@material-ui/icons/PhoneDisabled'
import SwapHoriz from '@material-ui/icons/SwapHoriz'

import { makeStyles } from '@material-ui/core/styles'
import {
  OTSession,
  OTPublisher,
  OTStreams,
  OTSubscriber,
  createSession,
} from 'opentok-react'
import PatientData from './patientData'
import { joineableCall } from 'common/utils/appointmentStatus'

import {
  getError,
  getLoading,
  getAppointments,
} from 'common/reducers/appointment'
import {
  getLoading as getPatientLoading,
  getPatient,
  getHealthRecords,
} from 'common/reducers/patients'
import {
  joinAppointment,
  requestAppointments,
  patientGetRequest,
  endAppointment,
} from 'common/actions'

const useStyles = makeStyles((theme) => ({
  cameraCol: {
    position: 'relative',
    backgroundColor: 'black',
    width: '100%',
    height: '100vh',
  },
  notStartedButtonsContainer: {
    position: 'absolute',
    top: '40%',
    textAlign: 'center',
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
  },
  backButtonContainer: {
    marginTop: theme.spacing(2),
  },
  patientDataCol: {
    maxHeight: '100vh',
    overflow: 'auto',
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(3),
    },
  },
  buttonsGroup: {
    right: 0,
    top: '40%',
    position: 'absolute',
    zIndex: 1,
    flexDirection: 'column',
    display: 'flex',
  },
  finishingCall: {
    top: '40%',
    left: '40%',
    position: 'absolute',
    zIndex: 2,
    textAlign: 'center',
    padding: theme.spacing(3),
    backgroundColor: 'white',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  toggleModeButton: {
    marginBottom: theme.spacing(3),
    height: 50,
  },
  endCallButton: {
    height: 50,
  },
  pipStyles: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid black',
  },
  timeLeft: {
    position: 'absolute',
    top: 5,
    zIndex: 1,
    right: 5,
    padding: theme.spacing(1),
    backgroundColor: (props) => (props.isWarning ? 'red' : 'white'),
    color: (props) => (props.isWarning ? 'white' : 'black'),
    borderRadius: '4px',
    opacity: 0.7,
    textAlign: 'center',
  },
}))

const Videocall = ({
  error,
  loading,
  appointments,
  onJoinAppointment,
  onRequestAppointment,
  onEndAppointment,
  provider,
  onRequestPatient,
  patient,
  patientLoading,
  healthRecords,
  company,
}) => {
  const { id } = useParams()
  const [isWarning, setIsWarning] = useState(false)
  const classes = useStyles({ isWarning })
  const [started, setStarted] = useState(false)
  const [finishing, setFinishing] = useState(false)
  const [bigCameraMode, setBigCameraMode] = useState(false)
  const [joinedCall, setJoinedCall] = useState(false)
  const [endAppointmentConfirmationModal, setEndAppointmentConfirmationModal] =
    useState(false)
  const [patientJoined, setPatientJoined] = useState(false)
  const [appointment, setAppointment] = useState(null)

  const publisherProperties = {
    showControls: false,
    publishVideo: true,
    publishAudio: true,
  }

  const confirmEndAppointment = () => {
    onEndAppointment(id)
    setFinishing(true)
    setEndAppointmentConfirmationModal(false)
  }

  useEffect(() => {
    setAppointment(appointments[id])
  }, [appointments])

  useEffect(() => {
    if (joinedCall || !appointment) return

    if (
      !patient ||
      !R.equals(patient.id, appointment.patient.id || appointment.patient)
    )
      onRequestPatient(appointment.patient.id || appointment.patient)

    if (joineableCall(appointment.status))
      onJoinAppointment({
        id,
        start: R.equals(appointment.status, 'PENDING'),
      })
    setJoinedCall(true)
  }, [appointment])

  useEffect(() => {
    if (!provider || appointments[id]) return
    onRequestAppointment({ timestamp: id, provider: provider.id })
  }, [provider])

  const hasHealthRecord = !!R.find(
    (hr) => hr.timestamp === appointment?.timestamp,
    healthRecords || []
  )

  const [timeLeft, setTimeLeft] = useState(null)

  useEffect(() => {
    if (!appointment) return

    const startDateTime = dayjs(appointment.timestamp * 1000).tz(
      provider.timezone
    )
    const endDateTime = dayjs(startDateTime)
      .add(appointment['length'], 'minutes')
      .tz(provider.timezone)
    const last30percent = dayjs(endDateTime).subtract(
      appointment['length'] * 0.3,
      'minutes'
    )

    const interval = setInterval(() => {
      const secondsLeft = endDateTime.diff(dayjs(), 'seconds')
      if (secondsLeft < 0) {
        setTimeLeft('00:00')
        clearInterval(interval)
        return
      }
      setIsWarning(dayjs().isAfter(last30percent))
      setTimeLeft(
        `${Math.floor(secondsLeft / 60)}:${(secondsLeft % 60)
          .toString()
          .padStart(2, '0')}`
      )
    }, 1000)
    return () => clearInterval(interval)
  }, [appointment])

  console.log('company', company)

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={bigCameraMode ? 8 : 4}
          className={classes.cameraCol}
        >
          {!started ? (
            <Box className={classes.notStartedButtonsContainer}>
              <Box textAlign='center'>
                {appointment?.status && !joineableCall(appointment.status) ? (
                  <Typography color='red'>
                    No puede unirse a una consulta completada.
                  </Typography>
                ) : (
                  <Button
                    onClick={() => {
                      setStarted(true)
                      setBigCameraMode(true)
                    }}
                    variant='contained'
                    color='primary'
                    disabled={!appointment || loading}
                  >
                    <VideoCall /> UNIRSE
                  </Button>
                )}
              </Box>
              <Box textAlign='center' className={classes.backButtonContainer}>
                <Button
                  as='a'
                  href='/providers/appointments'
                  variant='contained'
                  color='secondary'
                >
                  VOLVER
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Box className={classes.timeLeft}>
                <Typography variant='body2'>Tiempo Restante</Typography>
                <Typography variant='body1'>{timeLeft}</Typography>
              </Box>
              {finishing ? (
                <Box className={classes.finishingCall}>
                  Finalizando llamada...
                  <CircularProgress />
                </Box>
              ) : (
                <Box className={classes.buttonsGroup}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setBigCameraMode(!bigCameraMode)}
                    className={classes.toggleModeButton}
                  >
                    <SwapHoriz />
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() =>
                      R.includes(appointment.status, ['AWAITING_CUSTOMER']) &&
                      !patientJoined
                        ? setStarted(false)
                        : setEndAppointmentConfirmationModal(true)
                    }
                    className={classes.endCallButton}
                  >
                    <PhoneDisabled />
                  </Button>
                </Box>
              )}
              <OTSession
                apiKey={process.env.REACT_APP_TOKBOX_API_KEY}
                sessionId={appointment?.videoSessionId}
                token={appointment?.videoSessionToken}
                onError={(e) => console.log('session error', e)}
                eventHandlers={{
                  sessionConnected: () => console.log('Connected'),
                  sessionDisconnected: () => console.log('Disconnected'),
                  sessionReconnected: () => console.log('Reconnected'),
                  sessionReconnecting: () => console.log('Reconnecting'),
                  signal: ({ data }) =>
                    R.equals(data, 'SESSION_END') &&
                    setStarted(false) &&
                    setFinishing(false),
                }}
              >
                <OTPublisher
                  properties={publisherProperties}
                  onPublish={(e) => console.log('published', e)}
                  eventHandlers={{
                    accessDenied: () => {
                      console.log('User denied access to media source')
                    },
                    streamCreated: () => {
                      console.log('Publisher stream created')
                    },
                    streamDestroyed: ({ reason }) => {
                      console.log(
                        `Publisher stream destroyed because: ${reason}`
                      )
                    },
                  }}
                  className={classes.pipStyles}
                />
                <OTStreams>
                  <OTSubscriber
                    onSubscribe={() => {
                      console.log('subscribed')
                      setPatientJoined(true)
                    }}
                    onError={(e) => console.error(e)}
                    properties={{ width: 50, height: 50 }}
                    eventHandlers={{
                      videoEnabled: () => {
                        console.log('Subscriber video enabled')
                      },
                      videoDisabled: () => {
                        console.log('Subscriber video disabled')
                      },
                    }}
                  />
                </OTStreams>
              </OTSession>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={bigCameraMode ? 4 : 8}
          className={classes.patientDataCol}
        >
          {patientLoading || !patient || !appointment ? (
            <CircularProgress />
          ) : (
            <PatientData
              patient={patient}
              appointment={appointment}
              bigCameraMode={bigCameraMode}
            />
          )}
        </Grid>
      </Grid>
      <Dialog
        open={endAppointmentConfirmationModal}
        onClose={() => setEndAppointmentConfirmationModal(false)}
      >
        <DialogTitle id='alert-dialog-title'>Finalizar Consulta</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro que desea finalizar la consulta? No podrá volver a
            iniciarla una vez que sea finalizada.
          </DialogContentText>
          {company?.cantUploadPrescriptionInFinishedAppointment && (
            <DialogContentText>
              Si continúa, no podrá adjuntar recetas. Por favor adjunte la o las
              recetas necesarias y luego finalice la llamada.
            </DialogContentText>
          )}
          {!hasHealthRecord && (
            <DialogContentText color='error'>
              Para finalizar la llamada debe completar los datos de historia
              clinica.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            onClick={() => setEndAppointmentConfirmationModal(false)}
          >
            Volver
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={!hasHealthRecord}
            onClick={() => confirmEndAppointment()}
          >
            Finalizar Consulta
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getLoading(state),
    appointments: getAppointments(state),
    patientLoading: getPatientLoading(state),
    patient: getPatient(state),
    healthRecords: getHealthRecords(state),
  }
}

export default connect(mapStateToProps, {
  onJoinAppointment: joinAppointment,
  onRequestAppointment: requestAppointments,
  onRequestPatient: patientGetRequest,
  onEndAppointment: endAppointment,
})(Videocall)
