const downloadFile = (file) => {
  const a = document.createElement('a')
  a.href = file.url
  a.download = file.Key.replace('private/', '')
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(file.url)
      a.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
}

export default downloadFile
