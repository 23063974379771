import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { QueryParamProvider } from 'use-query-params'
import Admin from './admin'
import Providers from './providers'
import Assistants from './assistants'
import {
  Login,
  ForgotPassword,
  Registration,
  Logout,
} from 'common/components/partials'
import { countriesGetRequest } from 'common/actions'

const customHistory = createBrowserHistory()

const SentryRoute = Sentry.withSentryRouting(Route)

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation:
        Sentry.reactRouterV5Instrumentation(customHistory),
    }),
  ],
  environment: process.env.REACT_APP_ENV,
  SENTRY_RELEASE: process.env.REACT_APP_RELEASE_VERSION,
  tracesSampleRate: 1.0,
})

const Router = ({ requestCountries }) => {
  useEffect(() => {
    requestCountries()
  }, [])

  return (
    <BrowserRouter history={customHistory}>
      <QueryParamProvider ReactRouterRoute={SentryRoute}>
        <Switch>
          <SentryRoute path='/admin'>
            <Admin />
          </SentryRoute>
          <SentryRoute path='/providers'>
            <Providers />
          </SentryRoute>
          <SentryRoute path='/assistants'>
            <Assistants />
          </SentryRoute>
          <SentryRoute path='/login'>
            <Login />
          </SentryRoute>
          <SentryRoute path='/logout'>
            <Logout />
          </SentryRoute>
          <SentryRoute path='/registration'>
            <Registration />
          </SentryRoute>
          <SentryRoute path='/forgotpassword'>
            <ForgotPassword />
          </SentryRoute>
          <SentryRoute path='/'>
            <Login />
          </SentryRoute>
        </Switch>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {
  requestCountries: countriesGetRequest,
})(Router)
