import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'moment/locale/es'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/es'

import Amplify, { Storage } from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from 'common/theme'

import rootSaga from 'common/sagas'
import reducer from 'common/reducers'

import Router from './Router'
import * as serviceWorker from './serviceWorker'
import 'fontsource-roboto'
import 'index.css'
import { ErrorHappenedModal } from 'common/components/partials'

import awsAmplifyConfig from './aws-amplify-config'
import firebaseConfig from './firebase-config'

moment.locale('es')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
dayjs.locale('es')

const sagaMiddleware = createSagaMiddleware()
const store = createStore(reducer, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(rootSaga)

Amplify.configure(awsAmplifyConfig)
const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)

Storage.configure({
  customPrefix: {
    public: 'provider/',
  },
})

Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: process.env.REACT_APP_AWS_REGION,
    aws_pubsub_endpoint: process.env.REACT_APP_AWS_PUBSUB_ENDPOINT,
  })
)

const render = () => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale='es'
        libInstance={moment}
      >
        <Provider store={store}>
          <CssBaseline />
          <ErrorHappenedModal />
          <Router />
        </Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>,
    document.getElementById('root')
  )
}

render()
store.subscribe(render)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
