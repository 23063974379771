import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, CircularProgress } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { logout } from 'common/actions'
import { userLogout } from 'common/utils/awsConfig'
import getCurrentUser from 'common/utils/getCurrentUser'
import { getToken, unsubscribeFromCompanyTopic } from 'common/utils/firebase'

const Logout = ({ onLogout, loggedUser }) => {
  const history = useHistory()

  useEffect(() => {
    const terminateUser = async () => {
      try {
        const user = await getCurrentUser()
        const token = await getToken()
        await unsubscribeFromCompanyTopic(token, user['custom:company'])
      } catch (e) {}
      userLogout()
      onLogout()
      history.replace('/login')
    }
    terminateUser()
  }, [])

  return (
    <Box>
      <CircularProgress />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {
  onLogout: logout,
})(Logout)
