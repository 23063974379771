import {
  cancelled,
  take,
  takeLatest,
  put,
  call,
  fork,
  all,
  race,
} from 'redux-saga/effects'
import * as api from 'common/services/api'
import * as actions from 'common/actions'
import { ER_Header, Topics } from 'common/utils/constants'
import { isEREnabled } from 'common/utils/pubsub'

export function* passwordChallenge(action) {
  try {
    const data = yield call(api.completePasswordChallenge, action.data)
    yield put(actions.userPasswordChallengeSuccess({ user: data.user }))
  } catch (error) {
    yield put(actions.userPasswordChallengeFailure(error))
  }
}

export function* watchPasswordChallenge() {
  yield takeLatest(actions.USER_PW_CHALLENGE_REQUEST, passwordChallenge)
}
export function* login(action) {
  try {
    const data = yield call(api.loginUser, action.data)
    const providerId = data.user['custom:idProvider']
    if (providerId) {
      const provider = yield call(api.callAWS, {
        entity: `provider/${providerId}`,
        method: 'GET',
      })
      yield put(
        actions.loginSuccess({
          user: { ...data.user, provider: provider },
        })
      )
    } else {
      yield put(actions.loginSuccess({ user: data.user }))
    }
  } catch (error) {
    yield put(actions.loginFailure(error))
  }
}

export function* userGet(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `user/${action.data}`,
      method: 'GET',
    })
    yield put(actions.userGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.userGetError(error))
  }
}

export function* watchUserGet() {
  yield takeLatest(actions.USER_GET_REQUEST, userGet)
}

export function* userCreate(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `user/${action.data.type}`,
      method: 'POST',
      body: action.data.data,
    })
    yield put(actions.userCreateSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.userCreateError(error))
  }
}

export function* watchUserCreate() {
  yield takeLatest(actions.USER_CREATE_REQUEST, userCreate)
}

export function* userUpdate(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `user/attributes`,
      method: 'PUT',
      body: action.data,
    })
    yield put(actions.userCreateSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.userCreateError(error))
  }
}

export function* watchUserUpdate() {
  yield takeLatest(actions.USER_UPDATE_REQUEST, userUpdate)
}

export function* watchLogin() {
  yield takeLatest(actions.USER_LOGIN_REQUEST, login)
}

export function* watchLoginReset() {
  yield take(actions.USER_LOGIN_RESET, function* () {
    yield put(actions.loginReset())
  })
}

export function* registration(action) {
  try {
    const data = yield call(api.registerUser, {
      ...action.data,
      createProvider: true,
    })
    yield put(actions.registrationSuccess(data))
  } catch (error) {
    yield put(actions.registrationFailure(error))
  }
}

export function* watchRegistration() {
  yield takeLatest(actions.USER_REGISTRATION_REQUEST, registration)
}

export function* adminChangeStatus(action) {
  try {
    const data = yield call(api.callAWS, {
      entity: `user/${action.data.username}/${
        action.data.enable ? 'enable' : 'disable'
      }`,
      method: 'PATCH',
    })
    yield put(actions.adminChangeStatusSuccess(data))
  } catch (error) {
    yield put(actions.adminChangeStatusFailure(error))
  }
}

export function* watchAdminChangeStatus() {
  yield takeLatest(actions.USER_ADMIN_CHANGE_STATUS_REQUEST, adminChangeStatus)
}

export function* watchRegistrationReset() {
  yield take(actions.USER_REGISTRATION_RESET, function* () {
    yield put(actions.registrationReset())
  })
}

export function* forgotPassword(action) {
  try {
    const data = yield call(api.forgotPassword, action.data)
    yield put(actions.forgotPasswordSuccess(data))
  } catch (error) {
    yield put(actions.forgotPasswordFailure(error))
  }
}

export function* watchForgotPassword() {
  yield takeLatest(actions.USER_FORGOTPW_REQUEST, forgotPassword)
}

export function* watchForgotPasswordReset() {
  yield take(actions.USER_FORGOTPW_RESET, function* () {
    yield put(actions.forgotPasswordReset())
  })
}

export function* retrieveAppointmentReasons() {
  try {
    const response = yield call(api.callAWS, {
      entity: `appointment_reason`,
      method: 'GET',
    })
    yield put(actions.requestAppointmentReasonsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.requestAppointmentReasonsFailure(error))
  }
}

export function* watchAppointmentReasonsGet() {
  yield takeLatest(
    actions.APPOINTMENT_REASON_GET_REQUEST,
    retrieveAppointmentReasons
  )
}

const getRetrieveAppointmentsEntity = (data) => {
  if (data.timestamp) return `appointment/${data.timestamp}`
  if (data.entity) return `appointment/${data.entity}`
  return `provider/${data.providerId}/appointment`
}

export function* createAppointment(action) {
  try {
    if (action.data.shouldLink)
      yield call(api.callAWS, {
        entity: `deal/${action.data.deal}/link/patient/${action.data.patient}`,
        method: 'POST',
        body: { docNumber: action.data.patientDocNumber },
      })
    const response = yield call(api.callAWS, {
      entity: action.data.linked ? 'appointment/link' : 'appointment/assistant',
      method: 'POST',
      body: action.data,
    })
    yield put(actions.createAppointmentSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.createAppointmentFailure(error))
  }
}

export function* watchAppointmentCreate() {
  yield takeLatest(actions.APPOINTMENT_CREATE_REQUEST, createAppointment)
}

export function* watchAppointmentCreateReset() {
  yield take(actions.APPOINTMENT_CREATE_RESET, function* () {
    yield put(actions.resetCreateAppointment())
  })
}

export function* cancelAppointment(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `appointment/${action.data.timestamp}/provider/${action.data.providerId}/true`,
      method: 'DELETE',
    })
    yield put(actions.cancelAppointmentSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.cancelAppointmentFailure(error))
  }
}

export function* watchAppointmentCancel() {
  yield takeLatest(actions.APPOINTMENT_CANCEL_REQUEST, cancelAppointment)
}

export function* watchAppointmentCancelReset() {
  yield take(actions.APPOINTMENT_CANCEL_RESET, function* () {
    yield put(actions.resetCancelAppointment())
  })
}

export function* retrieveAppointments(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: getRetrieveAppointmentsEntity(action.data),
      method: 'GET',
      params: action.data.from
        ? {
            fromDate: action.data.from,
            toDate: action.data.to,
            ...(action.data.specialty
              ? { specialty: action.data.specialty }
              : {}),
            ...(action.data.providers
              ? {
                  providers: Array.isArray(action.data.providers)
                    ? action.data.providers.join(',')
                    : action.data.providers,
                }
              : {}),
          }
        : { provider: action.data.provider },
    })
    yield put(actions.requestAppointmentsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.requestAppointmentsFailure(error))
  }
}

export function* watchAppointmentsGet() {
  yield takeLatest(actions.APPOINTMENT_GET_REQUEST, retrieveAppointments)
}

export function* retrieveUpcomingAppointments(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: 'appointment/upcoming',
      method: 'GET',
    })
    yield put(actions.requestUpcomingAppointmentsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.requestUpcomingAppointmentsFailure(error))
  }
}

export function* watchAppointmentsUpcomingGet() {
  yield takeLatest(
    actions.APPOINTMENT_UPCOMING_REQUEST,
    retrieveUpcomingAppointments
  )
}

export function* joinAppointment(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `appointment/${action.data.id}/${
        action.data.start ? 'start' : 'join'
      }`,
      method: 'POST',
    })
    yield put(actions.joinAppointmentSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.joinAppointmentFailure(error))
  }
}

export function* watchAppointmentJoin() {
  yield takeLatest(actions.APPOINTMENT_JOIN_REQUEST, joinAppointment)
}

export function* endAppointment(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `appointment/${action.data}/end`,
      method: 'POST',
    })
    yield put(actions.endAppointmentSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.endAppointmentFailure(error))
  }
}

export function* watchAppointmentEnd() {
  yield takeLatest(actions.APPOINTMENT_END_REQUEST, endAppointment)
}

export function* retrieveSchedules(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: action.data.providerId
        ? `provider/${action.data.providerId}/schedule`
        : `schedule/providers`,
      method: 'GET',
      params: action.data.from
        ? {
            fromDate: action.data.from,
            toDate: action.data.to,
            specialty: action.data.specialty,
            providers: Array.isArray(action.data.providers)
              ? action.data.providers.join(',')
              : action.data.providers,
          }
        : null,
    })
    yield put(actions.providerScheduleGetSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.providerScheduleGetError(error))
  }
}

export function* watchSchedulesGet() {
  yield takeLatest(actions.PROVIDER_SCHEDULE_GET_REQUEST, retrieveSchedules)
}

export function* createSchedulesMultiple(action) {
  try {
    const responses = yield all(
      action.data.schedules.map((s) =>
        call(api.callAWS, {
          entity: `provider/${action.data.providerId}/schedule_multiple`,
          method: 'POST',
          body: s,
        })
      )
    )
    yield put(actions.providerScheduleMultipleCreateSuccess(responses))
  } catch (error) {
    console.log(error)
    yield put(actions.providerScheduleMultipleCreateError(error))
  }
}

export function* watchCreateSchedulesMultiple() {
  yield takeLatest(
    actions.PROVIDER_SCHEDULE_MULTIPLE_CREATE_REQUEST,
    createSchedulesMultiple
  )
}

export function* createMultiplePrescriptions(action) {
  try {
    const responses = yield all(
      action.data.files.map((s) =>
        call(api.callAWS, {
          entity: `appointment/${action.data.timestamp}/prescription`,
          method: 'POST',
          body: s,
          params: { provider: action.data.provider },
        })
      )
    )
    yield put(actions.prescriptionAppointmentSuccess(responses))
  } catch (error) {
    console.log(error)
    yield put(actions.prescriptionAppointmentFailure(error))
  }
}

export function* watchCreateMultiplePrescriptions() {
  yield takeLatest(
    actions.APPOINTMENT_PRESCRIPTION_REQUEST,
    createMultiplePrescriptions
  )
}

export function* requestWhatsappPrescription(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `appointment/${action.data}/prescription/request`,
      method: 'POST',
    })
    yield put(actions.whatsappPrescriptionAppointmentSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.whatsappPrescriptionAppointmentFailure(error))
  }
}

export function* watchWhatsappPrescriptionRequest() {
  yield takeLatest(
    actions.APPOINTMENT_WHATSAPP_PRESCRIPTION_REQUEST,
    requestWhatsappPrescription
  )
}

export function* requestPrescriptionDelete(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `appointment/${action.data.timestamp}/prescription`,
      method: 'DELETE',
      body: action.data.file,
    })
    yield put(actions.deletePrescriptionSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.deletePrescriptionFailure(error))
  }
}

export function* watchPrescriptionDelete() {
  yield takeLatest(
    actions.APPOINTMENT_PRESCRIPTION_DELETE_REQUEST,
    requestPrescriptionDelete
  )
}

export function* deleteSchedules(action) {
  try {
    const responses = yield all(
      action.data.schedules.map((s) =>
        call(api.callAWS, {
          entity: `provider/${action.data.providerId}/schedule/${s}`,
          method: 'DELETE',
        })
      )
    )
    yield put(actions.providerScheduleMultipleCreateSuccess(responses))
  } catch (error) {
    console.log(error)
    yield put(actions.providerScheduleMultipleCreateError(error))
  }
}

export function* watchDeleteSchedules() {
  yield takeLatest(actions.PROVIDER_SCHEDULE_DELETE_REQUEST, deleteSchedules)
}

export function* retrieveAssistantSpecialties() {
  try {
    const response = yield call(api.callAWS, {
      entity: `specialty/company`,
      method: 'GET',
    })
    yield put(actions.assistantSpecialtiesGetSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.assistantSpecialtiesGetError(error))
  }
}

export function* watchRetrieveAssistantSpecialtiesGet() {
  yield takeLatest(
    actions.ASSISTANT_SPECIALTIES_GET_REQUEST,
    retrieveAssistantSpecialties
  )
}

export function* retrieveSpecialties(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `specialty${action.retrieveAll ? '/all' : ''}`,
      method: 'GET',
    })
    yield put(actions.specialtiesGetSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.specialtiesGetError(error))
  }
}

export function* watchRetrieveSpecialtiesGet() {
  yield takeLatest(actions.SPECIALTIES_GET_REQUEST, retrieveSpecialties)
}

export function* createSpecialty(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: 'specialty',
      method: 'POST',
      body: action.data,
    })
    yield put(actions.specialtyCreateSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.specialtyCreateError(error))
  }
}

export function* watchSpecialtyCreate() {
  yield takeLatest(actions.SPECIALTIES_CREATE_REQUEST, createSpecialty)
}

export function* fileUpload(action) {
  try {
    const response = yield call(api.uploadToS3, action.data)
    yield put(actions.fileUploadSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.fileUploadError(error))
  }
}

export function* watchFileUpload() {
  yield takeLatest(actions.FILE_UPLOAD_REQUEST, fileUpload)
}

export function* watchFileUploadReset() {
  yield take(actions.FILE_UPLOAD_RESET, function* () {
    yield put(actions.fileUploadReset())
  })
}

export function* watchProviderReset() {
  yield take(actions.PROVIDER_RESET, function* () {
    yield put(actions.providerReset())
  })
}

export function* fileGet(action) {
  try {
    const response = yield call(api.getFromS3, action.data)
    yield put(actions.fileGetSuccess(response))
  } catch (error) {
    yield put(actions.fileGetError(error))
  }
}

export function* watchFileGet() {
  yield takeLatest(actions.FILE_GET_REQUEST, fileGet)
}

export function* fileDownloadGet(action) {
  try {
    const response = yield call(api.downloadFromS3, action.data)
    yield put(actions.fileDownloadGetSuccess(response))
  } catch (error) {
    yield put(actions.fileDownloadGetError(error))
  }
}

export function* watchFileDownloadGet() {
  yield takeLatest(actions.FILE_DOWNLOAD_GET_REQUEST, fileDownloadGet)
}

export function* multipleFileGet(action) {
  try {
    const files = yield all(action.data.map((f) => call(api.getFromS3, f)))
    yield put(actions.multipleFileGetSuccess(files))
  } catch (error) {
    console.log(error)
    yield put(actions.multipleFileGetError(error))
  }
}

export function* watchMultipleFileGet() {
  yield takeLatest(actions.MULTIPLE_FILE_GET_REQUEST, multipleFileGet)
}

// TODO: This can be unified with pubsubSubscribeClient
function* pubsubSubscribeER(provider) {
  const erChannel = yield call(api.subscribeToTopic, {
    topic: Topics.ER,
    provider,
    errorHandler: actions.pubSubErSubscribeError,
  })

  yield put(actions.pubSubErSubscribeSuccess(erChannel))

  try {
    while (true) {
      const erResponse = yield take(erChannel)
      yield put(actions.pubSubReceivedData({ erResponse }))
    }
  } catch (err) {
    yield put(actions.pubSubErSubscribeError(err))
    console.error('ER channel error:', err) // TODO: Move to reducer??
    erChannel.close()
  } finally {
    if (yield cancelled()) {
      console.log('closing channel ER...')
      erChannel.close()
    }
  }
}

export function* startStopErChannel() {
  while (true) {
    yield take(actions.PUBSUB_ER_SUBSCRIBE_REQUEST)
    yield race({
      task: call(pubsubSubscribeER),
      cancel: take(actions.PUBSUB_ER_UNSUBSCRIBE_REQUEST),
    })
  }
}

function* pubsubSubscribeClient(action) {
  const { provider } = action.data
  const clientChannel = yield call(api.subscribeToTopic, {
    topic: Topics.CLIENT,
    provider,
    errorHandler: actions.pubSubClientSubscribeError,
  })

  yield put(actions.pubSubClientSubscribeSuccess({ clientChannel })) // Only for information purposes

  while (true) {
    try {
      const clientResponse = yield take(clientChannel)
      yield put(actions.pubSubReceivedData({ clientResponse }))

      if (
        clientResponse.header == ER_Header.DISABLED ||
        (clientResponse.header == ER_Header.ENABLED && clientResponse.timestamp)
      ) {
        yield put(actions.pubSubErUnsubscribeRequest())
      }

      if (
        clientResponse.header == ER_Header.ENABLED &&
        !clientResponse.timestamp
      ) {
        yield put(actions.pubSubErSubscribeRequest())
      }
    } catch (err) {
      yield put(actions.pubSubClientSubscribeError(err))
      console.error('channel error:', err) // TODO: Move to reducer??
      yield put(actions.providerAttachRequest())
      clientChannel.close()
    } finally {
      // This is only for compatibility IF this is invoked through RACE.
      if (yield cancelled()) {
        console.log('closing channel CLIENT...')
        clientChannel.close()
      }
    }
  }
}

export function* watchPubSubSubscribe() {
  yield takeLatest(
    actions.PUBSUB_CLIENT_SUBSCRIBE_REQUEST,
    pubsubSubscribeClient
  )
}

export function* pubSubPublish(action) {
  try {
    const { provider, header, messageProps } = action.data
    const response = yield call(
      api.publishToTopic,
      Topics.CLIENT,
      provider,
      header,
      messageProps
    )
    yield put(actions.pubSubPublishSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.pubSubPublishError(error))
  }
}

export function* watchPubSubPublish() {
  yield takeLatest(actions.PUBSUB_PUBLISH_REQUEST, pubSubPublish)
}

export function* providerAttach() {
  try {
    const response = yield call(api.callAWS, {
      entity: `er/provider/attach`,
      method: 'POST',
    })
    yield put(actions.providerAttachSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.providerAttachError(error))
  }
}

export function* watchProviderAttach() {
  yield takeLatest(actions.PROVIDER_ATTACH_REQUEST, providerAttach)
}

export function* providerSubscribe(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `er/provider/subscription`,
      method: 'POST',
    })
    yield put(actions.providerSubscribeSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.providerSubscribeError(error))
  }
}

export function* watchProviderSubscribe() {
  yield takeLatest(actions.PROVIDER_SUBSCRIBE_REQUEST, providerSubscribe)
}

export function* providerUnsubscribe(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `er/provider/subscription/cancel`,
      method: 'POST',
    })
    yield put(actions.providerUnsubscribeSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.providerUnsubscribeError(error))
  }
}

export function* watchProviderUnsubscribe() {
  yield takeLatest(actions.PROVIDER_UNSUBSCRIBE_REQUEST, providerUnsubscribe)
}

export function* providerGet(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `provider/${action.data?.id || ''}`,
      method: 'GET',
      params: action.data?.params,
    })
    yield put(actions.providerGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.providerGetError(error))
  }
}

export function* watchProviderGet() {
  yield takeLatest(actions.PROVIDER_GET_REQUEST, providerGet)
}

export function* providerCurrentGet(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `provider/${action.data}`,
      method: 'GET',
    })
    yield put(actions.providerCurrentGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.providerCurrentGetError(error))
  }
}
export function* watchProviderCurrentGet() {
  yield takeLatest(actions.PROVIDER_CURRENT_GET_REQUEST, providerCurrentGet)
}

export function* providerCreate(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: 'provider',
      method: 'POST',
      body: action.data,
    })
    yield put(actions.providerCreateSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.providerCreateError(error))
  }
}

export function* watchProviderCreate() {
  yield takeLatest(actions.PROVIDER_CREATE_REQUEST, providerCreate)
}

export function* providerDelete(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `provider/${action.data}`,
      method: 'DELETE',
    })
    yield put(actions.providerDeleteSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.providerDeleteError(error))
  }
}

export function* watchProviderDelete() {
  yield takeLatest(actions.PROVIDER_DELETE_REQUEST, providerDelete)
}

export function* editProvider(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `provider/${action.data.id}`,
      method: 'PUT',
      body: action.data,
    })
    yield put(actions.providerEditSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.providerEditError(error))
  }
}

export function* watchProviderEdit() {
  yield takeLatest(actions.PROVIDER_EDIT_REQUEST, editProvider)
}

export function* validateProvider(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `provider/${action.data}/validate`,
      method: 'POST',
    })
    yield put(actions.providerValidateSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(actions.providerValidateError(error))
  }
}

export function* watchProviderValidate() {
  yield takeLatest(actions.PROVIDER_VALIDATE_REQUEST, validateProvider)
}

export function* countriesGet() {
  try {
    const response = yield call(api.callAWS, {
      entity: `country`,
      method: 'GET',
    })
    yield put(actions.countriesGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.countriesGetError(error))
  }
}
export function* watchCountriesGet() {
  yield takeLatest(actions.COUNTRIES_GET_REQUEST, countriesGet)
}

export function* provincesGet(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `country/${action.data}/province`,
      method: 'GET',
    })
    yield put(actions.provincesGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.provincesGetError(error))
  }
}
export function* watchProvincesGet() {
  yield takeLatest(actions.PROVINCES_GET_REQUEST, provincesGet)
}

export function* districtsGet(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `country/${action.data.country}/province/${action.data.province}`,
      method: 'GET',
    })
    yield put(actions.districtsGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.districtsGetError(error))
  }
}
export function* watchDistrictsGet() {
  yield takeLatest(actions.DISTRICTS_GET_REQUEST, districtsGet)
}

export function* patientGet(action) {
  try {
    yield put(actions.patientHealthRecordGetRequest(action.data))
    const response = yield call(api.callAWS, {
      entity: `patient/${action.data}`,
      method: 'GET',
    })
    yield put(actions.patientGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.patientGetError(error))
  }
}
export function* watchPatientGet() {
  yield takeLatest(actions.PATIENT_GET_REQUEST, patientGet)
}

export function* patientSearch(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `patient/search/${
        !action.data.email ? action.data.document : ''
      }${action.data.email ? `email/${action.data.email}` : ''}`,
      method: 'GET',
      params: { docNumber: action.data.document, idDeal: action.data.idDeal },
    })
    yield put(actions.patientSearchSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.patientSearchError(error))
  }
}
export function* watchPatientSearch() {
  yield takeLatest(actions.PATIENT_SEARCH_REQUEST, patientSearch)
}

export function* watchPatientSearchReset() {
  yield take(actions.PATIENT_SEARCH_RESET, function* () {
    yield put(actions.resetPatientSearch())
  })
}

export function* patientCreation(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `user/patient`,
      method: 'POST',
      body: action.data,
    })
    yield put(actions.patientCreationSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.patientCreationError(error))
  }
}
export function* watchPatientCreate() {
  yield takeLatest(actions.PATIENT_CREATE_REQUEST, patientCreation)
}

export function* watchPatientCreateReset() {
  yield take(actions.PATIENT_CREATE_RESET, function* () {
    yield put(actions.resetPatientCreation())
  })
}

export function* patientHealthRecordGet(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `healthrecord/${action.data}`,
      method: 'GET',
    })
    yield put(actions.patientHealthRecordGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.patientHealthRecordGetError(error))
  }
}
export function* watchPatientHealthRecordGet() {
  yield takeLatest(
    actions.PATIENT_HEALTH_RECORD_GET_REQUEST,
    patientHealthRecordGet
  )
}
export function* watchPatientHealthRecordDownloadReset() {
  yield take(actions.PATIENT_HEALTH_RECORD_DOWNLOAD_RESET, function* () {
    yield put(actions.patientHealthRecordDownloadReset())
  })
}

export function* patientHealthRecordDownload(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `healthrecord/${action.data.patient}/export/pdf?timestamp=${action.data.timestamp}`,
      method: 'DOWNLOAD',
    })
    yield put(actions.patientHealthRecordDownloadSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.patientHealthRecordDownloadError(error))
  }
}
export function* watchPatientHealthRecordDownload() {
  yield takeLatest(
    actions.PATIENT_HEALTH_RECORD_DOWNLOAD_REQUEST,
    patientHealthRecordDownload
  )
}

export function* patientHealthRecordCreate(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `healthrecord/${action.data.patient}/${action.data.timestamp}`,
      method: 'POST',
      body: action.data.healthRecord,
    })
    yield put(actions.patientHealthRecordCreateSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.patientHealthRecordCreateError(error))
  }
}
export function* watchPatientHealthRecordCreate() {
  yield takeLatest(
    actions.PATIENT_HEALTH_RECORD_CREATE_REQUEST,
    patientHealthRecordCreate
  )
}

export function* coveragesGet() {
  try {
    const response = yield call(api.callAWS, {
      entity: `coverage`,
      method: 'GET',
    })
    yield put(actions.coveragesGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.coveragesGetError(error))
  }
}
export function* watchCoveragesGet() {
  yield takeLatest(actions.COVERAGE_GET_REQUEST, coveragesGet)
}

export function* countryPut(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `country/${action.data.id}`,
      method: 'PUT',
      body: action.data,
    })
    yield put(actions.countryPutSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.countryPutError(error))
  }
}
export function* watchCountryPut() {
  yield takeLatest(actions.COUNTRY_PUT_REQUEST, countryPut)
}

export function* watchCountryPutReset() {
  yield take(actions.COUNTRY_PUT_RESET, function* () {
    yield put(actions.countryPutReset())
  })
}

export function* companyGet() {
  try {
    const response = yield call(api.callAWS, {
      entity: `company`,
      method: 'GET',
    })
    yield put(actions.companyGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.companyGetError(error))
  }
}
export function* watchCompanyGet() {
  yield takeLatest(actions.COMPANY_GET_REQUEST, companyGet)
}

export function* companiesGet(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `company/list`,
      method: 'GET',
    })
    yield put(actions.companiesGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.companiesGetError(error))
  }
}
export function* watchCompaniesGet() {
  yield takeLatest(actions.COMPANIES_GET_REQUEST, companiesGet)
}

export function* companyCreate(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `company`,
      method: 'POST',
      body: action.data,
    })
    yield put(actions.companyCreateSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.companyCreateError(error))
  }
}
export function* watchCompanyCreate() {
  yield takeLatest(actions.COMPANY_CREATE_REQUEST, companyCreate)
}

export function* dealsGet(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `deal`,
      method: 'GET',
      params: action.data?.params,
    })
    yield put(actions.dealsGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.dealsGetError(error))
  }
}
export function* watchDealsGet() {
  yield takeLatest(actions.DEALS_GET_REQUEST, dealsGet)
}

export function* dealGet(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `deal/${action.data}`,
      method: 'GET',
    })
    yield put(actions.dealGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.dealGetError(error))
  }
}
export function* watchDealGet() {
  yield takeLatest(actions.DEAL_GET_REQUEST, dealGet)
}

export function* dealCreate(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `deal`,
      method: 'POST',
      body: action.data,
    })
    yield put(actions.dealCreateSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.dealCreateError(error))
  }
}
export function* watchDealCreate() {
  yield takeLatest(actions.DEAL_CREATE_REQUEST, dealCreate)
}
export function* dealEdit(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `deal/${action.data.id}/provider`,
      method: 'PUT',
      body: action.data,
    })

    yield call(api.callAWS, {
      entity: `deal/${action.data.id}/er`,
      method: 'PUT',
      body: { erPrice: action.data.erPrice || 0.0 },
    })

    yield put(actions.dealEditSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.dealEditError(error))
  }
}
export function* watchDealEdit() {
  yield takeLatest(actions.DEAL_EDIT_REQUEST, dealEdit)
}

export function* reportsGet(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `report`,
      method: 'GET',
    })
    yield put(actions.reportsGetSuccess(response))
  } catch (error) {
    console.error(error)
    yield put(actions.reportsGetError(error))
  }
}
export function* watchReportsGet() {
  yield takeLatest(actions.REPORTS_GET_REQUEST, reportsGet)
}
export function* reportResults(action) {
  try {
    const response = yield call(api.callAWS, {
      entity: `report/${action.data.id}`,
      params: action.data.params,
      method: 'GET',
    })
    yield put(actions.reportResultsSuccess({ ...response, id: action.data.id }))
  } catch (error) {
    console.error(error)
    yield put(actions.reportResultsError(error))
  }
}
export function* watchReportResults() {
  yield takeLatest(actions.REPORTS_RESULTS_REQUEST, reportResults)
}

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchPasswordChallenge),
    fork(watchLoginReset),
    fork(watchRegistration),
    fork(watchRegistrationReset),
    fork(watchForgotPassword),
    fork(watchForgotPasswordReset),
    fork(watchAppointmentsGet),
    fork(watchAppointmentsUpcomingGet),
    fork(watchRetrieveSpecialtiesGet),
    fork(watchSpecialtyCreate),
    fork(watchFileUpload),
    fork(watchFileGet),
    fork(watchMultipleFileGet),
    fork(watchProviderGet),
    fork(watchProviderEdit),
    fork(watchAdminChangeStatus),
    fork(watchProviderCreate),
    fork(watchProviderCurrentGet),
    fork(watchProviderValidate),
    fork(watchProvincesGet),
    fork(watchDistrictsGet),
    fork(watchCountriesGet),
    fork(watchSchedulesGet),
    fork(watchCreateSchedulesMultiple),
    fork(watchAppointmentJoin),
    fork(watchAppointmentReasonsGet),
    fork(watchDeleteSchedules),
    fork(watchProviderDelete),
    fork(watchPatientGet),
    fork(watchAppointmentEnd),
    fork(watchCreateMultiplePrescriptions),
    fork(watchWhatsappPrescriptionRequest),
    fork(watchPrescriptionDelete),
    fork(watchFileDownloadGet),
    fork(watchPatientHealthRecordCreate),
    fork(watchPatientHealthRecordGet),
    fork(watchCoveragesGet),
    fork(watchCompaniesGet),
    fork(watchCompanyGet),
    fork(watchCompanyCreate),
    fork(watchDealsGet),
    fork(watchDealCreate),
    fork(watchDealGet),
    fork(watchDealEdit),
    fork(watchReportsGet),
    fork(watchReportResults),
    fork(watchUserGet),
    fork(watchUserCreate),
    fork(watchRetrieveAssistantSpecialtiesGet),
    fork(watchFileUploadReset),
    fork(watchPatientSearch),
    fork(watchAppointmentCreate),
    fork(watchAppointmentCreateReset),
    fork(watchPatientSearchReset),
    fork(watchPatientCreate),
    fork(watchPatientCreateReset),
    fork(watchAppointmentCancel),
    fork(watchAppointmentCancelReset),
    fork(watchPatientHealthRecordDownloadReset),
    fork(watchPatientHealthRecordDownload),
    fork(watchUserUpdate),
    fork(watchCountryPutReset),
    fork(watchCountryPut),
    fork(watchProviderSubscribe),
    fork(watchProviderUnsubscribe),
    fork(watchProviderAttach),
    fork(watchPubSubSubscribe),
    fork(watchPubSubPublish),
    fork(watchProviderReset),
    startStopErChannel(),
  ])
}
