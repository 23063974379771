import * as ActionTypes from '../actions'

const initialState = {
  providers: null,
  currentProvider: null,
  loading: false,
  error: null,
  success: false,
  schedules: null,
}

export function providersReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PROVIDER_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        providers: [],
        success: false,
      }
    case ActionTypes.PROVIDER_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        providers: action.data.items || [action.data],
      }
    case ActionTypes.PROVIDER_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case ActionTypes.PROVIDER_RESET:
      return {
        ...state,
        loading: false,
        success: false,
      }
    case ActionTypes.PROVIDER_SCHEDULE_DELETE_REQUEST:
    case ActionTypes.PROVIDER_DELETE_REQUEST:
    case ActionTypes.PROVIDER_EDIT_REQUEST:
    case ActionTypes.PROVIDER_CREATE_REQUEST:
    case ActionTypes.PROVIDER_VALIDATE_REQUEST:
    case ActionTypes.PROVIDER_SCHEDULE_MULTIPLE_CREATE_REQUEST:
    case ActionTypes.PROVIDER_ATTACH_REQUEST:
    case ActionTypes.PROVIDER_SUBSCRIBE_REQUEST:
    case ActionTypes.PROVIDER_UNSUBSCRIBE_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        success: false,
      }
    case ActionTypes.PROVIDER_SCHEDULE_DELETE_SUCCESS:
    case ActionTypes.PROVIDER_DELETE_SUCCESS:
    case ActionTypes.PROVIDER_EDIT_SUCCESS:
    case ActionTypes.PROVIDER_CREATE_SUCCESS:
    case ActionTypes.PROVIDER_VALIDATE_SUCCESS:
    case ActionTypes.PROVIDER_SCHEDULE_MULTIPLE_CREATE_SUCCESS:
    case ActionTypes.PROVIDER_ATTACH_SUCCESS:
    case ActionTypes.PROVIDER_SUBSCRIBE_SUCCESS:
    case ActionTypes.PROVIDER_UNSUBSCRIBE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case ActionTypes.PROVIDER_SCHEDULE_DELETE_FAILURE:
    case ActionTypes.PROVIDER_CURRENT_GET_FAILURE:
    case ActionTypes.PROVIDER_DELETE_FAILURE:
    case ActionTypes.PROVIDER_EDIT_FAILURE:
    case ActionTypes.PROVIDER_CREATE_FAILURE:
    case ActionTypes.PROVIDER_VALIDATE_FAILURE:
    case ActionTypes.PROVIDER_SCHEDULE_GET_FAILURE:
    case ActionTypes.PROVIDER_SCHEDULE_MULTIPLE_CREATE_FAILURE:
    case ActionTypes.PROVIDER_ATTACH_FAILURE:
    case ActionTypes.PROVIDER_SUBSCRIBE_FAILURE:
    case ActionTypes.PROVIDER_UNSUBSCRIBE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case ActionTypes.PROVIDER_CURRENT_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        currentProvider: null,
      }
    case ActionTypes.PROVIDER_CURRENT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        currentProvider: action.data,
      }
    case ActionTypes.PROVIDER_SCHEDULE_GET_REQUEST:
      return {
        ...state,
        loading: true,
        schedules: [],
      }
    case ActionTypes.PROVIDER_SCHEDULE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        schedules: action.data.items || action.data,
      }
    default:
      return state
  }
}

export function getSchedules(state) {
  return state.providersReducer.schedules
}

export function getCurrentProvider(state) {
  return state.providersReducer.currentProvider
}

export function getProviders(state) {
  return state.providersReducer.providers
}

export function getLoading(state) {
  return state.providersReducer.loading
}

export function getError(state) {
  return state.providersReducer.error
}
export function getSuccess(state) {
  return state.providersReducer.success
}
