import React, { useState } from 'react'
import * as R from 'ramda'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import { getCompany } from 'common/reducers/companies'

import Dashboard from './dashboard'
import Configuration from './configuration'
import Specialties from './specialties'
import Clients from './clients'
import ClientsNew from './clients/new'
import Providers from './providers'
import ProvidersNew from './providers/new'
import ProvidersEdit from './providers/edit'
import ProvidersView from './providers/view'
import Users from './users'
import Deals from './deals'
import DealsEdit from './deals/edit'
import Reports from './reports'
import ReportsView from './reports/view'

import PrivateRoute from './privateRoute'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

const Admin = ({ company }) => {
  const classes = useStyles()
  return (
    <BrowserRouter basename='/admin'>
      <Backdrop className={classes.backdrop} open={!company}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Switch>
        <PrivateRoute path='/reports/:id'>
          <ReportsView />
        </PrivateRoute>
        <PrivateRoute path='/reports'>
          <Reports />
        </PrivateRoute>
        <PrivateRoute path='/specialties'>
          <Specialties />
        </PrivateRoute>
        <PrivateRoute path='/users'>
          <Users />
        </PrivateRoute>
        <PrivateRoute path='/deals/:id'>
          <DealsEdit />
        </PrivateRoute>
        <PrivateRoute path='/deals'>
          <Deals />
        </PrivateRoute>
        <PrivateRoute path='/clients/new'>
          <ClientsNew />
        </PrivateRoute>
        <PrivateRoute path='/clients'>
          <Clients />
        </PrivateRoute>
        <PrivateRoute path='/providers/new'>
          <ProvidersNew />
        </PrivateRoute>
        <PrivateRoute path='/providers/:id/view'>
          <ProvidersView />
        </PrivateRoute>
        <PrivateRoute path='/providers/:id'>
          <ProvidersEdit />
        </PrivateRoute>
        <PrivateRoute path='/providers'>
          <Providers />
        </PrivateRoute>
        <PrivateRoute path='/configuration'>
          <Configuration />
        </PrivateRoute>
        <PrivateRoute path='/'>
          <Dashboard />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({
  company: getCompany(state),
})

export default connect(mapStateToProps, {})(Admin)
