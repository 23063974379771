import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'

import {
  Box,
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import dow from 'common/utils/dow'
import theme from 'common/theme'

const useStyles = makeStyles((theme) => ({
  inputBox: {
    marginBottom: theme.spacing(3),
  },
}))

const ScheduleSlotModal = ({
  isOpen,
  onSubmit,
  onClose,
  specialties,
  info,
  onDelete,
  schedule,
  provider,
}) => {
  const classes = useStyles()
  const [selectedSpecialties, setSelectedSpecialties] = useState([])
  const [selectedDuration, setSelectedDuration] = useState(30)
  const [defaultValues, setDefaultValues] = useState([])

  useEffect(() => {
    if (!schedule) return

    setDefaultValues(schedule.specialties || [])
    setSelectedDuration(schedule.duration)
  }, [schedule])

  useEffect(() => {
    if (schedule) return

    setDefaultValues([])
    setSelectedDuration(30)
  }, [isOpen])

  const submit = () => {
    const data = {
      start: info.startStr,
      end:
        selectedDuration >
        dayjs(info.endStr).diff(dayjs(info.startStr), 'minutes')
          ? dayjs(info.start).add(selectedDuration, 'minutes').utc().format()
          : info.endStr,
      specialties: selectedSpecialties,
      duration: selectedDuration,
    }
    onSubmit(data)
  }

  let start = dayjs(info?.startStr)
  let end = dayjs(info?.endStr)

  if (!info?.dontAddTimezone) {
    start = start.tz(provider?.timezone)
    end = end.tz(provider?.timezone)
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {info && (
          <>
            {dow[info.start.getDay()]} de {start.format('h:mm a')} hasta{' '}
            {end.format('h:mm a')}
          </>
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Seleccioná una o más especialidades y la duración de los turnos.
        </DialogContentText>
        <Box className={classes.inputBox}>
          <Autocomplete
            disabled={schedule && schedule.exists}
            multiple
            id='selectedSpecialties'
            options={specialties}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            disableCloseOnSelect
            defaultValue={defaultValues}
            onChange={(e, v) => {
              setSelectedSpecialties(v)
            }}
            renderInput={(params) => (
              <TextField
                name='selectedSpecialties'
                {...params}
                label='Especialidades'
                fullWidth
              />
            )}
          />
        </Box>
        <Box className={classes.inputBox}>
          <TextField
            disabled={schedule && schedule.exists}
            select
            label='Duración del Turno'
            value={selectedDuration}
            onChange={(e) => setSelectedDuration(e.target.value)}
            helperText='Seleccione la duración del turno'
          >
            {[15, 30, 45, 60, 75, 90, 105, 120].map((option) => (
              <MenuItem
                key={option}
                value={option}
                disabled={option > info?.maximumDuration}
              >
                {option} minutos
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions>
        {info && info.id && (
          <Button onClick={onDelete} style={theme.danger}>
            Remover
          </Button>
        )}
        <Button onClick={onClose} color='primary'>
          Volver
        </Button>
        <Button
          onClick={submit}
          color='primary'
          variant='contained'
          disabled={
            !selectedSpecialties.length ||
            !selectedDuration ||
            (schedule && schedule.exists)
          }
        >
          Seleccionar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ScheduleSlotModal.defaultProps = {
  duration: 30,
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, {})(ScheduleSlotModal)
