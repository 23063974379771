import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import dayjs from 'dayjs'

import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  IconButton,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Grid,
  MenuItem,
  FormLabel,
} from '@material-ui/core'
import { PhoneField } from 'common/components'
import defaultPhoneNumbers from 'common/utils/defaultPhoneNumbers'
import { Alert, AlertTitle } from '@material-ui/lab'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import {
  requestAppointments,
  requestAppointmentReasons,
  fileDownloadGetRequest,
  requestAssistantSpecialtiesGet,
  providerScheduleGetRequest,
  patientSearchRequest,
  fileGetRequest,
  fileUploadRequest,
  fileUploadReset,
  createAppointment,
  resetCreateAppointment,
  resetPatientSearch,
  dealsGetRequest,
} from 'common/actions'

import {
  getError,
  getLoading as getAppointmentsLoading,
  getAppointments,
  getReasons,
  getSuccess,
} from 'common/reducers/appointment'
import {
  getLoading as getFileLoading,
  getDownloadFile,
  getUploadResponse,
  getFiles,
} from 'common/reducers/files'
import { getSpecialties, getProviders } from 'common/reducers/assistant'
import {
  getPatientSearch,
  getLoading as getPatientLoading,
  getError as getPatientError,
} from 'common/reducers/patients'

import { validatePhoneNumber } from 'common/services/api'
import { phoneNumberRegex } from 'common/utils/constants'

import {
  getLoading as getSchedulesLoading,
  getSchedules,
} from 'common/reducers/providers'
import { AttachFile, Search } from '@material-ui/icons'
import { LoadingButton, SelectField } from 'common/components'
import { getDeals } from 'common/reducers/deals'
import { getCompany } from 'common/reducers/companies'

const useStyles = makeStyles((theme) => ({
  inputBox: {
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  fileInput: {
    display: 'none',
  },
}))

const LinkedNewAppointmentModal = ({
  onRequestFile,
  onResetFileUpload,
  onRequestUpload,
  uploadResponse,
  appointmentReasons,
  selectedSpecialty,
  selectedSchedule,
  selectedProvider,
  onClose,
  loading,
  onCreateAppointment,
  appointmentSuccess,
  onCreateAppointmentReset,
  isOpen,
  error,
  company,
  selectedTimezone,
  fileLoading,
}) => {
  const classes = useStyles()

  const fileInputRef = useRef(null)
  const [selectedReasons, setSelectedReasons] = useState([])
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [otherReason, setOtherReason] = useState('')

  const [phoneCountry, setPhoneCountry] = useState(null)

  const assistantMustAttachCoc = company?.assistantMustAttachCoc

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Este campo es requerido')
      .email('Por favor ingrese un email válido')
      .nullable(),
    name: yup.string().required('Este campo es requerido'),
    lastName: yup.string().required('Este campo es requerido'),
    documentType: yup.string().required('Este campo es requerido'),
    documentNumber: yup.string().required('Este campo es requerido'),
    gender: yup.string().required('Este campo es requerido'),
    appointmentCode: yup
      .string()
      .test('appcode-required', 'Este campo es requerido', (val) =>
        company.canSetAppointmentCode ? !R.isEmpty(val) : true
      ),
    phone: yup
      .string()
      .matches(phoneNumberRegex, 'El teléfono debe ser un número sin espacios')
      .required('Este campo es requerido'),
    coc: yup
      .object()
      .nullable()
      .test('coc-required', 'Este campo es requerido', (val) =>
        assistantMustAttachCoc ? val != null : true
      ),
  })

  useEffect(() => {
    if (!appointmentSuccess || !isOpen) return
    onCreateAppointmentReset()
    onClose({ refresh: true })
    setSnackbarOpen(true)
  }, [appointmentSuccess])

  const formDefaultValues = {
    email: '',
    name: '',
    lastName: '',
    documentNumber: '',
    documentType: 'dni',
    gender: '',
    appointmentCode: '',
    phone: '54',
    coc: null,
  }

  const {
    control,
    errors,
    handleSubmit,
    triggerValidation,
    watch,
    reset,
    setError,
    setValue,
    register,
  } = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    register('coc')
  }, [register])

  useEffect(() => {
    if (!uploadResponse) return
    const fileObj = {
      bucket: process.env.REACT_APP_AWS_S3_BUCKET,
      key: uploadResponse.key,
    }

    setValue('coc', fileObj, true)
    onResetFileUpload()
  }, [uploadResponse])

  const handleUpload = () => {
    const file = fileInputRef.current.files[0]
    onRequestUpload({ file, folder: 'coc', companyId: company.id })
  }

  const onSubmit = async (data) => {
    try {
      const validPhoneNumber = await validatePhoneNumber(
        data.phone,
        phoneCountry.countryCode
      )
      onCreateAppointment({
        ...data,
        phone: validPhoneNumber.phoneNumber,
        timestamp: selectedSchedule.timestamp,
        provider: selectedProvider.id,
        specialty: selectedSpecialty.id,
        reasonOther: otherReason,
        reason: selectedReasons,
        linked: true,
        timezone: selectedTimezone,
      })
    } catch (e) {
      setError('phone', { message: 'El número de teléfono no es válido' })
    }
  }

  useEffect(() => {
    reset(formDefaultValues)
    setSelectedReasons([])
    setOtherReason('')
  }, [isOpen])

  useEffect(() => {
    if (!company || !!watch('phone')) return
    setValue(
      'phone',
      defaultPhoneNumbers[company.country.shortName] || defaultPhoneNumbers.AR
    )
  }, [company])

  return (
    <>
      <Dialog open={isOpen} onClose={() => onClose({ refresh: false })}>
        <form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          {selectedSchedule && (
            <>
              <DialogTitle id='alert-dialog-title'>
                Nuevo Turno Con Enlace:{' '}
                {dayjs(selectedSchedule.timestamp * 1000)
                  .tz(selectedTimezone)
                  .format('DD/MM/YYYY HH:mm[hs]')}
              </DialogTitle>
              <DialogContent style={{ minWidth: '900px' }}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    {company.canSetAppointmentCode && (
                      <Box className={classes.inputBox}>
                        <Controller
                          control={control}
                          name='appointmentCode'
                          error={!!errors.appointmentCode}
                          helperText={
                            errors.appointmentCode &&
                            errors.appointmentCode.message
                          }
                          onBlur={() => triggerValidation('appointmentCode')}
                          fullWidth
                          as={<TextField />}
                          label='Número de referencia'
                        />
                      </Box>
                    )}
                    <Box className={classes.inputBox}>
                      <TextField
                        disabled
                        fullWidth
                        onChange={R.T}
                        value={`${selectedProvider?.title} ${selectedProvider?.name} ${selectedProvider?.lastName}`}
                        label='Profesional'
                      />
                    </Box>
                    <Box className={classes.inputBox}>
                      <TextField
                        disabled
                        fullWidth
                        onChange={R.T}
                        value={selectedSpecialty.name}
                        label='Especialidad'
                      />
                    </Box>
                    <Box className={classes.inputBox}>
                      <Controller
                        control={control}
                        name='email'
                        error={!!errors.email}
                        helperText={errors.email && errors.email.message}
                        label='Dirección de Email'
                        onBlur={() => triggerValidation('email')}
                        fullWidth
                        as={<TextField />}
                      />
                    </Box>
                    <Box className={classes.inputBox}>
                      <SelectField
                        error={!!errors.documentType}
                        label='Tipo de Documento'
                        options={[
                          { value: 'dni', label: 'DNI' },
                          { value: 'passport', label: 'Pasaporte' },
                        ]}
                        control={control}
                        name='documentType'
                        helperText={
                          errors.documentType && errors.documentType.message
                        }
                        onBlur={() => triggerValidation('documentType')}
                        fullWidth
                      />
                    </Box>
                    <Box className={classes.inputBox}>
                      <Controller
                        control={control}
                        name='documentNumber'
                        error={!!errors.documentNumber}
                        helperText={
                          errors.documentNumber && errors.documentNumber.message
                        }
                        label='Número de Documento'
                        onBlur={() => triggerValidation('documentNumber')}
                        fullWidth
                        as={<TextField />}
                      />
                    </Box>
                    <Box className={classes.inputBox}>
                      <SelectField
                        error={!!errors.gender}
                        label='Sexo'
                        options={[
                          { value: 'MALE', label: 'Masculino' },
                          { value: 'FEMALE', label: 'Femenino' },
                        ]}
                        control={control}
                        name='gender'
                        helperText={errors.gender && errors.gender.message}
                        onBlur={() => triggerValidation('gender')}
                        fullWidth
                      />
                    </Box>
                    <Box className={classes.inputBox}>
                      <Controller
                        control={control}
                        name='name'
                        error={!!errors.name}
                        helperText={errors.name && errors.name.message}
                        label='Nombre'
                        onBlur={() => triggerValidation('name')}
                        fullWidth
                        as={<TextField />}
                      />
                    </Box>
                    <Box className={classes.inputBox}>
                      <Controller
                        control={control}
                        name='lastName'
                        error={!!errors.lastName}
                        helperText={errors.lastName && errors.lastName.message}
                        label='Apellido'
                        onBlur={() => triggerValidation('lastName')}
                        fullWidth
                        as={<TextField />}
                      />
                    </Box>
                    <Box className={classes.inputBox}>
                      <Controller
                        control={control}
                        name='phone'
                        error={!!errors.phone}
                        helperText={errors.phone && errors.phone.message}
                        label='Teléfono'
                        onBlur={() => triggerValidation('phone')}
                        fullWidth
                        onCountrySelected={(country) =>
                          setPhoneCountry(country)
                        }
                        as={<PhoneField />}
                      />
                    </Box>
                    {error && (
                      <Box mt={3}>
                        <Alert severity='error'>
                          <AlertTitle>Error al crear el paciente</AlertTitle>
                          El email ya existe
                        </Alert>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className={classes.inputBox}>
                      Seleccione los motivos de consulta:
                      {appointmentReasons.map((reason) => (
                        <Box key={reason.id}>
                          <FormControlLabel
                            label={reason.name}
                            control={
                              <Checkbox
                                checked={R.includes(reason.id, selectedReasons)}
                                onChange={(e) =>
                                  e.target.checked
                                    ? setSelectedReasons(
                                        R.append(reason.id, selectedReasons)
                                      )
                                    : setSelectedReasons(
                                        R.without([reason.id], selectedReasons)
                                      )
                                }
                                name='selectedReasons'
                              />
                            }
                          />
                        </Box>
                      ))}
                    </Box>
                    <Box className={classes.inputBox}>
                      <TextField
                        label='Otro Motivo de consulta'
                        multiline
                        rows={5}
                        cols={10}
                        onChange={(e) => setOtherReason(e.target.value)}
                        value={otherReason}
                        variant='outlined'
                        fullWidth
                      />
                    </Box>
                    {assistantMustAttachCoc && (
                      <>
                        <Box className={classes.inputBox}>
                          <FormLabel>Certificado de Cobertura</FormLabel>
                          <Box>
                            {!watch('coc') ? (
                              <LoadingButton
                                variant='outlined'
                                color='primary'
                                loading={fileLoading}
                                onClick={() => fileInputRef.current.click()}
                              >
                                <AttachFile /> Adjuntar
                              </LoadingButton>
                            ) : (
                              <>
                                Archivo Subido.{' '}
                                <Button
                                  variant='outlined'
                                  onClick={() => setValue('coc', null)}
                                >
                                  Remover
                                </Button>
                              </>
                            )}
                          </Box>
                          {errors.coc && (
                            <Typography color='error'>
                              {errors.coc.message}
                            </Typography>
                          )}
                        </Box>
                        <Box className={classes.fileInput}>
                          <TextField
                            name='file'
                            type='file'
                            onChange={handleUpload}
                            inputProps={{
                              ref: fileInputRef,
                            }}
                          />
                        </Box>
                      </>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  onClick={() => onClose({ refresh: false })}
                >
                  Volver
                </Button>
                <LoadingButton
                  color='primary'
                  variant='contained'
                  type='submit'
                  loading={loading}
                  disabled={R.isEmpty(selectedReasons) && !otherReason}
                >
                  Crear Turno
                </LoadingButton>
              </DialogActions>
            </>
          )}
        </form>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='success'>
          TURNO CREADO EXITOSAMENTE
        </Alert>
      </Snackbar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getAppointmentsLoading(state) || getSchedulesLoading(state),
    appointments: getAppointments(state),
    reasons: getReasons(state),
    fileLoading: getFileLoading(state),
    downloadFile: getDownloadFile(state),
    specialties: getSpecialties(state),
    providers: getProviders(state),
    schedules: getSchedules(state),
    patientSearch: getPatientSearch(state),
    patientLoading: getPatientLoading(state),
    appointmentReasons: getReasons(state),
    files: getFiles(state),
    uploadResponse: getUploadResponse(state),
    appointmentSuccess: getSuccess(state),
    deals: getDeals(state),
    patientError: getPatientError(state),
    company: getCompany(state),
  }
}

export default connect(mapStateToProps, {
  onRetrieveSpecialties: requestAssistantSpecialtiesGet,
  onRetrieveAppointments: requestAppointments,
  onRetrieveAppointmentReasons: requestAppointmentReasons,
  onFileDownload: fileDownloadGetRequest,
  onRetrieveSchedules: providerScheduleGetRequest,
  onPatientSearch: patientSearchRequest,
  onRequestUpload: fileUploadRequest,
  onResetFileUpload: fileUploadReset,
  onRequestFile: fileGetRequest,
  onCreateAppointment: createAppointment,
  onCreateAppointmentReset: resetCreateAppointment,
  onResetPatientSearch: resetPatientSearch,
  onGetDeals: dealsGetRequest,
})(LinkedNewAppointmentModal)
