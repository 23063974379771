import React, { useState, useEffect } from 'react'
import * as R from 'ramda'
import { Route, useLocation, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import getCurrentUser from 'common/utils/getCurrentUser'
import { getCurrentProvider } from 'common/reducers/providers'
import { getCoverages } from 'common/reducers/coverages'
import { getFiles } from 'common/reducers/files'
import {
  providerCurrentGetRequest,
  coveragesGetRequest,
  fileGetRequest,
} from 'common/actions'

import { companyGetRequest } from 'common/actions'
import { getCompany } from 'common/reducers/companies'
import { getToken, subscribeToCompanyTopic } from 'common/utils/firebase'

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({
  children,
  requiresWizard,
  retrieveProvider,
  currentProvider,
  coverages,
  requestCoverages,
  retrieveFile,
  files,
  company,
  retrieveCompany,
  ...rest
}) => {
  const [isAuth, setIsAuth] = useState(true)
  const [loggedUser, setLoggedUser] = useState(null)
  const location = useLocation()

  useEffect(() => {
    if (!coverages?.length) requestCoverages()
  }, [children])

  useEffect(() => {
    const getUser = async () => {
      const user = await getCurrentUser()
      if (!user || !R.includes('assistant', user.groups)) {
        setIsAuth(false)
        return
      }
      setLoggedUser(user)
    }
    getUser()
  }, [location])

  useEffect(() => {
    if (company) {
      getToken().then((token) => subscribeToCompanyTopic(token, company.id))
      if (!company.canCreateAssistant) setIsAuth(false)
      return
    }
    retrieveCompany()
  }, [company])

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth
          ? React.cloneElement(children, { company })
          : (window.location = `${window.location.origin}/login`)
      }
    />
  )
}
PrivateRoute.defaultProps = {
  requiresWizard: true,
}

const mapStateToProps = (state) => ({
  currentProvider: getCurrentProvider(state),
  coverages: getCoverages(state),
  files: getFiles(state),
  company: getCompany(state),
})

export default connect(mapStateToProps, {
  retrieveProvider: providerCurrentGetRequest,
  requestCoverages: coveragesGetRequest,
  retrieveFile: fileGetRequest,
  retrieveCompany: companyGetRequest,
})(PrivateRoute)
