import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import dayjs from 'dayjs'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction' // for selectable
import timeGridPlugin from '@fullcalendar/timegrid'
import esLocale from '@fullcalendar/core/locales/es'

import {
  Box,
  Grid,
  Link,
  Button,
  Popover,
  Container,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ScheduleSlotModal from './scheduleSlotModal'
import { Header } from '../layout'
import { LoadingButton } from 'common/components'

import {
  requestAppointments,
  providerScheduleMultipleCreateRequest,
  providerScheduleGetRequest,
  requestSpecialtiesGet,
  providerScheduleDeleteRequest,
} from 'common/actions'
import { getAppointments } from 'common/reducers/appointment'
import { getLoading, getSuccess, getSchedules } from 'common/reducers/providers'
import { getSpecialties } from 'common/reducers/specialties'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  backBtn: {
    marginRight: theme.spacing(2),
  },
  selectedEventPopover: {
    padding: theme.spacing(3),
  },
  inputBox: {
    marginBottom: theme.spacing(3),
  },
  eventDuration: {
    marginTop: theme.spacing(1),
  },
  title: {
    flex: '1',
  },
  appointment: {
    backgroundColor: 'red',
    color: '#fff',
    textAlign: 'center',
    width: '100%',
    cursor: 'not-allowed',
  },
  schedule: {
    cursor: 'pointer',
    width: '100%',
    textAlign: 'center',
  },
  removedSchedule: {
    cursor: 'not-allowed',
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'yellow',
  },
}))

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone


const Schedule = ({
  provider,
  createMultipleSchedule,
  onRetrieveSchedules,
  loading,
  success,
  specialties,
  onRequestSpecialties,
  deleteSchedules,
  schedules,
  appointments,
  onRetrieveAppointments,
}) => {
  const classes = useStyles()
  const calendarRef = useRef()
  const [events, setEvents] = useState([])
  const [selectedEventId, setSelectedEventId] = useState(null)
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false)
  const [selectedInfo, setSelectedInfo] = useState(null)
  const [initialDates, setInitialDates] = useState(null)
  const [lastDates, setLastDates] = useState(null)
  const [loadedAppointments, setLoadedAppointments] = useState({})

  const providerTimezone = provider?.timezone || browserTimezone

  useEffect(() => {
    if (!schedules?.length || !specialties?.length) return

    const loadedSchedules = R.reduce(
      (acc, val) => {
        return {
          ...acc,
          [val.id]: val,
        }
      },
      {},
      R.filter((p) => !p.appointment && !p.removed, events)
    )

    setEvents(
      R.flatten([
        ...events,
        R.filter((s) => !loadedSchedules[s.timestamp], schedules).map(
          (s, i) => {
            return {
              id: s.timestamp,
              duration: s['length'],
              start: dayjs(s.timestamp * 1000)
                .tz(providerTimezone)
                .format(),
              end: dayjs(s.timestamp * 1000)
                .tz(providerTimezone)
                .add(s['length'], 'minutes')
                .format(),
              display: 'background',
              specialties: s.specialties.map((specialty) =>
                R.find((sp) => R.equals(sp.id, specialty), specialties || [])
              ),
              exists: true,
            }
          }
        ),
      ])
    )
  }, [schedules, specialties])

  useEffect(() => {
    if (!R.valuesIn(appointments)?.length) return
    setEvents(
      R.flatten([
        ...events,
        R.filter(
          (a) => !loadedAppointments[a.timestamp],
          R.valuesIn(appointments)
        ).map((a) => ({
          start: dayjs(a.timestamp * 1000)
            .tz(providerTimezone)
            .format(),
          end: dayjs(a.timestamp * 1000)
            .tz(providerTimezone)
            .add(a['length'], 'minutes')
            .format(),
          id: a.timestamp,
          duration: a['length'],
          appointment: true,
          display: 'background',
        })),
      ])
    )

    setLoadedAppointments(appointments)
  }, [appointments])

  useEffect(() => {
    if (!provider) return
    onRetrieveSchedules({ providerId: provider.id })
    onRequestSpecialties('all')
    if (initialDates) {
      setLastDates(null)
      refreshDate(initialDates.fromDate, initialDates.toDate)
      setInitialDates(null)
    }
  }, [provider])

  useEffect(() => {
    if (!success) return
    // for refreshing after saving
    setEvents(
      R.filter((e) => (e.exists && !e.removed) || e.appointment, events)
    )
    onRetrieveSchedules({ providerId: provider.id })
  }, [success])

  const refreshDate = (fromDate, toDate) => {
    if (!provider && !initialDates) {
      setInitialDates({ fromDate, toDate })
      return
    }
    if (
      !provider ||
      (R.equals(lastDates?.fromDate, fromDate) &&
        R.equals(lastDates?.toDate, toDate))
    )
      return
    onRetrieveAppointments({
      providerId: provider.id,
      from: fromDate,
      to: toDate,
    })
    setLastDates({ fromDate, toDate })
  }

  const onSave = () => {
    setSelectedEventId(null)
    const newOnes = R.filter((s) => !s.exists && !s.appointment, events)

    if (newOnes.length)
      createMultipleSchedule({
        providerId: provider.id,
        schedules: newOnes.map((s) => ({
          fromDatetime: dayjs.tz(s.start, providerTimezone).utc().format(),
          toDatetime: dayjs.tz(s.end, providerTimezone).utc().format(),
          length: s.duration,
          specialties: s.specialties.map((specialty) => specialty.id),
        })),
      })

    const deletedOnes = R.filter((s) => s.removed, events)
    if (deletedOnes.length)
      deleteSchedules({
        providerId: provider.id,
        schedules: deletedOnes.map((d) => d.id),
      })
  }

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
  }, [])

  const nextId = () =>
    R.reduce((acc, value) => (value.id > acc ? value.id : acc), 0, events) + 1

  const updateEvent = (data) => {
    const index = R.findIndex((s) =>
      R.equals(parseInt(data.id), parseInt(s.id))
    )(events)

    setEvents(
      R.update(
        index,
        {
          start: data.startStr || data.start,
          end: data.endStr || data.end,
          specialties: data.specialties || events[index].specialties,
          duration: data.duration || events[index].duration,
          id: events[index].id,
          ...data,
        },
        events
      )
    )
  }

  const removeEvent = (data) => {
    setEvents(
      R.remove(
        R.findIndex((s) => R.equals(parseInt(selectedEventId), parseInt(s.id)))(
          events
        ),
        1,
        events
      )
    )
    setSelectedEventId(null)
  }

  const addEvent = (data) =>
    setEvents(
      R.append(
        {
          ...data,
          id: nextId(),
        },
        events
      )
    )

  if (selectedInfo) {
    const sameDayEvents = R.filter(
      (e) => dayjs(e.start).isSame(selectedInfo.end, 'day'),
      events
    )
    const minimumDurationEvent = R.filter(
      (sde) => dayjs(selectedInfo.end).isSameOrBefore(sde.start),
      sameDayEvents
    ).map((e) => dayjs(selectedInfo.end).diff(e.start, 'minutes'))

    const minimumDurationEventTime = R.reduce(
      R.max,
      -Infinity,
      minimumDurationEvent
    )
    selectedInfo.maximumDuration = 30 + minimumDurationEventTime * -1
  }

  return (
    <>
      <Header />
      <Container className={classes.container}>
        <Box mb={3} display='flex' alignItems='center'>
          <Typography variant='h4' className={classes.title}>
            Mis Horarios
          </Typography>
          <LoadingButton
            disabled={!events.length}
            variant='contained'
            color='primary'
            onClick={() => onSave()}
            loading={loading}
          >
            GUARDAR CAMBIOS
          </LoadingButton>
        </Box>
        <Grid container>
          <ScheduleSlotModal
            isOpen={scheduleModalOpen}
            provider={provider}
            onSubmit={(data) => {
              if (selectedEventId) updateEvent({ ...data, id: selectedEventId })
              else addEvent(data)
              setSelectedEventId(null)
              setScheduleModalOpen(false)
            }}
            onClose={() => {
              setSelectedEventId(null)
              setScheduleModalOpen(false)
            }}
            specialties={R.filter(
              (s) => R.includes(s.id, provider?.specialties),
              specialties || []
            )}
            schedule={
              selectedEventId &&
              R.find((s) =>
                R.equals(parseInt(selectedEventId), parseInt(s.id))
              )(events)
            }
            onDelete={() => {
              const schedule = R.find((s) =>
                R.equals(parseInt(selectedEventId), parseInt(s.id))
              )(events)
              if (schedule.exists) updateEvent({ ...schedule, removed: true })
              else removeEvent()
              setScheduleModalOpen(false)
            }}
            info={selectedInfo}
          />
          <Grid item xs={12}>
            <FullCalendar
              timeZone={providerTimezone}
              locale={esLocale}
              headerToolbar={{
                left: 'prev,today,next',
                center: 'title',
                right: '',
              }}
              datesSet={(info) => refreshDate(info.startStr, info.endStr)}
              events={events}
              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
              initialView='timeGridWeek'
              selectable
              ref={calendarRef}
              allDaySlot={false}
              scrollTime='08:00:00'
              selectAllow={(info) => {
                return dayjs(dayjs(info.startStr).$d).isAfter(
                  dayjs().tz(providerTimezone).$d
                )
              }}
              select={(info) => {
                setSelectedEventId(null)
                setSelectedInfo({ ...info, dontAddTimezone: true })
                setScheduleModalOpen(true)
              }}
              eventClick={(info) => {
                const currentEvent = R.find(
                  (s) => R.equals(parseInt(s.id), parseInt(info.event.id)),
                  events
                )
                if (currentEvent.appointment || currentEvent.removed) return
                setSelectedEventId(info.event.id)
                setSelectedInfo(info.event)
                setScheduleModalOpen(true)
              }}
              editable
              selectOverlap={false}
              eventOverlap={false}
              eventChange={(info) => updateEvent(info.event)}
              eventResize={(info) => updateEvent(info.event)}
              eventContent={({ event }) => {
                if (!event) return

                const currentEvent = R.find(
                  (s) => R.equals(parseInt(s.id), parseInt(event.id)),
                  events
                )
                if (!currentEvent) return

                if (currentEvent.removed) {
                  return <Box className={classes.removedSchedule}>Removido</Box>
                }

                if (currentEvent.exists) {
                  return <Box className={classes.schedule}>Disponible</Box>
                }
                if (currentEvent.appointment)
                  return (
                    <Box className={classes.appointment}>No Disponible</Box>
                  )

                return (
                  <Box>
                    {currentEvent.specialties.map((s) => (
                      <Box key={s.id} fontWeight='600'>
                        - {s.name}
                      </Box>
                    ))}
                    <Box className={classes.eventDuration}>
                      Turnos de {currentEvent.duration} minutos
                    </Box>
                  </Box>
                )
              }}
            />
            <Box mt={4} textAlign='center' position='relative'>
              <LoadingButton
                disabled={!events.length}
                variant='contained'
                color='primary'
                onClick={() => onSave()}
                loading={loading}
              >
                GUARDAR CAMBIOS
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: getLoading(state),
    success: getSuccess(state),
    schedules: getSchedules(state),
    specialties: getSpecialties(state),
    appointments: getAppointments(state),
  }
}

export default connect(mapStateToProps, {
  createMultipleSchedule: providerScheduleMultipleCreateRequest,
  onRetrieveSchedules: providerScheduleGetRequest,
  onRequestSpecialties: requestSpecialtiesGet,
  deleteSchedules: providerScheduleDeleteRequest,
  onRetrieveAppointments: requestAppointments,
})(Schedule)
