import {
  getMessaging,
  onMessage,
  getToken as getTokenFb,
} from 'firebase/messaging'
import axios from 'axios'

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

const getToken = () => {
  const messaging = getMessaging()
  return new Promise((resolve, reject) => {
    getTokenFb(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_PRIVATE_KEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken)
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.'
          )
          // ...
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
        reject()
      })
  })
}

const onNotificationReceived = () => {
  return new Promise((resolve) => {
    const messaging = getMessaging()
    onMessage(messaging, (payload) => {
      console.log(payload)
      resolve(payload)
    })

    // const broadcast_channel = new BroadcastChannel('broadcast_telemeditar')
    // broadcast_channel.onmessage = (event) => {
    // console.log('Broadcast event', event)
    // resolve({ title: event.data.title, body: event.data.options.body })
    // }
    // broadcast_channel.close()
  })
}

const subscribeToCompanyTopic = (token, companyId) => {
  const topicURL = `https://iid.googleapis.com/iid/v1/${token}/rel/topics/COMPANY_${companyId}`
  // const topicURL = `https://iid.googleapis.com/iid/v1/${token}/rel/topics/Testing`
  axios
    .post(
      topicURL,
      {},
      {
        headers: {
          Authorization: `key=${process.env.REACT_APP_FIREBASE_PUBLIC_KEY}`,
        },
      }
    )
    .then((response) => {
      console.log('Successfully subscribed to topic:', response)
    })
    .catch((error) => {
      console.log('Error subscribing to topic:', error)
    })
}

const unsubscribeFromCompanyTopic = (token, companyId) => {
  return new Promise((resolve) => {
    const topicURL = `https://iid.googleapis.com/iid/v1/${token}/rel/topics/COMPANY_${companyId}`
    // const topicURL = `https://iid.googleapis.com/iid/v1/${token}/rel/topics/Testing`
    axios
      .delete(topicURL, {
        headers: {
          Authorization: `key=${process.env.REACT_APP_FIREBASE_PUBLIC_KEY}`,
        },
      })
      .then((response) => {
        console.log('Successfully unsubscribed to topic:', response)
      })
      .catch((error) => {
        console.log('Error unsubscribing to topic:', error)
      })
      .finally(() => resolve())
  })
}

export {
  getToken,
  onNotificationReceived,
  subscribeToCompanyTopic,
  unsubscribeFromCompanyTopic,
}
