import { useEffect } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { ER_Header } from 'common/utils/constants'
import { getClientResponse } from 'common/reducers/pubsub'

const AppointmentStart = ({ clientResponse }) => {
  useEffect(() => {
    if (!clientResponse) return
    const { header, timestamp } = clientResponse
    if (R.equals(header, ER_Header.APPOINTMENT_NEW)) {
      window.location.href = `/providers/videocall/${timestamp}`
    }
  }, [clientResponse])

  return null
}

const mapStateToProps = (state) => {
  return {
    clientResponse: getClientResponse(state),
  }
}

export default connect(mapStateToProps, {})(AppointmentStart)
