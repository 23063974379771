import * as ActionTypes from '../actions'

const initialState = {
  specialties: null,
  providers: null,
  loading: false,
  error: null,
  success: false,
}

export function assistantReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.ASSISTANT_SPECIALTIES_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        specialties: [],
        professionals: [],
      }
    case ActionTypes.ASSISTANT_SPECIALTIES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        specialties: action.data.specialties,
        providers: action.data.providers,
      }
    case ActionTypes.ASSISTANT_SPECIALTIES_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export function getSuccess(state) {
  return state.assistantReducer.success
}

export function getSpecialties(state) {
  return state.assistantReducer.specialties
}

export function getProviders(state) {
  return state.assistantReducer.providers
}

export function getLoading(state) {
  return state.assistantReducer.loading
}

export function getError(state) {
  return state.assistantReducer.error
}
