import * as ActionTypes from '../actions'

const initialState = {
  coverages: null,
  loading: false,
  error: null,
}

export function coveragesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.COVERAGE_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        coverages: [],
      }
    case ActionTypes.COVERAGE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        coverages: action.data.items,
      }
    case ActionTypes.COVERAGE_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    default:
      return state
  }
}

export function getCoverages(state) {
  return state.coveragesReducer.coverages
}

export function getLoading(state) {
  return state.coveragesReducer.loading
}

export function getError(state) {
  return state.coveragesReducer.error
}
