import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Link,
  Button,
  CircularProgress,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import { useHistory } from 'react-router-dom'
import { Header } from '../layout'


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  loginCard: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  loginCardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const Dashboard = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box>
      <Header />
      <Grid container className={classes.container}>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          Bienvenido/a al panel de administrador
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}

export default connect(mapStateToProps, {  })(Dashboard)
