import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Snackbar,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { cancelAppointment, resetCancelAppointment } from 'common/actions'
import theme from 'common/theme'

import { getError, getLoading, getSuccess } from 'common/reducers/appointment'
import { getCurrentProvider } from 'common/reducers/providers'

import { LoadingButton } from 'common/components'

const useStyles = makeStyles((theme) => ({
  inputBox: {
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  fileInput: {
    display: 'none',
  },
}))

const CancelAppointmentModal = ({
  onClose,
  loading,
  isOpen,
  appointment,
  onCancelAppointment,
  onResetCancelAppointment,
  success,
  provider,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  useEffect(() => {
    onResetCancelAppointment()
  }, [isOpen])

  useEffect(() => {
    if (!success || !isOpen) return
    setSnackbarOpen(true)
    onClose({ refresh: true })
  }, [success])

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        {appointment && (
          <>
            <DialogTitle id='alert-dialog-title'>
              Cancelación de Turno
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                ¿Está seguro que desea cancelar el siguiente turno?
              </DialogContentText>
              <DialogContentText>
                Fecha y Hora:{' '}
                {dayjs(appointment.timestamp * 1000)
                  .tz(provider?.timezone)
                  .format('DD/MM/YYYY HH:mm')}
                <br />
                Paciente:{' '}
                <Typography fontWeight='600' component='span'>
                  {appointment.patient &&
                    `${appointment.patient.name} ${appointment.patient.lastName}`}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                style={theme.danger}
                onClick={() => {
                  onCancelAppointment({
                    timestamp: appointment.timestamp,
                    providerId: appointment.provider,
                  })
                }}
                loading={loading}
              >
                Si, cancelar turno
              </LoadingButton>
              <Button color='primary' variant='contained' onClick={onClose}>
                Cerrar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity='info'>
          TURNO CANCELADO
        </Alert>
      </Snackbar>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    success: getSuccess(state),
    loading: getLoading(state),
    error: getError(state),
    provider: getCurrentProvider(state),
  }
}

export default connect(mapStateToProps, {
  onCancelAppointment: cancelAppointment,
  onResetCancelAppointment: resetCancelAppointment,
})(CancelAppointmentModal)
