import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem, TextField } from '@material-ui/core'
import { Controller } from 'react-hook-form'

export default function SelectField({ options, ...props }) {
  return (
    <Controller
      {...props}
      as={
        <TextField select>
          {options.map((opt) => (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </TextField>
      }
    />
  )
}

SelectField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    })
  ).isRequired,
}
