import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import dayjs from 'dayjs'

import {
  Box,
  Grid,
  IconButton,
  Typography,
  Container,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Link,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { makeStyles } from '@material-ui/core/styles'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction' // for selectable
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import esLocale from '@fullcalendar/core/locales/es'

import { useHistory, useLocation } from 'react-router-dom'
import { Header } from '../layout'
import theme from 'common/theme'
import {
  requestAppointments,
  requestAppointmentReasons,
  fileDownloadGetRequest,
  requestAssistantSpecialtiesGet,
  providerScheduleGetRequest,
  patientSearchRequest,
} from 'common/actions'
import {
  getError,
  getLoading as getAppointmentsLoading,
  getAppointments,
  getReasons,
} from 'common/reducers/appointment'
import {
  getLoading as getFileLoading,
  getDownloadFile,
} from 'common/reducers/files'
import { getSpecialties, getProviders } from 'common/reducers/assistant'

import {
  appointmentStatusStrings,
  cancellableAppointment,
} from 'common/utils/appointmentStatus'
import {
  getLoading as getSchedulesLoading,
  getSchedules,
} from 'common/reducers/providers'
import LinkedNewAppointmentModal from './linkedNewAppointmentModal'
import NewAppointmentModal from './newAppointmentModal'
import downloadFile from 'common/utils/downloadFile'
import NewPatientDrawer from './newPatientDrawer'
import CancelAppointmentModal from './cancelAppointmentModal'
import { onNotificationReceived } from 'common/utils/firebase'
import { NotificationTypes } from 'common/utils/constants'
import { getHardCodedCountries } from 'common/utils/timezones'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  dateBoxTitle: {
    marginRight: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: '#F1F0F2',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  },
  item: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  toggleBox: {
    textAlign: 'right',
  },
  leftContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
  drawerContainer: {
    padding: theme.spacing(3),
  },
  drawerTitle: {
    paddingRight: theme.spacing(6),
  },
  closeDrawerButton: {
    position: 'absolute',
    right: '0.5rem',
    top: '1rem',
  },
  timeAvatar: {
    fontWeight: 600,
    fontSize: '1.25rem',
    paddingRight: theme.spacing(2),
    alignItems: 'flex-start',
  },
}))

const appointmentTypes = {
  traditional: 'traditional',
  linked: 'linked',
}

const Appointments = ({
  error,
  loading,
  appointments,
  onRetrieveAppointments,
  schedules,
  onRetrieveSchedules,
  onRetrieveAppointmentReasons,
  onRetrieveSpecialties,
  specialties,
  providers,
  reasons,
  onFileDownload,
  fileToDownload,
  fileLoading,
  company,
}) => {
  const calendarRef = useRef()
  const classes = useStyles()
  const [events, setEvents] = useState({})
  const [newSearch, setNewSearch] = useState(true)

  const [cancelAppointment, setCancelAppointment] = useState(null)
  const [selectedAppointment, setSelectedAppointment] = useState(null)
  const [selectedSchedule, setSelectedSchedule] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState(null)

  const [currentDates, setCurrentDates] = useState(null)

  const [selectedSpecialty, setSelectedSpecialty] = useState(null)
  const [selectedProviders, setSelectedProviders] = useState([])
  const [appointmentType, setAppointmentType] = useState('appointments')

  const [newPatientDrawer, setNewPatientDrawer] = useState()
  const [defaultPatient, setDefaultPatient] = useState(null)

  const [newAppointmentType, setNewAppointmentType] = useState(null)

  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedProvince, setSelectedProvince] = useState(null)

  const countries = getHardCodedCountries()

  useEffect(() => {
    if (!fileToDownload) return
    downloadFile(fileToDownload)
  }, [fileToDownload])

  useEffect(() => {
    if (!reasons) onRetrieveAppointmentReasons()
    onRetrieveSpecialties()
  }, [])

  useEffect(() => {
    setEvents({})
  }, [specialties])

  const refreshDate = (fromDate, toDate) => {
    setEvents({})
    setCurrentDates({ fromDate, toDate })
    if (!selectedSpecialty || !selectedProviders.length) return
    if (R.equals(appointmentType, 'appointments'))
      onRetrieveAppointments({
        entity: 'assistant',
        from: fromDate,
        to: toDate,
        specialty: selectedSpecialty.id,
        providers: selectedProviders.map((p) => p.id),
      })
    else
      onRetrieveSchedules({
        from: fromDate,
        to: toDate,
        specialty: selectedSpecialty.id,
        providers: selectedProviders.map((p) => p.id),
      })
  }

  useEffect(() => {
    const subscribeToNotification = async () => {
      const notification = await onNotificationReceived()
      if (
        R.equals(
          notification.data.notificationType,
          NotificationTypes.NEW_APPOINTMENT
        ) &&
        currentDates
      ) {
        const { fromDate, toDate } = currentDates
        refreshDate(fromDate, toDate)
      }
      subscribeToNotification()
    }

    subscribeToNotification()
  }, [currentDates])

  useEffect(() => {
    if (!R.valuesIn(appointments)?.length) return

    const groupedEventsObj = R.reduce(
      (acc, val) => {
        const id = dayjs
          .utc(val.timestamp * 1000)
          .minute(0)
          .unix()

        let exists = acc[id]
        if (exists) exists.appointments.push(val)
        else
          exists = {
            start: dayjs
              .utc(val.timestamp * 1000)
              .minute(0)
              .tz(selectedProvince?.timezone)
              .format(),
            end: dayjs
              .utc(val.timestamp * 1000)
              .minute(60)
              .tz(selectedProvince?.timezone)
              .format(),
            id,
            appointments: [val],
          }
        return { ...acc, [id]: exists }
      },
      {},
      R.valuesIn(appointments)
    )

    setEvents(groupedEventsObj)
  }, [appointments])

  useEffect(() => {
    if (!schedules?.length) return

    const groupedEventsObj = R.reduce(
      (acc, val) => {
        const id = dayjs
          .utc(val.timestamp * 1000)
          .minute(0)
          .unix()
        let exists = acc[id]
        if (exists) exists.schedules.push(val)
        else
          exists = {
            start: dayjs
              .utc(val.timestamp * 1000)
              .minute(0)
              .tz(selectedProvince?.timezone)
              .format(),
            end: dayjs
              .utc(val.timestamp * 1000)
              .minute(60)
              .tz(selectedProvince?.timezone)
              .format(),
            id,
            schedules: [val],
          }
        return { ...acc, [id]: exists }
      },
      {},
      schedules
    )

    setEvents(groupedEventsObj)
  }, [schedules])

  return (
    <>
      <Header />
      <Box className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography variant='h5'>Filtros</Typography>
            <Box className={classes.leftContainer}>
              {company?.assistantCanSelectCountry ? (
                <>
                  <Typography variant='h6' className={classes.marginTop3}>
                    Región
                  </Typography>
                  <Box>
                    <Autocomplete
                      id='selectedCountry'
                      options={countries}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      value={selectedCountry}
                      onChange={(e, v) => {
                        setSelectedCountry(v)
                        setSelectedProvince(null)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='selectedCountry'
                          {...params}
                          label='País de Atención'
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                  <Box className={classes.marginTop3}>
                    <Autocomplete
                      disabled={!selectedCountry}
                      id='selectedProvince'
                      options={R.sortBy(
                        R.prop('name'),
                        selectedCountry?.provinces || []
                      )}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      value={selectedProvince}
                      onChange={(e, v) => {
                        setSelectedProvince(v)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='selectedProvince'
                          {...params}
                          label='Provincia'
                          fullWidth
                        />
                      )}
                    />
                  </Box>
                </>
              ) : (
                ''
              )}
              <Typography
                variant='h6'
                className={
                  company?.assistantCanSelectCountry ? classes.marginTop3 : null
                }
              >
                Turnos
              </Typography>
              <Box>
                <FormControl component='fieldset'>
                  <RadioGroup
                    aria-label='gender'
                    name='gender1'
                    value={appointmentType}
                    onChange={(e) => setAppointmentType(e.target.value)}
                  >
                    <FormControlLabel
                      value='schedules'
                      control={<Radio />}
                      label='Disponibles'
                    />
                    <FormControlLabel
                      value='appointments'
                      control={<Radio />}
                      label='Ocupados'
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box>
                {!!specialties?.length && (
                  <Autocomplete
                    id='selectedSpecialty'
                    options={R.filter(
                      (s) =>
                        !selectedProviders.length ||
                        R.any(
                          (p) => R.includes(s.id, p.specialties),
                          selectedProviders
                        ),
                      specialties
                    )}
                    disabled={!newSearch}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    value={selectedSpecialty}
                    onChange={(e, v) => {
                      setSelectedSpecialty(v)
                      setNewSearch(false)
                      if (v && !selectedProviders.length)
                        setSelectedProviders(
                          R.filter(
                            (p) => R.includes(v.id, p.specialties),
                            providers
                          )
                        )
                      else if (!v) setSelectedProviders([])
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='selectedSpecialty'
                        {...params}
                        label='Especialidad'
                        fullWidth
                      />
                    )}
                  />
                )}
              </Box>
              <Box className={classes.marginTop3}>
                {!!providers?.length && (
                  <Autocomplete
                    multiple
                    id='providers'
                    options={R.filter(
                      (p) =>
                        !selectedSpecialty ||
                        R.includes(selectedSpecialty.id, p.specialties),
                      providers
                    )}
                    getOptionLabel={(option) =>
                      `${option.title} ${option.name} ${option.lastName}`
                    }
                    filterSelectedOptions
                    disableCloseOnSelect
                    value={selectedProviders}
                    onChange={(e, v) => {
                      setSelectedProviders(v)
                    }}
                    renderInput={(params) => (
                      <TextField
                        name='providers'
                        {...params}
                        label='Profesionales'
                        fullWidth
                      />
                    )}
                  />
                )}
              </Box>
              <Box className={classes.marginTop3}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() =>
                    refreshDate(currentDates.fromDate, currentDates.toDate)
                  }
                  disabled={
                    loading ||
                    !selectedSpecialty ||
                    !selectedProviders.length ||
                    (company?.assistantCanSelectCountry && !selectedProvince)
                  }
                >
                  Buscar
                </Button>
                <Button
                  color='primary'
                  disabled={!selectedSpecialty && !selectedProviders.length}
                  onClick={() => {
                    setSelectedSpecialty(null)
                    setSelectedProviders([])
                    setNewSearch(true)
                  }}
                >
                  Nueva Búsqueda
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            {loading && <CircularProgress />}
            <FullCalendar
              ref={calendarRef}
              datesSet={(info) => refreshDate(info.startStr, info.endStr)}
              customButtons={{
                refreshButton: {
                  text: 'Refrescar',
                  click: () =>
                    refreshDate(currentDates.fromDate, currentDates.toDate),
                  disabled: !selectedSpecialty || !selectedProviders.length,
                },
              }}
              timeZone={selectedProvince?.timezone}
              locale={esLocale}
              slotDuration='00:30:00'
              headerToolbar={{
                left: 'prev,today,next',
                center: 'title',
                right: '', //'refreshButton',
              }}
              plugins={[
                listPlugin,
                dayGridPlugin,
                interactionPlugin,
                timeGridPlugin,
              ]}
              buttonText={{
                today: 'Hoy',
                month: 'Mes',
                week: 'Semana',
                day: 'Dia',
                list: 'Lista',
              }}
              // nowIndicator
              initialView='timeGridWeek'
              allDaySlot={false}
              scrollTime='08:00:00'
              events={R.valuesIn(events)}
              eventClick={(info) => {
                if (
                  (
                    events[info.event.id].appointments ||
                    events[info.event.id].schedules
                  ).length === 1
                ) {
                  if (events[info.event.id].appointments)
                    setSelectedAppointment(
                      R.head(events[info.event.id].appointments)
                    )
                  if (events[info.event.id].schedules) {
                    setSelectedSchedule(R.head(events[info.event.id].schedules))
                    if (company.assistantLinkAppointmentOnly)
                      setNewAppointmentType(appointmentTypes.linked)
                  }
                } else setSelectedEvent(events[info.event.id])
              }}
              eventContent={({ event }) => {
                if (
                  !event ||
                  (!R.valuesIn(appointments)?.length && !schedules?.length)
                )
                  return

                const eventObj = R.clone(events[event.id])

                const allEvents = eventObj.appointments || eventObj.schedules
                const firstEvent = R.head(allEvents)

                if (allEvents.length === 1) {
                  eventObj.start = dayjs
                    .utc(firstEvent.timestamp * 1000)
                    .format()
                  eventObj.end = dayjs(eventObj.start)
                    .add(firstEvent['length'], 'minutes')
                    .format()
                }

                return (
                  <Box overflow='hidden'>
                    {dayjs(eventObj.start)
                      .tz(selectedProvince?.timezone)
                      .format('HH:mm')}{' '}
                    -
                    {dayjs(eventObj.end)
                      .tz(selectedProvince?.timezone)
                      .format('HH:mm')}
                    {allEvents.length === 1 ? (
                      <Box>
                        {firstEvent.patient ? (
                          <>
                            {firstEvent.patient.name}{' '}
                            {firstEvent.patient.lastName}
                          </>
                        ) : (
                          'Disponible'
                        )}
                      </Box>
                    ) : (
                      <Box fontWeight='600'>
                        {eventObj.schedules ? 'Libres' : 'Ocupados'}:{' '}
                        {allEvents.length}
                      </Box>
                    )}
                  </Box>
                )
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Drawer
        anchor='left'
        open={!!selectedEvent}
        onClose={() => setSelectedEvent(null)}
      >
        {selectedEvent ? (
          <Box className={classes.drawerContainer}>
            <IconButton
              className={classes.closeDrawerButton}
              onClick={() => setSelectedEvent(null)}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant='h4'
              color='primary'
              className={classes.drawerTitle}
            >
              Turnos del {dayjs(selectedEvent.start).format('dddd DD [de] MMM')}
            </Typography>
            <Box>
              <List>
                {selectedEvent.appointments &&
                  R.sortBy(R.prop('timestamp'), selectedEvent.appointments).map(
                    (a) => {
                      const provider = R.find(
                        R.propEq('id', a.provider),
                        providers
                      )
                      const start = dayjs
                        .utc(a.timestamp * 1000)
                        .tz(selectedProvince?.timezone)
                        .format()
                      const end = dayjs
                        .utc(a.timestamp * 1000)
                        .add(a['length'], 'minutes')
                        .tz(selectedProvince?.timezone)
                        .format()
                      return (
                        <ListItem
                          key={a.timestamp}
                          button
                          onClick={() => setSelectedAppointment(a)}
                        >
                          <Box className={classes.timeAvatar}>
                            {dayjs(start)
                              .tz(selectedProvince?.timezone)
                              .format('HH:mm')}
                          </Box>
                          <ListItemText
                            primary={`${a.patient.name} ${a.patient.lastName}`}
                            secondary={`${provider.title} ${provider.name} ${provider.lastName}`}
                          />
                          <ChevronRightIcon />
                        </ListItem>
                      )
                    }
                  )}
                {selectedEvent.schedules &&
                  R.sortBy(R.prop('timestamp'), selectedEvent.schedules).map(
                    (s) => {
                      const provider = R.find(
                        R.propEq('id', s.provider),
                        providers
                      )
                      const start = dayjs
                        .utc(s.timestamp * 1000)
                        .tz(selectedProvince?.timezone)
                        .format()
                      const end = dayjs
                        .utc(s.timestamp * 1000)
                        .add(s['length'], 'minutes')
                        .tz(selectedProvince?.timezone)
                        .format()
                      return (
                        <ListItem
                          key={`${s.timestamp}_${s.provider}`}
                          button
                          onClick={() => {
                            setSelectedSchedule(s)
                            if (company.assistantLinkAppointmentOnly)
                              setNewAppointmentType(appointmentTypes.linked)
                          }}
                        >
                          <Box className={classes.timeAvatar} color='primary'>
                            {dayjs(start)
                              .tz(selectedProvince?.timezone)
                              .format('HH:mm')}
                          </Box>
                          <ListItemText
                            primary={`TURNO DISPONIBLE`}
                            secondary={`${provider.title} ${provider.name} ${provider.lastName}`}
                          />
                          <ChevronRightIcon />
                        </ListItem>
                      )
                    }
                  )}
              </List>
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Drawer>
      <Dialog
        open={!!selectedAppointment}
        onClose={() => setSelectedAppointment(null)}
      >
        {selectedAppointment && (
          <>
            <DialogTitle id='alert-dialog-title'>
              Turno:{' '}
              {dayjs(selectedAppointment.timestamp * 1000)
                .tz(selectedProvince?.timezone)
                .format('DD/MM/YYYY HH:mm')}
            </DialogTitle>
            <DialogContent>
              {selectedAppointment.appointmentCode && (
                <DialogContentText>
                  Número de referencia: {selectedAppointment.appointmentCode}
                </DialogContentText>
              )}
              <DialogContentText>
                Estado del turno:{' '}
                {appointmentStatusStrings[selectedAppointment.status]}
              </DialogContentText>
              <DialogContentText>
                Paciente:{' '}
                <Typography fontWeight='600' component='span'>
                  {selectedAppointment.patient &&
                    `${selectedAppointment.patient.name} ${selectedAppointment.patient.lastName}`}
                </Typography>
              </DialogContentText>
              <DialogContentText>
                Profesional:{' '}
                <Typography fontWeight='600' component='span'>
                  {[
                    R.find(
                      R.propEq('id', selectedAppointment?.provider),
                      providers || []
                    ),
                  ].map((p) => `${p.title} ${p.name} ${p.lastName}`)}
                </Typography>
              </DialogContentText>
              {(selectedAppointment.reason ||
                selectedAppointment.reasonOther) && (
                <Box>
                  Motivo de consulta:{' '}
                  {selectedAppointment.reason?.map((r) => (
                    <Typography key={r}>
                      {R.find((ar) => R.equals(ar.id, r), reasons)?.name}
                    </Typography>
                  ))}
                  {selectedAppointment.reasonOther && (
                    <Typography>{selectedAppointment.reasonOther}</Typography>
                  )}
                </Box>
              )}
              {selectedAppointment.attachments?.length ? (
                <Box className={classes.marginTop}>
                  <Typography>Archivos adjuntos: </Typography>
                  {selectedAppointment.attachments.map((a, i) => (
                    <Link key={a.key} onClick={() => onFileDownload(a)}>
                      Adjunto {i + 1}
                    </Link>
                  ))}
                  {fileLoading && (
                    <Box className={classes.marginTop}>
                      Su descarga comenzará pronto...
                    </Box>
                  )}
                </Box>
              ) : (
                ''
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color='primary'
                variant='contained'
                onClick={() => setSelectedAppointment(null)}
              >
                Volver
              </Button>
              {cancellableAppointment(selectedAppointment.status) && (
                <Button
                  style={theme.danger}
                  onClick={() => {
                    setCancelAppointment(selectedAppointment)
                    setSelectedAppointment(null)
                  }}
                >
                  Cancelar Turno
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
      <NewPatientDrawer
        isOpen={!!newPatientDrawer}
        onClose={({ schedule, patient }) => {
          setNewPatientDrawer(null)
          if (schedule) {
            setNewAppointmentType(appointmentTypes.traditional)
            setSelectedSchedule(schedule)
          }
          if (patient) {
            setDefaultPatient(patient)
          }
        }}
        preloadPatient={newPatientDrawer}
        country={company?.country.id}
      />
      <NewAppointmentModal
        isOpen={R.equals(newAppointmentType, appointmentTypes.traditional)}
        selectedSchedule={selectedSchedule}
        selectedSpecialty={selectedSpecialty}
        defaultPatient={defaultPatient}
        selectedTimezone={selectedProvince?.timezone}
        selectedProvider={R.find(
          R.propEq('id', selectedSchedule?.provider),
          providers || []
        )}
        onClose={({ refresh, newPatient }) => {
          setDefaultPatient(null)
          if (refresh) {
            // refreshDate(currentDates.fromDate, currentDates.toDate)
            setSelectedEvent(null)
          }
          if (newPatient) {
            setNewPatientDrawer(newPatient)
            setSelectedEvent(null)
          }
          setSelectedSchedule(null)
          setNewAppointmentType(null)
        }}
      />
      <Dialog
        open={!!selectedSchedule && !newAppointmentType}
        onClose={() => {
          setSelectedSchedule(null)
          setNewAppointmentType(null)
        }}
      >
        <DialogTitle id='alert-dialog-title'>Nuevo Turno</DialogTitle>
        <DialogContent style={{ minWidth: '600px' }}>
          <DialogContentText>
            Por favor, seleccione el tipo de turno a crear:
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setNewAppointmentType(appointmentTypes.traditional)
            }}
            style={{ marginRight: '1rem' }}
          >
            Tradicional
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              setNewAppointmentType(appointmentTypes.linked)
            }}
          >
            Con enlace
          </Button>
        </DialogActions>
      </Dialog>
      <LinkedNewAppointmentModal
        selectedTimezone={selectedProvince?.timezone}
        selectedSpecialty={selectedSpecialty}
        selectedProvider={R.find(
          R.propEq('id', selectedSchedule?.provider),
          providers || []
        )}
        isOpen={R.equals(newAppointmentType, appointmentTypes.linked)}
        selectedSchedule={selectedSchedule}
        onClose={({ refresh, newPatient }) => {
          setDefaultPatient(null)
          if (refresh) {
            refreshDate(currentDates.fromDate, currentDates.toDate)
            setSelectedEvent(null)
          }
          setSelectedSchedule(null)
          setNewAppointmentType(null)
        }}
      />
      <CancelAppointmentModal
        isOpen={!!cancelAppointment}
        onClose={({ refresh }) => {
          setCancelAppointment(false)
          if (refresh) {
            refreshDate(currentDates.fromDate, currentDates.toDate)
            setSelectedEvent(null)
          }
        }}
        appointment={cancelAppointment}
        selectedProvider={R.find(
          R.propEq('id', cancelAppointment?.provider),
          providers || []
        )}
        selectedTimezone={selectedProvince?.timezone}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    loading: getAppointmentsLoading(state) || getSchedulesLoading(state),
    appointments: getAppointments(state),
    reasons: getReasons(state),
    fileLoading: getFileLoading(state),
    fileToDownload: getDownloadFile(state),
    specialties: getSpecialties(state),
    providers: getProviders(state),
    schedules: getSchedules(state),
  }
}

export default connect(mapStateToProps, {
  onRetrieveSpecialties: requestAssistantSpecialtiesGet,
  onRetrieveAppointments: requestAppointments,
  onRetrieveAppointmentReasons: requestAppointmentReasons,
  onFileDownload: fileDownloadGetRequest,
  onRetrieveSchedules: providerScheduleGetRequest,
})(Appointments)
