import dayjs from 'dayjs'

export const addToDate = (date, property, value) =>
  dayjs(date).add(value, property).toDate()

export const formatDate = (date, format) => dayjs(date).format(format)

export const toUTC = (date, format) => dayjs(date, format).utc()

export const getAge = (date) => {
  const today = dayjs()
  return today.diff(date, 'years')
}

export const isDateValid = (val) =>
  dayjs(val).isValid() && dayjs(val).year() < 2099 && dayjs(val).year() > 1900
