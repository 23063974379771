export const AWS_REFRESH_CREDENTIALS_REQUEST = 'AWS_REFRESH_CREDENTIALS'
export const AWS_REFRESH_CREDENTIALS_SUCCESS = 'AWS_REFRESH_CREDENTIALS_SUCCESS'
export const AWS_REFRESH_CREDENTIALS_FAILURE = 'AWS_REFRESH_CREDENTIALS_FAILURE'

export function refreshCredentialsRequest() {
  return { type: AWS_REFRESH_CREDENTIALS_REQUEST }
}

export function refreshCredentialsSuccess() {
  return { type: AWS_REFRESH_CREDENTIALS_SUCCESS }
}

export function refreshCredentialsFailure(error) {
  return { type: AWS_REFRESH_CREDENTIALS_FAILURE }
}

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET'

export function loginReset() {
  return { type: USER_LOGIN_RESET }
}

export function login(data) {
  return { type: USER_LOGIN_REQUEST, data }
}

export function loginSuccess(user) {
  return {
    type: USER_LOGIN_SUCCESS,
    user,
  }
}

export function loginFailure(error) {
  return {
    type: USER_LOGIN_FAILURE,
    error,
  }
}

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'

export function userUpdateRequest(data) {
  return { type: USER_UPDATE_REQUEST, data }
}

export function userUpdateSuccess(user) {
  return {
    type: USER_UPDATE_SUCCESS,
    user,
  }
}

export function userUpdateError(error) {
  return {
    type: USER_UPDATE_FAILURE,
    error,
  }
}

export const USER_GET_REQUEST = 'USER_GET_REQUEST'
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS'
export const USER_GET_FAILURE = 'USER_GET_FAILURE'

export function userGetRequest(data) {
  return { type: USER_GET_REQUEST, data }
}

export function userGetSuccess(user) {
  return {
    type: USER_GET_SUCCESS,
    user,
  }
}

export function userGetError(error) {
  return {
    type: USER_GET_FAILURE,
    error,
  }
}

export const USER_CREATE_RESET = 'USER_CREATE_RESET'
export const USER_CREATE_REQUEST = 'USER_CREATE_REQUEST'
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS'
export const USER_CREATE_FAILURE = 'USER_CREATE_FAILURE'

export function userCreateReset() {
  return { type: USER_CREATE_RESET }
}
export function userCreateRequest(data) {
  return { type: USER_CREATE_REQUEST, data }
}

export function userCreateSuccess(user) {
  return {
    type: USER_CREATE_SUCCESS,
    user,
  }
}

export function userCreateError(error) {
  return {
    type: USER_CREATE_FAILURE,
    error,
  }
}

export const USER_PW_CHALLENGE_REQUEST = 'USER_PW_CHALLENGE_REQUEST'
export const USER_PW_CHALLENGE_SUCCESS = 'USER_PW_CHALLENGE_SUCCESS'
export const USER_PW_CHALLENGE_FAILURE = 'USER_PW_CHALLENGE_FAILURE'

export function userPasswordChallengeRequest(data) {
  return { type: USER_PW_CHALLENGE_REQUEST, data }
}

export function userPasswordChallengeSuccess(user) {
  return {
    type: USER_PW_CHALLENGE_SUCCESS,
    user,
  }
}

export function userPasswordChallengeFailure(error) {
  return {
    type: USER_PW_CHALLENGE_FAILURE,
    error,
  }
}

export function logout() {
  return { type: USER_LOGIN_SUCCESS, user: null }
}

export const USER_FORGOTPW_RESET = 'USER_FORGOTPW_RESET'
export const USER_FORGOTPW_REQUEST = 'USER_FORGOTPW_REQUEST'
export const USER_FORGOTPW_SUCCESS = 'USER_FORGOTPW_SUCCESS'
export const USER_FORGOTPW_FAILURE = 'USER_FORGOTPW_FAILURE'

export function forgotPasswordReset() {
  return {
    type: USER_FORGOTPW_RESET,
  }
}

export function forgotPassword(data) {
  return { type: USER_FORGOTPW_REQUEST, data }
}

export function forgotPasswordSuccess(data) {
  return {
    type: USER_FORGOTPW_SUCCESS,
    data,
  }
}

export function forgotPasswordFailure(error) {
  return {
    type: USER_FORGOTPW_FAILURE,
    error,
  }
}

export const USER_REGISTRATION_REQUEST = 'USER_REGISTRATION_REQUEST'
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS'
export const USER_REGISTRATION_FAILURE = 'USER_REGISTRATION_FAILURE'
export const USER_REGISTRATION_RESET = 'USER_REGISTRATION_RESET'

export function registrationReset() {
  return { type: USER_REGISTRATION_RESET }
}

export function registration(data) {
  return { type: USER_REGISTRATION_REQUEST, data }
}

export function registrationSuccess(user) {
  return {
    type: USER_REGISTRATION_SUCCESS,
    user,
  }
}

export function registrationFailure(error) {
  return {
    type: USER_REGISTRATION_FAILURE,
    error,
  }
}

export const USER_ADMIN_REGISTRATION_REQUEST = 'USER_ADMIN_REGISTRATION_REQUEST'
export const USER_ADMIN_REGISTRATION_SUCCESS = 'USER_ADMIN_REGISTRATION_SUCCESS'
export const USER_ADMIN_REGISTRATION_FAILURE = 'USER_ADMIN_REGISTRATION_FAILURE'

export function adminRegistration(data) {
  return { type: USER_ADMIN_REGISTRATION_REQUEST, data }
}

export function adminRegistrationSuccess(user) {
  return {
    type: USER_ADMIN_REGISTRATION_SUCCESS,
    user,
  }
}

export function adminRegistrationFailure(error) {
  return {
    type: USER_ADMIN_REGISTRATION_FAILURE,
    error,
  }
}

export const USER_ADMIN_CHANGE_STATUS_REQUEST =
  'USER_ADMIN_CHANGE_STATUS_REQUEST'
export const USER_ADMIN_CHANGE_STATUS_SUCCESS =
  'USER_ADMIN_CHANGE_STATUS_SUCCESS'
export const USER_ADMIN_CHANGE_STATUS_FAILURE =
  'USER_ADMIN_CHANGE_STATUS_FAILURE'

export function adminChangeStatus(data) {
  return { type: USER_ADMIN_CHANGE_STATUS_REQUEST, data }
}

export function adminChangeStatusSuccess(user) {
  return {
    type: USER_ADMIN_CHANGE_STATUS_SUCCESS,
    user,
  }
}

export function adminChangeStatusFailure(error) {
  return {
    type: USER_ADMIN_CHANGE_STATUS_FAILURE,
    error,
  }
}

export const APPOINTMENT_REASON_GET_REQUEST = 'APPOINTMENT_REASON_GET_REQUEST'
export const APPOINTMENT_REASON_GET_SUCCESS = 'APPOINTMENT_REASON_GET_SUCCESS'
export const APPOINTMENT_REASON_GET_FAILURE = 'APPOINTMENT_REASON_GET_FAILURE'

export function requestAppointmentReasons(data) {
  return { type: APPOINTMENT_REASON_GET_REQUEST, data }
}

export function requestAppointmentReasonsSuccess(data) {
  return {
    type: APPOINTMENT_REASON_GET_SUCCESS,
    data,
  }
}

export function requestAppointmentReasonsFailure(error) {
  return {
    type: APPOINTMENT_REASON_GET_FAILURE,
    error,
  }
}

export const APPOINTMENT_CREATE_RESET = 'APPOINTMENT_CREATE_RESET'
export const APPOINTMENT_CREATE_REQUEST = 'APPOINTMENT_CREATE_REQUEST'
export const APPOINTMENT_CREATE_SUCCESS = 'APPOINTMENT_CREATE_SUCCESS'
export const APPOINTMENT_CREATE_FAILURE = 'APPOINTMENT_CREATE_FAILURE'

export function resetCreateAppointment() {
  return { type: APPOINTMENT_CREATE_RESET }
}

export function createAppointment(data) {
  return { type: APPOINTMENT_CREATE_REQUEST, data }
}

export function createAppointmentSuccess(data) {
  return {
    type: APPOINTMENT_CREATE_SUCCESS,
    data,
  }
}

export function createAppointmentFailure(error) {
  return {
    type: APPOINTMENT_CREATE_FAILURE,
    error,
  }
}

export const APPOINTMENT_CANCEL_RESET = 'APPOINTMENT_CANCEL_RESET'
export const APPOINTMENT_CANCEL_REQUEST = 'APPOINTMENT_CANCEL_REQUEST'
export const APPOINTMENT_CANCEL_SUCCESS = 'APPOINTMENT_CANCEL_SUCCESS'
export const APPOINTMENT_CANCEL_FAILURE = 'APPOINTMENT_CANCEL_FAILURE'

export function resetCancelAppointment() {
  return { type: APPOINTMENT_CANCEL_RESET }
}

export function cancelAppointment(data) {
  return { type: APPOINTMENT_CANCEL_REQUEST, data }
}

export function cancelAppointmentSuccess(data) {
  return {
    type: APPOINTMENT_CANCEL_SUCCESS,
    data,
  }
}

export function cancelAppointmentFailure(error) {
  return {
    type: APPOINTMENT_CANCEL_FAILURE,
    error,
  }
}

export const APPOINTMENT_GET_REQUEST = 'APPOINTMENT_GET_REQUEST'
export const APPOINTMENT_GET_SUCCESS = 'APPOINTMENT_GET_SUCCESS'
export const APPOINTMENT_GET_FAILURE = 'APPOINTMENT_GET_FAILURE'

export function requestAppointments(data) {
  return { type: APPOINTMENT_GET_REQUEST, data }
}

export function requestAppointmentsSuccess(data) {
  return {
    type: APPOINTMENT_GET_SUCCESS,
    data,
  }
}

export function requestAppointmentsFailure(error) {
  return {
    type: APPOINTMENT_GET_FAILURE,
    error,
  }
}

export const APPOINTMENT_UPCOMING_REQUEST = 'APPOINTMENT_UPCOMING_REQUEST'
export const APPOINTMENT_UPCOMING_SUCCESS = 'APPOINTMENT_UPCOMING_SUCCESS'
export const APPOINTMENT_UPCOMING_FAILURE = 'APPOINTMENT_UPCOMING_FAILURE'

export function requestUpcomingAppointments(data) {
  return { type: APPOINTMENT_UPCOMING_REQUEST, data }
}

export function requestUpcomingAppointmentsSuccess(data) {
  return {
    type: APPOINTMENT_UPCOMING_SUCCESS,
    data,
  }
}

export function requestUpcomingAppointmentsFailure(error) {
  return {
    type: APPOINTMENT_UPCOMING_FAILURE,
    error,
  }
}

export const APPOINTMENT_JOIN_REQUEST = 'APPOINTMENT_JOIN_REQUEST'
export const APPOINTMENT_JOIN_SUCCESS = 'APPOINTMENT_JOIN_SUCCESS'
export const APPOINTMENT_JOIN_FAILURE = 'APPOINTMENT_JOIN_FAILURE'

export function joinAppointment(data) {
  return { type: APPOINTMENT_JOIN_REQUEST, data }
}

export function joinAppointmentSuccess(data) {
  return {
    type: APPOINTMENT_JOIN_SUCCESS,
    data,
  }
}

export function joinAppointmentFailure(error) {
  return {
    type: APPOINTMENT_JOIN_FAILURE,
    error,
  }
}

export const APPOINTMENT_END_REQUEST = 'APPOINTMENT_END_REQUEST'
export const APPOINTMENT_END_SUCCESS = 'APPOINTMENT_END_SUCCESS'
export const APPOINTMENT_END_FAILURE = 'APPOINTMENT_END_FAILURE'

export function endAppointment(data) {
  return { type: APPOINTMENT_END_REQUEST, data }
}

export function endAppointmentSuccess(data) {
  return {
    type: APPOINTMENT_END_SUCCESS,
    data,
  }
}

export function endAppointmentFailure(error) {
  return {
    type: APPOINTMENT_END_FAILURE,
    error,
  }
}

export const APPOINTMENT_PRESCRIPTION_DELETE_REQUEST =
  'APPOINTMENT_PRESCRIPTION_DELETE_REQUEST'
export const APPOINTMENT_PRESCRIPTION_DELETE_SUCCESS =
  'APPOINTMENT_PRESCRIPTION_DELETE_SUCCESS'
export const APPOINTMENT_PRESCRIPTION_DELETE_FAILURE =
  'APPOINTMENT_PRESCRIPTION_DELETE_FAILURE'

export function deletePrescriptionRequest(data) {
  return { type: APPOINTMENT_PRESCRIPTION_DELETE_REQUEST, data }
}

export function deletePrescriptionSuccess(data) {
  return {
    type: APPOINTMENT_PRESCRIPTION_DELETE_SUCCESS,
    data,
  }
}

export function deletePrescriptionFailure(error) {
  return {
    type: APPOINTMENT_PRESCRIPTION_DELETE_FAILURE,
    error,
  }
}

export const APPOINTMENT_PRESCRIPTION_REQUEST =
  'APPOINTMENT_PRESCRIPTION_REQUEST'
export const APPOINTMENT_PRESCRIPTION_SUCCESS =
  'APPOINTMENT_PRESCRIPTION_SUCCESS'
export const APPOINTMENT_PRESCRIPTION_FAILURE =
  'APPOINTMENT_PRESCRIPTION_FAILURE'

export function prescriptionAppointment(data) {
  return { type: APPOINTMENT_PRESCRIPTION_REQUEST, data }
}

export function prescriptionAppointmentSuccess(data) {
  return {
    type: APPOINTMENT_PRESCRIPTION_SUCCESS,
    data,
  }
}

export function prescriptionAppointmentFailure(error) {
  return {
    type: APPOINTMENT_PRESCRIPTION_FAILURE,
    error,
  }
}

export const APPOINTMENT_WHATSAPP_PRESCRIPTION_REQUEST =
  'APPOINTMENT_WHATSAPP_PRESCRIPTION_REQUEST'
export const APPOINTMENT_WHATSAPP_PRESCRIPTION_SUCCESS =
  'APPOINTMENT_WHATSAPP_PRESCRIPTION_SUCCESS'
export const APPOINTMENT_WHATSAPP_PRESCRIPTION_FAILURE =
  'APPOINTMENT_WHATSAPP_PRESCRIPTION_FAILURE'

export function whatsappPrescriptionAppointmentRequest(data) {
  return { type: APPOINTMENT_WHATSAPP_PRESCRIPTION_REQUEST, data }
}

export function whatsappPrescriptionAppointmentSuccess(data) {
  return {
    type: APPOINTMENT_WHATSAPP_PRESCRIPTION_SUCCESS,
    data,
  }
}

export function whatsappPrescriptionAppointmentFailure(error) {
  return {
    type: APPOINTMENT_WHATSAPP_PRESCRIPTION_FAILURE,
    error,
  }
}

export const SPECIALTIES_GET_REQUEST = 'SPECIALTIES_GET_REQUEST'
export const SPECIALTIES_GET_SUCCESS = 'SPECIALTIES_GET_SUCCESS'
export const SPECIALTIES_GET_FAILURE = 'SPECIALTIES_GET_FAILURE'

export function requestSpecialtiesGet(retrieveAll) {
  return { type: SPECIALTIES_GET_REQUEST, retrieveAll }
}

export function specialtiesGetSuccess(data) {
  return {
    type: SPECIALTIES_GET_SUCCESS,
    data,
  }
}

export function specialtiesGetError(error) {
  return {
    type: SPECIALTIES_GET_FAILURE,
    error,
  }
}

export const ASSISTANT_SPECIALTIES_GET_REQUEST =
  'ASSISTANT_SPECIALTIES_GET_REQUEST'
export const ASSISTANT_SPECIALTIES_GET_SUCCESS =
  'ASSISTANT_SPECIALTIES_GET_SUCCESS'
export const ASSISTANT_SPECIALTIES_GET_FAILURE =
  'ASSISTANT_SPECIALTIES_GET_FAILURE'

export function requestAssistantSpecialtiesGet() {
  return { type: ASSISTANT_SPECIALTIES_GET_REQUEST }
}

export function assistantSpecialtiesGetSuccess(data) {
  return {
    type: ASSISTANT_SPECIALTIES_GET_SUCCESS,
    data,
  }
}

export function assistantSpecialtiesGetError(error) {
  return {
    type: ASSISTANT_SPECIALTIES_GET_FAILURE,
    error,
  }
}

export const SPECIALTIES_CREATE_REQUEST = 'SPECIALTIES_CREATE_REQUEST'
export const SPECIALTIES_CREATE_SUCCESS = 'SPECIALTIES_CREATE_SUCCESS'
export const SPECIALTIES_CREATE_FAILURE = 'SPECIALTIES_CREATE_FAILURE'

export function requestSpecialtyCreate(data) {
  return { type: SPECIALTIES_CREATE_REQUEST, data }
}

export function specialtyCreateSuccess(data) {
  return {
    type: SPECIALTIES_CREATE_SUCCESS,
    data,
  }
}

export function specialtyCreateError(error) {
  return {
    type: SPECIALTIES_CREATE_FAILURE,
    error,
  }
}

export const SPECIALTIES_DELETE_REQUEST = 'SPECIALTIES_DELETE_REQUEST'
export const SPECIALTIES_DELETE_SUCCESS = 'SPECIALTIES_DELETE_SUCCESS'
export const SPECIALTIES_DELETE_FAILURE = 'SPECIALTIES_DELETE_FAILURE'

export function requestSpecialtyDelete(data) {
  return { type: SPECIALTIES_DELETE_REQUEST, data }
}

export function specialtyDeleteSuccess(data) {
  return {
    type: SPECIALTIES_DELETE_SUCCESS,
    data,
  }
}

export function specialtyDeleteError(error) {
  return {
    type: SPECIALTIES_DELETE_FAILURE,
    error,
  }
}

export const FILE_UPLOAD_RESET = 'FILE_UPLOAD_RESET'
export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST'
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS'
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE'

export function fileUploadReset() {
  return { type: FILE_UPLOAD_RESET }
}

export function fileUploadRequest(data) {
  return { type: FILE_UPLOAD_REQUEST, data }
}

export function fileUploadSuccess(data) {
  return {
    type: FILE_UPLOAD_SUCCESS,
    data,
  }
}

export function fileUploadError(error) {
  return {
    type: FILE_UPLOAD_FAILURE,
    error,
  }
}

export const MULTIPLE_FILE_GET_REQUEST = 'MULTIPLE_FILE_GET_REQUEST'
export const MULTIPLE_FILE_GET_SUCCESS = 'MULTIPLE_FILE_GET_SUCCESS'
export const MULTIPLE_FILE_GET_FAILURE = 'MULTIPLE_FILE_GET_FAILURE'

export function multipleFileGetRequest(keys) {
  return { type: MULTIPLE_FILE_GET_REQUEST, data: keys }
}

export function multipleFileGetSuccess(data) {
  return {
    type: MULTIPLE_FILE_GET_SUCCESS,
    data,
  }
}

export function multipleFileGetError(error) {
  return {
    type: MULTIPLE_FILE_GET_FAILURE,
    error,
  }
}

export const FILE_GET_REQUEST = 'FILE_GET_REQUEST'
export const FILE_GET_SUCCESS = 'FILE_GET_SUCCESS'
export const FILE_GET_FAILURE = 'FILE_GET_FAILURE'

export function fileGetRequest(data) {
  return { type: FILE_GET_REQUEST, data }
}

export function fileGetSuccess(data) {
  return {
    type: FILE_GET_SUCCESS,
    data,
  }
}

export function fileGetError(error) {
  return {
    type: FILE_GET_FAILURE,
    error,
  }
}

export const FILE_DOWNLOAD_GET_REQUEST = 'FILE_DOWNLOAD_GET_REQUEST'
export const FILE_DOWNLOAD_GET_SUCCESS = 'FILE_DOWNLOAD_GET_SUCCESS'
export const FILE_DOWNLOAD_GET_FAILURE = 'FILE_DOWNLOAD_GET_FAILURE'

export function fileDownloadGetRequest(data) {
  return { type: FILE_DOWNLOAD_GET_REQUEST, data }
}

export function fileDownloadGetSuccess(data) {
  return {
    type: FILE_DOWNLOAD_GET_SUCCESS,
    data,
  }
}

export function fileDownloadGetError(error) {
  return {
    type: FILE_DOWNLOAD_GET_FAILURE,
    error,
  }
}

export const PUBSUB_ER_SUBSCRIBE_REQUEST = 'PUBSUB_ER_SUBSCRIBE_REQUEST'
export const PUBSUB_ER_SUBSCRIBE_SUCCESS = 'PUBSUB_ER_SUBSCRIBE_SUCCESS'
export const PUBSUB_ER_SUBSCRIBE_FAILURE = 'PUBSUB_ER_SUBSCRIBE_FAILURE'

export function pubSubErSubscribeRequest() {
  return { type: PUBSUB_ER_SUBSCRIBE_REQUEST }
}

export function pubSubErSubscribeSuccess(data) {
  return { type: PUBSUB_ER_SUBSCRIBE_SUCCESS, data }
}

export function pubSubErSubscribeError(error) {
  return {
    type: PUBSUB_ER_SUBSCRIBE_FAILURE,
    error,
  }
}

export const PUBSUB_ER_UNSUBSCRIBE_REQUEST = 'PUBSUB_ER_UNSUBSCRIBE_REQUEST'
export const PUBSUB_ER_UNSUBSCRIBE_SUCCESS = 'PUBSUB_ER_UNSUBSCRIBE_SUCCESS'
export const PUBSUB_ER_UNSUBSCRIBE_FAILURE = 'PUBSUB_ER_UNSUBSCRIBE_FAILURE'

export function pubSubErUnsubscribeRequest() {
  return { type: PUBSUB_ER_UNSUBSCRIBE_REQUEST }
}

export function pubSubErUnsubscribeSuccess() {
  return { type: PUBSUB_ER_UNSUBSCRIBE_SUCCESS }
}

export function pubSubErUnsubscribeError(error) {
  return {
    type: PUBSUB_ER_UNSUBSCRIBE_FAILURE,
    error,
  }
}

export const PUBSUB_CLIENT_SUBSCRIBE_REQUEST = 'PUBSUB_CLIENT_SUBSCRIBE_REQUEST'
export const PUBSUB_CLIENT_SUBSCRIBE_SUCCESS = 'PUBSUB_CLIENT_SUBSCRIBE_SUCCESS'
export const PUBSUB_CLIENT_SUBSCRIBE_FAILURE = 'PUBSUB_CLIENT_SUBSCRIBE_FAILURE'

export function pubSubClientSubscribeRequest(data) {
  return { type: PUBSUB_CLIENT_SUBSCRIBE_REQUEST, data }
}

export function pubSubClientSubscribeSuccess(data) {
  return { type: PUBSUB_CLIENT_SUBSCRIBE_SUCCESS, data }
}

export function pubSubClientSubscribeError(error) {
  return {
    type: PUBSUB_CLIENT_SUBSCRIBE_FAILURE,
    error,
  }
}

export const PUBSUB_RECEIVED_DATA = 'PUBSUB_CLIENT_RECEIVED_DATA'

export function pubSubReceivedData(data) {
  return {
    type: PUBSUB_RECEIVED_DATA,
    data,
  }
}

export const PUBSUB_PUBLISH_REQUEST = 'PUBSUB_PUBLISH_REQUEST'
export const PUBSUB_PUBLISH_SUCCESS = 'PUBSUB_PUBLISH_SUCCESS'
export const PUBSUB_PUBLISH_FAILURE = 'PUBSUB_PUBLISH_FAILURE'

export function pubSubPublishRequest(data) {
  return { type: PUBSUB_PUBLISH_REQUEST, data }
}

export function pubSubPublishSuccess(data) {
  return {
    type: PUBSUB_PUBLISH_SUCCESS,
    data,
  }
}

export function pubSubPublishError(error) {
  return {
    type: PUBSUB_PUBLISH_FAILURE,
    error,
  }
}

export const PROVIDER_ATTACH_REQUEST = 'PROVIDER_ATTACH_REQUEST'
export const PROVIDER_ATTACH_SUCCESS = 'PROVIDER_ATTACH_SUCCESS'
export const PROVIDER_ATTACH_FAILURE = 'PROVIDER_ATTACH_FAILURE'

export function providerAttachRequest(data) {
  return { type: PROVIDER_ATTACH_REQUEST, data }
}

export function providerAttachSuccess(data) {
  return {
    type: PROVIDER_ATTACH_SUCCESS,
    data,
  }
}

export function providerAttachError(error) {
  return {
    type: PROVIDER_ATTACH_FAILURE,
    error,
  }
}

export const PROVIDER_SUBSCRIBE_REQUEST = 'PROVIDER_SUBSCRIBE_REQUEST'
export const PROVIDER_SUBSCRIBE_SUCCESS = 'PROVIDER_SUBSCRIBE_SUCCESS'
export const PROVIDER_SUBSCRIBE_FAILURE = 'PROVIDER_SUBSCRIBE_FAILURE'

export function providerSubscribeRequest(data) {
  return { type: PROVIDER_SUBSCRIBE_REQUEST, data }
}

export function providerSubscribeSuccess(data) {
  return {
    type: PROVIDER_SUBSCRIBE_SUCCESS,
    data,
  }
}

export function providerSubscribeError(error) {
  return {
    type: PROVIDER_SUBSCRIBE_FAILURE,
    error,
  }
}

export const PROVIDER_UNSUBSCRIBE_REQUEST = 'PROVIDER_UNSUBSCRIBE_REQUEST'
export const PROVIDER_UNSUBSCRIBE_SUCCESS = 'PROVIDER_UNSUBSCRIBE_SUCCESS'
export const PROVIDER_UNSUBSCRIBE_FAILURE = 'PROVIDER_UNSUBSCRIBE_FAILURE'

export function providerUnsubscribeRequest(data) {
  return { type: PROVIDER_UNSUBSCRIBE_REQUEST, data }
}

export function providerUnsubscribeSuccess(data) {
  return {
    type: PROVIDER_UNSUBSCRIBE_SUCCESS,
    data,
  }
}

export function providerUnsubscribeError(error) {
  return {
    type: PROVIDER_UNSUBSCRIBE_FAILURE,
    error,
  }
}

export const PROVIDER_GET_REQUEST = 'PROVIDER_GET_REQUEST'
export const PROVIDER_GET_SUCCESS = 'PROVIDER_GET_SUCCESS'
export const PROVIDER_GET_FAILURE = 'PROVIDER_GET_FAILURE'

export function providerGetRequest(data) {
  return { type: PROVIDER_GET_REQUEST, data }
}

export function providerGetSuccess(data) {
  return {
    type: PROVIDER_GET_SUCCESS,
    data,
  }
}

export function providerGetError(error) {
  return {
    type: PROVIDER_GET_FAILURE,
    error,
  }
}

export const PROVIDER_CURRENT_GET_REQUEST = 'PROVIDER_CURRENT_GET_REQUEST'
export const PROVIDER_CURRENT_GET_SUCCESS = 'PROVIDER_CURRENT_GET_SUCCESS'
export const PROVIDER_CURRENT_GET_FAILURE = 'PROVIDER_CURRENT_GET_FAILURE'

export function providerCurrentGetRequest(data) {
  return { type: PROVIDER_CURRENT_GET_REQUEST, data }
}

export function providerCurrentGetSuccess(data) {
  return {
    type: PROVIDER_CURRENT_GET_SUCCESS,
    data,
  }
}

export function providerCurrentGetError(error) {
  return {
    type: PROVIDER_CURRENT_GET_FAILURE,
    error,
  }
}

export const PROVIDER_CREATE_REQUEST = 'PROVIDER_CREATE_REQUEST'
export const PROVIDER_CREATE_SUCCESS = 'PROVIDER_CREATE_SUCCESS'
export const PROVIDER_CREATE_FAILURE = 'PROVIDER_CREATE_FAILURE'

export function providerCreateRequest(data) {
  return { type: PROVIDER_CREATE_REQUEST, data }
}

export function providerCreateSuccess(data) {
  return {
    type: PROVIDER_CREATE_SUCCESS,
    data,
  }
}

export function providerCreateError(error) {
  return {
    type: PROVIDER_CREATE_FAILURE,
    error,
  }
}

export const PROVIDER_VALIDATE_REQUEST = 'PROVIDER_VALIDATE_REQUEST'
export const PROVIDER_VALIDATE_SUCCESS = 'PROVIDER_VALIDATE_SUCCESS'
export const PROVIDER_VALIDATE_FAILURE = 'PROVIDER_VALIDATE_FAILURE'

export function providerValidateRequest(data) {
  return { type: PROVIDER_VALIDATE_REQUEST, data }
}

export function providerValidateSuccess(data) {
  return {
    type: PROVIDER_VALIDATE_SUCCESS,
    data,
  }
}

export function providerValidateError(error) {
  return {
    type: PROVIDER_VALIDATE_FAILURE,
    error,
  }
}

export const PROVIDER_SCHEDULE_GET_REQUEST = 'PROVIDER_SCHEDULE_GET_REQUEST'
export const PROVIDER_SCHEDULE_GET_SUCCESS = 'PROVIDER_SCHEDULE_GET_SUCCESS'
export const PROVIDER_SCHEDULE_GET_FAILURE = 'PROVIDER_SCHEDULE_GET_FAILURE'

export function providerScheduleGetRequest(data) {
  return { type: PROVIDER_SCHEDULE_GET_REQUEST, data }
}

export function providerScheduleGetSuccess(data) {
  return {
    type: PROVIDER_SCHEDULE_GET_SUCCESS,
    data,
  }
}

export function providerScheduleGetError(error) {
  return {
    type: PROVIDER_SCHEDULE_GET_FAILURE,
    error,
  }
}

export const PROVIDER_SCHEDULE_DELETE_REQUEST =
  'PROVIDER_SCHEDULE_DELETE_REQUEST'
export const PROVIDER_SCHEDULE_DELETE_SUCCESS =
  'PROVIDER_SCHEDULE_DELETE_SUCCESS'
export const PROVIDER_SCHEDULE_DELETE_FAILURE =
  'PROVIDER_SCHEDULE_DELETE_FAILURE'

export function providerScheduleDeleteRequest(data) {
  return { type: PROVIDER_SCHEDULE_DELETE_REQUEST, data }
}

export function providerScheduleDeleteSuccess(data) {
  return {
    type: PROVIDER_SCHEDULE_DELETE_SUCCESS,
    data,
  }
}

export function providerScheduleDeleteError(error) {
  return {
    type: PROVIDER_SCHEDULE_DELETE_FAILURE,
    error,
  }
}

export const PROVIDER_SCHEDULE_MULTIPLE_CREATE_REQUEST =
  'PROVIDER_SCHEDULE_MULTIPLE_CREATE_REQUEST'
export const PROVIDER_SCHEDULE_MULTIPLE_CREATE_SUCCESS =
  'PROVIDER_SCHEDULE_MULTIPLE_CREATE_SUCCESS'
export const PROVIDER_SCHEDULE_MULTIPLE_CREATE_FAILURE =
  'PROVIDER_SCHEDULE_MULTIPLE_CREATE_FAILURE'

export function providerScheduleMultipleCreateRequest(data) {
  return { type: PROVIDER_SCHEDULE_MULTIPLE_CREATE_REQUEST, data }
}

export function providerScheduleMultipleCreateSuccess(data) {
  return {
    type: PROVIDER_SCHEDULE_MULTIPLE_CREATE_SUCCESS,
    data,
  }
}

export function providerScheduleMultipleCreateError(error) {
  return {
    type: PROVIDER_SCHEDULE_MULTIPLE_CREATE_FAILURE,
    error,
  }
}

export const PROVIDER_RESET = 'PROVIDER_RESET'

export function providerReset() {
  return { type: PROVIDER_RESET }
}

export const PROVIDER_EDIT_REQUEST = 'PROVIDER_EDIT_REQUEST'
export const PROVIDER_EDIT_SUCCESS = 'PROVIDER_EDIT_SUCCESS'
export const PROVIDER_EDIT_FAILURE = 'PROVIDER_EDIT_FAILURE'

export function providerEditRequest(data) {
  return { type: PROVIDER_EDIT_REQUEST, data }
}

export function providerEditSuccess(data) {
  return {
    type: PROVIDER_EDIT_SUCCESS,
    data,
  }
}

export function providerEditError(error) {
  return {
    type: PROVIDER_EDIT_FAILURE,
    error,
  }
}

export const PROVIDER_DELETE_REQUEST = 'PROVIDER_DELETE_REQUEST'
export const PROVIDER_DELETE_SUCCESS = 'PROVIDER_DELETE_SUCCESS'
export const PROVIDER_DELETE_FAILURE = 'PROVIDER_DELETE_FAILURE'

export function providerDeleteRequest(data) {
  return { type: PROVIDER_DELETE_REQUEST, data }
}

export function providerDeleteSuccess(data) {
  return {
    type: PROVIDER_DELETE_SUCCESS,
    data,
  }
}

export function providerDeleteError(error) {
  return {
    type: PROVIDER_DELETE_FAILURE,
    error,
  }
}

export const USERS_GET_REQUEST = 'USERS_GET_REQUEST'
export const USERS_GET_SUCCESS = 'USERS_GET_SUCCESS'
export const USERS_GET_FAILURE = 'USERS_GET_FAILURE'

export function usersGetRequest(data) {
  return { type: USERS_GET_REQUEST, data }
}

export function usersGetSuccess(data) {
  return {
    type: USERS_GET_SUCCESS,
    data,
  }
}

export function usersGetError(error) {
  return {
    type: USERS_GET_FAILURE,
    error,
  }
}

export const COUNTRIES_GET_REQUEST = 'COUNTRIES_GET_REQUEST'
export const COUNTRIES_GET_SUCCESS = 'COUNTRIES_GET_SUCCESS'
export const COUNTRIES_GET_FAILURE = 'COUNTRIES_GET_FAILURE'

export function countriesGetRequest() {
  return { type: COUNTRIES_GET_REQUEST }
}

export function countriesGetSuccess(data) {
  return {
    type: COUNTRIES_GET_SUCCESS,
    data,
  }
}

export function countriesGetError(error) {
  return {
    type: COUNTRIES_GET_FAILURE,
    error,
  }
}

export const PROVINCES_GET_REQUEST = 'PROVINCES_GET_REQUEST'
export const PROVINCES_GET_SUCCESS = 'PROVINCES_GET_SUCCESS'
export const PROVINCES_GET_FAILURE = 'PROVINCES_GET_FAILURE'

export function provincesGetRequest(data) {
  return { type: PROVINCES_GET_REQUEST, data }
}

export function provincesGetSuccess(data) {
  return {
    type: PROVINCES_GET_SUCCESS,
    data,
  }
}

export function provincesGetError(error) {
  return {
    type: PROVINCES_GET_FAILURE,
    error,
  }
}

export const DISTRICTS_GET_REQUEST = 'DISTRICTS_GET_REQUEST'
export const DISTRICTS_GET_SUCCESS = 'DISTRICTS_GET_SUCCESS'
export const DISTRICTS_GET_FAILURE = 'DISTRICTS_GET_FAILURE'

export function districtsGetRequest(data) {
  return { type: DISTRICTS_GET_REQUEST, data }
}

export function districtsGetSuccess(data) {
  return {
    type: DISTRICTS_GET_SUCCESS,
    data,
  }
}

export function districtsGetError(error) {
  return {
    type: DISTRICTS_GET_FAILURE,
    error,
  }
}

export const PATIENT_GET_REQUEST = 'PATIENT_GET_REQUEST'
export const PATIENT_GET_SUCCESS = 'PATIENT_GET_SUCCESS'
export const PATIENT_GET_FAILURE = 'PATIENT_GET_FAILURE'

export function patientGetRequest(data) {
  return { type: PATIENT_GET_REQUEST, data }
}

export function patientGetSuccess(data) {
  return {
    type: PATIENT_GET_SUCCESS,
    data,
  }
}

export function patientGetError(error) {
  return {
    type: PATIENT_GET_FAILURE,
    error,
  }
}

export const PATIENT_SEARCH_RESET = 'PATIENT_SEARCH_RESET'
export const PATIENT_SEARCH_REQUEST = 'PATIENT_SEARCH_REQUEST'
export const PATIENT_SEARCH_SUCCESS = 'PATIENT_SEARCH_SUCCESS'
export const PATIENT_SEARCH_FAILURE = 'PATIENT_SEARCH_FAILURE'

export function resetPatientSearch() {
  return { type: PATIENT_SEARCH_RESET }
}

export function patientSearchRequest(data) {
  return { type: PATIENT_SEARCH_REQUEST, data }
}

export function patientSearchSuccess(data) {
  return {
    type: PATIENT_SEARCH_SUCCESS,
    data,
  }
}

export function patientSearchError(error) {
  return {
    type: PATIENT_SEARCH_FAILURE,
    error,
  }
}
export const PATIENT_CREATE_RESET = 'PATIENT_CREATE_RESET'
export const PATIENT_CREATE_REQUEST = 'PATIENT_CREATE_REQUEST'
export const PATIENT_CREATE_SUCCESS = 'PATIENT_CREATE_SUCCESS'
export const PATIENT_CREATE_FAILURE = 'PATIENT_CREATE_FAILURE'

export function resetPatientCreation() {
  return { type: PATIENT_CREATE_RESET }
}

export function patientCreationRequest(data) {
  return { type: PATIENT_CREATE_REQUEST, data }
}

export function patientCreationSuccess(data) {
  return {
    type: PATIENT_CREATE_SUCCESS,
    data,
  }
}

export function patientCreationError(error) {
  return {
    type: PATIENT_CREATE_FAILURE,
    error,
  }
}

export const PATIENT_HEALTH_RECORD_GET_REQUEST =
  'PATIENT_HEALTH_RECORD_GET_REQUEST'
export const PATIENT_HEALTH_RECORD_GET_SUCCESS =
  'PATIENT_HEALTH_RECORD_GET_SUCCESS'
export const PATIENT_HEALTH_RECORD_GET_FAILURE =
  'PATIENT_HEALTH_RECORD_GET_FAILURE'

export function patientHealthRecordGetRequest(data) {
  return { type: PATIENT_HEALTH_RECORD_GET_REQUEST, data }
}

export function patientHealthRecordGetSuccess(data) {
  return {
    type: PATIENT_HEALTH_RECORD_GET_SUCCESS,
    data,
  }
}

export function patientHealthRecordGetError(error) {
  return {
    type: PATIENT_HEALTH_RECORD_GET_FAILURE,
    error,
  }
}
export const PATIENT_HEALTH_RECORD_DOWNLOAD_RESET =
  'PATIENT_HEALTH_RECORD_DOWNLOAD_RESET'
export const PATIENT_HEALTH_RECORD_DOWNLOAD_REQUEST =
  'PATIENT_HEALTH_RECORD_DOWNLOAD_REQUEST'
export const PATIENT_HEALTH_RECORD_DOWNLOAD_SUCCESS =
  'PATIENT_HEALTH_RECORD_DOWNLOAD_SUCCESS'
export const PATIENT_HEALTH_RECORD_DOWNLOAD_FAILURE =
  'PATIENT_HEALTH_RECORD_DOWNLOAD_FAILURE'

export function patientHealthRecordDownloadReset() {
  return { type: PATIENT_HEALTH_RECORD_DOWNLOAD_RESET }
}

export function patientHealthRecordDownloadRequest(data) {
  return { type: PATIENT_HEALTH_RECORD_DOWNLOAD_REQUEST, data }
}

export function patientHealthRecordDownloadSuccess(data) {
  return {
    type: PATIENT_HEALTH_RECORD_DOWNLOAD_SUCCESS,
    data,
  }
}

export function patientHealthRecordDownloadError(error) {
  return {
    type: PATIENT_HEALTH_RECORD_DOWNLOAD_FAILURE,
    error,
  }
}

export const PATIENT_HEALTH_RECORD_CREATE_REQUEST =
  'PATIENT_HEALTH_RECORD_CREATE_REQUEST'
export const PATIENT_HEALTH_RECORD_CREATE_SUCCESS =
  'PATIENT_HEALTH_RECORD_CREATE_SUCCESS'
export const PATIENT_HEALTH_RECORD_CREATE_FAILURE =
  'PATIENT_HEALTH_RECORD_CREATE_FAILURE'

export function patientHealthRecordCreateRequest(data) {
  return { type: PATIENT_HEALTH_RECORD_CREATE_REQUEST, data }
}

export function patientHealthRecordCreateSuccess(data) {
  return {
    type: PATIENT_HEALTH_RECORD_CREATE_SUCCESS,
    data,
  }
}

export function patientHealthRecordCreateError(error) {
  return {
    type: PATIENT_HEALTH_RECORD_CREATE_FAILURE,
    error,
  }
}

export const COVERAGE_GET_REQUEST = 'COVERAGE_GET_REQUEST'
export const COVERAGE_GET_SUCCESS = 'COVERAGE_GET_SUCCESS'
export const COVERAGE_GET_FAILURE = 'COVERAGE_GET_FAILURE'

export function coveragesGetRequest(data) {
  return { type: COVERAGE_GET_REQUEST, data }
}

export function coveragesGetSuccess(data) {
  return {
    type: COVERAGE_GET_SUCCESS,
    data,
  }
}

export function coveragesGetError(error) {
  return {
    type: COVERAGE_GET_FAILURE,
    error,
  }
}

export const COUNTRY_PUT_RESET = 'COUNTRY_PUT_RESET'
export const COUNTRY_PUT_REQUEST = 'COUNTRY_PUT_REQUEST'
export const COUNTRY_PUT_SUCCESS = 'COUNTRY_PUT_SUCCESS'
export const COUNTRY_PUT_FAILURE = 'COUNTRY_PUT_FAILURE'

export function countryPutReset() {
  return { type: COUNTRY_PUT_RESET }
}

export function countryPutRequest(data) {
  return { type: COUNTRY_PUT_REQUEST, data }
}

export function countryPutSuccess(data) {
  return {
    type: COUNTRY_PUT_SUCCESS,
    data,
  }
}

export function countryPutError(error) {
  return {
    type: COUNTRY_PUT_FAILURE,
    error,
  }
}

export const COMPANIES_GET_REQUEST = 'COMPANIES_GET_REQUEST'
export const COMPANIES_GET_SUCCESS = 'COMPANIES_GET_SUCCESS'
export const COMPANIES_GET_FAILURE = 'COMPANIES_GET_FAILURE'

export function companiesGetRequest(data) {
  return { type: COMPANIES_GET_REQUEST, data }
}

export function companiesGetSuccess(data) {
  return {
    type: COMPANIES_GET_SUCCESS,
    data,
  }
}

export function companiesGetError(error) {
  return {
    type: COMPANIES_GET_FAILURE,
    error,
  }
}

export const COMPANY_CREATE_REQUEST = 'COMPANY_CREATE_REQUEST'
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS'
export const COMPANY_CREATE_FAILURE = 'COMPANY_CREATE_FAILURE'

export function companyCreateRequest(data) {
  return { type: COMPANY_CREATE_REQUEST, data }
}

export function companyCreateSuccess(data) {
  return {
    type: COMPANY_CREATE_SUCCESS,
    data,
  }
}

export function companyCreateError(error) {
  return {
    type: COMPANY_CREATE_FAILURE,
    error,
  }
}

export const COMPANY_GET_REQUEST = 'COMPANY_GET_REQUEST'
export const COMPANY_GET_SUCCESS = 'COMPANY_GET_SUCCESS'
export const COMPANY_GET_FAILURE = 'COMPANY_GET_FAILURE'

export function companyGetRequest(data) {
  return { type: COMPANY_GET_REQUEST, data }
}

export function companyGetSuccess(data) {
  return {
    type: COMPANY_GET_SUCCESS,
    data,
  }
}

export function companyGetError(error) {
  return {
    type: COMPANY_GET_FAILURE,
    error,
  }
}

export const DEALS_GET_REQUEST = 'DEALS_GET_REQUEST'
export const DEALS_GET_SUCCESS = 'DEALS_GET_SUCCESS'
export const DEALS_GET_FAILURE = 'DEALS_GET_FAILURE'

export function dealsGetRequest(data) {
  return { type: DEALS_GET_REQUEST, data }
}

export function dealsGetSuccess(data) {
  return {
    type: DEALS_GET_SUCCESS,
    data,
  }
}

export function dealsGetError(error) {
  return {
    type: DEALS_GET_FAILURE,
    error,
  }
}

export const DEAL_GET_REQUEST = 'DEAL_GET_REQUEST'
export const DEAL_GET_SUCCESS = 'DEAL_GET_SUCCESS'
export const DEAL_GET_FAILURE = 'DEAL_GET_FAILURE'

export function dealGetRequest(data) {
  return { type: DEAL_GET_REQUEST, data }
}

export function dealGetSuccess(data) {
  return {
    type: DEAL_GET_SUCCESS,
    data,
  }
}

export function dealGetError(error) {
  return {
    type: DEAL_GET_FAILURE,
    error,
  }
}

export const DEAL_CREATE_REQUEST = 'DEAL_CREATE_REQUEST'
export const DEAL_CREATE_SUCCESS = 'DEAL_CREATE_SUCCESS'
export const DEAL_CREATE_FAILURE = 'DEAL_CREATE_FAILURE'

export function dealCreateRequest(data) {
  return { type: DEAL_CREATE_REQUEST, data }
}

export function dealCreateSuccess(data) {
  return {
    type: DEAL_CREATE_SUCCESS,
    data,
  }
}

export function dealCreateError(error) {
  return {
    type: DEAL_CREATE_FAILURE,
    error,
  }
}

export const DEAL_EDIT_REQUEST = 'DEAL_EDIT_REQUEST'
export const DEAL_EDIT_SUCCESS = 'DEAL_EDIT_SUCCESS'
export const DEAL_EDIT_FAILURE = 'DEAL_EDIT_FAILURE'

export function dealEditRequest(data) {
  return { type: DEAL_EDIT_REQUEST, data }
}

export function dealEditSuccess(data) {
  return {
    type: DEAL_EDIT_SUCCESS,
    data,
  }
}

export function dealEditError(error) {
  return {
    type: DEAL_EDIT_FAILURE,
    error,
  }
}

export const REPORTS_GET_REQUEST = 'REPORTS_GET_REQUEST'
export const REPORTS_GET_SUCCESS = 'REPORTS_GET_SUCCESS'
export const REPORTS_GET_FAILURE = 'REPORTS_GET_FAILURE'

export function reportsGetRequest(data) {
  return { type: REPORTS_GET_REQUEST, data }
}

export function reportsGetSuccess(data) {
  return {
    type: REPORTS_GET_SUCCESS,
    data,
  }
}

export function reportsGetError(error) {
  return {
    type: REPORTS_GET_FAILURE,
    error,
  }
}

export const REPORTS_RESULTS_REQUEST = 'REPORTS_RESULTS_REQUEST'
export const REPORTS_RESULTS_SUCCESS = 'REPORTS_RESULTS_SUCCESS'
export const REPORTS_RESULTS_FAILURE = 'REPORTS_RESULTS_FAILURE'

export function reportResultsRequest(data) {
  return { type: REPORTS_RESULTS_REQUEST, data }
}

export function reportResultsSuccess(data) {
  return {
    type: REPORTS_RESULTS_SUCCESS,
    data,
  }
}

export function reportResultsError(error) {
  return {
    type: REPORTS_RESULTS_FAILURE,
    error,
  }
}
