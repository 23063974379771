import * as ActionTypes from '../actions'
import * as R from 'ramda'

const initialState = {
  uploadResponse: null,
  loading: false,
  error: null,
  files: {},
  downloadFile: null,
}

export function filesReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FILE_UPLOAD_RESET:
      return { ...state, error: null, loading: false, uploadResponse: null }
    case ActionTypes.FILE_UPLOAD_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        uploadResponse: null,
      }
    case ActionTypes.FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadResponse: action.data,
      }
    case ActionTypes.FILE_UPLOAD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case ActionTypes.FILE_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        uploadResponse: null,
      }
    case ActionTypes.FILE_DOWNLOAD_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
        downloadFile: null,
      }
    case ActionTypes.FILE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        files: { ...state.files, [action.data.Key]: action.data },
      }
    case ActionTypes.FILE_DOWNLOAD_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        downloadFile: action.data,
      }
    case ActionTypes.FILE_DOWNLOAD_GET_FAILURE:
    case ActionTypes.FILE_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case ActionTypes.MULTIPLE_FILE_GET_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case ActionTypes.MULTIPLE_FILE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        files: R.reduce(
          (acc, val) => ({ ...acc, [val.Key]: val }),
          state.files,
          action.data
        ),
      }
    case ActionTypes.MULTIPLE_FILE_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export function getDownloadFile(state) {
  return state.filesReducer.downloadFile
}

export function getFiles(state) {
  return state.filesReducer.files
}

export function getUploadResponse(state) {
  return state.filesReducer.uploadResponse
}

export function getLoading(state) {
  return state.filesReducer.loading
}

export function getError(state) {
  return state.filesReducer.error
}
