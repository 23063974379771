import React, { useEffect } from 'react'
import ReactPhoneInput from 'react-phone-input-material-ui'
import { Typography, TextField, withStyles } from '@material-ui/core'
import 'react-phone-input-material-ui/lib/style.css'

const styles = (theme) => ({
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
  errorField: {
    color: '#f44336',
    fontSize: '0.75rem',
    marginTop: '-5px',
  },
})

const PhoneField = React.forwardRef((props, ref) => {
  const {
    value,
    defaultCountry,
    onChange,
    classes,
    onCountrySelected,
    error,
    helperText,
  } = props

  useEffect(() => {
    if (onCountrySelected) onCountrySelected(ref.current.getCountryData())
  }, [ref.current?.state?.selectedCountry])

  return (
    <React.Fragment>
      <ReactPhoneInput
        ref={ref}
        {...props}
        value={value}
        defaultCountry={defaultCountry || 'ar'}
        onChange={(v) => {
          if (onCountrySelected) onCountrySelected(ref.current.getCountryData())
          onChange(v)
        }}
        inputClass={classes.field}
        dropdownClass={classes.countryList}
        component={TextField}
        preferredCountries={['ar', 'uy', 'py', 'us']}
        enableLongNumbers
      />
      {error && (
        <Typography className={classes.errorField}>{helperText}</Typography>
      )}
    </React.Fragment>
  )
})

export default withStyles(styles)(PhoneField)
