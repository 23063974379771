import { CompanyTypes } from './constants'

export default function getCompanyTypeLabel(companyType) {
  switch (companyType) {
    case CompanyTypes.CLIENT:
      return 'Cliente'
    case CompanyTypes.RESELLER:
      return 'Reseller'
    case CompanyTypes.PARTNER:
      return 'Partner'
    default: 
      return companyType
  }
}
