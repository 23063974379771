import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
  ButtonBase,
  Avatar,
  FormControlLabel,
  Switch,
  Paper,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import NightsStayIcon from '@material-ui/icons/NightsStay'
import CloudOffIcon from '@material-ui/icons/CloudOff'
import { useHistory, Link } from 'react-router-dom'

import { getERStatus } from 'common/utils/pubsub'
import {
  getCurrentProvider,
  getLoading,
  getSuccess,
} from 'common/reducers/providers'
import { getFiles } from 'common/reducers/files'
import { getCompany } from 'common/reducers/companies'
import { ER_Header, ER_Modes } from 'common/utils/constants'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import { grey } from '@material-ui/core/colors'

import { makeStyles, withStyles } from '@material-ui/core/styles'

import logo from 'assets/TeleMeditar_Logo_SM.png'
import dayjs from 'dayjs'
import {
  providerAttachRequest,
  providerSubscribeRequest,
  providerUnsubscribeRequest,
} from 'common/actions'
import { getClientResponse } from 'common/reducers/pubsub'
import { NavigateBeforeSharp } from '@material-ui/icons'

const GreySwitch = withStyles({
  switchBase: {
    color: grey[300],
    '&$checked': {
      color: grey[500],
    },
    '&$checked + $track': {
      backgroundColor: grey[500],
    },
  },
  checked: {},
  track: {},
})(Switch)

const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  btnLogo: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  btnText: {
    textAlign: 'right',
    color: theme.palette.primary.main,
  },
  logo: {
    width: '10rem',
  },
  er: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0),
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  formHelperText: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
    marginTop: '0px',
  },
  marginRight: {
    marginRight: '4px',
  },
}))

const Header = ({
  provider,
  files,
  company,
  onSubscribe,
  onUnsubscribe,
  clientResponse,
  loading,
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const [snoozeTimeLeft, setSnoozeTimeLeft] = useState(0)
  const open = Boolean(anchorEl)
  const [erMode, setErMode] = useState({ mode: ER_Modes.DISABLED })

  useEffect(() => {
    if (!clientResponse) return
    if (
      R.includes(clientResponse.header, [ER_Header.DISABLED, ER_Header.ENABLED]) // we only care about enabled or disabled statuses here
    )
      setErMode(getERStatus(clientResponse))
  }, [clientResponse])

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (erMode.mode != ER_Modes.SNOOZED) return

    const interval = setInterval(() => {
      const secondsLeft = dayjs(erMode.until * 1000).diff(dayjs(), 'seconds')
      if (secondsLeft <= 0) {
        setSnoozeTimeLeft('00:00')
        setErMode({
          mode: ER_Modes.ENABLED,
        })
        clearInterval(interval)
        return
      }
      setSnoozeTimeLeft(
        `${Math.floor(secondsLeft / 60)}:${(secondsLeft % 60)
          .toString()
          .padStart(2, '0')}`
      )
    }, 1000)
    return () => clearInterval(interval)
  }, [erMode])

  const SwitchComponent = (props) =>
    R.includes(erMode.mode, [ER_Modes.ENABLED, ER_Modes.DISABLED]) ? (
      <Switch {...props} />
    ) : (
      <GreySwitch {...props} />
    )

  return (
    <AppBar position='static'>
      <Toolbar>
        <Box className={classes.flexGrow}>
          <img src={logo} className={classes.logo} />

          {provider && provider.wizardComplete && (
            <Link className={classes.link} to='/appointments'>
              Agenda
            </Link>
          )}
        </Box>
        {provider && provider.wizardComplete && (
          <Paper elevation={3} className={classes.er}>
            {loading ? (
              <>
                <CircularProgress size='2rem' />
                &nbsp;Cargando…&nbsp;&nbsp;
              </>
            ) : (
              <FormControlLabel
                control={
                  <SwitchComponent
                    checked={R.includes(erMode.mode, [
                      ER_Modes.ENABLED,
                      ER_Modes.SNOOZED,
                    ])}
                    onChange={() => {
                      if (erMode.mode == ER_Modes.DISABLED) onSubscribe()
                      else if (erMode.mode == ER_Modes.ENABLED) onUnsubscribe()

                      //setErMode({
                      //  mode: R.equals(erMode.mode, ER_Modes.ENABLED)
                      //    ? ER_Modes.DISABLED
                      //    : ER_Modes.ENABLED,
                      //})
                    }}
                    name='ER'
                    color='primary'
                  />
                }
                label={
                  <Box display='flex' alignItems='center'>
                    {R.equals(erMode.mode, ER_Modes.DISABLED_IDLE) && (
                      <NightsStayIcon
                        className={classes.marginRight}
                        fontSize='small'
                      />
                    )}
                    {R.equals(erMode.mode, ER_Modes.DISABLED_CONN) && (
                      <CloudOffIcon
                        className={classes.marginRight}
                        fontSize='small'
                      />
                    )}
                    {!R.includes(erMode.mode, [
                      ER_Modes.DISABLED_IDLE,
                      ER_Modes.DISABLED_CONN,
                    ]) && (
                      <LocalHospitalIcon
                        fontSize='small'
                        className={classes.marginRight}
                      />
                    )}
                    Guardia
                  </Box>
                }
              />
            )}
            {R.equals(erMode.mode, ER_Modes.SNOOZED) &&
              dayjs(erMode.until * 1000).diff(dayjs(), 'seconds') > 0 && (
                <FormHelperText className={classes.formHelperText}>
                  <AccessTimeIcon
                    className={classes.marginRight}
                    fontSize='small'
                  />{' '}
                  Esperando {snoozeTimeLeft}
                </FormHelperText>
              )}
          </Paper>
        )}
        {provider && (
          <Box>
            <ButtonBase onClick={handleMenu} color='inherit'>
              <Box className={classes.btnText}>
                <Typography variant='body2'>
                  {provider.title} {provider.firstName} {provider.lastName}
                </Typography>
                <Typography variant='button'>{company?.name}</Typography>
              </Box>
              <Box className={classes.btnLogo}>
                <Avatar src={files[provider.profilePicture?.key]?.Image} />
              </Box>
            </ButtonBase>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              {provider && provider.wizardComplete && (
                <MenuItem onClick={() => history.push('/schedules')}>
                  Mis Horarios
                </MenuItem>
              )}
              {provider && provider.wizardComplete && (
                <MenuItem onClick={() => history.push('/configuration')}>
                  Configuración
                </MenuItem>
              )}
              <MenuItem onClick={() => (window.location.href = '/logout')}>
                Cerrar Sesión
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => {
  return {
    provider: getCurrentProvider(state),
    files: getFiles(state),
    company: getCompany(state),
    success: getSuccess(state),
    clientResponse: getClientResponse(state),
    loading: getLoading(state),
  }
}

export default connect(mapStateToProps, {
  onAttach: providerAttachRequest,
  onSubscribe: providerSubscribeRequest,
  onUnsubscribe: providerUnsubscribeRequest,
})(Header)
