import React, { useEffect } from 'react'
import * as yup from 'yup'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  TextField,
  Grid,
  Link,
  Typography,
  Button,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Email } from '@material-ui/icons'

import {
  BackgroundImage,
  IconTextField,
  LoadingButton,
} from 'common/components'

import { forgotPassword, forgotPasswordReset } from 'common/actions'
import {
  getUser,
  getError,
  getLoading,
  getForgotPwSuccess,
} from 'common/reducers/user'

import background from 'assets/94772307-doctor-in-hospital-background-with-copy-space-healthcare-and-medical-concept-.png'
import logo from 'assets/TeleMeditar_Logo.png'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}))

const ForgotPassword = ({
  error,
  loading,
  onForgotPassword,
  initialize,
  success,
}) => {
  const classes = useStyles()

  const schema = yup.object().shape({
    email: yup
      .string()
      .required('Este campo es requerido')
      .email('Por favor ingrese un email válido')
      .nullable(),
  })

  const { control, errors, handleSubmit, triggerValidation } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = (data) => {
    onForgotPassword(data)
  }
  useEffect(() => {
    initialize()
  }, [])

  const getErrorMessage = () => {
    switch (error.code) {
      case 'UserNotConfirmedException':
        return 'El email no está confirmado. Por favor revise su bandeja de correo.'
      case 'InvalidParameterException':
        return 'No se puede resetear la contraseña de una dirección de email que no está confirmada. Por favor revise su bandeja de correo. '
      case 'UserNotFoundException':
        return 'La dirección de email ingresada no existe.'
      default:
        return `Hubo un error desconocido al resetear la contraseña. Detalles: ${error.message}`
    }
  }

  return (
    <BackgroundImage image={background}>
      <Grid container className={classes.container}>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          <Card className={classes.card}>
            <CardMedia className={classes.logo} image={logo} />
            <CardContent className={classes.cardContent}>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Box textAlign='center'>
                  <Typography variant='h6'>Olvidé mi contraseña</Typography>
                  <Typography>
                    Por favor ingrese su dirección de email y le enviaremos un
                    enlace
                  </Typography>
                </Box>
                <Box mt={2}>
                  <IconTextField
                    icon={<Email />}
                    control={control}
                    name='email'
                    errors={errors}
                    label='Dirección de Email'
                    onBlur={() => triggerValidation('email')}
                    fullWidth
                  />
                </Box>
                {error && (
                  <Box mt={3}>
                    <Alert severity='error'>
                      <AlertTitle>Error al resetear la contraseña</AlertTitle>
                      {getErrorMessage()}
                    </Alert>
                  </Box>
                )}
                {success && (
                  <Box mt={3}>
                    <Alert severity='success'>
                      <AlertTitle>Contraseña reseteada</AlertTitle>
                      Por favor revise su bandeja de correo.
                    </Alert>
                  </Box>
                )}
                <Box mt={4} textAlign='center'>
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    loading={loading}
                  >
                    ENVIAR
                  </LoadingButton>
                </Box>
                <Box mt={3} textAlign='center'>
                  <Link href='login'>Volver</Link>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
    </BackgroundImage>
  )
}

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    user: getUser(state),
    loading: getLoading(state),
    success: getForgotPwSuccess(state),
  }
}

export default connect(mapStateToProps, {
  onForgotPassword: forgotPassword,
  initialize: forgotPasswordReset,
})(ForgotPassword)
